import React from 'react'
import _mapValues from 'lodash/mapValues'
import _keyBy from 'lodash/keyBy'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import gql from "graphql-tag"

import { loginUrl } from '@/utils/url'
import { PASSWORD_REQUIREMENTS } from '@/utils/validation'
import { SpinnerButton } from '@/components/common/button'
import { TextField } from '@/components/common/formik'
import { useGlobalContext } from '@/components/context'
import formStyle from '@sass/common/form.module.sass'
import { useUpdatePasswordMutation } from './__gen/update-password'


gql`
	mutation updatePassword(
		$id: ID!,
		$currentPassword: String,
		$password: String!
	) {
		user_Update(input: {
			id: $id,
			currentPassword: $currentPassword
			password: $password
		}) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`

type ValueType = { password: string, passwordConfirm: string }

export const UpdatePassword = () => {
	const { user } = useGlobalContext()
	const [mutate, {}] = useUpdatePasswordMutation()
	return (	
		<Formik<ValueType>
			initialValues={{
				password: '',
				passwordConfirm: '',
			}}
			validationSchema={Yup.object().shape({
				password: PASSWORD_REQUIREMENTS.required('Required'),
				passwordConfirm: Yup.string()
					.oneOf([Yup.ref('password'), null], 'Passwords must match').required('Required'),
			})}
			onSubmit={async (values, actions) => {
				const r = await mutate({variables: {id: user.id, ...values}})
				if(!r || !r.data || !r.data.user_Update) {
					alert('There was an issue saving data')
					return
				}
				else if(r.data.user_Update.errors) {
					const errors = _mapValues(_keyBy(r.data.user_Update.errors, 'field'), (a) => a?.messages.join('. ') || "Error")
					actions.setErrors(errors)
				}
				else {
					window.location.replace(loginUrl())
				}

			}}
		>
			{props => {
				const { isSubmitting, handleSubmit } = props
				return (
					<form onSubmit={handleSubmit} className={formStyle.commonForm}>
						<h3 className={formStyle.commonFormTitle}>Reset Password</h3>
						{user.usingTemporaryPassword && 
							<div>
								You are currently using a temporary password. Please change it to something more secure.
							</div>
						}

						{[
							...(user.usingTemporaryPassword ? [] : [['currentPassword', 'Current Password']]),
							['password', 'Password'],
							['passwordConfirm', 'Password (again)'],
						].map(([name, placeholder]) => {
							return <Field
								key={name}
								name={name}
								placeholder={placeholder}
								component={TextField}
								type='password'
							/>
						})}
						
						<div className={formStyle.formButton}>
							<SpinnerButton loading={isSubmitting} type="submit">
								Save
							</SpinnerButton>
						</div>
					</form>
				)
			}}
		</Formik>
	)
}
