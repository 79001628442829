import React, { useState } from 'react'
import { Popover, Checkbox } from 'antd'
import c from 'classnames'
import { Field } from 'formik'
import { MoveDatesType } from '@/__gen/types'
import { SelectField, TextField } from '@/components/common/formik'
import generalStyle from '@sass/common/general.module.sass'
import style from './inner.module.sass'


export type PassthroughProps = {
	moveDatesTo?: number
	moveDatesType?: {value: MoveDatesType, label: string}
	updateValue: (n: number) => void
	setFieldValue: (s: string, n: number| null) => void
}
type Props = {
	fields: {id: string, label: string}[]
} & PassthroughProps

export const MOVE_DATE_OPTIONS = [
	{value: MoveDatesType.YearSpecific, label: 'Specific Year'},
	{value: MoveDatesType.YearDelta, label: 'Move year forward'},
]

export const MoveDateOnCopyInputInner = (props: Props) => {
	const [show, setShow] = useState(false)
	return (
		<div>
			<br/>
			<Popover
				title={"This will apply to the following fields (if set)"}
				content={
					<ul>
						{props.fields.map(f => (
							<li key={f.id}>{f.label}</li>
						))}
					</ul>
				}
			>
				<Checkbox
					checked={show}
					onChange={(e) => {
						setShow(e.target.checked)
						props.setFieldValue("moveDatesTo", null)
					}}
				>
					Change the year of selected date fields 
				</Checkbox>
			</Popover>

			{ show && 
				<div className={style.row}>
					<Field
						className={style.select}
						name='moveDatesType'
						placeholder="Movement Type"
						component={SelectField}
						options={MOVE_DATE_OPTIONS}
					/>
					<Field
						className={c(style.numberInput, generalStyle.numberInput)}
						name="moveDatesTo"
						placeholder={props.moveDatesType?.value === MoveDatesType.YearDelta ? "e.g. 1" : "YYYY"}
						component={TextField}
						type="number"
					/>
				</div>
			}
		</div>
	)
}