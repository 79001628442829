import React from 'react'
import gql from 'graphql-tag'
import c from 'classnames'

import Placeholder from '@/components/common/placeholder'
import generalStyle from '@sass/common/general.module.sass'
import { useFormContext } from '@/state/main-form/reducer'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import commonStyle from '../common.module.sass'
import { useCreatePublicFormMutation, usePublicFormsForRecordQuery } from './__gen'
import ExternalLink, { EXTERNAL_LINK_FRAGMENT } from './external-link'


type Props = {
	readOnly: boolean
}

gql`
	query publicFormsForRecord($record: ID!, $form: ID!) {
		publicForm_List(record: $record, form: $form) {
			objects {
				...ExternalLink
			}
		}
	}
	${EXTERNAL_LINK_FRAGMENT}
`

gql`
	mutation createPublicForm($record: ID!, $form: ID!) {
		publicForm_Update(input: {record: $record, form: $form}) {
			object {
				...ExternalLink
			}
		}
	}
	${EXTERNAL_LINK_FRAGMENT}
`

const CreatePublicForm = ({ readOnly }: Props) => {
	const formContext = useFormContext()
	const variables = { variables: { record: formContext.record.id, form: formContext.form.id }}
	const { data, loading, refetch } = usePublicFormsForRecordQuery(variables)
	const [createPublicForm, { loading: mutationLoading }] = useCreatePublicFormMutation()

	const objects = data?.publicForm_List?.objects

	if (loading || mutationLoading) {
		return <div className={commonStyle.sectionTitle}><Placeholder /></div>
	} else if (objects === undefined) {
		return <div className={generalStyle.error}>error</div>
	}

	if(readOnly) return (
		<div className="public-link-container">
			<div className={generalStyle.muted}>Share links deactivated when archived</div>
		</div>
	)

	const externalLinks = objects.filter(externalLink => externalLink.active)

	return ( 
		formContext.form.allowPublicLinks ?
			<div>
				<div className={commonStyle.sectionTitle}>
					{formContext.form.publicLinkIsReadOnly && <>Read Only&nbsp;</>}Share
				</div>

				{ externalLinks && externalLinks.length == 0 &&
					<button
						className={c(iconStyle.world, buttonStyle.greySolid)}
						onClick={() => {
							createPublicForm(variables).then(r => {
								refetch()
							})
						}}
					>
						Create link
					</button>
				}

				{ externalLinks.map( externalLink => (
					<ExternalLink
						key={externalLink.id}
						externalLink={externalLink}
					/>
				))}
			</div>
		:
			<div/>
	)
}


export default CreatePublicForm