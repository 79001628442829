import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { UserEssentialsFragmentDoc } from '../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SwitchOrgMutationVariables = Types.Exact<{
  orgId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type SwitchOrgMutation = { __typename: 'PharosMutations', user_SwitchOrgAdmin: { __typename: 'SwitchOrgAdmin', user: { __typename: 'UserType', id: string, adminOfSuite: boolean, email: string, firstName: string, howYouHeardAboutUs: string | null, isActive: boolean, isAdmin: boolean, lastName: string, recordCount: number, seenWelcomeMessage: boolean, topLevelManager: boolean, usingTemporaryPassword: boolean, organisation: { __typename: 'OrganisationType', id: string, csvDownloadEnabled: boolean, logo: any | null, name: string, showTeamFiles: boolean, subdomain: string | null, suite: { __typename: 'SuiteType', id: string, logo: any | null, suiteAdminDashboardTitle: string | null, welcomeMessageHtml: string | null, moduleWord: string, moduleSetItems: Array<{ __typename: 'SuiteModuleType', id: string, rank: number, hiddenWhenEmpty: boolean, module: { __typename: 'ModuleType', id: string, addTeamPhrase: string | null, name: string, icon: string | null, singleton: boolean, descriptionHtml: string, showActiveLinksOnDashboard: boolean, showActionDashboard: boolean, allowRecordTemplateChange: boolean, allowPositionTextChange: boolean, recordFilesEnabled: boolean, recordImages: boolean, recordWord: string, showCalendar: boolean, showMap: boolean, positionWord: string, teamWord: string, teams: Array<{ __typename: 'TeamType', id: string, publicRecordCreateCode: string, logo: any | null, reminderFrequencyDays: number, name: string }>, publicRecordCreateLinks: Array<{ __typename: 'PublicRecordCreateLinkType', id: string, title: string, code: string }> } }>, adviceSection: { __typename: 'AdviceSectionType', id: string, title: string } | null } } } | null } | null };


export const SwitchOrgDocument = gql`
    mutation switchOrg($orgId: ID!, $teamId: ID) {
  user_SwitchOrgAdmin(organisationId: $orgId, teamId: $teamId) {
    user {
      ...UserEssentials
    }
  }
}
    ${UserEssentialsFragmentDoc}`;
export type SwitchOrgMutationFn = Apollo.MutationFunction<SwitchOrgMutation, SwitchOrgMutationVariables>;

/**
 * __useSwitchOrgMutation__
 *
 * To run a mutation, you first call `useSwitchOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSwitchOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [switchOrgMutation, { data, loading, error }] = useSwitchOrgMutation({
 *   variables: {
 *      orgId: // value for 'orgId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSwitchOrgMutation(baseOptions?: Apollo.MutationHookOptions<SwitchOrgMutation, SwitchOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SwitchOrgMutation, SwitchOrgMutationVariables>(SwitchOrgDocument, options);
      }
export type SwitchOrgMutationHookResult = ReturnType<typeof useSwitchOrgMutation>;
export type SwitchOrgMutationResult = Apollo.MutationResult<SwitchOrgMutation>;
export type SwitchOrgMutationOptions = Apollo.BaseMutationOptions<SwitchOrgMutation, SwitchOrgMutationVariables>;