import gql from "graphql-tag";


export const ANALYTICS_LINK_FRAGMENT = gql`
	fragment MetricFormLinkEssentials on MetricFormLink {
		formId
		formTitle
		recordId
		recordName
		fieldId
		fieldTitle
		organisationName
		teamName
	}
`

export const RECORD_ESSENTIALS_FRAGMENT = gql`
	fragment RecordEssentials on RecordType {
		id
		archived
		image
		name
		position
		team {
			id
		}
	}
`

export const USER_ESSENTIALS_FRAGMENT = gql`
	fragment UserEssentials on UserType {
		id
		adminOfSuite
		email
		firstName
		howYouHeardAboutUs
		isActive
		isAdmin
		lastName
		recordCount
		seenWelcomeMessage
		topLevelManager
		usingTemporaryPassword
		organisation {
			id
			csvDownloadEnabled
			logo
			name
			showTeamFiles
			subdomain
			suite {
				id
				logo
				suiteAdminDashboardTitle
				welcomeMessageHtml
				moduleSetItems(orderBy: [{field: rank}]) {
					id
					rank
					hiddenWhenEmpty
					module {
						id
						addTeamPhrase
						name
						icon
						singleton
						descriptionHtml
						showActiveLinksOnDashboard
						showActionDashboard
						allowRecordTemplateChange
						allowPositionTextChange
						recordFilesEnabled
						recordImages
						recordWord
						showCalendar
						showMap
						positionWord
						teamWord
						teams(orderBy: [{field: name}]) {
							id
							publicRecordCreateCode
							logo
							reminderFrequencyDays
							name
						}
						publicRecordCreateLinks {
							id
							title
							code
						}
					}
				}
				moduleWord
				adviceSection {
					id
					title
				}
			}
		}
	}
`