import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordListQueryVariables = Types.Exact<{
  offset: Types.Scalars['Int']['input'];
  search: Types.Scalars['String']['input'];
  module: Types.Scalars['ID']['input'];
  team?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type RecordListQuery = { __typename: 'PharosQuery', record_List: { __typename: 'RecordTypeListBase', objects: Array<{ __typename: 'RecordType', id: string, name: string, position: string, archived: boolean, team: { __typename: 'TeamType', id: string, name: string } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const RecordListDocument = gql`
    query recordList($offset: Int!, $search: String!, $module: ID!, $team: ID) {
  record_List(
    limit: 20
    offset: $offset
    orderBy: [{field: archived}, {field: name, modifiers: [CASE_INSENSITIVE]}, {field: position, modifiers: [CASE_INSENSITIVE]}]
    search: $search
    module: $module
    team: $team
  ) @connection(key: "recordList", filter: [$search]) {
    objects {
      id
      name
      position
      archived
      team {
        id
        name
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useRecordListQuery__
 *
 * To run a query within a React component, call `useRecordListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      module: // value for 'module'
 *      team: // value for 'team'
 *   },
 * });
 */
export function useRecordListQuery(baseOptions: Apollo.QueryHookOptions<RecordListQuery, RecordListQueryVariables> & ({ variables: RecordListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordListQuery, RecordListQueryVariables>(RecordListDocument, options);
      }
export function useRecordListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordListQuery, RecordListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordListQuery, RecordListQueryVariables>(RecordListDocument, options);
        }
export function useRecordListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecordListQuery, RecordListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordListQuery, RecordListQueryVariables>(RecordListDocument, options);
        }
export type RecordListQueryHookResult = ReturnType<typeof useRecordListQuery>;
export type RecordListLazyQueryHookResult = ReturnType<typeof useRecordListLazyQuery>;
export type RecordListSuspenseQueryHookResult = ReturnType<typeof useRecordListSuspenseQuery>;
export type RecordListQueryResult = Apollo.QueryResult<RecordListQuery, RecordListQueryVariables>;