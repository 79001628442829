import React from 'react'

import { ANSWER_LOOKUP } from './answer-types/lookup'
import { useFormContext } from '@/state/main-form/reducer'
import { useAppSelector } from '@/utils/hooks'
import { TableFieldEnumType } from '@/state/main-form/types'
import { selectAnswer } from '@/state/answer/selectors'

type Props = {
	id: string
	tableFieldId: string | null
	fieldId: string
	fieldIsDisabled: boolean
}

export const Cell = ({ fieldId, tableFieldId, id, fieldIsDisabled }: Props) => {
	const formContext = useFormContext()
	const field = formContext.fields[fieldId]
	// not sure why we have to map here...
	const tableField = field.tableFields.map(a => a).find(tf => tf.id == tableFieldId) || null
	const fieldOrTableField = tableField || field
	const answer = useAppSelector(
		state => selectAnswer(state, id)
	)

	if (!answer) {
		return <div style={{ color: '#ddd' }}>No answers</div>
	}

	const Component = ANSWER_LOOKUP[fieldOrTableField.type as TableFieldEnumType].component

	const disabled = (
		fieldIsDisabled
		|| tableField?.disabledAlways
		|| tableField?.disableWhenPublic && formContext.publicForm?.code
		|| (tableField?.disableWhenSigning && !!field.disabledForFormActions.length)
	)
	return (
		<Component
			disabled={disabled}
			answer={answer}
			field={fieldOrTableField}
			publicFormCode={formContext.publicForm?.code}
			placeholder={fieldOrTableField.placeholder}
			selectOptions={fieldOrTableField.selectOptions}
		/>
	)
}