import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RemoveWebauthnCredentialsMutationVariables = Types.Exact<{
  credentialId: Types.Scalars['ID']['input'];
}>;


export type RemoveWebauthnCredentialsMutation = { __typename: 'PharosMutations', webauthnCredentials_Update: { __typename: 'webauthn_credentialsPayload', object: { __typename: 'WebauthnCredentialsType', id: string, active: boolean } | null } | null };


export const RemoveWebauthnCredentialsDocument = gql`
    mutation removeWebauthnCredentials($credentialId: ID!) {
  webauthnCredentials_Update(input: {id: $credentialId, active: false}) {
    object {
      id
      active
    }
  }
}
    `;
export type RemoveWebauthnCredentialsMutationFn = Apollo.MutationFunction<RemoveWebauthnCredentialsMutation, RemoveWebauthnCredentialsMutationVariables>;

/**
 * __useRemoveWebauthnCredentialsMutation__
 *
 * To run a mutation, you first call `useRemoveWebauthnCredentialsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWebauthnCredentialsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWebauthnCredentialsMutation, { data, loading, error }] = useRemoveWebauthnCredentialsMutation({
 *   variables: {
 *      credentialId: // value for 'credentialId'
 *   },
 * });
 */
export function useRemoveWebauthnCredentialsMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWebauthnCredentialsMutation, RemoveWebauthnCredentialsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWebauthnCredentialsMutation, RemoveWebauthnCredentialsMutationVariables>(RemoveWebauthnCredentialsDocument, options);
      }
export type RemoveWebauthnCredentialsMutationHookResult = ReturnType<typeof useRemoveWebauthnCredentialsMutation>;
export type RemoveWebauthnCredentialsMutationResult = Apollo.MutationResult<RemoveWebauthnCredentialsMutation>;
export type RemoveWebauthnCredentialsMutationOptions = Apollo.BaseMutationOptions<RemoveWebauthnCredentialsMutation, RemoveWebauthnCredentialsMutationVariables>;