import React, { useState } from 'react'
import { Modal } from 'antd'

import { ValueType } from 'react-select/src/types';
import Select from 'react-select'
import SimpleIconButton from '@/components/common/simple-icon-button'
import { useGlobalContext } from '@/components/context'
import iconStyle from '@sass/common/icon.module.sass'
import style from './index.module.sass'
import { publicCreateRecordQrCodeUrl, publicCreateRecordUrl } from '@/utils/url'

const QrCode = () => {
	const { currentModule, currentTeam } = useGlobalContext();
	const [show, setShow] = useState(false)
	const [team, setTeam] = useState(currentTeam || currentModule.teams[0])
	const title = `Public ${currentModule.recordWord} submission links`
	return (
		<>
			<SimpleIconButton
				onClick={() => setShow(true)}
				iconClass={iconStyle.qrCode}
				title={title}
			/>
			<Modal open={show}  footer={null} onCancel={() => setShow(false)} title={title}>
				{!currentTeam && 
					<div className={style.teamSelect}>
						<b>Select the team to display links for:</b>
						<Select
							value={team}
							onChange={(t: ValueType<typeof team>) => {
								if (t) {
									// this should be easy, on a plane so don't have internet to google...
									setTeam(t as typeof team)
								}
							}}
							options={currentModule.teams}
							formatOptionLabel={t => (
								<span>
									<b>{t.name}</b>
								</span>
							)}
							getOptionValue={t => t.id}

						/>
					</div>
				}
				{currentModule.publicRecordCreateLinks.map(l => {
					const path = publicCreateRecordUrl({linkCode: l.code, teamCode: team.publicRecordCreateCode})
					const url = `${window.location.origin}${path}`
					return (
						<div key={l.code} className={style.link}>
							<span><b>Name</b>: {l.title}</span>
							<span><b>Links to</b>: <a href={url}>{url}</a></span>
							<br/>
							<img className={style.qrCode} src={publicCreateRecordQrCodeUrl(url)} />
						</div>
					)
				})}
			</Modal>

		</>
	)
}

export default QrCode