import { FormAction } from "../edit-modal/once-off/state"

type Params = null | Pick<FormAction, "assignedUser" | "assignedAnonEmailAnswer" | "assignedAnonEmail" | "assignedAnonName">

export const getUserDetails = (formAction: Params) => {

	return !formAction ? {
		type: null,
		name: "Not selected",
		email: null,
	} : formAction.assignedUser ? {
		type: "User",
		name: formAction.assignedUser.firstName + " " + formAction.assignedUser.lastName,
		email: formAction.assignedUser.email,
	} : formAction.assignedAnonEmail ? {
		type: "Email",
		name: formAction.assignedAnonName,
		email: formAction.assignedAnonEmail,
	} : formAction.assignedAnonEmailAnswer ? {
		type: "Contact",
		name: (formAction.assignedAnonEmailAnswer.contactName),
		email: formAction.assignedAnonEmailAnswer.content,
	} : {
		type: "Error",
		name: "Error",
		email: "Error",
	}
}

