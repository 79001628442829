import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailOptionsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type EmailOptionsQuery = { __typename: 'PharosQuery', emailOption_List: Array<{ __typename: 'EmailOption', objId: string, email: string, sourceType: string, name: string, detail: string | null, recordWord: string | null }> | null };

export type SendEmailsMutationVariables = Types.Exact<{
  typeString: Types.Scalars['String']['input'];
  createdBy: Types.Scalars['ID']['input'];
  userSuppliedContext: Types.Scalars['String']['input'];
  sendToEmail: Types.Scalars['String']['input'];
  sendToContentType?: Types.InputMaybe<Types.Scalars['String']['input']>;
  sendToObjectId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  regardingContentType: Types.Scalars['String']['input'];
  regardingObjectId: Types.Scalars['ID']['input'];
}>;


export type SendEmailsMutation = { __typename: 'PharosMutations', email_Update: { __typename: 'emailPayload', object: { __typename: 'EmailType', id: string } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const EmailOptionsDocument = gql`
    query emailOptions {
  emailOption_List {
    objId
    email
    sourceType
    name
    detail
    recordWord
  }
}
    `;

/**
 * __useEmailOptionsQuery__
 *
 * To run a query within a React component, call `useEmailOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailOptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmailOptionsQuery(baseOptions?: Apollo.QueryHookOptions<EmailOptionsQuery, EmailOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailOptionsQuery, EmailOptionsQueryVariables>(EmailOptionsDocument, options);
      }
export function useEmailOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailOptionsQuery, EmailOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailOptionsQuery, EmailOptionsQueryVariables>(EmailOptionsDocument, options);
        }
export function useEmailOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailOptionsQuery, EmailOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailOptionsQuery, EmailOptionsQueryVariables>(EmailOptionsDocument, options);
        }
export type EmailOptionsQueryHookResult = ReturnType<typeof useEmailOptionsQuery>;
export type EmailOptionsLazyQueryHookResult = ReturnType<typeof useEmailOptionsLazyQuery>;
export type EmailOptionsSuspenseQueryHookResult = ReturnType<typeof useEmailOptionsSuspenseQuery>;
export type EmailOptionsQueryResult = Apollo.QueryResult<EmailOptionsQuery, EmailOptionsQueryVariables>;
export const SendEmailsDocument = gql`
    mutation sendEmails($typeString: String!, $createdBy: ID!, $userSuppliedContext: String!, $sendToEmail: String!, $sendToContentType: String, $sendToObjectId: ID, $regardingContentType: String!, $regardingObjectId: ID!) {
  email_Update(
    input: {typeString: $typeString, createdBy: $createdBy, userSuppliedContext: $userSuppliedContext, sendToEmail: $sendToEmail, sendToContentType: $sendToContentType, sendToObjectId: $sendToObjectId, regardingContentType: $regardingContentType, regardingObjectId: $regardingObjectId}
  ) {
    object {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type SendEmailsMutationFn = Apollo.MutationFunction<SendEmailsMutation, SendEmailsMutationVariables>;

/**
 * __useSendEmailsMutation__
 *
 * To run a mutation, you first call `useSendEmailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailsMutation, { data, loading, error }] = useSendEmailsMutation({
 *   variables: {
 *      typeString: // value for 'typeString'
 *      createdBy: // value for 'createdBy'
 *      userSuppliedContext: // value for 'userSuppliedContext'
 *      sendToEmail: // value for 'sendToEmail'
 *      sendToContentType: // value for 'sendToContentType'
 *      sendToObjectId: // value for 'sendToObjectId'
 *      regardingContentType: // value for 'regardingContentType'
 *      regardingObjectId: // value for 'regardingObjectId'
 *   },
 * });
 */
export function useSendEmailsMutation(baseOptions?: Apollo.MutationHookOptions<SendEmailsMutation, SendEmailsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendEmailsMutation, SendEmailsMutationVariables>(SendEmailsDocument, options);
      }
export type SendEmailsMutationHookResult = ReturnType<typeof useSendEmailsMutation>;
export type SendEmailsMutationResult = Apollo.MutationResult<SendEmailsMutation>;
export type SendEmailsMutationOptions = Apollo.BaseMutationOptions<SendEmailsMutation, SendEmailsMutationVariables>;