import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { FormActionFragmentDoc } from '../../common/__gen/query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetFormActionsMutationVariables = Types.Exact<{
  objects: Array<Types.Form_Action_BulkBulkInput>;
}>;


export type ResetFormActionsMutation = { __typename: 'PharosMutations', formAction_BulkUpdate: { __typename: 'form_action_bulkPayload', objects: Array<{ __typename: 'FormActionType', id: string, assignedAnonEmail: string, assignedAnonName: string, completedOn: string | null, deleted: boolean, isScheduled: boolean, link: string | null, priority: number, reminderNext: any | null, reminderLast: any | null, requestSignature: boolean, userSuppliedContext: string, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, form: { __typename: 'FormType', pdfGenerators: Array<{ __typename: 'PdfGeneratorType', id: string, name: string }> } }> | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const ResetFormActionsDocument = gql`
    mutation resetFormActions($objects: [form_action_bulkBulkInput!]!) {
  formAction_BulkUpdate(input: {objects: $objects}) {
    objects {
      ...FormAction
    }
    errors {
      field
      messages
    }
  }
}
    ${FormActionFragmentDoc}`;
export type ResetFormActionsMutationFn = Apollo.MutationFunction<ResetFormActionsMutation, ResetFormActionsMutationVariables>;

/**
 * __useResetFormActionsMutation__
 *
 * To run a mutation, you first call `useResetFormActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFormActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFormActionsMutation, { data, loading, error }] = useResetFormActionsMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useResetFormActionsMutation(baseOptions?: Apollo.MutationHookOptions<ResetFormActionsMutation, ResetFormActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetFormActionsMutation, ResetFormActionsMutationVariables>(ResetFormActionsDocument, options);
      }
export type ResetFormActionsMutationHookResult = ReturnType<typeof useResetFormActionsMutation>;
export type ResetFormActionsMutationResult = Apollo.MutationResult<ResetFormActionsMutation>;
export type ResetFormActionsMutationOptions = Apollo.BaseMutationOptions<ResetFormActionsMutation, ResetFormActionsMutationVariables>;