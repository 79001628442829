import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'
import { useRouter } from '@/components/context'
import style from './index.module.sass'

type Props = {
	tabs: {
		name: string
		url: string
		matchUrl?: string
	}[]
}

export const Tabs = (props: Props) => {
	const router = useRouter()
	if (props.tabs.length <= 1) {
		return null
	}
	return (
		<div className={style.tabContainer}>
			{props.tabs.map(t => (
				<Link
					key={t.name}
					className={c(
						style.tab,
						{[style.active]: router.location.pathname.includes(t.matchUrl || t.url)}
					)}
					to={t.url}
				>
					{t.name}
				</Link>
			))}
		</div>
	)
}

