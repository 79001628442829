import React from 'react'
import { useRouteMatch, Route } from 'react-router-dom'
import gql from 'graphql-tag'
import { Switch } from 'react-router'

import { Spinner } from '@/components/common/loading'
import { adviceItemUrl } from '@/utils/url'
import AdviceRightPanel from './right-panel'
import LeftPanel from './left-panel'
import style from './index.module.sass'
import { useAdviceSectionQuery, AdviceSectionQuery } from './__gen'


gql`
	query adviceSection {
		adviceSection_List(limit: 4) {
			objects {
				id
				categories(orderBy: {field: rank}) {
					id
					title
					rank
					subcategories(orderBy: {field: rank}) {
						id
						title
						content
						rank
					}
				}
			}
		}
	}
`

type Props = {
	sections: NonNullable<AdviceSectionQuery["adviceSection_List"]>["objects"]
}

const AdviceInner = (props: Props) => {
	const match = useRouteMatch({
		path: adviceItemUrl(':categoryId', ':subcategoryId'),
	}) || { params: {}}

	const currentCategoryProps = {
		// @ts-ignore this is an easy fix...
		currentCategoryId: match.params.categoryId || props.sections[0].categories[0].id,
		// @ts-ignore this is an easy fix...
		currentSubcategoryId: match.params.subcategoryId || props.sections[0].categories[0].subcategories[0].id,
	}

	return (
		<React.Fragment>
			<LeftPanel
				sections={props.sections}
				{...currentCategoryProps}
				
			/>
			<Switch>
				<Route
					path={adviceItemUrl(':categoryId', ':subcategoryId')}
					component={() => <AdviceRightPanel
						sections={props.sections}
						{...currentCategoryProps}
					/>}
				/>
				{/* hack to default home screen */}
				<Route
					path="*"
					component={() => <AdviceRightPanel
						sections={props.sections}
						{...currentCategoryProps}
					/>}
				/>
		
			</Switch>
		</React.Fragment>
	)
}

export const Advice = (props: any) => {
	const {data, loading} = useAdviceSectionQuery()

	return (
		<div className={style.container}>
			{loading
				? <Spinner />
				: !data || !data.adviceSection_List
					? <div>Error</div>
					: <AdviceInner sections={data.adviceSection_List.objects} />
			}
		</div>
	)
}
