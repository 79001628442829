import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { SinglePanel } from '@/components/common/panel'
import Placeholder from '@/components/common/placeholder'

import { Error } from '@/components/common/error-boundary'
import Form from './form'
import { useFormContext } from '@/state/main-form/reducer'
import style from './form.module.sass'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { getAnswers } from '@/state/answer/thunks'
import { getForm } from '@/state/main-form/thunks'
import { RequestFailures } from '@/utils/functions'

type Props = RouteComponentProps<{recordId: string, formId: string}> | RouteComponentProps<{publicFormCode: string}>

const FormContainer = ({ match: { params } }: Props) => {
	const dispatch = useAppDispatch()
	useEffect(() => {
		dispatch(getForm(params)).then(() => {
			dispatch(getAnswers(params))
		})
	}, 'publicFormCode' in params ? [params.publicFormCode]: [params.recordId, params.formId])
	const formContext = useFormContext()
	
	const loading = useAppSelector(state => (
		state.answers.loading
		|| state.mainForm.loading
		// This last clause isn't essential. It handles the case when we're waiting for
		// answers to save when switching from one form to another.
		|| (!('publicFormCode' in params) && state.mainForm.data?.form.id !== params.formId)
	))
	const error = useAppSelector(state => state.mainForm.error)
	if (error) {
		const message = {
			[RequestFailures.PERMISSION_DENIED]: {title: "This form does not exist", content: "This link has either been deactivated or does not exists. If you believe it should, please contact your administrator."},
			[RequestFailures.UNKNOWN_ERROR]: {title: null, content: null} // default error message
		}[error]
		return <Error {...message} />
	}

	if (loading || !formContext) {
		return <div className={style.container}>
		<SinglePanel type="full-width">
			<Placeholder style={{height: 50, maxWidth: 400}}/>
			<Placeholder style={{height: 300}}/>
			<Placeholder style={{height: 600}}/>
		</SinglePanel>
		<div className={style.rightPanelContainer}>
			<Placeholder style={{height: 30, width: 100, marginTop: 0, marginBottom: 0 }}/>
			<Placeholder style={{height: 100}}/>
			<Placeholder style={{height: 100}}/>
		</div>
	</div>
	}

	return (
		<Form />
	)
}

export default FormContainer