import React, { useState } from 'react'
import c from 'classnames'

import { useFormParam } from '@/components/context'
import Form from './form'
import style from './category.module.sass'
import { FormsForRecordQuery } from '../__gen'


type RecordType = NonNullable<FormsForRecordQuery["record"]>
type FormType = RecordType["template"]["formSet"]["forms"][number]
//This is in the utilities, don't know why it must be defined...
export type CategoryType = FormType["category"] & {
	forms: FormType[]
}


type Props = {
	category: CategoryType
	record: RecordType
	allForms: FormType[]
}

export const Category = ({category, ...props}: Props) => {
	const formParamId = useFormParam().formId
	const initialOpen = !!category.forms.filter(f => (
		f.id === formParamId || f.hasSaved
	)).length
	const [show, updateShow] = useState(initialOpen || !category.collapsible)
	return (
		<div>
			<div className={style.title} onClick={() => updateShow(!show)}>
				{category.title}
			</div>
			<div className={c(style.forms, { [style.formsShow]: show })}>
				{category.forms.map(f => {
					let disabled = false
					if(f.dependency) {
						const dependentRf = props.allForms.find(currForm => {
							return currForm.id == f.dependency!.id
						})
						disabled = (dependentRf && dependentRf.hasCreatedPdf) || false
					}
					return (
						<Form 
							key={f.id}
							form={f} 
							record={props.record}
							disabled={false}
						/>
					)
				})}
			</div>
		</div>
	)
}