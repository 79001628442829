import React from 'react'
import gql from "graphql-tag"
import { RouteComponentProps } from 'react-router-dom'

import { useGlobalContext } from '@/components/context'
import { Title } from '@/components/common/typeography'
import { useTitle } from '@/utils/hooks'
import formStyle from '@sass/common/form.module.sass'
import RecordEditForm from './edit-form'
import Container from './container'
import { FormLoading } from './common'
import { useAddRecordInfoQuery } from './__gen/add'
import { RecordDetailsQuery, useRecordDetailsQuery } from './__gen/queries'


gql`
	query addRecordInfo {
		hasRecord: record_List(offset:0, limit: 1) {
			objects {
				id
			}
		}
	}
`

type Props = {
	copyRecord: RecordDetailsQuery["record"]
}

const AddRecordContainer: React.FC<Props> = (props) => {
	const { copyRecord } = props
	const { currentTeam, currentModule } = useGlobalContext()

	const { data, error, loading } = useAddRecordInfoQuery()

	useTitle(`Add ${currentModule.recordWord}`)

	if(loading) {
		return <FormLoading />
	}
	else if (error || !data || !data.hasRecord) return <div>error</div>

	let title, subtitle
	if(data.hasRecord!.objects.length) { 
		
		title = copyRecord
			? `Copy ${currentModule.recordWord}`
			: `Add ${currentModule.recordWord}`

		subtitle = ''
	}
	else {
		title = `Welcome!`
		subtitle = `Its time to create your first ${currentModule.recordWord.toLowerCase()}`
	}
	return (
		<>
			<Title type="page">{title}</Title>
			{subtitle && <h5 className={formStyle.commonFormSubtitle}>{subtitle}</h5>}
			
			<RecordEditForm
				initialValues={{
					id: null,
					name: '',
					position: copyRecord ? copyRecord.position : '',
					team: currentTeam,
					image: null,
					copyRecord,
					template: copyRecord ? copyRecord.template : null,
				}}
				dedicatedCopyUrl={!!copyRecord}
			/>
		</>
			
	)
}

type OuterProps = RouteComponentProps<{recordId: string}>

const GetCopyRecord: React.FC<OuterProps> = (props) => {
	const globalContext = useGlobalContext()
	const copyRecordId = props.match.params.recordId
	let newProps: Props = {
		copyRecord: null
	}
	if (copyRecordId) {
		const { data, error, loading } = useRecordDetailsQuery({variables: {id: copyRecordId}})
		if(loading) return <FormLoading />
		if(!data || error) {
			return <div>Error fetching {globalContext.currentModule.recordWord} to copy</div>
		}
		newProps.copyRecord = data.record
	}
	
	return <AddRecordContainer {...props} {...newProps} />
}

export default (props: OuterProps) => (
	<Container>
		<GetCopyRecord {...props} />
	</Container>
)