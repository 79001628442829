import React from 'react'
import { FieldContentType } from '@/types/common'
import { QueryResult } from '@apollo/client'
import { FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables } from './__gen'

export type ModalProps = {
	fieldType: FieldContentType
	fieldId: string
	recordId: string
	teamId: string
	formId: string
}

export type QueryType = QueryResult<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>

// Without memoization AND useContext, modal will flicker when data is updated
export const NotificationQueryContext = React.createContext<null | QueryType>(null)
