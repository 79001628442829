import React, { useState, useRef, useCallback, useEffect, createRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core'
import {
	useSortable,
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { reorderAnswers } from '@/state/answer/thunks'
import { DragHandle } from '@/components/common/drag-handle'
import { AnswerAnimationGroup } from './answer-animation-group/index.module'
import { RowDeletable } from './row-deletable'
import { RootState } from '@/state/redux-store'
import { selectAnswersForField } from '@/state/answer/selectors'
import { getRanksFromAnswers } from '@/state/answer/utils'

type PropsA = {
	sortEnabled: boolean,
	id: string,
	children: React.ReactNode,
}

export const SortableItem = ({ id, sortEnabled, children }: PropsA) => {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: id });

	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		backgroundColor: 'white', display: 'flex'
	};

	return (
		<div ref={setNodeRef} style={style}>
			{sortEnabled && <DragHandle attributes={attributes} listeners={listeners} />}
			{children}
		</div>
	);
}

type Props = {
	fieldId: string
	tableFieldShowColumns: number
	fieldIsDisabled: boolean
	showDeleteButton: boolean
	sortEnabled: boolean,
}

type Row = {
	id: string
	rank: number
	animationRef: React.RefObject<HTMLDivElement>
}

const getRowIds = (fieldId: string, state: RootState): {rows: Row[]} => {
	const answers = selectAnswersForField(state, fieldId);
	const ranks = getRanksFromAnswers(answers)
	const rows = ranks.map(r => ({
		id: uuidv4(),
		rank: r,
		animationRef: createRef<HTMLDivElement>()
	}))
	return {rows}
}

export const SortableRows = ({ fieldId, tableFieldShowColumns, fieldIsDisabled, showDeleteButton, sortEnabled }: Props) => {
	const dispatch = useAppDispatch()
	// represents rows from redux, will be used to populate / update
	// sortRows if necessary
	const {rows: initRows} = useAppSelector(
		(state: RootState) => getRowIds(fieldId, state),
		// We only need to update if we add / remove answers
		(a, b) => a.rows.length === b.rows.length
	)

	const [sortRows, setSortRows] = useState(initRows)

	useEffect(() => {
		const nextSortRows = [...sortRows]
		let dirty = false
		if (initRows.length > sortRows.length) {
			dirty = true
			// we have added a row, push to end
			nextSortRows.push(initRows[initRows.length - 1])
			setSortRows(nextSortRows)
			
		}
		if (dirty) {
			setSortRows(nextSortRows)
		}
	}, [initRows, sortRows])

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) {
			return
		}

		if (active.id !== over.id) {
			const oldIndex = sortRows.findIndex((i) => i.id == active.id)
			const newIndex = sortRows.findIndex((i) => i.id == over.id)

			const nextSortRows = arrayMove(sortRows, oldIndex, newIndex)

			const rankChanges: { [fromRank: number]: number } = {}

			nextSortRows.forEach((row, index) => {
				rankChanges[row.rank] = index
			})
			nextSortRows.forEach((r, index) => {
				r.rank = rankChanges[r.rank]
			})

			setSortRows(nextSortRows)
			dispatch(reorderAnswers({fieldId, rankChanges: rankChanges}))
		}
	}, [sortRows, fieldId])

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
		>
			<SortableContext
				items={sortRows}
				strategy={verticalListSortingStrategy}
			>
				<AnswerAnimationGroup
					elements={sortRows.map((r, i) => ({
						id: r.id,
						show: true,
						ref: r.animationRef,
						node: (
							<div ref={r.animationRef}>
								<SortableItem id={r.id} sortEnabled={sortEnabled}>
									<RowDeletable
										rank={r.rank}
										fieldId={fieldId}
										tableFieldShowColumns={tableFieldShowColumns}
										fieldIsDisabled={fieldIsDisabled}
										showDeleteButton={showDeleteButton}
									/>
								</SortableItem>
							</div>
						),
					}))}
				/>
			</SortableContext>
		</DndContext>
	);
}