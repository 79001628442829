import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrganisationsQueryVariables = Types.Exact<{
  search: Types.Scalars['String']['input'];
}>;


export type AdminOrganisationsQuery = { __typename: 'PharosQuery', organisationAdmin_List: { __typename: 'AdminOrganisationTypeListBase', objects: Array<{ __typename: 'AdminOrganisationType', id: string, name: string, userCount: number, recordCount: number }> } | null };


export const AdminOrganisationsDocument = gql`
    query adminOrganisations($search: String!) {
  organisationAdmin_List(search: $search, limit: 300, annotateCounts: true) {
    objects {
      id
      name
      userCount
      recordCount
    }
  }
}
    `;

/**
 * __useAdminOrganisationsQuery__
 *
 * To run a query within a React component, call `useAdminOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrganisationsQuery({
 *   variables: {
 *      search: // value for 'search'
 *   },
 * });
 */
export function useAdminOrganisationsQuery(baseOptions: Apollo.QueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables> & ({ variables: AdminOrganisationsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
      }
export function useAdminOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
        }
export function useAdminOrganisationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
        }
export type AdminOrganisationsQueryHookResult = ReturnType<typeof useAdminOrganisationsQuery>;
export type AdminOrganisationsLazyQueryHookResult = ReturnType<typeof useAdminOrganisationsLazyQuery>;
export type AdminOrganisationsSuspenseQueryHookResult = ReturnType<typeof useAdminOrganisationsSuspenseQuery>;
export type AdminOrganisationsQueryResult = Apollo.QueryResult<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>;