import { MoreOutlined } from '@ant-design/icons'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Dropdown, Typography } from 'antd'
import { Tooltip } from 'antd'
import React from 'react'

import iconStyle from '@sass/common/icon.module.sass'
import { getUserDetails } from '../../../../common/utils'
import { Actions, Escalation, State } from '../hooks'

import style from './index.module.sass'

const { Title } = Typography

type Props = {
	dispatch: React.Dispatch<Actions>
	state: State
}

type RowProps = {
	e: Escalation
	dispatch: React.Dispatch<Actions>
}

const Row = ({ e, dispatch }: RowProps) => {
	const details = getUserDetails(e)
	return (
		<div key={e.id} className={style.row}>
			<div>
				<b>{details.name}</b> ({details.email}) will be CC'ed when overdue by {e.deltaDays} days
			</div>
			<div>
				<Dropdown
					menu={{
						items: [
							{
								key: 'delete',
								label: (
									<div className={iconStyle.bin} onClick={() => dispatch({ type: 'DELETE', id: e.id })}>
										Delete
									</div>
								),
							},
						],
					}}
					trigger={['click']}
				>
					<MoreOutlined style={{ transform: 'scale(1.4)' }} />
				</Dropdown>
			</div>
		</div>
	)
}

export const EscalationList = ({ dispatch, state }: Props) => {
	const escalations = Object.values(state)
		.filter((e) => !e.deleted)
		.sort((a, b) => a.deltaDays - b.deltaDays)
	return (
		<>
			<Title level={5}>
				Escalations&nbsp;
				<Tooltip title="If the actions defined above remain incomplete, additional users can be added to the email chain.">
					<InfoCircleOutlined />
				</Tooltip>
			</Title>

			<div>
				{escalations && escalations.map((e) => <Row key={e.id} e={e} dispatch={dispatch} />)}
				{escalations.length === 0 && <div>No escalations set</div>}
			</div>
		</>
	)
}
