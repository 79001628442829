import React from 'react'
import { Route, Switch, RouteComponentProps } from 'react-router-dom'

import { ErrorBoundaryHoc } from '@/components/common/error-boundary'
import { SinglePanel } from '@/components/common/panel'
import { useGlobalContext } from '@/components/context'
import ExportToCsv from '@/components/dashboard/pages/module/pages/export-to-csv'
import TeamSettings from '@/components/dashboard/pages/module/pages/team-settings'
import Form from '@/components/form'
import Analytics from '@/components/dashboard/pages/module/pages/analytics'
import RecordEdit from '@/components/dashboard/pages/module/pages/record-update/edit'
import { FilesTeam } from '@/components/dashboard/pages/module/pages/team-files'
import RecordAdd from '@/components/dashboard/pages/module/pages/record-update/add'
import { MergePdf } from '@/components/dashboard/pages/module/pages/merge-pdf'
import RecordDashboard from '@/components/dashboard/pages/module/pages/record-dashboard'
import { WrappedRouteNotFound } from '@/components/common/route-not-found'
import {
	GLOBAL_CONTEXT_PARAMS,
	formUrl,
	exportDataUrl,
	teamSettingsUrl,
	teamUrl,
	recordUrl,
	teamFilesUrl,
	teamAnalyticsBaseUrl,
	recordAddUrl,
	recordDashboardUrl,
	recordEditUrl,
	recordCopyUrl,
	recordMergePdfsUrl,
} from '@/utils/url'
import iconStyle from '@sass/common/icon.module.sass'
import { useTitle } from '@/utils/hooks'
import LeftSidePanel from './left-side-panel'

type Props = RouteComponentProps<{recordId: string}>

const ROUTES = [
	{ component: ErrorBoundaryHoc(Analytics), path: teamAnalyticsBaseUrl(GLOBAL_CONTEXT_PARAMS),},
	{ component: ErrorBoundaryHoc(Form), path: formUrl({recordId: ':recordId', formId: ':formId', ...GLOBAL_CONTEXT_PARAMS}),},
	{ component: ErrorBoundaryHoc(RecordAdd), path: recordAddUrl(GLOBAL_CONTEXT_PARAMS),},
	{ component: ErrorBoundaryHoc(TeamSettings), path: teamSettingsUrl(GLOBAL_CONTEXT_PARAMS),},
	{ component: ErrorBoundaryHoc(ExportToCsv), path: exportDataUrl(GLOBAL_CONTEXT_PARAMS),},
	{ component: ErrorBoundaryHoc(FilesTeam), path: teamFilesUrl(GLOBAL_CONTEXT_PARAMS),},
	{ component: ErrorBoundaryHoc(RecordDashboard), path: recordDashboardUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS}),},
	{ component: ErrorBoundaryHoc(RecordEdit), path: recordEditUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS}),},
	{ component: ErrorBoundaryHoc(RecordAdd), path: recordCopyUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS}),},
	{ component: ErrorBoundaryHoc(MergePdf), path: recordMergePdfsUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS}),},
	{ component: ErrorBoundaryHoc(WrappedRouteNotFound), path: "*",},
]

const LeftPanelRoutes = ({ match }: Props) => {
	return (
		<>
			<LeftSidePanel recordId={match.params.recordId || null} />
			<Switch>
				{ROUTES.map((props: {path: string, component: React.ComponentType<any>}) => (
					<Route key={props.path} path={props.path} component={props.component} />
				))}
			</Switch>
		</>
	)
}

export const Module = (props: RouteComponentProps<{teamId: string}>) => {
	const context = useGlobalContext()
	const teamError = props.match.params.teamId !== "all" && !context.currentTeam

	const teamName = context.currentTeam?.name

	useTitle((teamName || 'All') + ' - ' + context.currentModule?.name)

	if (teamError || !context.currentModule) {
		return (
			<SinglePanel type="thin">
				<h1><span className={iconStyle.warning} />Permission error</h1>
				<p>There was an issue fetching your current {context.organisation.suite.moduleWord}/team.</p>
				<p>If you think this is a bug, please contact support.</p>
			</SinglePanel>
		)
	} else if (context.currentModule.teams.length === 0) {
		return (
			<SinglePanel type="thin">
				<h1><span className={iconStyle.warning} />Error: No {context.currentModule.teamWord.toLowerCase()}</h1>
				<p>
					Please create a {context.currentModule.teamWord.toLowerCase()} for {context.currentModule.name}
				</p>
				<p>If you think this is a bug, please contact support.</p>
			</SinglePanel>
		)
	}
	return (
		<Switch>
			<Route path={recordUrl({recordId: ':recordId', ...GLOBAL_CONTEXT_PARAMS})} component={LeftPanelRoutes} />
			<Route path={teamUrl(GLOBAL_CONTEXT_PARAMS)} component={LeftPanelRoutes} />
			<Route path="*" component={WrappedRouteNotFound} />
		</Switch>
	)
}