import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EmailHistoryQueryVariables = Types.Exact<{
  regardingObjectId: Types.Scalars['ID']['input'];
  regardingObjectContentType: Types.Scalars['String']['input'];
}>;


export type EmailHistoryQuery = { __typename: 'PharosQuery', email_List: { __typename: 'EmailTypeListBase', objects: Array<{ __typename: 'EmailType', id: string, sendToEmail: string, created: string }> } | null };


export const EmailHistoryDocument = gql`
    query emailHistory($regardingObjectId: ID!, $regardingObjectContentType: String!) {
  email_List(
    regardingObjectId: $regardingObjectId
    regardingContentTypeString: $regardingObjectContentType
    limit: 1000
    orderBy: [{field: id}]
  ) {
    objects {
      id
      sendToEmail
      created
    }
  }
}
    `;

/**
 * __useEmailHistoryQuery__
 *
 * To run a query within a React component, call `useEmailHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailHistoryQuery({
 *   variables: {
 *      regardingObjectId: // value for 'regardingObjectId'
 *      regardingObjectContentType: // value for 'regardingObjectContentType'
 *   },
 * });
 */
export function useEmailHistoryQuery(baseOptions: Apollo.QueryHookOptions<EmailHistoryQuery, EmailHistoryQueryVariables> & ({ variables: EmailHistoryQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailHistoryQuery, EmailHistoryQueryVariables>(EmailHistoryDocument, options);
      }
export function useEmailHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailHistoryQuery, EmailHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailHistoryQuery, EmailHistoryQueryVariables>(EmailHistoryDocument, options);
        }
export function useEmailHistorySuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EmailHistoryQuery, EmailHistoryQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EmailHistoryQuery, EmailHistoryQueryVariables>(EmailHistoryDocument, options);
        }
export type EmailHistoryQueryHookResult = ReturnType<typeof useEmailHistoryQuery>;
export type EmailHistoryLazyQueryHookResult = ReturnType<typeof useEmailHistoryLazyQuery>;
export type EmailHistorySuspenseQueryHookResult = ReturnType<typeof useEmailHistorySuspenseQuery>;
export type EmailHistoryQueryResult = Apollo.QueryResult<EmailHistoryQuery, EmailHistoryQueryVariables>;