import { MoreOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd'
import { format } from 'date-fns'
import gql from 'graphql-tag'
import React from 'react'
import { useDispatch } from 'react-redux'
import { Link, Route } from 'react-router-dom'
import { PdfPdfOriginChoices } from '@/__gen/types'
import { useGlobalContext } from '@/components/context'
import EmailModal from '@/components/email/modal'
import { useFormContext } from '@/state/main-form/reducer'
import { showModal } from '@/state/modal/actions'
import { PDF_CONTENT_TYPE } from '@/utils/constants'
import {
	downloadPdfFileApiUrl,
	editPdfNamelUrl,
	emailModalSendUrl,
	emailModalUrl,
	recordMergePdfsCreateUrl,
} from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { useDeletePdfMutation } from './__gen/pdf'
import { PdfGeneratorsForFormDocument, PdfGeneratorsForFormQuery } from './__gen'
import { EditName } from './edit-name'
import style from './pdf.module.sass'

type Props = {
	pdf: NonNullable<PdfGeneratorsForFormQuery['pdfGenerator_List']>['objects'][number]['pdfs'][number]
}

export const PDF_FRAGMENT = gql`
	fragment Pdf on PdfType {
		id
		name
		created
		origin
		isActive
	}
`

gql`
	mutation deletePdf($id: ID!) {
		pdf_Update(input: { id: $id, isActive: false }) {
			object {
				id
				isActive
			}
		}
	}
`

const Pdf = ({ pdf }: Props) => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const recordId = formContext.record.id
	const formId = formContext.form.id
	const dispatch = useDispatch()
	const [deletePdfMutation, { loading }] = useDeletePdfMutation()
	const [deleting, setDeleting] = React.useState(false)

	const showDeleteModal = () => {
		dispatch(
			showModal({
				title: 'Are you sure?',
				content: <p>You will no longer have access to this pdf</p>,
				confirmClass: buttonStyle.red,
				confirmText: 'Delete pdf',
				confirmAction: async () => {
					setDeleting(true)
					await deletePdfMutation({
						variables: { id: pdf.id },
						refetchQueries: [PdfGeneratorsForFormDocument],
					})
					setDeleting(false)
				},
			}),
		)
	}

	const emailProps = {
		recordId,
		formId,
		regardingObjectId: pdf.id,
		regardingObjectContentType: PDF_CONTENT_TYPE,
	}

	const menuItems = [
		{ key: 'email', label: <Link to={emailModalSendUrl({ ...globalContext, ...emailProps })}>Email</Link> },
		{
			key: 'rename',
			label: <Link to={editPdfNamelUrl({ ...globalContext, pdfId: pdf.id, recordId, formId })}>Rename</Link>,
		},
		{
			key: 'combine',
			label: <Link to={recordMergePdfsCreateUrl({ ...globalContext, recordId })}>Combine</Link>,
		},
		{ key: 'delete', label: <div onClick={showDeleteModal}>Delete</div> },
	]

	return (
		<div className={style.pdfContainer} key={pdf.id}>
			<div className={style.pdf} style={deleting ? { opacity: 0.3 } : {}}>
				<a target="_blank" className={style.link} href={downloadPdfFileApiUrl(pdf)}>
					<div className={style.pdfTextContainer}>
						<div>
							<span className={`${style.icon} ${iconStyle.doc}`}></span>
							<b>{pdf.name}</b>
						</div>
						<div className={generalStyle.muted}>{format(new Date(pdf.created), 'MMM do yyyy h:mma')}</div>
						{pdf.origin == PdfPdfOriginChoices.PublicForm && <div className={style.badge}>Share Link</div>}
						{pdf.origin == PdfPdfOriginChoices.Reset && <div className={style.badge}>Reset Archive</div>}
						{pdf.origin == PdfPdfOriginChoices.FormAction && (
							<div className={style.badge}>Actions Complete</div>
						)}
					</div>
				</a>
				<Dropdown menu={{ items: menuItems }} trigger={['click']}>
					<MoreOutlined style={{ fontSize: '1.5rem' }} />
				</Dropdown>
			</div>
			<Route
				path={emailModalUrl({ ...globalContext, ...emailProps })}
				component={() => <EmailModal {...emailProps} />}
			/>
			<Route
				path={editPdfNamelUrl({ ...globalContext, pdfId: pdf.id, recordId, formId })}
				component={() => <EditName pdf={pdf} recordId={recordId} formId={formId} />}
			/>
		</div>
	)
}

export default Pdf
