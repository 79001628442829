import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldNotificationsForUserQueryVariables = Types.Exact<{
  fieldId: Types.Scalars['String']['input'];
  fieldType: Types.Scalars['String']['input'];
  userId: Types.Scalars['ID']['input'];
}>;


export type FieldNotificationsForUserQuery = { __typename: 'PharosQuery', fieldNotification_List: { __typename: 'FieldNotificationTypeListBase', objects: Array<{ __typename: 'FieldNotificationType', id: string, delta: number, sendToOrganisation: boolean, isActive: boolean, fieldNotificationUsers: Array<{ __typename: 'FieldNotificationUserType', id: string, isActive: boolean, user: { __typename: 'UserType', id: string, firstName: string, lastName: string } }> }> } | null };


export const FieldNotificationsForUserDocument = gql`
    query fieldNotificationsForUser($fieldId: String!, $fieldType: String!, $userId: ID!) {
  fieldNotification_List(
    fieldId: $fieldId
    fieldContentType: $fieldType
    relevantToUser: $userId
  ) {
    objects {
      id
      delta
      sendToOrganisation
      isActive
      fieldNotificationUsers {
        id
        isActive
        user {
          id
          firstName
          lastName
        }
      }
    }
  }
}
    `;

/**
 * __useFieldNotificationsForUserQuery__
 *
 * To run a query within a React component, call `useFieldNotificationsForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldNotificationsForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldNotificationsForUserQuery({
 *   variables: {
 *      fieldId: // value for 'fieldId'
 *      fieldType: // value for 'fieldType'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFieldNotificationsForUserQuery(baseOptions: Apollo.QueryHookOptions<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables> & ({ variables: FieldNotificationsForUserQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>(FieldNotificationsForUserDocument, options);
      }
export function useFieldNotificationsForUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>(FieldNotificationsForUserDocument, options);
        }
export function useFieldNotificationsForUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>(FieldNotificationsForUserDocument, options);
        }
export type FieldNotificationsForUserQueryHookResult = ReturnType<typeof useFieldNotificationsForUserQuery>;
export type FieldNotificationsForUserLazyQueryHookResult = ReturnType<typeof useFieldNotificationsForUserLazyQuery>;
export type FieldNotificationsForUserSuspenseQueryHookResult = ReturnType<typeof useFieldNotificationsForUserSuspenseQuery>;
export type FieldNotificationsForUserQueryResult = Apollo.QueryResult<FieldNotificationsForUserQuery, FieldNotificationsForUserQueryVariables>;