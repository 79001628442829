import { FieldFieldTypeChoices, FieldTableFieldTypeChoices } from "@/__gen/types"
import { MainFormAuthQuery } from "./get-main-form-auth/__gen"
import { MainFormPublicQuery } from "./get-main-form-public/__gen"

type PublicForm = NonNullable<MainFormPublicQuery["publicFormPub"]>

export const TableFieldTypeEnum = FieldTableFieldTypeChoices
export const FieldTypeEnum = FieldFieldTypeChoices


export type TableFieldEnumType = FieldTableFieldTypeChoices
export type FieldEnumType = FieldFieldTypeChoices | FieldTableFieldTypeChoices // This allows us to mix enum types


export type FieldType = 
	| NonNullable<MainFormAuthQuery['form']>['fields'][number]
	| PublicForm['form']['fields'][number]

export type TableFieldType = 
	| NonNullable<MainFormAuthQuery['form']>['fields'][number]['tableFields'][number]
	| PublicForm['form']['fields'][number]['tableFields'][number]

export type InitialAnswerType = 
	| NonNullable<MainFormPublicQuery['initialAnswerPub_List']>['objects'][number]
	| NonNullable<MainFormAuthQuery['initialAnswer_List']>['objects'][number]

export type RecordType =
	| NonNullable<MainFormAuthQuery['record']>
	| PublicForm['record']

export type FormType =
	| NonNullable<MainFormAuthQuery['form']>
	| PublicForm['form']

export type GenericFieldType = FieldType | TableFieldType

export const isTableField = (f: GenericFieldType): f is TableFieldType => {
	return f.__typename == 'TableFieldType' || f.__typename == 'TableFieldPubType'
}

export const isAuthResponse = (resp: PublicForm| MainFormAuthQuery): resp is MainFormAuthQuery => {
	return false
}