import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type EventListQueryVariables = Types.Exact<{
  module?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  team?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  record?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  limit?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type EventListQuery = { __typename: 'PharosQuery', activityEvent_List: { __typename: 'ActivityEventTypeListBase', objects: Array<{ __typename: 'ActivityEventType', id: string, type: Types.ActivityEventTypeType, created: string, record: { __typename: 'RecordType', id: string, name: string }, user: { __typename: 'UserType', id: string, firstName: string } | null, form: { __typename: 'FormType', id: string, title: string } | null }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const EventListDocument = gql`
    query eventList($module: ID, $team: ID, $record: ID, $offset: Int, $limit: Int) {
  activityEvent_List(
    module: $module
    team: $team
    record: $record
    limit: $limit
    offset: $offset
    orderBy: [{field: created, direction: DESC}]
  ) {
    objects {
      id
      type
      record {
        id
        name
      }
      user {
        id
        firstName
      }
      form {
        id
        title
      }
      created
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useEventListQuery__
 *
 * To run a query within a React component, call `useEventListQuery` and pass it any options that fit your needs.
 * When your component renders, `useEventListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEventListQuery({
 *   variables: {
 *      module: // value for 'module'
 *      team: // value for 'team'
 *      record: // value for 'record'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useEventListQuery(baseOptions?: Apollo.QueryHookOptions<EventListQuery, EventListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EventListQuery, EventListQueryVariables>(EventListDocument, options);
      }
export function useEventListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EventListQuery, EventListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EventListQuery, EventListQueryVariables>(EventListDocument, options);
        }
export function useEventListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EventListQuery, EventListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EventListQuery, EventListQueryVariables>(EventListDocument, options);
        }
export type EventListQueryHookResult = ReturnType<typeof useEventListQuery>;
export type EventListLazyQueryHookResult = ReturnType<typeof useEventListLazyQuery>;
export type EventListSuspenseQueryHookResult = ReturnType<typeof useEventListSuspenseQuery>;
export type EventListQueryResult = Apollo.QueryResult<EventListQuery, EventListQueryVariables>;