import React from 'react'
import gql from 'graphql-tag'

import { useRemoveWebauthnCredentialsMutation } from './__gen/use-remove-mfa'


gql`
	mutation removeWebauthnCredentials($credentialId: ID!) {
		webauthnCredentials_Update(input: {id: $credentialId, active: false}) {
			object {
				id
				active
			}
		}
	}
`

type Params = {
	credentialId: string
	onComplete: () => void
}

export const useRemoveWebauthn = ({credentialId, onComplete}: Params) => {
	const [removeMfaMutation, { }] = useRemoveWebauthnCredentialsMutation({variables: {credentialId}})
	const [loading, setLoading] = React.useState(false)
	const removeMfa = async () => {
		setLoading(true)
		try {
			await removeMfaMutation()
			onComplete()
		} catch {
			alert('Unexpected error occurred. If this persists please contact support.')
		}finally {
			setLoading(false)
		}
	}
	return {
		removeMfa,
		loading,
	}
}