import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import gql from 'graphql-tag'

import { getAggregatedTablesList } from '@/state/analytics/actions'
import { togglePrintableDashboard, disablePrintableDashboard } from '@/state/user-interface'
import { useAppSelector } from '@/utils/hooks'
import { useGlobalContext } from '@/components/context'
import iconStyle from '@sass/common/icon.module.sass'
import { teamAggregatedTableUrl, teamConcatenatedTableUrl, teamMetricsUrl, calendarUrl, calendarBaseUrl, formActionMetricsBaseUrl, formActionMetricsUrlDefault, mapUrl } from '@/utils/url'
import { getPropsFromDate } from '../calendar/utils'
import style from './index.module.sass'
import { Tabs } from './tabs'
import { useConcatenatedTablesQuery } from './__gen'


gql`
	query concatenatedTables($moduleId: ID!) {
		concatenatedTable_List(module: $moduleId) {
			objects {
				id
				name
			}
		}
	}
`

const TopRow = () => {
	const globalContext = useGlobalContext()
	const [loading, setLoading] = useState(false)
	
	const { data } = useConcatenatedTablesQuery({
		variables: { moduleId: globalContext.currentModule.id }
	})
	const concatenatedTables = data?.concatenatedTable_List?.objects || []

	const tableObj = useAppSelector(state => (state.analytics.aggregatedTable))
	const tables = Object.keys(tableObj).map(tId => tableObj[tId]).filter(t => t.module == globalContext.currentModule.id)

	const printableDashboard = useAppSelector(state => state.userInterface.printableDashboard)

	const dispatch = useDispatch()

	useEffect(() => {
		setLoading(true)
		//@ts-ignore: Should be a simple one, 
		dispatch(getAggregatedTablesList(globalContext.currentModule.id)).then(() => {
			setLoading(false)
		})
	}, [globalContext.currentModule])

	// remove printable version on unmount
	useEffect(
		() => (() => {dispatch(disablePrintableDashboard())}),
		[],
	)
	return (
		<div className={style.topRow}>
			{ !printableDashboard &&
				<Tabs tabs={[
					{name: 'Dashboard', url: teamMetricsUrl(globalContext)},
					...(globalContext.currentModule.showCalendar ? [{
						name: 'Calendar',
						matchUrl: calendarBaseUrl(globalContext),
						url: calendarUrl({
							...globalContext,
							...getPropsFromDate(new Date()),
						})
					}] : []),
					...(globalContext.currentModule.showMap ? [{
						name: 'Map',
						url: mapUrl(globalContext),
					}] : []),
					...(globalContext.currentModule.showActionDashboard ? [{
						name: 'Actions',
						matchUrl: formActionMetricsBaseUrl(globalContext),
						url: formActionMetricsUrlDefault(globalContext)
					}] : []),
					...concatenatedTables.map(c => ({
						name: c.name,
						url: teamConcatenatedTableUrl({ tableId: c.id, ...globalContext}),
					})),
					...tables.map(t => ({
						name: t.name,
						url: teamAggregatedTableUrl({ tableId: t.id, ...globalContext}),
					}))
				]}/>
			}

			<div className={style.buttonContainer}>
				<button 
					className={style.icon + " " + (printableDashboard ? iconStyle.cross : iconStyle.expand)}
					onClick={() => dispatch(togglePrintableDashboard())}
				/>
			</div>
		</div>
	)
}

export default TopRow
