import React, { useEffect } from 'react'

import { SinglePanel } from '@/components/common/panel'
import { useRouter, useGlobalContext } from '@/components/context'
import { teamMetricsUrl } from '@/utils/url'


export const NoTeamPage = () => {
	const router = useRouter()
	const { organisation } = useGlobalContext()

	const modules = organisation.suite.moduleSetItems
	const moduleSetItemWithTeams = modules.find(msi => msi.module.teams.length > 0)

	useEffect(
		() => {
			if(moduleSetItemWithTeams) {
				router.history.push(teamMetricsUrl({
					currentModule: {id: moduleSetItemWithTeams.id},
					currentTeam: {id: moduleSetItemWithTeams.module.teams[0].id},
				}))
			}
		},
	)

	return (
		<SinglePanel type="thin">
			<h1>Sorry!</h1>
			<h4>
				You haven't been assigned to a {organisation.suite.moduleWord.toLocaleLowerCase()},
				please contact your administrator.
			</h4>
		</SinglePanel>
	)
}
