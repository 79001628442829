import React, { useState } from 'react'
import gql from "graphql-tag"
import classNames from 'classnames'

import { SpinnerButton } from '@/components/common/button'
import { SinglePanel } from '@/components/common/panel'
import { CenteredSpinner } from '@/components/common/loading'
import { Input } from '@/components/common/inputs'
import generalStyle from '@sass/common/general.module.sass'
import { RouteComponentProps } from 'react-router'
import { useTitle } from '@/utils/hooks'
import style from './index.module.sass'
import { PublicRecordCreateLinkQuery, useCreateRecordMutation, usePublicRecordCreateLinkQuery } from './__gen'

gql`
	query publicRecordCreateLink($teamCode: String!, $linkCode: String!) {
		publicRecordCreateLink(teamCode: $teamCode, linkCode: $linkCode) {
			title
			htmlMessage
			recordNamePlaceholder
			choices {
				id
				positionName
				template {
					namePlaceholder
				}
				form {
					id
				}
			}

		}
	}
`

gql`
	mutation createRecord($teamCode: String!, $linkCode: String!, $recordName: String!, $choice: ID!) {
		publicRecordCreate(teamCode: $teamCode, linkCode: $linkCode, recordName: $recordName, choice: $choice) {
			publicFormCode
			success
		}
	}
`

type Choice = NonNullable<PublicRecordCreateLinkQuery["publicRecordCreateLink"]>['choices'][number]

type Props = RouteComponentProps<{teamCode: string, linkCode: string}>

export const PublicRecordCreate: React.FC<Props> = ({ match }) => {
	const [recordName, setRecordName] = useState('')
	const [formErrorMessage, setFormErrorMessage] = useState<string>("")
	const [selectedChoice, setSelectedChoice] = useState<Choice | null>(null)
	const { data, error, loading } = usePublicRecordCreateLinkQuery({
		variables: {
			teamCode: match.params.teamCode,
			linkCode: match.params.linkCode,
		},
		onCompleted: (data) => {
			if (data.publicRecordCreateLink?.choices.length == 1) {
				setSelectedChoice(data.publicRecordCreateLink?.choices[0])
			}
		}
	})
	const [createRecord, {loading: mutationLoading}] = useCreateRecordMutation()
		
	const link = data?.publicRecordCreateLink
	useTitle(data?.publicRecordCreateLink?.title || 'Add new')
	
	if (loading) {
		return <CenteredSpinner />
	} else if (error || !link) {
		return(
			<SinglePanel type='thin'>
				<h2> This link is invalid </h2>
				<p> Please double check the url and try again</p>
			</SinglePanel>
		)
	}

	const onSubmit = () => {
		if(!selectedChoice || !recordName) {
			setFormErrorMessage("Please complete all fields")
			return
		}
		const variables = {
			recordName,
			choice: selectedChoice.id,
			linkCode: match.params.linkCode,
			teamCode: match.params.teamCode,
		}
		createRecord({variables}).then(r => {
			if (!r.data || !r.data.publicRecordCreate || !r.data.publicRecordCreate.success) {
				setFormErrorMessage("There was an issue submitting your data, please contact support if your issue persists")
			}
			else {
				document.location.href = `/public_form/${r.data.publicRecordCreate.publicFormCode}`
			}
		})
	}

	
	return	(
		<SinglePanel type="thin">
			<div className={style.publicCreateRecord}>
				<h3>{link.title}</h3>
				<p dangerouslySetInnerHTML={{__html: link.htmlMessage}}></p>
				<Input
					placeholder={link.recordNamePlaceholder || "Name"}
					value={recordName}
					onChange={(e) => setRecordName(e.target.value)} 
				/>
				{(link.choices.length != 1 || !selectedChoice) &&
					<div className={style.choiceSection}>
						<h6>Select a category</h6>
						{ link.choices.map(choice => {
							const selected = selectedChoice && selectedChoice.id == choice.id
							return (
								<div 
									onClick={() => setSelectedChoice(choice)} 
									className={classNames(style.choice, {[style.selected]: selected})} 
									key={choice.id}>
									{choice.positionName}
								</div>
							)
						})}
					</div>
				}
				{ formErrorMessage && 
					<div className={generalStyle.error}>{formErrorMessage}</div>
				}
				<div><SpinnerButton loading={mutationLoading} onClick={onSubmit}>Submit</SpinnerButton></div>
			</div>
		</SinglePanel>
	)
}
