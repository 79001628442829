import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { UserEssentialsFragmentDoc } from '../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignupMutationVariables = Types.Exact<{
  suiteId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  organisationName: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
  email: Types.Scalars['String']['input'];
  password: Types.Scalars['String']['input'];
  howYouHeardAboutUs: Types.Scalars['String']['input'];
}>;


export type SignupMutation = { __typename: 'PharosMutations', user_Signup: { __typename: 'UserSignupMutation', user: { __typename: 'UserType', id: string, adminOfSuite: boolean, email: string, firstName: string, howYouHeardAboutUs: string | null, isActive: boolean, isAdmin: boolean, lastName: string, recordCount: number, seenWelcomeMessage: boolean, topLevelManager: boolean, usingTemporaryPassword: boolean, organisation: { __typename: 'OrganisationType', id: string, csvDownloadEnabled: boolean, logo: any | null, name: string, showTeamFiles: boolean, subdomain: string | null, suite: { __typename: 'SuiteType', id: string, logo: any | null, suiteAdminDashboardTitle: string | null, welcomeMessageHtml: string | null, moduleWord: string, moduleSetItems: Array<{ __typename: 'SuiteModuleType', id: string, rank: number, hiddenWhenEmpty: boolean, module: { __typename: 'ModuleType', id: string, addTeamPhrase: string | null, name: string, icon: string | null, singleton: boolean, descriptionHtml: string, showActiveLinksOnDashboard: boolean, showActionDashboard: boolean, allowRecordTemplateChange: boolean, allowPositionTextChange: boolean, recordFilesEnabled: boolean, recordImages: boolean, recordWord: string, showCalendar: boolean, showMap: boolean, positionWord: string, teamWord: string, teams: Array<{ __typename: 'TeamType', id: string, publicRecordCreateCode: string, logo: any | null, reminderFrequencyDays: number, name: string }>, publicRecordCreateLinks: Array<{ __typename: 'PublicRecordCreateLinkType', id: string, title: string, code: string }> } }>, adviceSection: { __typename: 'AdviceSectionType', id: string, title: string } | null } } } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const SignupDocument = gql`
    mutation signup($suiteId: ID, $organisationName: String!, $firstName: String!, $lastName: String!, $email: String!, $password: String!, $howYouHeardAboutUs: String!) {
  user_Signup(
    orgData: {suiteId: $suiteId, name: $organisationName}
    userData: {firstName: $firstName, lastName: $lastName, email: $email, password: $password, howYouHeardAboutUs: $howYouHeardAboutUs}
  ) {
    user {
      ...UserEssentials
    }
    errors {
      field
      messages
    }
  }
}
    ${UserEssentialsFragmentDoc}`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      suiteId: // value for 'suiteId'
 *      organisationName: // value for 'organisationName'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      howYouHeardAboutUs: // value for 'howYouHeardAboutUs'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;