import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotifiationsUserListQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
}>;


export type NotifiationsUserListQuery = { __typename: 'PharosQuery', user_List: { __typename: 'UserTypeListBase', objects: Array<{ __typename: 'UserType', id: string, firstName: string, lastName: string }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const NotifiationsUserListDocument = gql`
    query notifiationsUserList($teamId: ID!) {
  user_List(
    limit: 200
    offset: 0
    hasAccessToTeam: $teamId
    orderBy: [{field: firstName, modifiers: [CASE_INSENSITIVE]}, {field: lastName, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      firstName
      lastName
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useNotifiationsUserListQuery__
 *
 * To run a query within a React component, call `useNotifiationsUserListQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotifiationsUserListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotifiationsUserListQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useNotifiationsUserListQuery(baseOptions: Apollo.QueryHookOptions<NotifiationsUserListQuery, NotifiationsUserListQueryVariables> & ({ variables: NotifiationsUserListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>(NotifiationsUserListDocument, options);
      }
export function useNotifiationsUserListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>(NotifiationsUserListDocument, options);
        }
export function useNotifiationsUserListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>(NotifiationsUserListDocument, options);
        }
export type NotifiationsUserListQueryHookResult = ReturnType<typeof useNotifiationsUserListQuery>;
export type NotifiationsUserListLazyQueryHookResult = ReturnType<typeof useNotifiationsUserListLazyQuery>;
export type NotifiationsUserListSuspenseQueryHookResult = ReturnType<typeof useNotifiationsUserListSuspenseQuery>;
export type NotifiationsUserListQueryResult = Apollo.QueryResult<NotifiationsUserListQuery, NotifiationsUserListQueryVariables>;