import React from 'react'
import { Select } from 'antd'
import { useGlobalContext } from '@/components/context'
import { NotifiationsUserListQuery } from './__gen'

const { Option } = Select

export const EVERYONE = 'EVERYONE'

type Props = {
	users: NonNullable<NotifiationsUserListQuery["user_List"]>["objects"]
	onSelect: (userId: string) => void
}

export const UserSelect = ({users, onSelect}: Props) => {
	const globalContext = useGlobalContext()
	return (
		<Select style={{width: 220}} onChange={onSelect}>
			{globalContext.user.topLevelManager && <Option value={EVERYONE}><b>Everyone</b></Option> }
			{users.map(u => (
				<Option value={u.id} key={u.id}>{u.firstName} {u.lastName}</Option>
			))}
		</Select>
	)
}
