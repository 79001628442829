import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import gql from "graphql-tag"
import c from "classnames"

import { useGlobalContext } from '@/components/context'
import { setRefreshRecordList } from '@/state/record/actions'
import { apolloClient } from '@/state/apollo-client'
import { recordAddUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import RecordList from './record-list'
import IconRow from './icon-row'
import TeamDetails from './team-details'
import style from './index.module.sass'
import { PanelTypes } from '../const'
import { useDispatch } from 'react-redux'
import LoadingRecords from './loading-records'
import { useRecordListQuery } from './__gen'


const GET_EMPLOYEES = gql`
	query recordList($offset: Int!, $search: String!, $module: ID!, $team: ID) {
		record_List(
			limit: 20,
			offset: $offset,
			orderBy: [
				{field: archived},
				{field: name, modifiers: [CASE_INSENSITIVE]},
				{field: position, modifiers: [CASE_INSENSITIVE]}
			],
			search: $search,
			module: $module,
			team: $team,
		) @connection(key: "recordList", filter: [$search]) {
			objects {
				id
				name
				position
				archived
				team {
					id
					name
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

export const resetRecordList = () => {
	apolloClient.writeQuery({
		query: GET_EMPLOYEES,
		data: {
			record_List: {
				__typename:"RecordTypeListBase",
				objects: [],
				pageInfo: {
					hasNextPage: true,
					total: 0,
					__typename:"PageInfo"
				}
			}
		},
	})
}

type Props = {
	setShowPanel: (p: PanelTypes) => void
	recordId: string | null
}


const TeamPanel = (props: Props) => {
	const globalContext = useGlobalContext()
	const [search, setSearch] = useState("")

	const fetchParams = {
		search,
		module: globalContext.currentModule.id,
		team: globalContext.currentTeam && globalContext.currentTeam.id,
	}

	const { data, loading, fetchMore, refetch } = useRecordListQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: 'network-only', // so stale records don't show on team change
		variables: {
			...fetchParams,
			offset: 0,
		}
	})

	const shouldFetchMore = () => {
		if(!loading && data && data.record_List!.pageInfo.hasNextPage) {
			fetchMore({
				variables: {
					...fetchParams,
					offset: data!.record_List!.objects.length,
				},
				updateQuery: (prev, data) => {
					const { fetchMoreResult } = data
					if (!fetchMoreResult) return prev
					return {
						...prev,
						record_List: {
							__typename: 'RecordTypeListBase',
							...prev.record_List,
							objects: [...prev!.record_List!.objects, ...fetchMoreResult!.record_List!.objects],
							pageInfo: fetchMoreResult!.record_List!.pageInfo
						}
					}
				}
			})
		}
	}

	const dispatch = useDispatch()

	const refreshList = () => {
		resetRecordList()
		refetch()
	}

	useEffect(
		() => {dispatch(setRefreshRecordList(refreshList))},
		[],
	)

	return (
		<>
			<TeamDetails />
			<Link
				className={style.addButton}
				to={recordAddUrl(globalContext)}
			>
				<button className={c(buttonStyle.primarySolid, iconStyle.add, style.addButtonInner)}>
					{globalContext.currentModule.recordWord}
				</button>
			</Link>
			<IconRow
				setSearch={setSearch}
				search={search}
			/>
			{data && data.record_List
				? <RecordList
					data={data!.record_List}
					loading={loading}
					setShowPanel={props.setShowPanel}
					fetchMore={shouldFetchMore}
					recordId={props.recordId}
				/>
				: <LoadingRecords />

			}
		</>
	)
}

export default TeamPanel