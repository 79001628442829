import { FormActionMetricsQuery } from "../__gen"

export const monthKey = ({ year, month }: { year: number, month: number }) => {
	return `${year}-${month / 10 < 1 ? '0' : ''}${month}`
}

export const monthIdFromString = (dateString: string) => {
	const date = new Date(dateString)
	return monthKey({ year: new Date(date).getFullYear(), month: new Date(date).getMonth() + 1 })
}

export const MONTHS = [
	{title: 'Jan', index: 1},
	{title: 'Feb', index: 2},
	{title: 'Mar', index: 3},
	{title: 'Apr', index: 4},
	{title: 'May', index: 5},
	{title: 'Jun', index: 6},
	{title: 'Jul', index: 7},
	{title: 'Aug', index: 8},
	{title: 'Sep', index: 9},
	{title: 'Oct', index: 10},
	{title: 'Nov', index: 11},
	{title: 'Dec', index: 12},
]


const TEST_USER_DATA = {
	assignedUser: {
		__typename: 'UserType',
		id: '1',
		firstName: "Test",
		lastName: "Man",
		email: "test@man.com",
	},
	assignedAnonEmail: '',
	assignedAnonName: '',
	assignedAnonEmailAnswer: null
}

// Data for testing:
// filterOnGetRecords:
// id: 2855,
export const TEST_DATA: FormActionMetricsQuery = {
	__typename: 'PharosQuery',
	formAction_List: {
		__typename: 'FormActionTypeListBase',
		objects: [
			// {
			// 	__typename: 'FormActionType',
			// 	id: '1',
			// 	created: '2024-03-29T07:43+00:00',
			// 	completedOn: '2024-06-29T07:43+00:00',
			// 	record: {
			// 		__typename: 'RecordType',
			// 		id: '2855'
			// 	},
			// 	form: {
			// 		__typename: 'FormType',
			// 		id: '321'
			// 	},
			// 	...TEST_USER_DATA,
			// },
			// {
			// 	__typename: 'FormActionType',
			// 	id: '2',
			// 	created: '2024-03-29T07:43+00:00',
			// 	completedOn: '2024-03-29T07:43+00:00',
			// 	record: {
			// 		__typename: 'RecordType',
			// 		id: '2855'
			// 	},
			// 	form: {
			// 		__typename: 'FormType',
			// 		id: '323'
			// 	}
			// 	...TEST_USER_DATA,
			// },
			// {
			// 	__typename: 'FormActionType',
			// 	id: '3',
			// 	created: '2024-05-29T07:43+00:00',
			// 	completedOn: null,
			// 	record: {
			// 		__typename: 'RecordType',
			// 		id: '2855'
			// 	},
			// 	form: {
			// 		__typename: 'FormType',
			// 		id: '323'
			// 	}
			// 	...TEST_USER_DATA,
			// },
		]
	},
	formActionSchedule_List: {
		__typename: 'FormActionScheduleTypeListBase',
		objects: [
			// {
			// 	__typename: 'FormActionScheduleType',
			// 	id: '4',
			// 	dueAt: '2024-09-29T07:43+00:00',
			// 	record: {
			// 		__typename: 'RecordType',
			// 		id: '2855'
			// 	},
			// 	form: {
			// 		__typename: 'FormType',
			// 		id: '321'
			// 	}
			// 	...TEST_USER_DATA,
			// },
		]
	},
	formActionEscalationEvent_List: {
		__typename: 'FormActionEscalationEventTypeListBase',
		objects: [
		]
	}
}