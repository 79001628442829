import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CalendarFieldsQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
}>;


export type CalendarFieldsQuery = { __typename: 'PharosQuery', field_List: { __typename: 'FieldTypeListBase', objects: Array<{ __typename: 'FieldType', id: string, title: string, calendarNames: Array<string> }> } | null };


export const CalendarFieldsDocument = gql`
    query calendarFields($moduleId: ID!) {
  field_List(hasDate: true, inModule: $moduleId) {
    objects {
      id
      title
      calendarNames(moduleId: $moduleId)
    }
  }
}
    `;

/**
 * __useCalendarFieldsQuery__
 *
 * To run a query within a React component, call `useCalendarFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCalendarFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCalendarFieldsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useCalendarFieldsQuery(baseOptions: Apollo.QueryHookOptions<CalendarFieldsQuery, CalendarFieldsQueryVariables> & ({ variables: CalendarFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CalendarFieldsQuery, CalendarFieldsQueryVariables>(CalendarFieldsDocument, options);
      }
export function useCalendarFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CalendarFieldsQuery, CalendarFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CalendarFieldsQuery, CalendarFieldsQueryVariables>(CalendarFieldsDocument, options);
        }
export function useCalendarFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CalendarFieldsQuery, CalendarFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CalendarFieldsQuery, CalendarFieldsQueryVariables>(CalendarFieldsDocument, options);
        }
export type CalendarFieldsQueryHookResult = ReturnType<typeof useCalendarFieldsQuery>;
export type CalendarFieldsLazyQueryHookResult = ReturnType<typeof useCalendarFieldsLazyQuery>;
export type CalendarFieldsSuspenseQueryHookResult = ReturnType<typeof useCalendarFieldsSuspenseQuery>;
export type CalendarFieldsQueryResult = Apollo.QueryResult<CalendarFieldsQuery, CalendarFieldsQueryVariables>;