import React, { useRef } from 'react'
import gql from 'graphql-tag'

import { useAppDispatch } from '@/utils/hooks'
import { publicFormUrl, getDjangoDomain } from '@/utils/url'
import { PUBLIC_FORM_CONTENT_TYPE } from '@/utils/constants'
import { showModal } from '@/state/modal/actions'
import EmailModalIcon from '@/components/email/icon'
import { Input } from '@/components/common/inputs'
import SimpleIconButton from '@/components/common/simple-icon-button'
import { useFormContext } from '@/state/main-form/reducer'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { ExternalLinkFragment, useDeactivatePublicFormMutation } from './__gen/external-link'
import style from './external-link.module.sass'



const getPublicUrl = (code: string) => {
	if(code) {
		return getDjangoDomain() + publicFormUrl(code)
	}
	else {
		return ""
	}
}

export const EXTERNAL_LINK_FRAGMENT = gql`
	fragment ExternalLink on PublicFormType {
		id
		active
		code
	}
`

gql`
	mutation deactivatePublicForm($id: ID!) {
		publicForm_Update(input: {id: $id, active: false}) {
			object {
				...ExternalLink
			}
		}
	}
	${EXTERNAL_LINK_FRAGMENT}
`

type Props = {
	externalLink: ExternalLinkFragment
}


const ExternalLink = ({ externalLink }: Props) => {
	const formContext = useFormContext()
	const publicUrlRef = useRef<HTMLInputElement>(null)
	const publicUrl = getPublicUrl(externalLink.code)
	const dispatch = useAppDispatch()
	const [deactivatePublicForm, { loading: mutationLoading }] = useDeactivatePublicFormMutation()
	return (
		<div className={style.externalLink}>
			<Input
				className={style.url}
				ref={publicUrlRef}
				readOnly
				value={publicUrl}
				onClick={() => publicUrlRef.current && publicUrlRef.current.select()}
			/>
			<div className={style.linkButtonRow}>
				<SimpleIconButton
					iconClass={iconStyle.link}
					title="Open link"
					onClick={() => window.open(publicUrl)}
				/>
				<EmailModalIcon
					recordId={formContext.record.id}
					formId={formContext.form.id}
					regardingObjectId={externalLink.id}
					regardingObjectContentType={PUBLIC_FORM_CONTENT_TYPE}
				/>
				
				<SimpleIconButton
					iconClass={iconStyle.bin}
					title="Delete link"
					description={`Note: A deleted link cannot be reactivated, you must create a new one`}
					onClick={() => {
						dispatch(showModal({
							title: 'Warning',
							content: "You can't reactivate a deleted link, only create a new one.",
							confirmClass: buttonStyle.red,
							confirmText: 'Delete',
							confirmAction: () => {
								deactivatePublicForm({variables: {id: externalLink.id}})
							}
						}))
					}}
				/>
			</div>
		</div>
	)
}

export default ExternalLink