import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldAggregationMetricQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  isSuiteAdminDashboard?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type FieldAggregationMetricQuery = { __typename: 'PharosQuery', fieldAggregationMetric: { __typename: 'FieldAggregationMetricType', id: string, name: string, result: { __typename: 'ValueResultType', value: string | null, formLink: { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string } | null } } | null };


export const FieldAggregationMetricDocument = gql`
    query fieldAggregationMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
  fieldAggregationMetric(id: $id) {
    id
    name
    result(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
      value
      formLink {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useFieldAggregationMetricQuery__
 *
 * To run a query within a React component, call `useFieldAggregationMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldAggregationMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldAggregationMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      isSuiteAdminDashboard: // value for 'isSuiteAdminDashboard'
 *   },
 * });
 */
export function useFieldAggregationMetricQuery(baseOptions: Apollo.QueryHookOptions<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables> & ({ variables: FieldAggregationMetricQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>(FieldAggregationMetricDocument, options);
      }
export function useFieldAggregationMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>(FieldAggregationMetricDocument, options);
        }
export function useFieldAggregationMetricSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>(FieldAggregationMetricDocument, options);
        }
export type FieldAggregationMetricQueryHookResult = ReturnType<typeof useFieldAggregationMetricQuery>;
export type FieldAggregationMetricLazyQueryHookResult = ReturnType<typeof useFieldAggregationMetricLazyQuery>;
export type FieldAggregationMetricSuspenseQueryHookResult = ReturnType<typeof useFieldAggregationMetricSuspenseQuery>;
export type FieldAggregationMetricQueryResult = Apollo.QueryResult<FieldAggregationMetricQuery, FieldAggregationMetricQueryVariables>;