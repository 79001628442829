import React, { useState } from 'react'

import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { Teams } from './teams'
import { Switch } from './switch'
import { AdminOrganisationsQuery } from './__gen/orgs'
import style from './org.module.sass'


type Props = {
	org: NonNullable<AdminOrganisationsQuery["organisationAdmin_List"]>["objects"][number]
}

export const Org = ({org}: Props) => {
	const [show, setShow] = useState(false);
	return (
		<div 
			key={org.id}
			className={style.orgContainer}
		>
			<div className={style.orgRow}>
				<div className={style.leftCol}>
					<div className={style.id}>{org.id}</div>
					<div className={style.orgName} onClick={() => setShow(!show)}>{org.name}</div>
					<div className={`${style.count} ${iconStyle.user}`}>{org.userCount}</div>
					<div className={`${style.count} ${iconStyle.doc}`}>{org.recordCount}</div>
				</div>
				<Switch orgId={org.id} >
					<button className={buttonStyle.small}>
						Switch
					</button>
				</Switch>
			</div>
			{show && <Teams orgId={org.id} />}
		</div>
	)
}