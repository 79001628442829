import React from 'react'

import iconStyle from '@sass/common/icon.module.sass'
import style from './index.module.sass'

type Props = {
	title?: React.ReactNode,
	content?: React.ReactNode,
}

const DEFAULT_MESSAGE = <>
	<p>Looks like something has gone wrong</p>
	<p>Please contact support if this issue persists</p>
</>

export const Error = ({title = "Error", content}: Props) => (
	<div className={style.container}>
		<div className={style.inner}>
			<h1 className={style.title}><span className={iconStyle.warning} />{title}</h1>
			{content || DEFAULT_MESSAGE}
		</div>
	</div>
)

export class ErrorBoundary extends React.Component<{children: React.ReactNode}> {
	state = {hasError: false}
	componentDidCatch() {
		this.setState({ hasError: true })
	}
	render() {
		if (this.state.hasError) {
			return <Error />
		}
		return this.props.children
	}
}

export const ErrorBoundaryHoc = <P extends {}>(ToWrap: React.ComponentType<P>) => (
	(props: P) => (
		<ErrorBoundary>
			<ToWrap {...props} />
		</ErrorBoundary>
	)
)