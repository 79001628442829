import React from 'react'
import gql from 'graphql-tag'
import { Spin } from 'antd'

import { Error } from '@/components/common/error-boundary'
import { MergePdfForm }  from './merge-pdf-form'
import { usePdfsToMergeQuery } from './__gen'



gql`
	query pdfsToMerge($recordId: ID!) {
		pdf_List(recordId: $recordId, orderBy: [{field: form_Category_Rank}, {field: form_Rank}]) {
			objects {
				id
				name
				created
				form {
					id
					category {
						id
						title
					}
				}
			}
		}
	}
`

type Props = {
	recordId: string
}

export const CreateMergedPdf = ({ recordId }: Props) => {
	const {data, loading, error } = usePdfsToMergeQuery({variables: { recordId }})
	return (
		<div>
			{ loading && <Spin size="large" /> }
			{ !loading && error && <Error /> }
			{ !loading && data?.pdf_List?.objects &&
				<MergePdfForm pdfs={data.pdf_List.objects} />
			}
		</div>
	)
}