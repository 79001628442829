import React from 'react'
import { SingleValueProps, InputProps, OptionProps, components } from 'react-select'
import c from 'classnames'
import iconStyle from '@sass/common/icon.module.sass'

import style from './option.module.sass'

export interface IOption {
	labelDisplay: React.ReactNode
	label: string
	color: string | null
}

export const OTHER_OPTION: IOption = {
	labelDisplay: (
		<div>
			Other &nbsp;<span className={iconStyle.pencil} />
		</div>
	),
	label: '_other',
	color: null,
}

const InnerOption = (data: IOption) => (
	<div className={c(style.container, { [style.otherContainer]: data === OTHER_OPTION })}>
		{data.color && <div className={style.colorDot} style={{backgroundColor: data.color}} />}
		<div className={style.label}>
			{data.labelDisplay}
		</div>
	</div>
)

export const Option = (props: OptionProps<IOption>) => (
	<components.Option {...props}>
		<InnerOption {...props.data} />
	</components.Option>
)

export const SingleValue = (props: SingleValueProps<IOption>) => (
	<div className={style.singleValueContainer}>
		<InnerOption {...props.data} />
	</div>
)

export const OptionInput = (props: InputProps) => (
		//@ts-ignore issue with @types library
		<div style={props.selectProps.value ? {height: 0, overflow: 'hidden'} : {}}>
			<components.Input {...props} />
		</div>
)