import React from 'react'
import { useFormContext } from '@/state/main-form/reducer'
import { TABLE_FIELD_CONTENT_TYPE } from '@/utils/constants'
import { ANSWER_LOOKUP } from './answer-types/lookup'
import FieldNotification from '../field-notification'
import {HelpInfoButton} from '../help-info/button'

import answerTableStyle from './row-table.module.sass'
import { TableFieldTypeEnum } from '@/state/main-form/types'


type Props = {
	fieldId: string
	tableFieldShowColumns: number
}

export const RowHeaders = ({fieldId, tableFieldShowColumns}: Props) => {
	const {fields} = useFormContext()
	const tableFields = fields[fieldId].tableFields

	const formContext = useFormContext()
	return (
		<div className={answerTableStyle.answerTable}>
			<div className={answerTableStyle.answerTableRow}>
				{tableFields.map((tf, i) => {
					if (i + 1 > tableFieldShowColumns) return null

					return (
						<div
							className={`${answerTableStyle.cell} ${answerTableStyle.tableFieldTitle}`}
							key={tf.id}
							style={ANSWER_LOOKUP[tf.type].size}
						>
							{tf.title}
							{tf.helpInfo && <HelpInfoButton title={tf.title} htmlContent={tf.helpInfo.htmlContent} />}
							{tf.type == TableFieldTypeEnum.Date && !formContext.publicForm &&
								<FieldNotification
									recordId={formContext.record.id}
									teamId={formContext.record.team.id}
									formId={formContext.form.id}
									fieldType={TABLE_FIELD_CONTENT_TYPE}
									fieldId={tf.id}
								/>
							}
						</div>
					)
				})}
			</div>
		</div>
	)
}