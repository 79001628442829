import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateMergedPdfMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  pdfIds: Array<Types.Scalars['ID']['input']>;
}>;


export type CreateMergedPdfMutation = { __typename: 'PharosMutations', mergedPdf_Update: { __typename: 'merged_pdfPayload', object: { __typename: 'MergedPdfType', id: string, name: string } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const CreateMergedPdfDocument = gql`
    mutation createMergedPdf($name: String!, $pdfIds: [ID!]!) {
  mergedPdf_Update(input: {name: $name, pdfIds: $pdfIds}) {
    object {
      id
      name
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateMergedPdfMutationFn = Apollo.MutationFunction<CreateMergedPdfMutation, CreateMergedPdfMutationVariables>;

/**
 * __useCreateMergedPdfMutation__
 *
 * To run a mutation, you first call `useCreateMergedPdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMergedPdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMergedPdfMutation, { data, loading, error }] = useCreateMergedPdfMutation({
 *   variables: {
 *      name: // value for 'name'
 *      pdfIds: // value for 'pdfIds'
 *   },
 * });
 */
export function useCreateMergedPdfMutation(baseOptions?: Apollo.MutationHookOptions<CreateMergedPdfMutation, CreateMergedPdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMergedPdfMutation, CreateMergedPdfMutationVariables>(CreateMergedPdfDocument, options);
      }
export type CreateMergedPdfMutationHookResult = ReturnType<typeof useCreateMergedPdfMutation>;
export type CreateMergedPdfMutationResult = Apollo.MutationResult<CreateMergedPdfMutation>;
export type CreateMergedPdfMutationOptions = Apollo.BaseMutationOptions<CreateMergedPdfMutation, CreateMergedPdfMutationVariables>;