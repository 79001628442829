import { PayloadAction, createSlice, isAnyOf } from '@reduxjs/toolkit'

import { useAppSelector } from '@/utils/hooks'
import { FieldType, FormType, InitialAnswerType, RecordType } from './types'
import { getFormAuth, getFormPublic, setFieldToHidden } from './thunks'
import { RequestFailures } from '@/utils/functions'
import { getAnswersAuth, getAnswersPublic } from '@/state/answer/thunks'
import { MainFormPublicQuery } from './get-main-form-public/__gen'


export type MainFormStateDataType = {
	organisation: {id: string}
	record: RecordType
	form: FormType
	fields: {
		[fieldId: string]: FieldType
	}
	initialAnswers: {
		[fieldId: string]: {
			[rank: number]: InitialAnswerType[]
		}
	}
	publicForm: {
		id: string,
		requestEmailOnSubmit: boolean,
		formAction: NonNullable<MainFormPublicQuery["publicFormPub"]>["formAction"] | null,
		code: string
	} | null
	fullScreenField: string | null
}

export type MainFormStateType = {
	loading: boolean
	error: RequestFailures | null
	data: MainFormStateDataType  | null
}

const transformInitialAnswers = <T extends InitialAnswerType,>(inArray: T[])  => {
	let initialAnswers: {[fieldId: string]: {[rank: number]: T[]}} = {}
	for (const initialAnswer of inArray) {
		if (!initialAnswers[initialAnswer.fieldId]) {
			initialAnswers[initialAnswer.fieldId] = {}
		}
		if (!initialAnswers[initialAnswer.fieldId][initialAnswer.rank]) {
			initialAnswers[initialAnswer.fieldId][initialAnswer.rank] = []

		}
		initialAnswers[initialAnswer.fieldId][initialAnswer.rank].push(initialAnswer)
	}
	return initialAnswers
}


export const mainFormSlice = createSlice({
	name: 'mainForm',
	initialState: {
		loading: false,
		error: null,
		data: null
	} as MainFormStateType,
	reducers: {
		setLockedFromFormAction: (state, action: PayloadAction<boolean>): MainFormStateType => (
			state.data?.form
				? {...state, data: {...state.data, form: {...state.data.form, lockedFromFormAction: action.payload}}}
				: state
			)
	},
	extraReducers: (builder) => {
		builder
		.addCase(getFormAuth.fulfilled, (state, { payload, meta }) => {
			state.loading = false
			if (!payload.form || !payload.record || !payload.initialAnswers) {
				return state
			}
			const fields: MainFormStateDataType["fields"] = {}
			for (const field of payload.form!.fields) {
				fields[field.id] = field
			}
			state.data = {
				form: payload.form,
				record: payload.record,
				fields,
				initialAnswers: transformInitialAnswers(payload.initialAnswers),
				publicForm: null,
				fullScreenField: null,
				organisation: {
					id: payload.record.team.organisation.id
				}
			}
		})
		.addCase(getFormPublic.fulfilled, (state, { payload, meta }) => {
			state.loading = false
			if (!payload.form || !payload.record) {
				return state
			}
			const fields: MainFormStateDataType["fields"] = {}
			for (const field of payload.form!.fields) {
				fields[field.id] = field
			}
			state.data = {
				form: payload.form,
				record: payload.record,
				fields: fields,
				initialAnswers: transformInitialAnswers(payload.initialAnswers),
				publicForm: {
					id: payload.id,
					requestEmailOnSubmit: payload.requestEmailOnSubmit,
					formAction: payload.formAction,
					code: meta.arg.publicFormCode,
				},
				fullScreenField: null,
				organisation: {
					id: payload.record.team.organisation.id
				}
			}
		})
		.addCase(setFieldToHidden.fulfilled, (state, { payload, meta }) => {
			if (!state.data) {
				return
			}
			state.data.fields[payload.field.id] = {
				...state.data.fields[payload.field.id],
				hiddenFieldId: payload.field.hiddenFieldId,
			}
		})
		.addMatcher(isAnyOf(getFormAuth.pending, getFormPublic.pending), (state, { payload, meta }) => {
			state = {
				loading: true,
				error: null,
				data: null,
			}
			return state
		})
		.addMatcher<RequestFailures>(isAnyOf(
			getAnswersAuth.rejected,
			getAnswersPublic.rejected,
			getFormAuth.rejected,
			getFormPublic.rejected
		), (state, { payload, meta }) => {
			state = {
				loading: false,
				error: payload || state.error || RequestFailures.UNKNOWN_ERROR,
				data: null,
			}
			return state
		})
	},
})


export const useFormContext = () => {
	return useAppSelector(state =>  state.mainForm.data as NonNullable<MainFormStateType['data']>)
}

export default mainFormSlice.reducer
