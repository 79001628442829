import React from 'react'
import { RouteComponentProps } from 'react-router-dom'
import gql from "graphql-tag"

import Placeholder from '@/components/common/placeholder'
import EditUserForm from './form'
import UserTeamEdit from './user-team-edit'
import style from './index.module.sass'
import DeleteUserButton from './delete-button'
import { ResetMfaButton } from './reset-mfa-button'
import { useUserToManageQuery } from './__gen/edit'


gql`
	query userToManage($id: ID!) {
		user(id: $id) {
			id
			firstName
			lastName
			email
			topLevelManager
			webauthnCredentials {
				id
			}
			teams {
				id
				name
			}
		}
	}
`

type Props = RouteComponentProps<{userId: string}>

export default (props: Props) => {
	const { data, error, loading, refetch } = useUserToManageQuery(
		{ variables: {id: props.match.params.userId} }
	)

	return (
		<div className={style.container}>
			{loading
				? (
					<>
						<Placeholder />
						<Placeholder style={{height: 230}} />
						<Placeholder />
						<Placeholder style={{height: 200}} />
					</>
				)
				: (!data?.user || error)
					? <div>Error</div>
					: (
						<>
							<div className={style.titleRow}>
								<h3>User details</h3>
								<div style={{display: 'flex'}}>
									<ResetMfaButton user={data.user} refetchUser={refetch} />
									<DeleteUserButton user={data.user} />
								</div>
							</div>
							
							<EditUserForm user={data.user} />
							<UserTeamEdit user={data.user} />
						</>
					)
			}
		</div>
	)
}