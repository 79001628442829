import React, { Component } from 'react'

import { SinglePanel } from '@/components/common/panel'
import Form from './form'
import { useTitle } from '@/utils/hooks'

export const OrganisationSettings = () => {
	useTitle('Organisation Settings')
	return (
		<>
			<h3 style={{textAlign: 'center'}}>Organisation Details</h3>
			<Form />
		</>
	)
}
