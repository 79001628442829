import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminResetMfaMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type AdminResetMfaMutation = { __typename: 'PharosMutations', webauthnCredentials_AdminResetForUser: { __typename: 'WebauthnAdminResetForUser', success: boolean | null } | null };


export const AdminResetMfaDocument = gql`
    mutation adminResetMfa($userId: ID!) {
  webauthnCredentials_AdminResetForUser(resetUserId: $userId) {
    success
  }
}
    `;
export type AdminResetMfaMutationFn = Apollo.MutationFunction<AdminResetMfaMutation, AdminResetMfaMutationVariables>;

/**
 * __useAdminResetMfaMutation__
 *
 * To run a mutation, you first call `useAdminResetMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminResetMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminResetMfaMutation, { data, loading, error }] = useAdminResetMfaMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAdminResetMfaMutation(baseOptions?: Apollo.MutationHookOptions<AdminResetMfaMutation, AdminResetMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminResetMfaMutation, AdminResetMfaMutationVariables>(AdminResetMfaDocument, options);
      }
export type AdminResetMfaMutationHookResult = ReturnType<typeof useAdminResetMfaMutation>;
export type AdminResetMfaMutationResult = Apollo.MutationResult<AdminResetMfaMutation>;
export type AdminResetMfaMutationOptions = Apollo.BaseMutationOptions<AdminResetMfaMutation, AdminResetMfaMutationVariables>;