import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import gql from "graphql-tag"

import { useGlobalContext } from '@/components/context'
import { showModal } from '@/state/modal/actions'
import { useMarkWelcomeModalSeenMutation } from './__gen'

gql`
	mutation markWelcomeModalSeen($id: ID!) {
		user_Update(
			input: {
				id: $id,
				seenWelcomeMessage: true
			}
		) {
			object {
				id
				seenWelcomeMessage
			}
		}
	}
`

export const WelcomeModal = () => {
	const { user, organisation } = useGlobalContext()
	const [shown, updateShown] = useState(false)
	const [mutate, {}] = useMarkWelcomeModalSeenMutation()
	const dispatch = useDispatch()
	useEffect(() => {
		if (!shown && user && !user.seenWelcomeMessage) {
			updateShown(true)
			mutate({variables: {id:user.id}})
			if(organisation.suite.welcomeMessageHtml) {
				dispatch(showModal({
					content: <div
						dangerouslySetInnerHTML={{
							__html: organisation.suite.welcomeMessageHtml
						}}
					/>,
					cancelText: 'OK',
				}))
			}
		}
	}, [user && user.id])

	return null
}
