import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionEscalationFragment = { __typename: 'FormActionEscalationType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, deltaDays: number, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null };

export type EscalationsForActionQueryVariables = Types.Exact<{
  record: Types.Scalars['ID']['input'];
  form: Types.Scalars['ID']['input'];
}>;


export type EscalationsForActionQuery = { __typename: 'PharosQuery', formActionEscalation_List: { __typename: 'FormActionEscalationTypeListBase', objects: Array<{ __typename: 'FormActionEscalationType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, deltaDays: number, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null }> } | null };

export const FormActionEscalationFragmentDoc = gql`
    fragment formActionEscalation on FormActionEscalationType {
  id
  assignedAnonEmail
  assignedAnonName
  assignedAnonEmailAnswer {
    id
    content
    contactName
  }
  assignedUser {
    id
    firstName
    lastName
    email
  }
  deleted
  deltaDays
}
    `;
export const EscalationsForActionDocument = gql`
    query escalationsForAction($record: ID!, $form: ID!) {
  formActionEscalation_List(record: $record, form: $form) {
    objects {
      ...formActionEscalation
    }
  }
}
    ${FormActionEscalationFragmentDoc}`;

/**
 * __useEscalationsForActionQuery__
 *
 * To run a query within a React component, call `useEscalationsForActionQuery` and pass it any options that fit your needs.
 * When your component renders, `useEscalationsForActionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEscalationsForActionQuery({
 *   variables: {
 *      record: // value for 'record'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useEscalationsForActionQuery(baseOptions: Apollo.QueryHookOptions<EscalationsForActionQuery, EscalationsForActionQueryVariables> & ({ variables: EscalationsForActionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EscalationsForActionQuery, EscalationsForActionQueryVariables>(EscalationsForActionDocument, options);
      }
export function useEscalationsForActionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EscalationsForActionQuery, EscalationsForActionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EscalationsForActionQuery, EscalationsForActionQueryVariables>(EscalationsForActionDocument, options);
        }
export function useEscalationsForActionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<EscalationsForActionQuery, EscalationsForActionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<EscalationsForActionQuery, EscalationsForActionQueryVariables>(EscalationsForActionDocument, options);
        }
export type EscalationsForActionQueryHookResult = ReturnType<typeof useEscalationsForActionQuery>;
export type EscalationsForActionLazyQueryHookResult = ReturnType<typeof useEscalationsForActionLazyQuery>;
export type EscalationsForActionSuspenseQueryHookResult = ReturnType<typeof useEscalationsForActionSuspenseQuery>;
export type EscalationsForActionQueryResult = Apollo.QueryResult<EscalationsForActionQuery, EscalationsForActionQueryVariables>;