import React from 'react'
import gql from 'graphql-tag'
import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import PieMetric from '../../types/pie'
import MetricPlaceholder from '../metric-placeholder'
import { useFormStatusMetricQuery } from './__gen'


gql`
	query formStatusMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		formStatusMetric(id: $id) {
			id
			name
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				name
				color
				formLinks {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FormStatusMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useFormStatusMetricQuery({
		variables: {
			id,
			teamId: globalContext.currentTeam?.id,
			isSuiteAdminDashboard,
		}
	})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.formStatusMetric) {
		return <div>error</div>
	}
	return (
		<PieMetric
			useFormTitleInList={true}
			isSuiteAdminDashboard={isSuiteAdminDashboard}
			{...data?.formStatusMetric}
		/>
	)

}

export default FormStatusMetricWrapper