import React from 'react'
import gql from 'graphql-tag'
import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import generalStyle from '@sass/common/general.module.sass'
import ListMetric from '../../types/list'
import MetricPlaceholder from '../metric-placeholder'
import { useNotificationsMetricQuery } from './__gen'


gql`
	query notificationsMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		notificationsMetric(id: $id) {
			id
			name
			type
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				content
				contentObjectName
				formLink {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`

type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const RED = '#e62b2b'

const NotificationsMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useNotificationsMetricQuery({
		variables: {
			id,
			teamId: globalContext.currentTeam?.id,
			isSuiteAdminDashboard
		},
	})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.notificationsMetric) {
		return (
			<div className={generalStyle.muted}>
				We're having trouble fetching this metric.
				If this issue persists, please contact support
			</div>
		)
	}

	const extraProps = data.notificationsMetric.type == 'PAST'
		? {color: RED}
		: {}

	return (
		<ListMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...extraProps} {...data?.notificationsMetric} />
	)

}

export default NotificationsMetricWrapper