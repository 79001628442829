import React from 'react'

import { OptionalLink } from '@/components/common/optional-link'
import { useGlobalContext } from '@/components/context'
import { formUrl } from '@/utils/url'
import { FormLink, NoData, ListItemTooltip } from '../common'
import style from './index.module.sass'


type Props = {
	__typename?: string
	useFieldTitle?: boolean
	isSuiteAdminDashboard?: boolean
	color?: string
	values: {
		__typename?: string
		content: string
		contentObjectName: string
		formLink: FormLink | null
	}[]
}

const ListMetric = ({ values, useFieldTitle, color, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	if (values.length == 0) {
		return <NoData />
	}
	
	return (
		<div className={style.container}>
			{ values.map((v, i) => {
				const toUrl = v.formLink && !isSuiteAdminDashboard
					? formUrl({
						recordId: v.formLink?.recordId,
						formId: v.formLink?.formId,
						highlightFieldId: v.formLink?.fieldId,
						...globalContext,
					})
					: null
				return (
					<ListItemTooltip
						key={i}
						formLink={v.formLink}
						isSuiteAdminDashboard={isSuiteAdminDashboard}
					>
						<OptionalLink
							to={toUrl}
							className={style.row}
						>
							<div className={style.topRow}>
								<div className={style.leftContent} style={color ? { color } : {}}>
									{v.formLink?.recordName} 
								</div>
								<div>
									{v.content} 
								</div>
							</div>
							<div className={style.bottomContent}>
								{v.formLink?.fieldTitle}
							</div>
						</OptionalLink>
					</ListItemTooltip>
				)
			})}
		</div>
	)
}

export default ListMetric