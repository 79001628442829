import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormsForRecordQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type FormsForRecordQuery = { __typename: 'PharosQuery', record: { __typename: 'RecordType', id: string, name: string, position: string, archived: boolean, team: { __typename: 'TeamType', id: string, name: string }, template: { __typename: 'TemplateType', id: string, formSet: { __typename: 'FormSetType', id: string, forms: Array<{ __typename: 'FormType', id: string, hasCreatedPdf: boolean | null, hasSaved: boolean | null, title: string, rank: number, category: { __typename: 'CategoryType', id: string, title: string | null, rank: number, collapsible: boolean } | null, dependency: { __typename: 'FormType', id: string } | null }> } } } | null };

export type UpdateForm_HasSavedFragment = { __typename: 'FormType', hasSaved: boolean | null };

export type UpdateForm_HasCreatedPdfFragment = { __typename: 'FormType', hasCreatedPdf: boolean | null };

export const UpdateForm_HasSavedFragmentDoc = gql`
    fragment UpdateForm_hasSaved on FormType {
  hasSaved(recordId: "")
}
    `;
export const UpdateForm_HasCreatedPdfFragmentDoc = gql`
    fragment UpdateForm_hasCreatedPdf on FormType {
  hasCreatedPdf(recordId: "")
}
    `;
export const FormsForRecordDocument = gql`
    query formsForRecord($id: ID!) {
  record(id: $id) {
    id
    name
    position
    archived
    team {
      id
      name
    }
    template {
      id
      formSet {
        id
        forms(orderBy: [{field: category_Rank}, {field: rank}]) {
          id
          hasCreatedPdf(recordId: $id)
          hasSaved(recordId: $id)
          title
          rank
          category {
            id
            title
            rank
            collapsible
          }
          dependency {
            id
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFormsForRecordQuery__
 *
 * To run a query within a React component, call `useFormsForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormsForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormsForRecordQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFormsForRecordQuery(baseOptions: Apollo.QueryHookOptions<FormsForRecordQuery, FormsForRecordQueryVariables> & ({ variables: FormsForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormsForRecordQuery, FormsForRecordQueryVariables>(FormsForRecordDocument, options);
      }
export function useFormsForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormsForRecordQuery, FormsForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormsForRecordQuery, FormsForRecordQueryVariables>(FormsForRecordDocument, options);
        }
export function useFormsForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormsForRecordQuery, FormsForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormsForRecordQuery, FormsForRecordQueryVariables>(FormsForRecordDocument, options);
        }
export type FormsForRecordQueryHookResult = ReturnType<typeof useFormsForRecordQuery>;
export type FormsForRecordLazyQueryHookResult = ReturnType<typeof useFormsForRecordLazyQuery>;
export type FormsForRecordSuspenseQueryHookResult = ReturnType<typeof useFormsForRecordSuspenseQuery>;
export type FormsForRecordQueryResult = Apollo.QueryResult<FormsForRecordQuery, FormsForRecordQueryVariables>;