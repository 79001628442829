import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdviceSectionQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdviceSectionQuery = { __typename: 'PharosQuery', adviceSection_List: { __typename: 'AdviceSectionTypeListBase', objects: Array<{ __typename: 'AdviceSectionType', id: string, categories: Array<{ __typename: 'AdviceCategoryType', id: string, title: string, rank: number, subcategories: Array<{ __typename: 'AdviceSubcategoryType', id: string, title: string, content: string, rank: number }> }> }> } | null };


export const AdviceSectionDocument = gql`
    query adviceSection {
  adviceSection_List(limit: 4) {
    objects {
      id
      categories(orderBy: {field: rank}) {
        id
        title
        rank
        subcategories(orderBy: {field: rank}) {
          id
          title
          content
          rank
        }
      }
    }
  }
}
    `;

/**
 * __useAdviceSectionQuery__
 *
 * To run a query within a React component, call `useAdviceSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdviceSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdviceSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdviceSectionQuery(baseOptions?: Apollo.QueryHookOptions<AdviceSectionQuery, AdviceSectionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdviceSectionQuery, AdviceSectionQueryVariables>(AdviceSectionDocument, options);
      }
export function useAdviceSectionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdviceSectionQuery, AdviceSectionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdviceSectionQuery, AdviceSectionQueryVariables>(AdviceSectionDocument, options);
        }
export function useAdviceSectionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdviceSectionQuery, AdviceSectionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdviceSectionQuery, AdviceSectionQueryVariables>(AdviceSectionDocument, options);
        }
export type AdviceSectionQueryHookResult = ReturnType<typeof useAdviceSectionQuery>;
export type AdviceSectionLazyQueryHookResult = ReturnType<typeof useAdviceSectionLazyQuery>;
export type AdviceSectionSuspenseQueryHookResult = ReturnType<typeof useAdviceSectionSuspenseQuery>;
export type AdviceSectionQueryResult = Apollo.QueryResult<AdviceSectionQuery, AdviceSectionQueryVariables>;