import { RightCircleOutlined, RightCircleTwoTone } from "@ant-design/icons"
import { useGlobalContext } from "@/components/context"
import gql from "graphql-tag"
import _delay from "lodash/delay"
import React, { useEffect } from "react"
import { pharosAdminUrl } from "@/utils/url"

import { Switch } from "../pages/admin/search-results/switch"

import { useAdminOrganisationsQuery } from "./__gen/icon-admin"
import Icon from "./icon"
import style from "./icon-admin.module.sass"

gql`
	query adminOrganisations {
		adminOrgSwitchHistory_List(limit: 6, orderBy: [{ field: lastSwitch, direction: DESC }]) {
			objects {
				id
				organisation {
					id
					name
				}
			}
		}
	}
`

type Props = {
	closeDropdown: () => void
}

export const IconAdmin = ({ closeDropdown }: Props) => {
	const { organisation } = useGlobalContext()

	const { data, refetch } = useAdminOrganisationsQuery()

	useEffect(() => {
		refetch()
	}, [organisation.id])

	const options = (data?.adminOrgSwitchHistory_List?.objects || [])
		.filter((o) => o.organisation.id != organisation.id)
		.map((o) => ({
			name: (
				<Switch orgId={o.organisation.id}>
					<RightCircleOutlined /> {o.organisation.name}
				</Switch>
			),
		}))

	return (
		<Icon
			iconLink={pharosAdminUrl()}
			activePath={pharosAdminUrl()}
			icon={'mediumUsersSwap'}
			title={
				<>
					<div>Superuser</div>
					<div className={style.orgName}>
						<RightCircleTwoTone /> {organisation.name}
					</div>
				</>
			}
			closeDropdown={closeDropdown}
			moveDropdown={-20 + -40 * options.length}
			links={[
				...options,
				{
					name: (
						<div className={style.selectGeneral}>
							<RightCircleOutlined /> Select...
						</div>
					),
					to: pharosAdminUrl(),
				},
				{
					name: <a href="/admin">Django admin</a>,
				},
			]}
			small
		/>
	)
}
