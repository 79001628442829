import React from 'react'

import { AdviceSectionQuery } from '../__gen'
import style from './index.module.sass'

type SectionsType = NonNullable<AdviceSectionQuery["adviceSection_List"]>["objects"]


type Props = {
	sections: SectionsType
	currentSubcategoryId: string | null
}

const AdviceRightPanel = (props: Props) => {
	let match = null
	for(const section of props.sections) {
		for(const cat of section.categories) {
			if (!match) {
				match = cat.subcategories.find(sub => sub.id === props.currentSubcategoryId) || null
			}
		}
	}

	if (!match) return <div>Error: Category doesn't exist</div>

	return (
		<div className={style.container}>
			<div className={style.inner}>
				<h5>{match.title}</h5>
				<div dangerouslySetInnerHTML={{__html: match.content}} />
			</div>
		</div>
	)
}

export default AdviceRightPanel