import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnswerFileToDownloadQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type AnswerFileToDownloadQuery = { __typename: 'PharosQuery', answerFile: { __typename: 'AnswerFileType', id: string, name: string | null, modified: string, field: { __typename: 'FieldType', id: string, title: string } } | null };


export const AnswerFileToDownloadDocument = gql`
    query answerFileToDownload($id: ID!) {
  answerFile(id: $id) {
    id
    name
    modified
    field {
      id
      title
    }
  }
}
    `;

/**
 * __useAnswerFileToDownloadQuery__
 *
 * To run a query within a React component, call `useAnswerFileToDownloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerFileToDownloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerFileToDownloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAnswerFileToDownloadQuery(baseOptions: Apollo.QueryHookOptions<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables> & ({ variables: AnswerFileToDownloadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>(AnswerFileToDownloadDocument, options);
      }
export function useAnswerFileToDownloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>(AnswerFileToDownloadDocument, options);
        }
export function useAnswerFileToDownloadSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>(AnswerFileToDownloadDocument, options);
        }
export type AnswerFileToDownloadQueryHookResult = ReturnType<typeof useAnswerFileToDownloadQuery>;
export type AnswerFileToDownloadLazyQueryHookResult = ReturnType<typeof useAnswerFileToDownloadLazyQuery>;
export type AnswerFileToDownloadSuspenseQueryHookResult = ReturnType<typeof useAnswerFileToDownloadSuspenseQuery>;
export type AnswerFileToDownloadQueryResult = Apollo.QueryResult<AnswerFileToDownloadQuery, AnswerFileToDownloadQueryVariables>;