import React from 'react'
import { Link } from 'react-router-dom'
import c from 'classnames'

import { userManagementEditUrl } from '@/utils/url'
import { useRouter } from '@/components/context'
import { Pill } from '@/components/common/pill'
import { UserManagementListQuery } from './__gen'
import style from './user-item.module.sass'

type UserType = NonNullable<UserManagementListQuery["user_List"]>["objects"][number]


export default (props: {user: UserType}) => {
	const router = useRouter()
	const activeLink = userManagementEditUrl(props.user.id) === router.location.pathname
	return (
		<Link
			to={userManagementEditUrl(props.user.id)}
			className={style.user}
		>
			<div className={style.topRow}>
				<div className={c(style.name, {[style.userActive]: activeLink})}>
					{props.user.firstName} {props.user.lastName}
				</div>
				{props.user.topLevelManager &&
					<>&nbsp;&nbsp;<Pill color='blue'>ADMIN</Pill></>
				}
			</div>
			<div className={style.email}>
				{props.user.email}
			</div>
			
		</Link>
	)
}
