import React from 'react'
import { Link } from 'react-router-dom'

import TeamForm from './team-form'
import { SinglePanel } from '@/components/common/panel'
import { useGlobalContext } from '@/components/context'
import formStyle from '@sass/common/form.module.sass'

export default () => {
	const globalContext = useGlobalContext()
	const team =globalContext.currentTeam
	if (!team) {
		return <div>Error: no team</div>
	}
	return (
		<SinglePanel type="thin"> 
			<div className={formStyle.pageFormContainer}>
				<TeamForm team={team} />
			</div>
		</SinglePanel>
	)
}