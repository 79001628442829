import React from 'react'
import gql from "graphql-tag"

import { LargeSpinner } from '@/components/common/loading'
import { useTitle } from '@/utils/hooks'
import formStyle from '@sass/common/form.module.sass'
import SignupForm from './signup-form'
import { usePublicSchemasQuery } from './__gen'


gql`
	query publicSchemas {
		schemaPublic_List(orderBy: [{field: name}]) {
			objects {
				id
				name
				public
				signupSubdomain
				moduleSetItems {
					module {
						id
						name
					}
				}
			}
		}
	}
`


export const Signup = () => {
	const { data, loading} = usePublicSchemasQuery()
	useTitle('Sign Up')
	return (
		<div className={formStyle.pageFormContainer}>
			<div className={formStyle.pageForm}>
				{ loading 
					? <LargeSpinner />
					: <SignupForm suiteOptions={data!.schemaPublic_List!.objects} />
				}
			</div>
			<br/><br/>
		</div>
	)
}
