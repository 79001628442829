import React, { useEffect, useRef, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { LoadingBar } from '@/components/common/loading'
import { Input } from '@/components/common/inputs'
import { getQueryParamString } from '@/utils/functions'
import { MAPBOX_PUBLIC_TOKEN } from '@/utils/constants'
import { updateAnswers } from '@/state/answer/thunks'
import { useDebounce } from '@/utils/hooks'
import { useAppDispatch } from '@/utils/hooks'
import generalStyle from '@sass/common/general.module.sass'
import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'

type Result = {
	title: string
	subtitle: string
	id: string
}

type ResultSet = {
	results: Result[]
	error: boolean
}

const emptyResultSet: ResultSet = {error: false, results: []}
const MAPBOX_URL = `https://api.mapbox.com/search/searchbox/v1/retrieve/`

const stubbedResults = async ({search}: {search: string}) => {
	// Useful for debugging / offline dev
	const placeStrings: Result[] = [
		{title: '80 Rowe Street', subtitle: 'Fitzroy North VIC 3068', id: uuidv4()},
		{title: '80 Rowe Street', subtitle: 'Bananaville Perth', id: uuidv4()},
		{title: '80 Rowina Road', subtitle: 'Fitzroy North, VIC', id: uuidv4()},
		{title: '80 Roggle Place', subtitle: 'Melbourne, Australia', id: uuidv4()},
		{title: '8000 Rumba Drive', subtitle: 'Haha Town in the Universe', id: uuidv4()},
		{title: '8000 Bing Bong Street', subtitle: 'Blah location haha', id: uuidv4()},
	]
	await new Promise<void>((resolve) => {
		setTimeout(() => {resolve()}, 80)
	})
	const results = placeStrings.filter(s => (s.title + ' ' + s.subtitle).startsWith(search))

	return {
		results,
		error: false,
	}
}

const stubbedRetreive = async ({id, sessionToken}: {id: string, sessionToken: string}) => {
	// Useful for debugging / offline dev
	await new Promise<void>((resolve) => {
		setTimeout(() => {resolve()}, 80)
	})
	return {
		content: "Stubbed retrieved address",
		lng: 24,
		lat: 12,
		error: false
	}
}

const getAutocomplete = async ({search, sessionToken}: {search: string, sessionToken: string}): Promise<ResultSet> => {
	// return stubbedResults({search})

	const queryParams = getQueryParamString({
		q: search,
		access_token: MAPBOX_PUBLIC_TOKEN,
		session_token: sessionToken,
		limit: 3,
	})

	const url = `https://api.mapbox.com/search/searchbox/v1/suggest${queryParams}`

	try {
		const response = await fetch(url);
		const data = await response.json() as {suggestions: {name: string, place_formatted: string, mapbox_id: string}[]};
		return {
			results: data.suggestions.map(s => ({title: s.name, subtitle: s.place_formatted, id: s.mapbox_id})),
			error: false,
		}
	 } catch (error) {
		console.error('Error fetching suggestions:', error);
		return {
			results: [],
			error: true
		}
	 }
}

type FinalResult = {
	content: string,
	lat: string,
	lng: string,
	error: boolean,
}

const retreive = async ({id, sessionToken}: {id: string, sessionToken: string}): Promise<FinalResult> => {
	// return stubbedRetreive({id, sessionToken})
	const queryParams = getQueryParamString({
		access_token: MAPBOX_PUBLIC_TOKEN,
		session_token: sessionToken,
	})

	const errorResult: FinalResult = {
		content: "",
		lat: "",
		lng: "",
		error: true
	}

	try {
		const response = await fetch(`${MAPBOX_URL}${id}${queryParams}`);
		const data = await response.json() as {
			features: {geometry: {coordinates: [number, number]},
			properties: {
				full_address: string
				name: string
				place_formatted: string
		}}[]};
		const resultData = data.features[0]
		if (!resultData) {
			return errorResult
		}
		return {
			content: resultData.properties.full_address || resultData.properties + " " + resultData.properties.place_formatted,
			lat: resultData.geometry.coordinates[1].toFixed(6).toString(),
			lng: resultData.geometry.coordinates[0].toFixed(6).toString(),
			error: false
		}
	 } catch (error) {
		console.error('Error fetching final result with geometry:', error);
		return errorResult
	 }
}


const AnswerAddress = ({ answer, field, disabled}: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()
	const input = useRef<HTMLInputElement>(null)
	const [sessionToken, _] = useState(uuidv4())
	const [inputText, setInputText] = useState(answer.content || "")
	const [isValid, setIsValid] = useState(true)

	const [autocompleteResults, setAutocompleteResults] = useState<ResultSet>(emptyResultSet)
	const [loadingAutocomplete, setLoadingAutocomplete] = useState(false)
	const [focused, setFocused] = useState(false)
	const [debouncedContent, setDebouncedContent] = useState(inputText)

	const enabled = focused && (inputText).length > 3

	const [, cancel] = useDebounce(
		() => {
			if (!enabled) {
				return
			}
			setDebouncedContent(inputText)
		},
		300,
		[inputText, enabled]
	)

	const onInputTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setInputText(e.target.value)
		if (!e.target.value) {
			dispatch(updateAnswers({ answers: [{ ...answer, content: ""}] }))
			setIsValid(true)
		}
		else {
			setIsValid(false)
		}

	}
	
	const select = async ({id}: {id: string}) => {
		setIsValid(true)
		const result = await retreive({id, sessionToken})
		if (result.error) {
			// Timing the blue & valid to show error is difficult so optimistically update and revert
			setIsValid(false)
		}
		setInputText(result.content)
		dispatch(updateAnswers({ answers: [{ ...answer, content: result.content, lat: result.lat, lng: result.lng}] }))
		setAutocompleteResults(emptyResultSet)
	}

	useEffect(() => {
		if (isValid) {
			input.current?.blur()
		}
	}, [isValid])

	useEffect(() => {
		if (!debouncedContent || !enabled) {
			return
		}
		setLoadingAutocomplete(true)
		getAutocomplete({search: debouncedContent, sessionToken: sessionToken}).then(res => {
			setLoadingAutocomplete(false)
			setAutocompleteResults(res)
		})
		return () => {cancel()}
	}, [debouncedContent, enabled])

	return (
		disabled ? (
			<div className={commonStyle.answerTextDisabled}>
				{answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		)
		: (
			<div className={style.container}>
				<Input
					ref={input}
					onChange={onInputTextChange}
					onFocus={() => setFocused(true)}
					onBlur={() => setFocused(false)}
					placeholder={field.placeholder || undefined}
					value={inputText}
				/>
				{enabled &&
					<div className={style.autocompleteContainer}>
						<div className={style.autocompleteContainerInner}>
							{autocompleteResults.results.map(a => (
								<div
									key={a.id}
									className={style.autocompleteItem}
									onMouseDown={() => select({id: a.id})}
								>
									<div className={style.autocompleteTitle}>{a.title}</div>
									<div>{a.subtitle}</div>
								</div>
							))}
							{loadingAutocomplete && <LoadingBar />}
						</div>
					</div>
				}
				{!focused && !isValid &&
					<div className={generalStyle.error}>Please select an address from the dropdown</div>
				}
			</div>
		)
	)
}

export default AnswerAddress
