export const ORGANISATION_CONTENT_TYPE = 'organisation'
export const TEAM_CONTENT_TYPE = 'team'
export const EMPLOYEE_CONTENT_TYPE = 'record'
export const PUBLIC_FORM_CONTENT_TYPE = 'publicform'
export const PDF_CONTENT_TYPE = 'pdf'
export const FIELD_CONTENT_TYPE = 'field'
export const TABLE_FIELD_CONTENT_TYPE = 'tablefield'
export const ANSWER_TEXT_CONTENT_TYPE = 'answertext'
export const USER_CONTENT_TYPE = 'user'
export const EMAIL_CONTENT_TYPE = 'email'

export const FILE_CONTENT_TYPES = [
	ORGANISATION_CONTENT_TYPE,
	TEAM_CONTENT_TYPE,
	EMPLOYEE_CONTENT_TYPE,
]

export const TEMP_ID_PREFIX = `temp-`

export {
	SITES
} from '@/types/common'

export {
	DJANGO_SITE_NAME as SITE_NAME,
	DJANGO_GOOGLE_ANALYTICS_ID as GOOGLE_ANALYTICS_ID,
} from './global-vars'

export const MAPBOX_PUBLIC_TOKEN = 'pk.eyJ1IjoicGhhcm9zaHJhZG1pbiIsImEiOiJjbTRja2h3Nm4wZHRlMmxwZm12eHJ3ZDE4In0.c8c8ksS0TM4MzkgGG8vB5A'