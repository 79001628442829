import { Typography } from 'antd'
import { format } from 'date-fns'
import React from 'react'

import { getUserDetails } from '../../../common/utils'
import { AddFormState } from '../add'
import { CYCLE_MAP, getStringFromCycle } from '../common'

const Title = Typography.Title

type Props = {
	state: AddFormState | null
	formAction: { reminderLast: Date | null; reminderNext: Date | null } | null
}

export const Details = ({ state, formAction }: Props) => {
	const hasSchedule = state && !state.deleted
	const details = getUserDetails(state)
	return (
		<>
			{hasSchedule ?
				<div style={{ display: 'flex', justifyContent: 'space-between', maxWidth: 650 }}>
					<div>
						<Title level={5}>Assigned to</Title>
						{details.type} - {details.name}
						<br />
						{details.email}
					</div>

					<div>
						<Title level={5}>Due</Title>
						{format(new Date(new Date(state.dueAt)), 'dd/MM/yyyy')}
						<br />
						Repeating {CYCLE_MAP[getStringFromCycle(state)].label.toLocaleLowerCase()}
					</div>

					{formAction && (
						<div>
							{(formAction.reminderLast || formAction.reminderNext) && <Title level={5}>Reminders</Title>}
							{formAction.reminderLast && (
								<>
									Last: &nbsp;
									{format(new Date(new Date(formAction.reminderLast)), 'dd/MM/yyyy')}
									<br />
								</>
							)}
							{formAction.reminderNext && (
								<>
									Next: &nbsp;
									{format(new Date(new Date(formAction.reminderNext)), 'dd/MM/yyyy')}
								</>
							)}
						</div>
					)}
				</div>
			:	<p>
					Assign this form to a user or email. Users will be notified and reminded in order to complete this
					action. Reminders will stop after 1 year.
				</p>
			}

			<br />
			<br />
		</>
	)
}
