import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamSettingsUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  reminderFrequencyDays?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  active: Types.Scalars['Boolean']['input'];
}>;


export type TeamSettingsUpdateMutation = { __typename: 'PharosMutations', team_Update: { __typename: 'teamPayload', object: { __typename: 'TeamType', id: string, name: string, reminderFrequencyDays: number } | null } | null };


export const TeamSettingsUpdateDocument = gql`
    mutation teamSettingsUpdate($id: ID!, $name: String!, $reminderFrequencyDays: Int, $active: Boolean!) {
  team_Update(
    input: {id: $id, name: $name, reminderFrequencyDays: $reminderFrequencyDays, active: $active}
  ) {
    object {
      id
      name
      reminderFrequencyDays
    }
  }
}
    `;
export type TeamSettingsUpdateMutationFn = Apollo.MutationFunction<TeamSettingsUpdateMutation, TeamSettingsUpdateMutationVariables>;

/**
 * __useTeamSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useTeamSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTeamSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [teamSettingsUpdateMutation, { data, loading, error }] = useTeamSettingsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      reminderFrequencyDays: // value for 'reminderFrequencyDays'
 *      active: // value for 'active'
 *   },
 * });
 */
export function useTeamSettingsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<TeamSettingsUpdateMutation, TeamSettingsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TeamSettingsUpdateMutation, TeamSettingsUpdateMutationVariables>(TeamSettingsUpdateDocument, options);
      }
export type TeamSettingsUpdateMutationHookResult = ReturnType<typeof useTeamSettingsUpdateMutation>;
export type TeamSettingsUpdateMutationResult = Apollo.MutationResult<TeamSettingsUpdateMutation>;
export type TeamSettingsUpdateMutationOptions = Apollo.BaseMutationOptions<TeamSettingsUpdateMutation, TeamSettingsUpdateMutationVariables>;