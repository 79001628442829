import c from 'classnames'
import React from 'react'
import { useDispatch } from 'react-redux'

import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { toggleFullScreenField } from '@/state/user-interface'
import style from './index.module.sass'
import { useAppSelector } from '@/utils/hooks'


const ShrinkButtonInner = () => {
	const fullScreenField = useAppSelector(state => state.userInterface.fullScreenField)
	const dispatch = useDispatch()

	if(!fullScreenField) {
		return null
	}
	
	else {
		return (
			<button
				className={c(style.shrinkButton, iconStyle.shrink, buttonStyle.primarySolid)}
				onClick={() => dispatch(toggleFullScreenField(null))}
			>
				Show menu
			</button>
		)
	}
}

export default ShrinkButtonInner