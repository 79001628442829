import { GLOBAL_CONTEXT_EMPTY, GlobalContextProps, useRouter } from '@/components/context'
import { Context, GLOBAL_CONTEXT_PARAMS, calendarBaseUrl, calendarUrl, formActionMetricsBaseUrl, formActionMetricsUrl, formActionMetricsUrlDefault, mapUrl, teamAggregatedTableBaseUrl, teamAggregatedTableUrl, teamConcatenatedTableBaseUrl, teamConcatenatedTableUrl, teamMetricsUrl } from '../url'
import { matchPath } from 'react-router'


export type AnalyticsRoutes =
 | 'ModuleDashboard'
 | 'FormActionMetrics'
 | 'Calendar'
 | 'Map'
 | 'ConcatenatedTable'
 | 'AggregatedTable'



type RouteFn = {
	baseFn: (curr: GlobalContextProps) => string
	nextFn: (p: {
		nextContext: GlobalContextProps
		currPath: string
	}) => string | null
	isAvailableFn?: (p: {nextContext: GlobalContextProps, currContext: GlobalContextProps}) => boolean
}


// This is a bit complicated, but it keeps the context so that switching between teams/modules which
// is nice UX
export const ANALYTICS_ROUTE_TRANSLATION: {[b in AnalyticsRoutes]: RouteFn} = {
	ModuleDashboard: {
		baseFn: teamMetricsUrl,
		nextFn: ({nextContext}) => teamMetricsUrl(nextContext),
	},
	FormActionMetrics: {
		baseFn: formActionMetricsBaseUrl,
		isAvailableFn: ({nextContext}) => nextContext.currentModule.showActionDashboard,
		nextFn: ({nextContext, currPath}) => {
			const match = matchPath(currPath, formActionMetricsUrl({...GLOBAL_CONTEXT_PARAMS, startYear: ':startYear', startMonth: ':startMonth'}))
			const params: {[k: string]: string} = match?.params || {}
			return params.startYear && params.startMonth
				? formActionMetricsUrl({...nextContext, startYear: params.startYear, startMonth: params.startMonth})
				: null
		},
	},
	Calendar: {
		baseFn: calendarBaseUrl,
		isAvailableFn: ({nextContext}) => nextContext.currentModule.showCalendar,
		nextFn: ({nextContext, currPath}) => {
			const match = matchPath(currPath, calendarUrl({...GLOBAL_CONTEXT_PARAMS, year: ':year', month: ':month'}))
			const params: {[k: string]: string} = match?.params || {}
			return params.year && params.month
				? calendarUrl({...nextContext, year: params.year, month: params.month})
				: null
		}
	},
	Map: {
		baseFn: mapUrl,
		nextFn: ({nextContext}) => nextContext.currentModule.showMap
			? mapUrl(nextContext)
			: null
	},
	AggregatedTable: {
		baseFn: teamAggregatedTableBaseUrl,
		isAvailableFn: ({nextContext, currContext}) => currContext.currentModule.id == nextContext.currentModule.id,
		nextFn: ({nextContext, currPath}) => {
			const match = matchPath(currPath, teamAggregatedTableUrl({...GLOBAL_CONTEXT_PARAMS, tableId: ':tableId'}))
			const params: {[k: string]: string} = match?.params || {}
			return params.tableId
				? teamAggregatedTableUrl({...nextContext, tableId: params.tableId})
				: null
		}
	},
	ConcatenatedTable: {
		baseFn: teamConcatenatedTableBaseUrl,
		isAvailableFn: ({nextContext, currContext}) => currContext.currentModule.id == nextContext.currentModule.id,
		nextFn: ({nextContext, currPath}) => {
			const match = matchPath(currPath, teamConcatenatedTableUrl({...GLOBAL_CONTEXT_PARAMS, tableId: ':tableId'}))
			const params: {[k: string]: string} = match?.params || {}
			return params.tableId
				? teamConcatenatedTableUrl({...nextContext, tableId: params.tableId})
				: null
		}
	},
}


type RelatedAnalyticsUrlParams = {
	router: ReturnType<typeof useRouter>,
	currContext: GlobalContextProps,
	nextContext: GlobalContextProps,
}

export const relatedAnalyticsUrl = ({router, currContext, nextContext}: RelatedAnalyticsUrlParams) => {
	const currPath = router.location.pathname

	const fallback = teamMetricsUrl(nextContext)

	if (!currContext.currentModule) {
		// If we're switching from admin pages, just default as we don't have a current module
		return fallback
	}

	for (let {baseFn, nextFn, isAvailableFn} of Object.values(ANALYTICS_ROUTE_TRANSLATION).values()) {
		const isAvailable = !isAvailableFn || isAvailableFn({currContext, nextContext})
		if (!isAvailable) {
			continue
		}
		const base = baseFn(currContext)
		if (!currPath.startsWith(base)) {
			continue
		}

		const next = nextFn({nextContext, currPath})
		if (next) {
			return next
		}
	}
	return fallback
}
