import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { RecordEssentialsFragmentDoc } from '../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateRecordMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  position: Types.Scalars['String']['input'];
  template: Types.Scalars['ID']['input'];
  copyRecord?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  team: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  image?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  moveDatesTo?: Types.InputMaybe<Types.Scalars['Int']['input']>;
  moveDatesType?: Types.InputMaybe<Types.MoveDatesType>;
}>;


export type UpdateRecordMutation = { __typename: 'PharosMutations', record_Update: { __typename: 'recordPayload', object: { __typename: 'RecordType', id: string, archived: boolean, image: any | null, name: string, position: string, template: { __typename: 'TemplateType', id: string, formSet: { __typename: 'FormSetType', id: string, forms: Array<{ __typename: 'FormType', id: string }> } }, team: { __typename: 'TeamType', id: string } } | null } | null };

export type RecordTemplateOptionsQueryVariables = Types.Exact<{
  modules: Types.Scalars['ID']['input'];
}>;


export type RecordTemplateOptionsQuery = { __typename: 'PharosQuery', templateOptions: { __typename: 'TemplateTypeListBase', objects: Array<{ __typename: 'TemplateType', id: string, position: string, namePlaceholder: string | null, copyPositionText: boolean }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null, recordOptions: { __typename: 'RecordTypeListBase', objects: Array<{ __typename: 'RecordType', id: string, name: string, position: string, template: { __typename: 'TemplateType', id: string, namePlaceholder: string | null } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const UpdateRecordDocument = gql`
    mutation updateRecord($id: ID, $position: String!, $template: ID!, $copyRecord: ID, $team: ID!, $name: String!, $image: Upload, $moveDatesTo: Int, $moveDatesType: MoveDatesType) {
  record_Update(
    input: {id: $id, team: $team, position: $position, name: $name, image: $image, template: $template, copyRecord: $copyRecord, moveDatesTo: $moveDatesTo, moveDatesType: $moveDatesType}
  ) {
    object {
      ...RecordEssentials
      template {
        id
        formSet {
          id
          forms(orderBy: [{field: rank}], limit: 1) {
            id
          }
        }
      }
    }
  }
}
    ${RecordEssentialsFragmentDoc}`;
export type UpdateRecordMutationFn = Apollo.MutationFunction<UpdateRecordMutation, UpdateRecordMutationVariables>;

/**
 * __useUpdateRecordMutation__
 *
 * To run a mutation, you first call `useUpdateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecordMutation, { data, loading, error }] = useUpdateRecordMutation({
 *   variables: {
 *      id: // value for 'id'
 *      position: // value for 'position'
 *      template: // value for 'template'
 *      copyRecord: // value for 'copyRecord'
 *      team: // value for 'team'
 *      name: // value for 'name'
 *      image: // value for 'image'
 *      moveDatesTo: // value for 'moveDatesTo'
 *      moveDatesType: // value for 'moveDatesType'
 *   },
 * });
 */
export function useUpdateRecordMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecordMutation, UpdateRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecordMutation, UpdateRecordMutationVariables>(UpdateRecordDocument, options);
      }
export type UpdateRecordMutationHookResult = ReturnType<typeof useUpdateRecordMutation>;
export type UpdateRecordMutationResult = Apollo.MutationResult<UpdateRecordMutation>;
export type UpdateRecordMutationOptions = Apollo.BaseMutationOptions<UpdateRecordMutation, UpdateRecordMutationVariables>;
export const RecordTemplateOptionsDocument = gql`
    query recordTemplateOptions($modules: ID!) {
  templateOptions: template_List(
    limit: 1000
    offset: 0
    module: $modules
    orderBy: [{field: copyPositionText, direction: DESC}, {field: position, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      position
      namePlaceholder
      copyPositionText
    }
    pageInfo {
      hasNextPage
      total
    }
  }
  recordOptions: record_List(
    limit: 1000
    offset: 0
    orderBy: [{field: position}, {field: name}]
  ) {
    objects {
      id
      name
      position
      template {
        id
        namePlaceholder
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useRecordTemplateOptionsQuery__
 *
 * To run a query within a React component, call `useRecordTemplateOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordTemplateOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordTemplateOptionsQuery({
 *   variables: {
 *      modules: // value for 'modules'
 *   },
 * });
 */
export function useRecordTemplateOptionsQuery(baseOptions: Apollo.QueryHookOptions<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables> & ({ variables: RecordTemplateOptionsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>(RecordTemplateOptionsDocument, options);
      }
export function useRecordTemplateOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>(RecordTemplateOptionsDocument, options);
        }
export function useRecordTemplateOptionsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>(RecordTemplateOptionsDocument, options);
        }
export type RecordTemplateOptionsQueryHookResult = ReturnType<typeof useRecordTemplateOptionsQuery>;
export type RecordTemplateOptionsLazyQueryHookResult = ReturnType<typeof useRecordTemplateOptionsLazyQuery>;
export type RecordTemplateOptionsSuspenseQueryHookResult = ReturnType<typeof useRecordTemplateOptionsSuspenseQuery>;
export type RecordTemplateOptionsQueryResult = Apollo.QueryResult<RecordTemplateOptionsQuery, RecordTemplateOptionsQueryVariables>;