import React, { useState } from 'react'
import { Select, InputNumber, Button, Spin } from 'antd'

import { useGlobalContext } from '@/components/context'
import style from './add.module.sass'
import { ModalProps } from '../../common'
import { UserSelect, EVERYONE } from './user-select'
import { useCreateNotificationUserMutation, useSaveFieldNotificationMutation } from './__gen/queries'
import { NotifiationsUserListQuery } from './__gen'


const BEFORE = 'Before'
const AFTER = 'After'
type BeforeAftertype = typeof BEFORE | typeof AFTER




type Props =  ModalProps & {
	refetch: () => void
	users: NonNullable<NotifiationsUserListQuery["user_List"]>["objects"]
}

const AddFieldNotification = ({ fieldId, fieldType, refetch, users }: Props) => {
	const globalContext = useGlobalContext()
	const [newDelta, updateNewDelta] = useState(0)
	const [beforeOrAfter, setBeforeOrAfter] = useState<BeforeAftertype>(BEFORE)
	const [sendTo, setSendTo] = useState<string>(globalContext.user.id)
	const [createNotificationMutation, { loading: saving }] = useSaveFieldNotificationMutation()
	const [createNotificationUserMutation, { loading: creatingNotificationUser }] = useCreateNotificationUserMutation()


	const createFieldNotification = async () => {
		const r = await createNotificationMutation({variables: {
			fieldId: fieldId.toString(),
			fieldType,
			sendToOrganisation: sendTo == EVERYONE,
			delta: Math.min(newDelta, 365) * (beforeOrAfter === BEFORE ? -1 : 1)
		}})

		if (!r.data?.fieldNotification_Update?.object?.id) {
			throw Error('fieldNotificationUpdateError!')
		}

		const fieldNotificationId = r.data?.fieldNotification_Update?.object?.id
		if (sendTo != EVERYONE) {
			await createNotificationUserMutation({variables: {
				fieldNotificationId,
				userId: sendTo || globalContext.user.id,
			}})
		}

		refetch()
	}
	return (
		<div className={style.saveRow}>
			<div className={style.addRow}>
				Notify
				<UserSelect
					users={users}
					onSelect={setSendTo}
				/>

				<InputNumber
					defaultValue={newDelta}
					min={0}
					style={{width: 65}}
					max={365}
					placeholder="Days"
					onChange={(v) => updateNewDelta(v || 0)}
				/>
				days
				<Select defaultValue={BEFORE} style={{ width: 90 }} onChange={(v: BeforeAftertype) => setBeforeOrAfter(v)}>
					<Select.Option value={BEFORE}>{BEFORE}</Select.Option>
					<Select.Option value={AFTER}>{AFTER}</Select.Option>
				</Select>
				
				{ saving
					? <Spin />
					:	(
						<Button
							type="primary"
							onClick={createFieldNotification}
						>
							Add
						</Button>
					)
				}
			</div>
			
		</div>
	)
}

export default AddFieldNotification



