import { Auth } from "@/components/auth"
import { RedirectToLogin } from "@/components/common/redirect-to-login"
import { WrappedRouteNotFound } from "@/components/common/route-not-found"
import { Dashboard } from "@/components/dashboard"
import { DashboardLoading } from "@/components/dashboard/loading"
import Form from "@/components/form"
import { PublicFormSuccess } from "@/components/general/full-page-messages/PublicFormSuccess"
import { WelcomeModal } from "@/components/general/welcome-modal"
import { PublicRecordCreate } from "@/components/public-record-create"
import { USER_ESSENTIALS_FRAGMENT } from "@/fragments"
import gql from "graphql-tag"
import _pick from "lodash/pick"
import React, { useEffect, useMemo } from "react"
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom"
import {
	accountUrl,
	dashboardUrl,
	getTeamParams,
	loginUrl,
	oldPublicLandingPageUrl,
	publicCreateRecordUrl,
	publicFormSuccessUrl,
	publicFormUrl,
	switchingOrgsUrl,
} from "@/utils/url"

import { useCurrentUserQuery } from "./__gen"
import { GLOBAL_CONTEXT_EMPTY, GlobalContext, RouterContext } from "./context"
import MainModal from "./general/modal/main"
import Notifications from "./general/notifications"

declare const FS: any

gql`
	query currentUser {
		user_List(isSelf: true) {
			objects {
				...UserEssentials
			}
		}
	}
	${USER_ESSENTIALS_FRAGMENT}
`

type Props = RouteComponentProps

const App = (props: Props) => {
	const { data, loading } = useCurrentUserQuery()
	const params = getTeamParams(props.location.pathname)

	// useMemo so that the globalContext only changes if it needs to
	// thus avoiding unecessary rerenders
	const globalContext = useMemo(() => {
		const user_List = data && data.user_List
		const user = user_List && user_List.objects && user_List.objects.length > 0 && user_List.objects[0]
		const teamId = params.teamId == "all" ? null : params.teamId
		if (user) {
			const organisation = user.organisation
			const currentModuleSetItem = organisation.suite.moduleSetItems.find(
				(m) => m.module.id == params.moduleId,
			)
			const currentModule = currentModuleSetItem?.module!
			const currentTeam = (currentModule && currentModule.teams.find((t) => t.id == teamId)) || null
			return {
				organisation,
				user,
				currentModule,
				currentTeam,
			}
		} else {
			return GLOBAL_CONTEXT_EMPTY
		}
	}, [params.moduleId, params.teamId, data?.user_List?.objects[0]])

	useEffect(() => {
		if (globalContext.user && globalContext.user.id) {
			try {
				FS.identify(globalContext.user.id, {
					email: globalContext.user.email,
					organisationId: globalContext.organisation!.id,
					organisationName: globalContext.organisation!.name,
				})
			} catch (e) {}
		}
	}, [globalContext.user && globalContext.user.id])

	return (
		<RouterContext.Provider value={_pick(props, ["history", "location", "match"])}>
			<GlobalContext.Provider value={globalContext}>
				{loading ? (
					<DashboardLoading />
				) : (
					<>
						<Switch>
							<Route path="/" exact component={RedirectToLogin} />
							<Route path={oldPublicLandingPageUrl()} component={RedirectToLogin} />
							<Route path={accountUrl()} component={Auth} />
							{/* Redirc old login route, as some people have bookmarked, (changed on the 20th July 2019) */}
							<Redirect from="/login" to={{ pathname: loginUrl() }} />
							<Route path={dashboardUrl()} component={Dashboard} />
							<Route path={publicFormUrl(":publicFormCode")} component={Form} />
							<Route path={publicFormSuccessUrl()} component={PublicFormSuccess} />
							<Route
								path={publicCreateRecordUrl({ teamCode: ":teamCode", linkCode: ":linkCode" })}
								component={PublicRecordCreate}
							/>
							{!(
								props.location.pathname === "/" ||
								props.location.pathname.startsWith(oldPublicLandingPageUrl())
							) && <Route path="*" component={WrappedRouteNotFound} />}
						</Switch>
						<MainModal />
						<Notifications />
						<WelcomeModal />
					</>
				)}
			</GlobalContext.Provider>
		</RouterContext.Provider>
	)
}

const AppContainer = (props: Props) => {
	return (
		<>
			{props.location.pathname == switchingOrgsUrl() ? (
				<DashboardLoading>
					<h5>Switching orgs. You will be redirected shortly</h5>
				</DashboardLoading>
			) : (
				<App {...props} />
			)}
		</>
	)
}

export default AppContainer
