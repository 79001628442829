import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type NotificationsMetricQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  isSuiteAdminDashboard?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type NotificationsMetricQuery = { __typename: 'PharosQuery', notificationsMetric: { __typename: 'NotificationsMetricType', id: string, name: string, type: Types.AnalyticsUpcomingNotificationsMetricTypeChoices, values: Array<{ __typename: 'FieldDateValueType', content: string, contentObjectName: string, formLink: { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string } | null }> } | null };


export const NotificationsMetricDocument = gql`
    query notificationsMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
  notificationsMetric(id: $id) {
    id
    name
    type
    values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
      content
      contentObjectName
      formLink {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useNotificationsMetricQuery__
 *
 * To run a query within a React component, call `useNotificationsMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      isSuiteAdminDashboard: // value for 'isSuiteAdminDashboard'
 *   },
 * });
 */
export function useNotificationsMetricQuery(baseOptions: Apollo.QueryHookOptions<NotificationsMetricQuery, NotificationsMetricQueryVariables> & ({ variables: NotificationsMetricQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsMetricQuery, NotificationsMetricQueryVariables>(NotificationsMetricDocument, options);
      }
export function useNotificationsMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsMetricQuery, NotificationsMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsMetricQuery, NotificationsMetricQueryVariables>(NotificationsMetricDocument, options);
        }
export function useNotificationsMetricSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NotificationsMetricQuery, NotificationsMetricQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<NotificationsMetricQuery, NotificationsMetricQueryVariables>(NotificationsMetricDocument, options);
        }
export type NotificationsMetricQueryHookResult = ReturnType<typeof useNotificationsMetricQuery>;
export type NotificationsMetricLazyQueryHookResult = ReturnType<typeof useNotificationsMetricLazyQuery>;
export type NotificationsMetricSuspenseQueryHookResult = ReturnType<typeof useNotificationsMetricSuspenseQuery>;
export type NotificationsMetricQueryResult = Apollo.QueryResult<NotificationsMetricQuery, NotificationsMetricQueryVariables>;