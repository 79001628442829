import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { FormActionFragmentDoc } from '../../../common/__gen/query';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UndoFormActionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UndoFormActionMutation = { __typename: 'PharosMutations', formAction_Update: { __typename: 'form_actionPayload', object: { __typename: 'FormActionType', id: string, assignedAnonEmail: string, assignedAnonName: string, completedOn: string | null, deleted: boolean, isScheduled: boolean, link: string | null, priority: number, reminderNext: any | null, reminderLast: any | null, requestSignature: boolean, userSuppliedContext: string, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, form: { __typename: 'FormType', pdfGenerators: Array<{ __typename: 'PdfGeneratorType', id: string, name: string }> } } | null } | null };


export const UndoFormActionDocument = gql`
    mutation undoFormAction($id: ID!) {
  formAction_Update(
    input: {id: $id, completedOn: null, signatureImage: null, signatureText: ""}
  ) {
    object {
      ...FormAction
    }
  }
}
    ${FormActionFragmentDoc}`;
export type UndoFormActionMutationFn = Apollo.MutationFunction<UndoFormActionMutation, UndoFormActionMutationVariables>;

/**
 * __useUndoFormActionMutation__
 *
 * To run a mutation, you first call `useUndoFormActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoFormActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoFormActionMutation, { data, loading, error }] = useUndoFormActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndoFormActionMutation(baseOptions?: Apollo.MutationHookOptions<UndoFormActionMutation, UndoFormActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoFormActionMutation, UndoFormActionMutationVariables>(UndoFormActionDocument, options);
      }
export type UndoFormActionMutationHookResult = ReturnType<typeof useUndoFormActionMutation>;
export type UndoFormActionMutationResult = Apollo.MutationResult<UndoFormActionMutation>;
export type UndoFormActionMutationOptions = Apollo.BaseMutationOptions<UndoFormActionMutation, UndoFormActionMutationVariables>;