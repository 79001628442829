import React, { useReducer } from 'react'
import { SpinnerButton } from '@/components/common/button'
import buttonStyle from '@sass/common/button.module.sass'
import style from './index.module.sass'

type Props = {
	save: () => void
	close: () => void
	left?: React.ReactNode
	loading: boolean
}

export const BottomRow = (props: Props) => (
	<div className={style.bottomRow}>
		<div className={style.buttonGroup}>
			{props.left ? props.left : <div />}
		</div>
		<div className={style.buttonGroup}>
			<button className={buttonStyle.greySolid} onClick={props.close}>Cancel</button>
			<SpinnerButton loading={props.loading} className={buttonStyle.primarySolid} onClick={props.save}>Save</SpinnerButton>
		</div>
	</div>
)
