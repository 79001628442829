import React, {useState} from 'react'
import { useGlobalContext } from '@/components/context'
import iconStyle from '@sass/common/icon.module.sass'
import { ModalProps } from '../../common'
import { UserSelect, EVERYONE } from './user-select'
import style from './item.module.sass'
import { useCreateNotificationUserMutation, useDeactivateNotificationMutation, useDeactivateNotificationUserMutation, useSaveFieldNotificationMutation } from './__gen/queries'
import { NotifiationsUserListQuery } from './__gen'
import { FieldNotificationsForUserQuery } from '../../__gen'


const getText = (num: number) => {
	const dayWord = Math.abs(num) == 1 ? " day" : " days"
	if(num > 0) return num + dayWord + ' after'
	else if(num < 0) return -num + dayWord + ' before'
	else if(num == 0) return 'On the day'
}

type PillProps = {
	title: string
	pillClass: string
	onDelete?: () => void
}

const Pill = ({title, pillClass, onDelete}: PillProps) => (
	<div className={style.pillcontainer}>
		<div className={pillClass}>{title}</div>
		{onDelete &&
			<div className={style.pillDelete} onClick={onDelete}>x</div>
		}
	</div>
)

type NotificationType = NonNullable<FieldNotificationsForUserQuery["fieldNotification_List"]>["objects"][number]

type ItemProps = ModalProps & {
	notification: NotificationType
	users: NonNullable<NotifiationsUserListQuery["user_List"]>["objects"]
	refetch: () => Promise<{}>
}

export const NotificationItem = ({notification, users, refetch, ...modalProps}: ItemProps) => {
	const globalContext = useGlobalContext()
	const [deactivateNotificationMutation, {}] = useDeactivateNotificationMutation()
	const [deactivateNotificationUserMutation, {}] = useDeactivateNotificationUserMutation()
	const [createNotificationUserMutation, {}] = useCreateNotificationUserMutation()
	const [saveNotificationMutation, {}] = useSaveFieldNotificationMutation()

	const deactivateNotificationUser = (u: NotificationType['fieldNotificationUsers'][0]) => {
		deactivateNotificationUserMutation({variables: {id: u.id}})
	}
	
	const deactivateNotification = async (n: NotificationType) => {
		await deactivateNotificationMutation({variables: {id: n.id}})
	}

	const [showAdd, setShowAdd] = useState(false);

	const handleUserAdd = async (userId: string) => {
		setShowAdd(false)
		if (userId == EVERYONE) {
			await saveNotificationMutation({variables: {
				id: notification.id,
				delta: notification.delta,
				fieldId: modalProps.fieldId.toString(),
				fieldType: modalProps.fieldType,
				sendToOrganisation: !notification.sendToOrganisation,
			}})
		} else {
			await createNotificationUserMutation({variables: {userId, fieldNotificationId: notification.id}})
		}
		await refetch()
	}

	const notificationUsers = notification.fieldNotificationUsers.filter(u => u.isActive)

	return (
		<div className={style.row}>
			<div className={style.deltaText}>{getText(notification.delta)}</div>
			<div className={style.sendToContainer}>
				<div>
					{notificationUsers.length == 0 && !notification.sendToOrganisation &&
						<Pill title="Nobody" pillClass={style.nobody} />
					}
					{notification.sendToOrganisation &&
						<Pill title="Everyone" pillClass={style.everyone} onDelete={() => handleUserAdd(EVERYONE)} />
					}
					{notificationUsers.map(u => (
						<Pill
							key={u.id}
							title={`${u.user.firstName} ${u.user.lastName}`}
							pillClass={style.user}
							onDelete={() => deactivateNotificationUser(u)}
						/>
					))}
					{!notification.sendToOrganisation && !showAdd &&
						<div className={style.addPeople} onClick={() => setShowAdd(!showAdd)}>Add more people</div>
					}
					{showAdd &&
						<div className={style.userSelectContainer}>
							<UserSelect
								users={users.filter(u1 => !notificationUsers.find(u2 => u1.id == u2.user.id))}
								onSelect={handleUserAdd}
							/>
						</div>
					}
				</div>
			</div>
			<div style={{width: 30}}>
				{ (!notification.sendToOrganisation || globalContext.user.topLevelManager) &&
					<div
						className={`${iconStyle.bin} ${style.deleteIcon}`}
						onClick={() => deactivateNotification(notification)}
					/>
				}
			</div>
		</div>
	)
}
