import gql from 'graphql-tag'
import React from 'react'
import Placeholder from '@/components/common/placeholder'
import generalStyle from '@sass/common/general.module.sass'
import { usePdfGeneratorsForFormQuery } from './__gen'
import { PDF_FRAGMENT } from './pdf'
import PdfGenerator from './pdf-generator'

type Props = {
	readOnly: boolean
	recordId: string
	formId: string
}

// NOTE: This query is references as a refetch in formActions
gql`
	query pdfGeneratorsForForm($formId: ID!, $recordId: ID!) {
		pdfGenerator_List(formId: $formId, limit: 20, offset: 0) {
			objects {
				id
				name
				pdfs(recordId: $recordId) {
					...Pdf
				}
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
	${PDF_FRAGMENT}
`

const PdfSection = (props: Props) => {
	const { data, loading } = usePdfGeneratorsForFormQuery({
		variables: {
			formId: props.formId,
			recordId: props.recordId,
		},
	})

	const generators = data?.pdfGenerator_List?.objects

	return (
		<>
			{generators &&
				generators.map((generator) => (
					<PdfGenerator key={generator.id} {...props} pdfGenerator={generator} />
				))}
			{loading && !generators && <Placeholder style={{ height: 100 }} />}
			{!generators && !loading && <div className={generalStyle.error}> Pdf section error</div>}
		</>
	)
}

export default PdfSection
