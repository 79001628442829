import React from 'react'

import { SinglePanel } from '@/components/common/panel'

export const PublicFormSuccess = () => {
  	return (
		<SinglePanel type="thin">
			<br/>
			<h1>Thanks for completing your form. It has been submitted</h1>
		</SinglePanel>
  	)
}
