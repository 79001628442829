import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ExternalLinkFragment = { __typename: 'PublicFormType', id: string, active: boolean, code: string };

export type DeactivatePublicFormMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeactivatePublicFormMutation = { __typename: 'PharosMutations', publicForm_Update: { __typename: 'public_formPayload', object: { __typename: 'PublicFormType', id: string, active: boolean, code: string } | null } | null };

export const ExternalLinkFragmentDoc = gql`
    fragment ExternalLink on PublicFormType {
  id
  active
  code
}
    `;
export const DeactivatePublicFormDocument = gql`
    mutation deactivatePublicForm($id: ID!) {
  publicForm_Update(input: {id: $id, active: false}) {
    object {
      ...ExternalLink
    }
  }
}
    ${ExternalLinkFragmentDoc}`;
export type DeactivatePublicFormMutationFn = Apollo.MutationFunction<DeactivatePublicFormMutation, DeactivatePublicFormMutationVariables>;

/**
 * __useDeactivatePublicFormMutation__
 *
 * To run a mutation, you first call `useDeactivatePublicFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePublicFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePublicFormMutation, { data, loading, error }] = useDeactivatePublicFormMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivatePublicFormMutation(baseOptions?: Apollo.MutationHookOptions<DeactivatePublicFormMutation, DeactivatePublicFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivatePublicFormMutation, DeactivatePublicFormMutationVariables>(DeactivatePublicFormDocument, options);
      }
export type DeactivatePublicFormMutationHookResult = ReturnType<typeof useDeactivatePublicFormMutation>;
export type DeactivatePublicFormMutationResult = Apollo.MutationResult<DeactivatePublicFormMutation>;
export type DeactivatePublicFormMutationOptions = Apollo.BaseMutationOptions<DeactivatePublicFormMutation, DeactivatePublicFormMutationVariables>;