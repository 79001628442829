import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConcatenatedTableDataQueryVariables = Types.Exact<{
  concatenatedTableId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  limit: Types.Scalars['Int']['input'];
  offset: Types.Scalars['Int']['input'];
  ordering: Array<Types.InputMaybe<Types.ConcatenatedTableOrdering>>;
  search: Types.Scalars['String']['input'];
}>;


export type ConcatenatedTableDataQuery = { __typename: 'PharosQuery', concatenatedTableData: { __typename: 'ConcatenatedTableDataType', hasNextPage: boolean | null, rows: Array<{ __typename: 'ConcatenatedTableDataRowType', jsonData: string, link: { __typename: 'ConcatenatedTableDataRowLinkType', formId: string, recordId: string, teamId: string, fieldId: string } }> } | null };


export const ConcatenatedTableDataDocument = gql`
    query concatenatedTableData($concatenatedTableId: ID!, $teamId: ID, $limit: Int!, $offset: Int!, $ordering: [ConcatenatedTableOrdering]!, $search: String!) {
  concatenatedTableData(
    concatenatedTableId: $concatenatedTableId
    teamId: $teamId
    limit: $limit
    offset: $offset
    ordering: $ordering
    search: $search
  ) {
    rows {
      jsonData
      link {
        formId
        recordId
        teamId
        fieldId
      }
    }
    hasNextPage
  }
}
    `;

/**
 * __useConcatenatedTableDataQuery__
 *
 * To run a query within a React component, call `useConcatenatedTableDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcatenatedTableDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcatenatedTableDataQuery({
 *   variables: {
 *      concatenatedTableId: // value for 'concatenatedTableId'
 *      teamId: // value for 'teamId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      ordering: // value for 'ordering'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useConcatenatedTableDataQuery(baseOptions: Apollo.QueryHookOptions<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables> & ({ variables: ConcatenatedTableDataQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>(ConcatenatedTableDataDocument, options);
      }
export function useConcatenatedTableDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>(ConcatenatedTableDataDocument, options);
        }
export function useConcatenatedTableDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>(ConcatenatedTableDataDocument, options);
        }
export type ConcatenatedTableDataQueryHookResult = ReturnType<typeof useConcatenatedTableDataQuery>;
export type ConcatenatedTableDataLazyQueryHookResult = ReturnType<typeof useConcatenatedTableDataLazyQuery>;
export type ConcatenatedTableDataSuspenseQueryHookResult = ReturnType<typeof useConcatenatedTableDataSuspenseQuery>;
export type ConcatenatedTableDataQueryResult = Apollo.QueryResult<ConcatenatedTableDataQuery, ConcatenatedTableDataQueryVariables>;