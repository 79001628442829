import React, { useRef } from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { LoadingBar } from '@/components/common/loading'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { PdfGeneratorsForFormQuery } from './__gen'
import Pdf from './pdf'
import style from './pdf-list.module.sass'
import pdfStyle from './pdf.module.sass'

type Props = {
	creating: boolean
	newPdfName: string
	pdfs: NonNullable<PdfGeneratorsForFormQuery['pdfGenerator_List']>['objects'][number]['pdfs']
}

export const PdfList = ({ creating, newPdfName, pdfs }: Props) => {
	const nodeRef = useRef(null)
	return (
		<TransitionGroup>
			{creating && (
				<CSSTransition
					nodeRef={nodeRef}
					classNames={'new-pdf-animation'}
					timeout={{ enter: 300, exit: 300 }}
					key="generating-pdf"
					enter={true}
					exit={false}
				>
					<div className={pdfStyle.pdf} ref={nodeRef}>
						<div className={pdfStyle.pdfTextContainer}>
							<div className={generalStyle.muted}>
								<span className={`${pdfStyle.icon} ${iconStyle.doc}`}></span>
								<b>{newPdfName}</b>
							</div>
							<div className={style.loadingBarContainer}>
								<LoadingBar small />
							</div>
							<div className={generalStyle.muted}>Generating</div>
						</div>
						<div className={style.dropdownPlaceholder} />
					</div>
				</CSSTransition>
			)}

			{pdfs.map((pdf, i) => (
				<CSSTransition
					key={pdf.id}
					classNames={'new-pdf-animation'}
					timeout={{ enter: 300, exit: 300 }}
					exit={true}
				>
					<Pdf pdf={pdf} />
				</CSSTransition>
			))}
		</TransitionGroup>
	)
}
