import React, { useRef } from 'react'
import c from 'classnames'
import { CSSTransition, TransitionGroup } from 'react-transition-group'

import style from './index.module.sass'
import { useAppSelector } from '@/utils/hooks'

const Notifications = () => {
	const notifications = useAppSelector(state => state.userInterface.notifications)
	const nodeRef = useRef(null);
	
	return (
		<div className={style.container}>
			<TransitionGroup>
				{[...notifications].reverse().map(n => 
					<CSSTransition
						nodeRef={nodeRef}
						classNames={"notification-animation"}
						timeout={{enter: 300, exit: 500}}
						key={n.id}
					>
						<div
							ref={nodeRef}
							className={c(
								style.notification, 
								{
									[style.positive]: n.type != 'error' && n.type != 'neutral',
									[style.error]: n.type=='error',
									[style.neutral]: n.type=='neutral',
								},
							)}
						>
							{n.content}
						</div>
					</CSSTransition>
				)}
			</TransitionGroup>
		</div>
	)
}

export default Notifications
