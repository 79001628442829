import React from 'react'
import _mapValues from 'lodash/mapValues'
import _keyBy from 'lodash/keyBy'
import c from 'classnames'
import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import gql from "graphql-tag"
import { Link } from 'react-router-dom'

import { USER_ESSENTIALS_FRAGMENT } from '@/fragments'
import { loginUrl } from '@/utils/url'
import { PASSWORD_REQUIREMENTS } from '@/utils/validation'
import { apolloClient } from '@/state/apollo-client'
import { TextField, SelectField } from '@/components/common/formik'
import { SpinnerButton } from '@/components/common/button'
import generalStyle from '@sass/common/general.module.sass'
import formStyle from '@sass/common/form.module.sass'
import buttonStyle from '@sass/common/button.module.sass'
import { useSignupMutation } from './__gen/signup-form'
import { PublicSchemasQuery } from './__gen'

type SchemaType = NonNullable<PublicSchemasQuery["schemaPublic_List"]>["objects"][number]


gql`
	mutation signup(
		$suiteId: ID,
		$organisationName: String!,
		$firstName: String!,
		$lastName: String!,
		$email: String!,
		$password: String!,
		$howYouHeardAboutUs: String!,
	) {
		user_Signup(
			orgData: {
					suiteId: $suiteId,
					name: $organisationName,
			},
			userData: {
					firstName: $firstName,
					lastName: $lastName,
					email: $email,
					password: $password,
					howYouHeardAboutUs: $howYouHeardAboutUs,
			}
		) {
			user {
				...UserEssentials
			}
			errors {
				field
				messages
			}
		}
	}
	${USER_ESSENTIALS_FRAGMENT}
`

const VALIDATION_SCHEMA = Yup.object().shape({
	suite: Yup.object().required('Required'),
	organisationName: Yup.string().required('Required'),
	email: Yup.string().email().required('Required'),
	firstName: Yup.string().required('Required'),
	lastName: Yup.string().required('Required'),
	password: PASSWORD_REQUIREMENTS.required('Required'),
	passwordConfirm: (
		Yup.string()
		.oneOf([Yup.ref('password'), null], 'Passwords must match')
		.required('Required')
	),
})

type Props = {
	suiteOptions: SchemaType[]
}

export default ({ suiteOptions }: Props) => {
	const [signup, {}] = useSignupMutation()
	return (
		<Formik
			initialValues={{
				suite: suiteOptions.length > 1 ? undefined : suiteOptions[0],
				organisationName: '',
				firstName: '',
				lastName: '',
				email: '',
				password: '',
				passwordConfirm: '',
				howYouHeardAboutUs: '',
			}} 
			validationSchema={VALIDATION_SCHEMA}
			onSubmit={(values, actions) => {
				signup({variables: {
					suiteId: values.suite && values.suite.id,
					organisationName: values.organisationName,
					firstName: values.firstName,
					lastName: values.lastName,
					email: values.email,
					password: values.password,
					howYouHeardAboutUs: values.howYouHeardAboutUs,
				}}).then((r) => {
					if(r.data!.user_Signup!.errors) {
						const errors = _mapValues(
							_keyBy(r.data!.user_Signup!.errors, 'field'),
							'messages'
						)
						actions.setErrors(errors)
					}
					else {
						apolloClient.resetStore()
					}
				}).catch((r: any) => {
					console.error(r)
					actions.setFieldError('all', 'Error!')
				}).finally(() => {
					actions.setSubmitting(false)
				})
			}}
		>
			{ props => {
				const { errors, isSubmitting, handleSubmit } = props
				return (
					<form onSubmit={handleSubmit} className={formStyle.commonForm}>
						<h3 className={formStyle.commonFormTitle}>Sign Up</h3>
						<br/>
						<br/>
						{ suiteOptions.length == 0 &&
							<div className={generalStyle.error}>
								Public signup is not currently available.
								Please contact support if you wish to create an account.
							</div>
						}

						{ suiteOptions.length > 1 &&
							<Field
								name={'suite'}
								placeholder="Company type"
								component={SelectField}
								options={suiteOptions}
								renderOption={(s: SchemaType) => s.name}
								formatOptionLabel={(s: SchemaType) => s.name}
								getOptionValue={(s: SchemaType) => s.id}
							/>
						}
						
						{([
							['organisationName', 'Organisation name'],
							['firstName', 'First name'],
							['lastName', 'Last name'],
							['email', 'Email'],
							['password', 'Password', {type: 'password'}],
							['passwordConfirm', 'Password (again)', {type: 'password'}],
							['howYouHeardAboutUs', 'How you heard about us'],
						] as [string, string , {type: string}][]).map(([name, placeholder, options={}]) => {
							return <Field
								key={name}
								name={name}
								placeholder={placeholder}
								component={TextField}
								{...options}
							/>
						})}
						
						{/* 
						//@ts-ignore */}
						<div className={generalStyle.error}>{errors.all}</div>


						{ suiteOptions.length >= 1 &&
							<div className={formStyle.formButton}>
								<SpinnerButton loading={isSubmitting} type="submit">
									Sign Up
								</SpinnerButton>
							</div>
						}
						<Link className={c(buttonStyle.noOutline, buttonStyle.small, buttonStyle.grey)} to={loginUrl()}>Already have an account?</Link>
					</form>
				)
			}}
		</Formik>
	)
}

