import { Modal } from 'antd'
import gql from 'graphql-tag'
import React from 'react'

import { SpinnerButton } from '@/components/common/button'
import { useGlobalContext, useRouter } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { getFormAuth } from '@/state/main-form/thunks'
import { useAppDispatch } from '@/utils/hooks'
import { formUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'

import { FORM_ACTION_FRAGMENT } from '../common/query'
import { useResetFormActionsMutation } from './__gen'
import style from './index.module.sass'

type Props = {
	formActions: { id: string }[]
}

gql`
	mutation resetFormActions($objects: [form_action_bulkBulkInput!]!) {
		formAction_BulkUpdate(input: { objects: $objects }) {
			objects {
				...FormAction
			}
			errors {
				field
				messages
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
`

export const ResetFormActionModal = ({ formActions }: Props) => {
	const reduxDispatch = useAppDispatch()
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const router = useRouter()

	const close = () => {
		router.history.push(
			formUrl({ recordId: formContext.record.id, formId: formContext.form.id, ...globalContext }),
		)
	}

	const [resetFormAction, { loading }] = useResetFormActionsMutation({
		variables: {
			objects: formActions.map(({ id }) => ({ id, deleted: true })),
		},
		onCompleted: () => {
			reduxDispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
			close()
		},
	})

	return (
		<Modal title="Reset Form Actions" open maskClosable onCancel={close} footer={null} width={800}>
			<p>Are you sure? This will remove existing form actions.</p>

			{formContext.form.lockedFromFormAction && <p>You will be able to edit this form after resetting.</p>}
			<div className={style.buttonGroup}>
				<button className={buttonStyle.greySolid} onClick={close}>
					Cancel
				</button>
				<SpinnerButton loading={loading} className={buttonStyle.primarySolid} onClick={() => resetFormAction()}>
					Reset
				</SpinnerButton>
			</div>
		</Modal>
	)
}
