import React from 'react'
import style from './index.module.sass'

export function Spinner() {
	return (
		<div className={style.spinnerContainer}>
			<div className={style.spinner}>
			</div>
		</div>
	)
}

export function SmallSpinner() {
	return (
		<div className={style.smallSpinnerContainer}>
			<div className={style.spinner}>
			</div>
		</div>
	)
}

export function LargeSpinner() {
	return (
		<div className={style.largeSpinnerContainer}>
			<div className={style.spinner}>
			</div>
		</div>
	)
}

export function CenteredSpinner() {
	return (
		<div className={style.centeredSpinnerContainer}>
			<div className={style.spinner}>
			</div>
		</div>
	)
}

export function LoadingBar({small}: {small?: boolean}) {
	return (
		<div className={small ? style.loadingBarSmall : style.loadingBar} />
	)
}