import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AddRecordInfoQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AddRecordInfoQuery = { __typename: 'PharosQuery', hasRecord: { __typename: 'RecordTypeListBase', objects: Array<{ __typename: 'RecordType', id: string }> } | null };


export const AddRecordInfoDocument = gql`
    query addRecordInfo {
  hasRecord: record_List(offset: 0, limit: 1) {
    objects {
      id
    }
  }
}
    `;

/**
 * __useAddRecordInfoQuery__
 *
 * To run a query within a React component, call `useAddRecordInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddRecordInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddRecordInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAddRecordInfoQuery(baseOptions?: Apollo.QueryHookOptions<AddRecordInfoQuery, AddRecordInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddRecordInfoQuery, AddRecordInfoQueryVariables>(AddRecordInfoDocument, options);
      }
export function useAddRecordInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddRecordInfoQuery, AddRecordInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddRecordInfoQuery, AddRecordInfoQueryVariables>(AddRecordInfoDocument, options);
        }
export function useAddRecordInfoSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AddRecordInfoQuery, AddRecordInfoQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AddRecordInfoQuery, AddRecordInfoQueryVariables>(AddRecordInfoDocument, options);
        }
export type AddRecordInfoQueryHookResult = ReturnType<typeof useAddRecordInfoQuery>;
export type AddRecordInfoLazyQueryHookResult = ReturnType<typeof useAddRecordInfoLazyQuery>;
export type AddRecordInfoSuspenseQueryHookResult = ReturnType<typeof useAddRecordInfoSuspenseQuery>;
export type AddRecordInfoQueryResult = Apollo.QueryResult<AddRecordInfoQuery, AddRecordInfoQueryVariables>;