import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveTeamMutationVariables = Types.Exact<{
  name: Types.Scalars['String']['input'];
  organisation: Types.Scalars['ID']['input'];
  module: Types.Scalars['ID']['input'];
}>;


export type SaveTeamMutation = { __typename: 'PharosMutations', team_Update: { __typename: 'teamPayload', object: { __typename: 'TeamType', id: string, name: string, logo: any | null } | null } | null };


export const SaveTeamDocument = gql`
    mutation saveTeam($name: String!, $organisation: ID!, $module: ID!) {
  team_Update(input: {name: $name, organisation: $organisation, module: $module}) {
    object {
      id
      name
      logo
    }
  }
}
    `;
export type SaveTeamMutationFn = Apollo.MutationFunction<SaveTeamMutation, SaveTeamMutationVariables>;

/**
 * __useSaveTeamMutation__
 *
 * To run a mutation, you first call `useSaveTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveTeamMutation, { data, loading, error }] = useSaveTeamMutation({
 *   variables: {
 *      name: // value for 'name'
 *      organisation: // value for 'organisation'
 *      module: // value for 'module'
 *   },
 * });
 */
export function useSaveTeamMutation(baseOptions?: Apollo.MutationHookOptions<SaveTeamMutation, SaveTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveTeamMutation, SaveTeamMutationVariables>(SaveTeamDocument, options);
      }
export type SaveTeamMutationHookResult = ReturnType<typeof useSaveTeamMutation>;
export type SaveTeamMutationResult = Apollo.MutationResult<SaveTeamMutation>;
export type SaveTeamMutationOptions = Apollo.BaseMutationOptions<SaveTeamMutation, SaveTeamMutationVariables>;