import React from 'react'
import { Link } from 'react-router-dom'
import { useGlobalContext } from '@/components/context'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { SinglePanel } from '../panel'
import { teamMetricsDefaultUrl, landingPageUrl } from '@/utils/url'

const RouteNotFound = () => {
	const globalContext = useGlobalContext()
	return (
		<div>
			<h1><span className={iconStyle.notFound}/>Page not found</h1>
			<h4>Please contact support if this issue persists</h4>
			{globalContext.user
				? <Link className={generalStyle.classicAnchor} to={teamMetricsDefaultUrl(globalContext)}>Go to dashboard</Link>
				: <Link className={generalStyle.classicAnchor} to={landingPageUrl()}>Go to home page</Link>
			}
		</div>
	)
}

export const WrappedRouteNotFound = () => (
	<SinglePanel>
		<RouteNotFound />
	</SinglePanel>	
)



export default RouteNotFound