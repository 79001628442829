import gql from 'graphql-tag'
import { useDispatch } from 'react-redux'
import { mainFormSlice } from '@/state/main-form/reducer'
import { PdfGeneratorsForFormDocument } from '../../pdf-section/__gen'
import { FormActionsForRecordDocument } from '../common/__gen/query'
import {
	CompleteFormActionMutationVariables,
	useCompleteFormActionMutation,
	useCompleteFormActionPubMutation,
} from './__gen/query'
import { SignatureType } from './complete-modal'
import { isReusableSignature, isTextSignature, isUploadSignature } from './complete-modal/common'

gql`
	mutation CompleteFormAction(
		$id: ID!
		$completedOn: DateTime
		$signatureImage: Upload
		$signatureText: String
		$signatureNote: String
		$reuseSignatureFormActionId: ID
	) {
		formAction_Update(
			input: {
				id: $id
				completedOn: $completedOn
				signatureImage: $signatureImage
				signatureText: $signatureText
				signatureNote: $signatureNote
				reuseSignatureFormActionId: $reuseSignatureFormActionId
			}
		) {
			object {
				id
				completedOn
			}
		}
	}
`

gql`
	mutation CompleteFormActionPub(
		$id: ID!
		$completedOn: DateTime
		$signatureImage: Upload
		$signatureNote: String
		$signatureText: String
	) {
		formActionPub_Update(
			input: {
				id: $id
				completedOn: $completedOn
				signatureImage: $signatureImage
				signatureNote: $signatureNote
				signatureText: $signatureText
			}
		) {
			object {
				id
				completedOn
			}
		}
	}
`

type Props = {
	id: string
	code: string | null
	signature: SignatureType | null
}

export const useComplete = ({ id, code, signature }: Props) => {
	const args = {
		id,
		completedOn: new Date(),
		signatureNote: signature?.signatureNote,
		...(!signature ? {}
		: isReusableSignature(signature) ? { reuseSignatureFormActionId: signature.reusePreviousFormAction.id }
		: isTextSignature(signature) ? { signatureImage: signature?.file, signatureText: signature?.text }
		: isUploadSignature(signature) ? { signatureImage: signature?.file }
		: {}),
	} satisfies CompleteFormActionMutationVariables

	const dispatch = useDispatch()

	const [completeFormAction, { loading }] = useCompleteFormActionMutation({
		variables: args,
		refetchQueries: [PdfGeneratorsForFormDocument, FormActionsForRecordDocument],
		onCompleted: () => {
			if (signature) {
				dispatch(mainFormSlice.actions.setLockedFromFormAction(true))
			}
		},
	})
	const [completeFormActionPub, { loading: loadingPub }] = useCompleteFormActionPubMutation({
		variables: args,
		context: { headers: { 'X-Public-Form-Code': code } },
	})

	return {
		completeMutation: code ? completeFormActionPub : completeFormAction,
		loading: loading || loadingPub,
	}
}
