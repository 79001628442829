import React, { useEffect, useRef, useState } from 'react'
import { Input } from '@/components/common/inputs'
import style from './signature-text.module.sass'
import { SignatureType, isTextSignature } from '../common'
import { SignaturePreview } from './preview'
import font from './hallywood.ttf'

const FONT_NAME = 'PharosSignature'

const FONT_FACE = `@font-face {
	font-family: '${FONT_NAME}';
	src: url(${font}) format('woff');
}`


function scaleCanvas(
	canvas: HTMLCanvasElement,
) {
	// Scale for high density displays
	// Copied from https://gist.github.com/callumlocke/cc258a193839691f60dd
	// (I adjusted parameters a little)
	const ctx = canvas.getContext("2d")!
	const width = canvas.width
	const height = canvas.height

	// Handle window for SSR
	if (typeof window === 'undefined') return null

	// determine the actual ratio we want to draw at
	const ratio = window.devicePixelRatio || 1

	if (devicePixelRatio !== 1) {
		// set the 'real' canvas size to the higher width/height
		canvas.width = width * ratio
		canvas.height = height * ratio

		// ...then scale it back down with CSS
		canvas.style.width = width + 'px'
		canvas.style.height = height + 'px'
	} else {
		// this is a normal 1:1 device, just scale it simply
		canvas.width = width
		canvas.height = height
		canvas.style.width = ''
		canvas.style.height = ''
	}

	// scale the drawing context so everything will work at the higher ratio
	ctx.scale(ratio, ratio)
}

// Warning, changing these must be reflected in PdfGenerator
const FONT_SIZE = 65
const PADDING_TOP = 10
const PADDING_LEFT = 5
const WIDTH = 420 
const HEIGHT = FONT_SIZE + PADDING_TOP

type Props = {
	setSignature: (s: SignatureType | null) => void
	signature: SignatureType | null
}

export const TextSignature = ({ setSignature, signature }: Props) => {
	const canvasRef = useRef<HTMLCanvasElement | null>(null)
	const [scaled, setScaled] = useState(false)
	const [name, setName] = useState("")
	
	const hasSignature = name.replace(' ', '').length > 2

	useEffect(() => {
		if (canvasRef.current) {
			writeText(name)
		}
	}, [name])

	useEffect(() => {
		// This isn't essential, just a nice to have
		if (!scaled && canvasRef.current) {
			scaleCanvas(canvasRef.current)
			setScaled(true)
		}
		if (!canvasRef.current) {
			throw Error("Couldn't scale canvas for high density displays")
		}
	}, [canvasRef])

	const writeText = async (info: string) => {
		if (!canvasRef.current) {
			throw Error("No canvas element")
		}
		const canvas = canvasRef.current
		let ctx = canvas.getContext("2d")
		if (!ctx) {
			throw Error("No canvas context")
		}

		ctx.clearRect(0, 0, canvas.width, canvas.height)
		ctx.beginPath()
		ctx.font = `${FONT_SIZE}px ${FONT_NAME}`
		ctx.textAlign = 'left'
		ctx.textBaseline = 'top'
		ctx.fillStyle = 'black'
		ctx.fillText(name, PADDING_LEFT, PADDING_TOP)
		ctx.stroke()

		if (hasSignature) {
			const blob: Blob | null = await new Promise(resolve => canvas.toBlob(resolve));
			if (!blob) {
				throw Error("No blob returned from canvas")
			}
			const file = new File([blob], "signature.png")
			setSignature({file, text: name, signatureNote: signature?.signatureNote || ""})
		} else {
			setSignature(null)
		}
	}

	return (
		<>
			{/* This is a hack, load the font yolo style */}
			<style>{FONT_FACE}</style>

			<b>Full Name</b><br/>
			<Input
				placeholder="Enter Name"
				className={style.input}
				onChange={(e) => { setName(e.target.value) }}
				value={name}
			/>
			<br/>
			<br/>
			<SignaturePreview
				child={<canvas className={style.canvas} width={WIDTH} height={HEIGHT} ref={canvasRef}/>}
			/>
		</>
	)
}