import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminTeamsQueryVariables = Types.Exact<{
  organisation: Types.Scalars['ID']['input'];
}>;


export type AdminTeamsQuery = { __typename: 'PharosQuery', teamAdmin_List: { __typename: 'AdminTeamTypeListBase', objects: Array<{ __typename: 'AdminTeamType', id: string, name: string, organisation: { __typename: 'AdminOrganisationType', id: string } }> } | null };


export const AdminTeamsDocument = gql`
    query adminTeams($organisation: ID!) {
  teamAdmin_List(limit: 300, organisation: $organisation) {
    objects {
      id
      name
      organisation {
        id
      }
    }
  }
}
    `;

/**
 * __useAdminTeamsQuery__
 *
 * To run a query within a React component, call `useAdminTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminTeamsQuery({
 *   variables: {
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useAdminTeamsQuery(baseOptions: Apollo.QueryHookOptions<AdminTeamsQuery, AdminTeamsQueryVariables> & ({ variables: AdminTeamsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminTeamsQuery, AdminTeamsQueryVariables>(AdminTeamsDocument, options);
      }
export function useAdminTeamsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminTeamsQuery, AdminTeamsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminTeamsQuery, AdminTeamsQueryVariables>(AdminTeamsDocument, options);
        }
export function useAdminTeamsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminTeamsQuery, AdminTeamsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminTeamsQuery, AdminTeamsQueryVariables>(AdminTeamsDocument, options);
        }
export type AdminTeamsQueryHookResult = ReturnType<typeof useAdminTeamsQuery>;
export type AdminTeamsLazyQueryHookResult = ReturnType<typeof useAdminTeamsLazyQuery>;
export type AdminTeamsSuspenseQueryHookResult = ReturnType<typeof useAdminTeamsSuspenseQuery>;
export type AdminTeamsQueryResult = Apollo.QueryResult<AdminTeamsQuery, AdminTeamsQueryVariables>;