import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TeamsToAssignQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type TeamsToAssignQuery = { __typename: 'PharosQuery', team_List: { __typename: 'TeamTypeListBase', objects: Array<{ __typename: 'TeamType', id: string, name: string, memberId: string | null, module: { __typename: 'ModuleType', id: string, name: string } }> } | null };


export const TeamsToAssignDocument = gql`
    query teamsToAssign($userId: ID!) {
  team_List(limit: 200, offset: 0, orderBy: [{field: module_Name}, {field: name}]) {
    objects {
      id
      name
      module {
        id
        name
      }
      memberId(userId: $userId)
    }
  }
}
    `;

/**
 * __useTeamsToAssignQuery__
 *
 * To run a query within a React component, call `useTeamsToAssignQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamsToAssignQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamsToAssignQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useTeamsToAssignQuery(baseOptions: Apollo.QueryHookOptions<TeamsToAssignQuery, TeamsToAssignQueryVariables> & ({ variables: TeamsToAssignQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TeamsToAssignQuery, TeamsToAssignQueryVariables>(TeamsToAssignDocument, options);
      }
export function useTeamsToAssignLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TeamsToAssignQuery, TeamsToAssignQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TeamsToAssignQuery, TeamsToAssignQueryVariables>(TeamsToAssignDocument, options);
        }
export function useTeamsToAssignSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TeamsToAssignQuery, TeamsToAssignQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<TeamsToAssignQuery, TeamsToAssignQueryVariables>(TeamsToAssignDocument, options);
        }
export type TeamsToAssignQueryHookResult = ReturnType<typeof useTeamsToAssignQuery>;
export type TeamsToAssignLazyQueryHookResult = ReturnType<typeof useTeamsToAssignLazyQuery>;
export type TeamsToAssignSuspenseQueryHookResult = ReturnType<typeof useTeamsToAssignSuspenseQuery>;
export type TeamsToAssignQueryResult = Apollo.QueryResult<TeamsToAssignQuery, TeamsToAssignQueryVariables>;