import React from 'react'

import { useAppDispatch } from '@/utils/hooks'
import { updateAnswers } from '@/state/answer/thunks'
import { Input } from '@/components/common/inputs'
import { isAnswerValid } from '@/state/answer/utils'
import { TableFieldEnumType } from '@/state/main-form/types'
import { AnswerTextBaseProps } from '../types'
import commonStyle from '../common.module.sass'


const AnswerEmail = (props: AnswerTextBaseProps) => {
	const dispatch = useAppDispatch()
	const isValid = isAnswerValid(props.field.type as TableFieldEnumType, props.answer)
	return props.disabled
		? (
			<div className={commonStyle.answerTextDisabled}>
				{props.answer.content || <i className={commonStyle.noContent}>No content</i>}
			</div>
		) : (
			<Input
				className={commonStyle.formInput}
				onChange={(e) => dispatch(updateAnswers({ answers: [{ ...props.answer, ...props.answer, content: e.target.value }] }))}
				placeholder={props.field.placeholder || undefined}
				value={props.answer.content || ""}
				style={isValid ? {} : {borderColor: 'red'}}
			/>
		)
}

export default AnswerEmail