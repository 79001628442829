import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ResetFormAnswersMutationVariables = Types.Exact<{
  formId: Types.Scalars['ID']['input'];
  recordId: Types.Scalars['ID']['input'];
}>;


export type ResetFormAnswersMutation = { __typename: 'PharosMutations', form_ResetAnswers: { __typename: 'FormResetAnswersMutation', success: boolean | null, message: string | null } | null };


export const ResetFormAnswersDocument = gql`
    mutation resetFormAnswers($formId: ID!, $recordId: ID!) {
  form_ResetAnswers(formId: $formId, recordId: $recordId) {
    success
    message
  }
}
    `;
export type ResetFormAnswersMutationFn = Apollo.MutationFunction<ResetFormAnswersMutation, ResetFormAnswersMutationVariables>;

/**
 * __useResetFormAnswersMutation__
 *
 * To run a mutation, you first call `useResetFormAnswersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetFormAnswersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetFormAnswersMutation, { data, loading, error }] = useResetFormAnswersMutation({
 *   variables: {
 *      formId: // value for 'formId'
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useResetFormAnswersMutation(baseOptions?: Apollo.MutationHookOptions<ResetFormAnswersMutation, ResetFormAnswersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetFormAnswersMutation, ResetFormAnswersMutationVariables>(ResetFormAnswersDocument, options);
      }
export type ResetFormAnswersMutationHookResult = ReturnType<typeof useResetFormAnswersMutation>;
export type ResetFormAnswersMutationResult = Apollo.MutationResult<ResetFormAnswersMutation>;
export type ResetFormAnswersMutationOptions = Apollo.BaseMutationOptions<ResetFormAnswersMutation, ResetFormAnswersMutationVariables>;