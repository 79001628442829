import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { FormActionFragmentDoc } from '../../../common/__gen/query';
import { FormActionEscalationFragmentDoc } from '../../common/escalations/__gen/hooks';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveFormActionsMutationVariables = Types.Exact<{
  formActions: Array<Types.Form_Action_BulkBulkInput>;
  escalations: Array<Types.Form_Action_Escalation_BulkBulkInput>;
}>;


export type SaveFormActionsMutation = { __typename: 'PharosMutations', formAction_BulkUpdate: { __typename: 'form_action_bulkPayload', objects: Array<{ __typename: 'FormActionType', id: string, assignedAnonEmail: string, assignedAnonName: string, completedOn: string | null, deleted: boolean, isScheduled: boolean, link: string | null, priority: number, reminderNext: any | null, reminderLast: any | null, requestSignature: boolean, userSuppliedContext: string, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, form: { __typename: 'FormType', pdfGenerators: Array<{ __typename: 'PdfGeneratorType', id: string, name: string }> } }> | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null, formActionEscalation_BulkUpdate: { __typename: 'form_action_escalation_bulkPayload', objects: Array<{ __typename: 'FormActionEscalationType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, deltaDays: number, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null }> | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const SaveFormActionsDocument = gql`
    mutation saveFormActions($formActions: [form_action_bulkBulkInput!]!, $escalations: [form_action_escalation_bulkBulkInput!]!) {
  formAction_BulkUpdate(input: {objects: $formActions}) {
    objects {
      ...FormAction
    }
    errors {
      field
      messages
    }
  }
  formActionEscalation_BulkUpdate(input: {objects: $escalations}) {
    objects {
      ...formActionEscalation
    }
    errors {
      field
      messages
    }
  }
}
    ${FormActionFragmentDoc}
${FormActionEscalationFragmentDoc}`;
export type SaveFormActionsMutationFn = Apollo.MutationFunction<SaveFormActionsMutation, SaveFormActionsMutationVariables>;

/**
 * __useSaveFormActionsMutation__
 *
 * To run a mutation, you first call `useSaveFormActionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormActionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormActionsMutation, { data, loading, error }] = useSaveFormActionsMutation({
 *   variables: {
 *      formActions: // value for 'formActions'
 *      escalations: // value for 'escalations'
 *   },
 * });
 */
export function useSaveFormActionsMutation(baseOptions?: Apollo.MutationHookOptions<SaveFormActionsMutation, SaveFormActionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFormActionsMutation, SaveFormActionsMutationVariables>(SaveFormActionsDocument, options);
      }
export type SaveFormActionsMutationHookResult = ReturnType<typeof useSaveFormActionsMutation>;
export type SaveFormActionsMutationResult = Apollo.MutationResult<SaveFormActionsMutation>;
export type SaveFormActionsMutationOptions = Apollo.BaseMutationOptions<SaveFormActionsMutation, SaveFormActionsMutationVariables>;