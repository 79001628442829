import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { UserEssentialsFragmentDoc } from '../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CurrentUserQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = { __typename: 'PharosQuery', user_List: { __typename: 'UserTypeListBase', objects: Array<{ __typename: 'UserType', id: string, adminOfSuite: boolean, email: string, firstName: string, howYouHeardAboutUs: string | null, isActive: boolean, isAdmin: boolean, lastName: string, recordCount: number, seenWelcomeMessage: boolean, topLevelManager: boolean, usingTemporaryPassword: boolean, organisation: { __typename: 'OrganisationType', id: string, csvDownloadEnabled: boolean, logo: any | null, name: string, showTeamFiles: boolean, subdomain: string | null, suite: { __typename: 'SuiteType', id: string, logo: any | null, suiteAdminDashboardTitle: string | null, welcomeMessageHtml: string | null, moduleWord: string, moduleSetItems: Array<{ __typename: 'SuiteModuleType', id: string, rank: number, hiddenWhenEmpty: boolean, module: { __typename: 'ModuleType', id: string, addTeamPhrase: string | null, name: string, icon: string | null, singleton: boolean, descriptionHtml: string, showActiveLinksOnDashboard: boolean, showActionDashboard: boolean, allowRecordTemplateChange: boolean, allowPositionTextChange: boolean, recordFilesEnabled: boolean, recordImages: boolean, recordWord: string, showCalendar: boolean, showMap: boolean, positionWord: string, teamWord: string, teams: Array<{ __typename: 'TeamType', id: string, publicRecordCreateCode: string, logo: any | null, reminderFrequencyDays: number, name: string }>, publicRecordCreateLinks: Array<{ __typename: 'PublicRecordCreateLinkType', id: string, title: string, code: string }> } }>, adviceSection: { __typename: 'AdviceSectionType', id: string, title: string } | null } } }> } | null };


export const CurrentUserDocument = gql`
    query currentUser {
  user_List(isSelf: true) {
    objects {
      ...UserEssentials
    }
  }
}
    ${UserEssentialsFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export function useCurrentUserSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserSuspenseQueryHookResult = ReturnType<typeof useCurrentUserSuspenseQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;