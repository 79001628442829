import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type QuickLinksQueryVariables = Types.Exact<{
  formId: Types.Scalars['ID']['input'];
}>;


export type QuickLinksQuery = { __typename: 'PharosQuery', quickLink_List: { __typename: 'QuickLinkTypeListBase', objects: Array<{ __typename: 'QuickLinkType', id: string, title: string, moduleNotActivated: boolean, module: { __typename: 'ModuleType', id: string, name: string, recordWord: string } | null, linkToForm: { __typename: 'FormType', id: string, title: string }, records: Array<{ __typename: 'RecordType', id: string, name: string, team: { __typename: 'TeamType', id: string, name: string, module: { __typename: 'ModuleType', id: string } } }> }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const QuickLinksDocument = gql`
    query quickLinks($formId: ID!) {
  quickLink_List(form: $formId, limit: 20, offset: 0) {
    objects {
      id
      title
      moduleNotActivated
      module {
        id
        name
        recordWord
      }
      linkToForm {
        id
        title
      }
      records {
        id
        name
        team {
          id
          name
          module {
            id
          }
        }
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useQuickLinksQuery__
 *
 * To run a query within a React component, call `useQuickLinksQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuickLinksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuickLinksQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *   },
 * });
 */
export function useQuickLinksQuery(baseOptions: Apollo.QueryHookOptions<QuickLinksQuery, QuickLinksQueryVariables> & ({ variables: QuickLinksQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, options);
      }
export function useQuickLinksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuickLinksQuery, QuickLinksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, options);
        }
export function useQuickLinksSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<QuickLinksQuery, QuickLinksQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<QuickLinksQuery, QuickLinksQueryVariables>(QuickLinksDocument, options);
        }
export type QuickLinksQueryHookResult = ReturnType<typeof useQuickLinksQuery>;
export type QuickLinksLazyQueryHookResult = ReturnType<typeof useQuickLinksLazyQuery>;
export type QuickLinksSuspenseQueryHookResult = ReturnType<typeof useQuickLinksSuspenseQuery>;
export type QuickLinksQueryResult = Apollo.QueryResult<QuickLinksQuery, QuickLinksQueryVariables>;