import { ReduxState } from "@/types/redux"
import { SHOW_MODAL, HIDE_MODAL } from './actions'

type ShowModalType = {
	type: typeof SHOW_MODAL
	modal: ReduxState["modal"]
}

type HideModalType = {
	type: typeof HIDE_MODAL
	modal: null
}

const modal = (state=null, action: any) => {
	if(action.type == SHOW_MODAL) {
		return { ...action.modal }
	}
	else if(action.type == HIDE_MODAL) {
		return null
	}
	return state
}

export default modal