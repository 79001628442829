import React from 'react'
import gql from 'graphql-tag'
import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import PieMetric from '../../types/pie'
import MetricPlaceholder from '../metric-placeholder'
import { useFieldGroupMetricQuery } from './__gen'


gql`
	query fieldGroupMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		fieldGroupMetric(id: $id) {
			id
			name
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				name
				color
				formLinks {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FieldGroupMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useFieldGroupMetricQuery({
		variables: {
			id,
			teamId: globalContext.currentTeam?.id,
			isSuiteAdminDashboard,
		}
	})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.fieldGroupMetric) {
		return <div>error</div>
	}
	return (
		<PieMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...data?.fieldGroupMetric} />
	)

}

export default FieldGroupMetricWrapper