import { ReuseSignaturesQuery } from "./signature/__gen"

type SignatureTextType = {
	file: File,
	text: string,
	signatureNote: string,
}

type SignatureUploadType = {
	file: File,
	signatureNote: string,
}

type SignatureReuseType = {
	reusePreviousFormAction: NonNullable<ReuseSignaturesQuery["formAction_List"]>["objects"][number]
	signatureNote: string,
}

export type SignatureType = SignatureTextType | SignatureUploadType | SignatureReuseType

export function isReusableSignature (s: SignatureType): s is SignatureReuseType {
	if ('reusePreviousFormAction' in s) {
		return true
	}
	return false
}

export function isTextSignature (s: SignatureType): s is SignatureTextType {
	if ('file' in s && 'text' in s) {
		return true
	}
	return false
}

export function isUploadSignature (s: SignatureType): s is SignatureUploadType {
	if ('file' in s && 'text' ! in s) {
		return true
	}
	return false
}