import React from 'react'
import gql from 'graphql-tag'
import { Spin } from 'antd'
import { format } from 'date-fns'
import { Empty } from 'antd'

import { Error } from '@/components/common/error-boundary'
import { mergedPdfDownloadApiUrl } from '@/utils/url'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { useMergedPdfsQuery } from './__gen'
import style from './index.module.sass'


gql`
	query mergedPdfs($recordId: ID!) {
		mergedPdf_List(recordId: $recordId) {
			objects {
				id
				name
				created
				mergedPdfItems {
					id
				}
			}
		}
	}
`

type Props = {
	recordId: string
}

const MergedPdfHistory =  ({ recordId }: Props) => {
	const {data, loading, error } = useMergedPdfsQuery({variables: { recordId }})
	return (
		<div>
			{ loading && <Spin size="large" /> }
			{ !loading && error && <Error /> }
			{ !loading && data?.mergedPdf_List?.objects &&
				<div>
					{data.mergedPdf_List.objects.map(mPdf => (
						<div className={style.pdf} key={mPdf.id}>
							<a target="_blank" className={iconStyle.doc} href={mergedPdfDownloadApiUrl(mPdf)}>
								{mPdf.name}.pdf
							</a>
							&nbsp;-&nbsp;
							<span className={generalStyle.muted}>{format(new Date(mPdf.created), 'MMM do yyyy h:mma')}</span>
						</div>
					))}
					{data.mergedPdf_List.objects.length == 0 && <Empty />}
				</div>
			}
		</div>
	)
}

export default MergedPdfHistory