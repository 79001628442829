import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConcatenatedTableQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type ConcatenatedTableQuery = { __typename: 'PharosQuery', concatenatedTable: { __typename: 'ConcatenatedTableType', id: string, name: string, includeFields: Array<{ __typename: 'IncludeFieldType', id: string, field: { __typename: 'FieldType', id: string, title: string } }>, includeTableFields: Array<{ __typename: 'IncludeTableFieldType', id: string, tableField: { __typename: 'TableFieldType', id: string, title: string } }> } | null };


export const ConcatenatedTableDocument = gql`
    query concatenatedTable($id: ID!) {
  concatenatedTable(id: $id) {
    id
    name
    includeFields {
      id
      field {
        id
        title
      }
    }
    includeTableFields {
      id
      tableField {
        id
        title
      }
    }
  }
}
    `;

/**
 * __useConcatenatedTableQuery__
 *
 * To run a query within a React component, call `useConcatenatedTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcatenatedTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcatenatedTableQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConcatenatedTableQuery(baseOptions: Apollo.QueryHookOptions<ConcatenatedTableQuery, ConcatenatedTableQueryVariables> & ({ variables: ConcatenatedTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>(ConcatenatedTableDocument, options);
      }
export function useConcatenatedTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>(ConcatenatedTableDocument, options);
        }
export function useConcatenatedTableSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>(ConcatenatedTableDocument, options);
        }
export type ConcatenatedTableQueryHookResult = ReturnType<typeof useConcatenatedTableQuery>;
export type ConcatenatedTableLazyQueryHookResult = ReturnType<typeof useConcatenatedTableLazyQuery>;
export type ConcatenatedTableSuspenseQueryHookResult = ReturnType<typeof useConcatenatedTableSuspenseQuery>;
export type ConcatenatedTableQueryResult = Apollo.QueryResult<ConcatenatedTableQuery, ConcatenatedTableQueryVariables>;