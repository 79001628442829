import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { PdfFragmentDoc } from './pdf';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PdfGeneratorsForFormQueryVariables = Types.Exact<{
  formId: Types.Scalars['ID']['input'];
  recordId: Types.Scalars['ID']['input'];
}>;


export type PdfGeneratorsForFormQuery = { __typename: 'PharosQuery', pdfGenerator_List: { __typename: 'PdfGeneratorTypeListBase', objects: Array<{ __typename: 'PdfGeneratorType', id: string, name: string, pdfs: Array<{ __typename: 'PdfType', id: string, name: string, created: string, origin: Types.PdfPdfOriginChoices, isActive: boolean }> }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const PdfGeneratorsForFormDocument = gql`
    query pdfGeneratorsForForm($formId: ID!, $recordId: ID!) {
  pdfGenerator_List(formId: $formId, limit: 20, offset: 0) {
    objects {
      id
      name
      pdfs(recordId: $recordId) {
        ...Pdf
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    ${PdfFragmentDoc}`;

/**
 * __usePdfGeneratorsForFormQuery__
 *
 * To run a query within a React component, call `usePdfGeneratorsForFormQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfGeneratorsForFormQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfGeneratorsForFormQuery({
 *   variables: {
 *      formId: // value for 'formId'
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function usePdfGeneratorsForFormQuery(baseOptions: Apollo.QueryHookOptions<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables> & ({ variables: PdfGeneratorsForFormQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>(PdfGeneratorsForFormDocument, options);
      }
export function usePdfGeneratorsForFormLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>(PdfGeneratorsForFormDocument, options);
        }
export function usePdfGeneratorsForFormSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>(PdfGeneratorsForFormDocument, options);
        }
export type PdfGeneratorsForFormQueryHookResult = ReturnType<typeof usePdfGeneratorsForFormQuery>;
export type PdfGeneratorsForFormLazyQueryHookResult = ReturnType<typeof usePdfGeneratorsForFormLazyQuery>;
export type PdfGeneratorsForFormSuspenseQueryHookResult = ReturnType<typeof usePdfGeneratorsForFormSuspenseQuery>;
export type PdfGeneratorsForFormQueryResult = Apollo.QueryResult<PdfGeneratorsForFormQuery, PdfGeneratorsForFormQueryVariables>;