import React from 'react'
import { useGlobalContext } from '@/components/context'
import { emailModalUrl, emailModalSendUrl } from '@/utils/url'
import SimpleIconButton from '@/components/common/simple-icon-button'
import iconStyle from '@sass/common/icon.module.sass'
import { Route } from 'react-router-dom'
import EmailModal from './modal'

type Props = {
	recordId: string
	formId: string
	regardingObjectId: string
	regardingObjectContentType: string
}

const EmailIcon = (props: Props) => {
	const globalContext = useGlobalContext()
	return (
		<>
			<SimpleIconButton
				iconClass={iconStyle.letter}
				title="Email"
				description={`Send an email to a user, ${globalContext.currentModule.recordWord} or custom email`}
				to={emailModalSendUrl({...globalContext, ...props})}
			/>
			<Route
				path={emailModalUrl({...globalContext, ...props})}
				component={() => <EmailModal {...props} />}
			/>
		</>
	)
}

export default EmailIcon