import React, { useState } from 'react'
import c from 'classnames'

import AddTeamModal from '@/components/common/add-team-modal'
import { useGlobalContext } from '@/components/context'
import { UserEssentialsFragment } from '@/fragments/__gen'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import styles from './index.module.sass'

type Props = {
	module: UserEssentialsFragment["organisation"]["suite"]["moduleSetItems"][number]["module"]
}

const ModuleCard = ({module}: Props) => {
	const [showModal, setShowModal] = useState(false)
	const globalContext = useGlobalContext()
	const disabled = module.teams.length > 0
	return (
		<>
			<div
				className={c({
					[styles.cardDisabled]: disabled,
					[styles.cardEnabled]: !disabled,
				})}
				onClick={() => !disabled && setShowModal(true)}
			>
				<h6 className={styles.titleRow}>
					<span className={c(styles.moduleIcon, `icon-${module.icon}`)}/>
					{module.name}
				</h6>
				<div
					className={styles.description} 
					dangerouslySetInnerHTML={{ __html: module.descriptionHtml }}
				/>
				<div className={styles.grow} />
				<div className={styles.buttonRow}>
					{disabled
						? <button disabled={disabled}>Already Active</button>
						: <button className={c(buttonStyle.primarySolid, iconStyle.add)}>Activate</button>
					}
				</div>
			</div>
			<AddTeamModal module={module} show={showModal} callback={() => {setShowModal(false)}} />
		</>
	)
}

export default ModuleCard