import gql from 'graphql-tag'

import { FormAnswersAuthQuery, FormAnswersPublicQuery } from './__gen'

export type AuthAnswerText = NonNullable<FormAnswersAuthQuery["answerText_List"]>["objects"][number]
export type AuthAnswerBoolean = NonNullable<FormAnswersAuthQuery["answerBoolean_List"]>["objects"][number]
export type AuthAnswerDate = NonNullable<FormAnswersAuthQuery["answerDate_List"]>["objects"][number]
export type AuthAnswerFile = NonNullable<FormAnswersAuthQuery["answerFile_List"]>["objects"][number]
export type AuthAnswerDecimal = NonNullable<FormAnswersAuthQuery["answerDecimal_List"]>["objects"][number]

export type PublicAnswerText = NonNullable<FormAnswersPublicQuery["answerTextPub_List"]>["objects"][number]
export type PublicAnswerBoolean = NonNullable<FormAnswersPublicQuery["answerBooleanPub_List"]>["objects"][number]
export type PublicAnswerDate = NonNullable<FormAnswersPublicQuery["answerDatePub_List"]>["objects"][number]
export type PublicAnswerFile = NonNullable<FormAnswersPublicQuery["answerFilePub_List"]>["objects"][number]
export type PublicAnswerDecimal = NonNullable<FormAnswersPublicQuery["answerDecimalPub_List"]>["objects"][number]

export const FORM_ANSWERS_AUTH = gql`
	query formAnswersAuth($record: ID!, $form: ID!) {
		answerText_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBoolean_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDate_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimal_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFile_List(relatedToRecord: $record, form: $form, deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`

export const FORM_ANSWERS_PUBLIC = gql`
	query formAnswersPublic {
		answerTextPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerBooleanPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDatePub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerDecimalPub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated
			}
		}
		answerFilePub_List(deactivated_Isnull: true) {
			objects {
				id
				content
				fieldId
				tableFieldId
				contentType
				objectId
				rank
				deactivated

				name
			}
		}
	}
`







