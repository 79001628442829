import React, { useState } from 'react'
import { startRegistration } from '@simplewebauthn/browser'
import gql from 'graphql-tag'

import { useWebauthnBeginRegistrationMutation, useWebauthnCompleteRegistrationMutation } from './__gen/use-create-mfa'


gql`
	mutation webauthnBeginRegistration {
		webauthnCredentials_BeginRegistration {
			optionsString
		}
	}
`

gql`
	mutation webauthnCompleteRegistration($registrationResponse: String!, $displayName: String!) {
		webauthnCredentials_CompleteRegistration(registrationResponse: $registrationResponse, displayName: $displayName) {
			errorText
		}
	}
`

type OperationResult = Promise<{ success: boolean, error: string }>

type Params = {
	onComplete: () => void
}

export const useEnableMfa = ({ onComplete }: Params) => {
	const [error, setError] = useState<string | null>(null)
	const [beginRegistration, { }] = useWebauthnBeginRegistrationMutation()
	const [completeRegistration, { }] = useWebauthnCompleteRegistrationMutation()
	const [loading, setLoading] = useState<boolean>(false)
	const [registrationResponse, setRegistrationResponse] = useState<string | null>(null)
	const [displayName, setDisplayName] = useState<string>('')

	const startMfaRegistration = async () => {
		setLoading(true)
		setError("")
		try {
			const r = await beginRegistration()
			const optionsString = r.data?.webauthnCredentials_BeginRegistration?.optionsString
			if (!optionsString) {
				setError('An unexpected error occured')
				return
			}
			const options = JSON.parse(optionsString)

			let registrationResponse
			try {
				registrationResponse = await startRegistration(options);
			} catch (e) {
				console.error(e)
				if ((e instanceof Error)) {
					setError("Error: " + e.message)
				}
				setError("An unexpected error occurred, if this persists please contact support.")
			}
			setRegistrationResponse(JSON.stringify(registrationResponse))
		} finally {
			setLoading(false)
		}
	}

	const completeMfaRegistration = async () => {
		setLoading(true)
		setError("")
		try {
			const { data } = await completeRegistration({
				variables: {
					registrationResponse: JSON.stringify(registrationResponse),
					displayName
				}
			})

			if (!data?.webauthnCredentials_CompleteRegistration) {
				setError("An unexpected error occured")
				return
			} else if (data.webauthnCredentials_CompleteRegistration.errorText) {
				setError(data.webauthnCredentials_CompleteRegistration.errorText || "Unexpected error")
				return
			}
			onComplete()
			return { success: true, error: '' }
		} finally {
			setLoading(false)
			setRegistrationResponse(null)
		}
	}

	const cancelMfaRegistration = () => {
		setRegistrationResponse(null)

	}

	return {
		startMfaRegistration,
		completeMfaRegistration,
		cancelMfaRegistration,
		error,
		showDisplayNameModal: !!registrationResponse,
		setDisplayName,
		displayName,
		loading,
	}
}
