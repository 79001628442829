import { Modal } from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Input } from '@/components/common/inputs'
import { useGlobalContext } from '@/components/context'
import history from '@/utils/history'
import { formUrl } from '@/utils/url'
import { PdfGeneratorsForFormQuery } from './__gen'
import { useRenamePdfMutation } from './__gen/edit-name'
import style from './edit-name.module.sass'

type Props = {
	pdf: NonNullable<PdfGeneratorsForFormQuery['pdfGenerator_List']>['objects'][number]['pdfs'][number]
	recordId: string
	formId: string
}

gql`
	mutation renamePdf($id: ID!, $name: String!) {
		pdf_Update(input: { id: $id, name: $name }) {
			object {
				id
				name
			}
		}
	}
`

export const EditName = ({ pdf, recordId, formId }: Props) => {
	const [name, setName] = useState(pdf.name)
	const [renamePdfMutation, { loading }] = useRenamePdfMutation()
	const globalContext = useGlobalContext()
	return (
		<Modal
			title="Rename this pdf"
			open
			maskClosable
			onCancel={() => history.push(formUrl({ recordId, formId, ...globalContext }))}
			okText="Save"
			onOk={() => {
				renamePdfMutation({ variables: { id: pdf.id, name } })
				history.push(formUrl({ recordId, formId, ...globalContext }))
			}}
		>
			<div className={style.container}>
				<Input
					autoFocus
					className={style.input}
					value={name}
					maxLength={64}
					onChange={(e) => setName(e.target.value)}
				/>
			</div>
		</Modal>
	)
}
