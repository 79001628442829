import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebauthnCredentialsListQueryVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
}>;


export type WebauthnCredentialsListQuery = { __typename: 'PharosQuery', webauthnCredentials_List: { __typename: 'WebauthnCredentialsTypeListBase', objects: Array<{ __typename: 'WebauthnCredentialsType', id: string, lastUsed: string | null, created: string, displayName: string }> } | null };


export const WebauthnCredentialsListDocument = gql`
    query webauthnCredentialsList($userId: ID!) {
  webauthnCredentials_List(
    user: $userId
    orderBy: {field: lastUsed, direction: DESC}
  ) {
    objects {
      id
      lastUsed
      created
      displayName
    }
  }
}
    `;

/**
 * __useWebauthnCredentialsListQuery__
 *
 * To run a query within a React component, call `useWebauthnCredentialsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useWebauthnCredentialsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWebauthnCredentialsListQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useWebauthnCredentialsListQuery(baseOptions: Apollo.QueryHookOptions<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables> & ({ variables: WebauthnCredentialsListQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>(WebauthnCredentialsListDocument, options);
      }
export function useWebauthnCredentialsListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>(WebauthnCredentialsListDocument, options);
        }
export function useWebauthnCredentialsListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>(WebauthnCredentialsListDocument, options);
        }
export type WebauthnCredentialsListQueryHookResult = ReturnType<typeof useWebauthnCredentialsListQuery>;
export type WebauthnCredentialsListLazyQueryHookResult = ReturnType<typeof useWebauthnCredentialsListLazyQuery>;
export type WebauthnCredentialsListSuspenseQueryHookResult = ReturnType<typeof useWebauthnCredentialsListSuspenseQuery>;
export type WebauthnCredentialsListQueryResult = Apollo.QueryResult<WebauthnCredentialsListQuery, WebauthnCredentialsListQueryVariables>;