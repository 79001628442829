import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordDashboardQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RecordDashboardQuery = { __typename: 'PharosQuery', record: { __typename: 'RecordType', id: string, name: string, position: string, archived: boolean, image: any | null, team: { __typename: 'TeamType', id: string, name: string }, template: { __typename: 'TemplateType', id: string, position: string, formSet: { __typename: 'FormSetType', id: string, dashboard: { __typename: 'FormSetDashboardType', id: string, dashboardFields: Array<{ __typename: 'FormSetDashboard_FieldType', id: string, hideIfBlank: boolean, rank: number, answers: Array<string>, form: string, field: { __typename: 'FieldType', id: string, title: string } }>, sections: Array<{ __typename: 'FormSetDashboardSectionType', id: string, name: string, rank: number }> } | null } } } | null };


export const RecordDashboardDocument = gql`
    query recordDashboard($id: ID!) {
  record(id: $id) {
    id
    name
    position
    archived
    image
    team {
      id
      name
    }
    template {
      id
      position
      formSet {
        id
        dashboard {
          id
          dashboardFields {
            id
            field {
              id
              title
            }
            hideIfBlank
            rank
            answers(recordId: $id)
            form(recordId: $id)
          }
          sections {
            id
            name
            rank
          }
        }
      }
    }
  }
}
    `;

/**
 * __useRecordDashboardQuery__
 *
 * To run a query within a React component, call `useRecordDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordDashboardQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecordDashboardQuery(baseOptions: Apollo.QueryHookOptions<RecordDashboardQuery, RecordDashboardQueryVariables> & ({ variables: RecordDashboardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordDashboardQuery, RecordDashboardQueryVariables>(RecordDashboardDocument, options);
      }
export function useRecordDashboardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordDashboardQuery, RecordDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordDashboardQuery, RecordDashboardQueryVariables>(RecordDashboardDocument, options);
        }
export function useRecordDashboardSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecordDashboardQuery, RecordDashboardQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordDashboardQuery, RecordDashboardQueryVariables>(RecordDashboardDocument, options);
        }
export type RecordDashboardQueryHookResult = ReturnType<typeof useRecordDashboardQuery>;
export type RecordDashboardLazyQueryHookResult = ReturnType<typeof useRecordDashboardLazyQuery>;
export type RecordDashboardSuspenseQueryHookResult = ReturnType<typeof useRecordDashboardSuspenseQuery>;
export type RecordDashboardQueryResult = Apollo.QueryResult<RecordDashboardQuery, RecordDashboardQueryVariables>;