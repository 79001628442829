import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MergedPdfsQueryVariables = Types.Exact<{
  recordId: Types.Scalars['ID']['input'];
}>;


export type MergedPdfsQuery = { __typename: 'PharosQuery', mergedPdf_List: { __typename: 'MergedPdfTypeListBase', objects: Array<{ __typename: 'MergedPdfType', id: string, name: string, created: string, mergedPdfItems: Array<{ __typename: 'MergedPdfItemType', id: string }> }> } | null };


export const MergedPdfsDocument = gql`
    query mergedPdfs($recordId: ID!) {
  mergedPdf_List(recordId: $recordId) {
    objects {
      id
      name
      created
      mergedPdfItems {
        id
      }
    }
  }
}
    `;

/**
 * __useMergedPdfsQuery__
 *
 * To run a query within a React component, call `useMergedPdfsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMergedPdfsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMergedPdfsQuery({
 *   variables: {
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useMergedPdfsQuery(baseOptions: Apollo.QueryHookOptions<MergedPdfsQuery, MergedPdfsQueryVariables> & ({ variables: MergedPdfsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MergedPdfsQuery, MergedPdfsQueryVariables>(MergedPdfsDocument, options);
      }
export function useMergedPdfsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MergedPdfsQuery, MergedPdfsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MergedPdfsQuery, MergedPdfsQueryVariables>(MergedPdfsDocument, options);
        }
export function useMergedPdfsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MergedPdfsQuery, MergedPdfsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MergedPdfsQuery, MergedPdfsQueryVariables>(MergedPdfsDocument, options);
        }
export type MergedPdfsQueryHookResult = ReturnType<typeof useMergedPdfsQuery>;
export type MergedPdfsLazyQueryHookResult = ReturnType<typeof useMergedPdfsLazyQuery>;
export type MergedPdfsSuspenseQueryHookResult = ReturnType<typeof useMergedPdfsSuspenseQuery>;
export type MergedPdfsQueryResult = Apollo.QueryResult<MergedPdfsQuery, MergedPdfsQueryVariables>;