import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MarkWelcomeModalSeenMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type MarkWelcomeModalSeenMutation = { __typename: 'PharosMutations', user_Update: { __typename: 'userPayload', object: { __typename: 'UserType', id: string, seenWelcomeMessage: boolean } | null } | null };


export const MarkWelcomeModalSeenDocument = gql`
    mutation markWelcomeModalSeen($id: ID!) {
  user_Update(input: {id: $id, seenWelcomeMessage: true}) {
    object {
      id
      seenWelcomeMessage
    }
  }
}
    `;
export type MarkWelcomeModalSeenMutationFn = Apollo.MutationFunction<MarkWelcomeModalSeenMutation, MarkWelcomeModalSeenMutationVariables>;

/**
 * __useMarkWelcomeModalSeenMutation__
 *
 * To run a mutation, you first call `useMarkWelcomeModalSeenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkWelcomeModalSeenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markWelcomeModalSeenMutation, { data, loading, error }] = useMarkWelcomeModalSeenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMarkWelcomeModalSeenMutation(baseOptions?: Apollo.MutationHookOptions<MarkWelcomeModalSeenMutation, MarkWelcomeModalSeenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkWelcomeModalSeenMutation, MarkWelcomeModalSeenMutationVariables>(MarkWelcomeModalSeenDocument, options);
      }
export type MarkWelcomeModalSeenMutationHookResult = ReturnType<typeof useMarkWelcomeModalSeenMutation>;
export type MarkWelcomeModalSeenMutationResult = Apollo.MutationResult<MarkWelcomeModalSeenMutation>;
export type MarkWelcomeModalSeenMutationOptions = Apollo.BaseMutationOptions<MarkWelcomeModalSeenMutation, MarkWelcomeModalSeenMutationVariables>;