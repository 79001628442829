import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { FormActionScheduleFragmentDoc } from '../../../common/__gen/query';
import { FormActionEscalationFragmentDoc } from '../../common/escalations/__gen/hooks';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveFormActionScheduleMutationVariables = Types.Exact<{
  formActionSchedule: Types.Form_Action_ScheduleInput;
  escalations: Array<Types.Form_Action_Escalation_BulkBulkInput>;
}>;


export type SaveFormActionScheduleMutation = { __typename: 'PharosMutations', formActionSchedule_Update: { __typename: 'form_action_schedulePayload', object: { __typename: 'FormActionScheduleType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, dueAt: string, requestSignature: boolean, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, userSuppliedContext: string, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null, formActionEscalation_BulkUpdate: { __typename: 'form_action_escalation_bulkPayload', objects: Array<{ __typename: 'FormActionEscalationType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, deltaDays: number, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null }> | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const SaveFormActionScheduleDocument = gql`
    mutation saveFormActionSchedule($formActionSchedule: form_action_scheduleInput!, $escalations: [form_action_escalation_bulkBulkInput!]!) {
  formActionSchedule_Update(input: $formActionSchedule) {
    object {
      ...FormActionSchedule
    }
    errors {
      field
      messages
    }
  }
  formActionEscalation_BulkUpdate(input: {objects: $escalations}) {
    objects {
      ...formActionEscalation
    }
    errors {
      field
      messages
    }
  }
}
    ${FormActionScheduleFragmentDoc}
${FormActionEscalationFragmentDoc}`;
export type SaveFormActionScheduleMutationFn = Apollo.MutationFunction<SaveFormActionScheduleMutation, SaveFormActionScheduleMutationVariables>;

/**
 * __useSaveFormActionScheduleMutation__
 *
 * To run a mutation, you first call `useSaveFormActionScheduleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFormActionScheduleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFormActionScheduleMutation, { data, loading, error }] = useSaveFormActionScheduleMutation({
 *   variables: {
 *      formActionSchedule: // value for 'formActionSchedule'
 *      escalations: // value for 'escalations'
 *   },
 * });
 */
export function useSaveFormActionScheduleMutation(baseOptions?: Apollo.MutationHookOptions<SaveFormActionScheduleMutation, SaveFormActionScheduleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFormActionScheduleMutation, SaveFormActionScheduleMutationVariables>(SaveFormActionScheduleDocument, options);
      }
export type SaveFormActionScheduleMutationHookResult = ReturnType<typeof useSaveFormActionScheduleMutation>;
export type SaveFormActionScheduleMutationResult = Apollo.MutationResult<SaveFormActionScheduleMutation>;
export type SaveFormActionScheduleMutationOptions = Apollo.BaseMutationOptions<SaveFormActionScheduleMutation, SaveFormActionScheduleMutationVariables>;