import React from 'react'

import Category from './category'
import style from './index.module.sass'
import { AdviceSectionQuery } from '../__gen'

type SectionType = NonNullable<AdviceSectionQuery["adviceSection_List"]>["objects"]

type Props = {
	sections: SectionType
	currentCategoryId: string | null
	currentSubcategoryId: string | null
}

const AdviceLeftPanel = (props: Props) => {
	return (
		<div className={style.container}>
			<div className={style.inner}>
				{props.sections.map(adviceSection => (
					adviceSection.categories.map(c => (
						<Category 
							key={c.id}
							category={c}
							currentCategoryId={props.currentCategoryId}
							currentSubcategoryId={props.currentSubcategoryId}
						/>
					))
				))}
			</div>
		</div>
	)
}

export default AdviceLeftPanel