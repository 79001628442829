import { Modal } from 'antd'
import gql from 'graphql-tag'
import React, { useState } from 'react'

import { Form_ActionInput, Origin_Format } from '@/__gen/types'
import { SpinnerButton } from '@/components/common/button'
import { useGlobalContext, useRouter } from '@/components/context'
import { validateFlushAndWaitForSave } from '@/state/answer/thunks'
import { useFormContext } from '@/state/main-form/reducer'
import { useAppDispatch } from '@/utils/hooks'
import { formUrl } from '@/utils/url'

import { SignatureType } from '../../form-actions/complete-button/complete-modal'
import {
	isReusableSignature,
	isTextSignature,
} from '../../form-actions/complete-button/complete-modal/common'
import { Signature } from '../../form-actions/complete-button/complete-modal/signature'
import { PdfGeneratorsForFormDocument, PdfGeneratorsForFormQuery } from '../__gen'

import { useSignOffPdfActionMutation } from './__gen'
import style from './index.module.sass'

type Props = {
	pdfGenerator: NonNullable<PdfGeneratorsForFormQuery['pdfGenerator_List']>['objects'][number]
	onPdfCreate: () => void
}

gql`
	mutation signOffPdfAction($formAction: form_actionInput!) {
		formAction_Update(input: $formAction) {
			object {
				id
			}
			errors {
				field
				messages
			}
		}
	}
`

export const SignOff = ({ pdfGenerator, onPdfCreate }: Props) => {
	const dispatch = useAppDispatch()
	const globalContext = useGlobalContext()
	const router = useRouter()
	const [creating, setCreating] = useState(false)

	const formContext = useFormContext()
	const [signOffPdfActionMutation, { loading }] = useSignOffPdfActionMutation()
	const code = formContext.publicForm?.code || null
	const [signature, setSignature] = useState<SignatureType | null>(null)

	const backUrl = formUrl({ ...globalContext, formId: formContext.form.id, recordId: formContext.record.id })

	const signPdf = async () => {
		const result = await dispatch(validateFlushAndWaitForSave({}))
		if (result.meta.requestStatus == 'rejected') {
			setCreating(false)
			return
		}
		if (!signature) {
			throw Error('wow')
		}

		const signatureVars =
			isReusableSignature(signature) ?
				({ reuseSignatureFormActionId: signature.reusePreviousFormAction.id } satisfies Form_ActionInput)
			: isTextSignature(signature) ?
				({ signatureText: signature.text, signatureImage: signature.file } satisfies Form_ActionInput)
			:	({ signatureImage: signature.file } satisfies Form_ActionInput)

		const response = await signOffPdfActionMutation({
			variables: {
				formAction: {
					assignedUser: globalContext.user.id,
					completedOn: new Date(),
					form: formContext.form.id,
					record: formContext.record.id,
					priority: 1,
					requestSignature: true,
					signatureNote: signature.signatureNote,
					originFormat: Origin_Format.PdfCreation,
					...signatureVars,
				},
			},
			// Wait for dependant queries to finish loading with new pdf
			// before we take away loading card
			awaitRefetchQueries: true,
			refetchQueries: [PdfGeneratorsForFormDocument],
		})

		const action = response.data?.formAction_Update?.object

		setCreating(false)
		if (!action) {
			alert('An unknown error occurred signing your PDF.')
			return
		}

		onPdfCreate()
		router.history.push(backUrl)
	}

	return (
		<Modal title={'Sign Off'} open width={650} footer={null} onCancel={() => router.history.push(backUrl)}>
			<div>
				<Signature setSignature={setSignature} signature={signature} pdfGenerators={[pdfGenerator]} />

				<div style={{ marginTop: 10, width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
					<SpinnerButton loading={loading} onClick={signPdf} disabled={!signature}>
						Sign
					</SpinnerButton>
				</div>
			</div>
		</Modal>
	)
}
