import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type RecordDetailsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RecordDetailsQuery = { __typename: 'PharosQuery', record: { __typename: 'RecordType', id: string, name: string, position: string, image: any | null, team: { __typename: 'TeamType', id: string, name: string }, template: { __typename: 'TemplateType', id: string, namePlaceholder: string | null, copyPositionText: boolean, position: string } } | null };


export const RecordDetailsDocument = gql`
    query recordDetails($id: ID!) {
  record(id: $id) {
    id
    name
    position
    image
    team {
      id
      name
    }
    template {
      id
      namePlaceholder
      copyPositionText
      position
    }
  }
}
    `;

/**
 * __useRecordDetailsQuery__
 *
 * To run a query within a React component, call `useRecordDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecordDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecordDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecordDetailsQuery(baseOptions: Apollo.QueryHookOptions<RecordDetailsQuery, RecordDetailsQueryVariables> & ({ variables: RecordDetailsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecordDetailsQuery, RecordDetailsQueryVariables>(RecordDetailsDocument, options);
      }
export function useRecordDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecordDetailsQuery, RecordDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecordDetailsQuery, RecordDetailsQueryVariables>(RecordDetailsDocument, options);
        }
export function useRecordDetailsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecordDetailsQuery, RecordDetailsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<RecordDetailsQuery, RecordDetailsQueryVariables>(RecordDetailsDocument, options);
        }
export type RecordDetailsQueryHookResult = ReturnType<typeof useRecordDetailsQuery>;
export type RecordDetailsLazyQueryHookResult = ReturnType<typeof useRecordDetailsLazyQuery>;
export type RecordDetailsSuspenseQueryHookResult = ReturnType<typeof useRecordDetailsSuspenseQuery>;
export type RecordDetailsQueryResult = Apollo.QueryResult<RecordDetailsQuery, RecordDetailsQueryVariables>;