import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DashboardMetricsQueryVariables = Types.Exact<{
  moduleId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  visibleToSuiteAdmin?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type DashboardMetricsQuery = { __typename: 'PharosQuery', fieldDateRangeMetric_List: { __typename: 'FieldDateRangeMetricTypeListBase', objects: Array<{ __typename: 'FieldDateRangeMetricType', id: string, name: string, order: number }> } | null, fieldAggregationMetric_List: { __typename: 'FieldAggregationMetricTypeListBase', objects: Array<{ __typename: 'FieldAggregationMetricType', id: string, name: string, order: number }> } | null, fieldGroupMetric_List: { __typename: 'FieldGroupMetricTypeListBase', objects: Array<{ __typename: 'FieldGroupMetricType', id: string, name: string, order: number }> } | null, specificMetric_List: { __typename: 'SpecificMetricTypeListBase', objects: Array<{ __typename: 'SpecificMetricType', id: string, name: string, order: number }> } | null, formStatusMetric_List: { __typename: 'FormStatusMetricTypeListBase', objects: Array<{ __typename: 'FormStatusMetricType', id: string, name: string, order: number }> } | null, notificationsMetric_List: { __typename: 'NotificationsMetricTypeListBase', objects: Array<{ __typename: 'NotificationsMetricType', id: string, name: string, order: number }> } | null };


export const DashboardMetricsDocument = gql`
    query DashboardMetrics($moduleId: ID, $visibleToSuiteAdmin: Boolean) {
  fieldDateRangeMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
  fieldAggregationMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
  fieldGroupMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
  specificMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
  formStatusMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
  notificationsMetric_List(
    limit: 100
    module: $moduleId
    visibleToSuiteAdmin: $visibleToSuiteAdmin
  ) {
    objects {
      id
      name
      order
    }
  }
}
    `;

/**
 * __useDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardMetricsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      visibleToSuiteAdmin: // value for 'visibleToSuiteAdmin'
 *   },
 * });
 */
export function useDashboardMetricsQuery(baseOptions?: Apollo.QueryHookOptions<DashboardMetricsQuery, DashboardMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(DashboardMetricsDocument, options);
      }
export function useDashboardMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardMetricsQuery, DashboardMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(DashboardMetricsDocument, options);
        }
export function useDashboardMetricsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DashboardMetricsQuery, DashboardMetricsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(DashboardMetricsDocument, options);
        }
export type DashboardMetricsQueryHookResult = ReturnType<typeof useDashboardMetricsQuery>;
export type DashboardMetricsLazyQueryHookResult = ReturnType<typeof useDashboardMetricsLazyQuery>;
export type DashboardMetricsSuspenseQueryHookResult = ReturnType<typeof useDashboardMetricsSuspenseQuery>;
export type DashboardMetricsQueryResult = Apollo.QueryResult<DashboardMetricsQuery, DashboardMetricsQueryVariables>;