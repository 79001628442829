import React from 'react'

import SimpleIconButton from '@/components/common/simple-icon-button'
import { useAppDispatch } from '@/utils/hooks'
import { toggleHelpInfoField } from '@/state/user-interface'
import iconStyle from '@sass/common/icon.module.sass'


interface Props {
	title: string
	htmlContent: string
}

export const HelpInfoButton = (props: Props) => {
	const dispatch = useAppDispatch()
	
	return (
		<SimpleIconButton
			small
			iconClass={iconStyle.info}
			onClick={() => dispatch(toggleHelpInfoField(props))} 
		/>
	)
}
