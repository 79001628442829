import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SignOffPdfActionMutationVariables = Types.Exact<{
  formAction: Types.Form_ActionInput;
}>;


export type SignOffPdfActionMutation = { __typename: 'PharosMutations', formAction_Update: { __typename: 'form_actionPayload', object: { __typename: 'FormActionType', id: string } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const SignOffPdfActionDocument = gql`
    mutation signOffPdfAction($formAction: form_actionInput!) {
  formAction_Update(input: $formAction) {
    object {
      id
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type SignOffPdfActionMutationFn = Apollo.MutationFunction<SignOffPdfActionMutation, SignOffPdfActionMutationVariables>;

/**
 * __useSignOffPdfActionMutation__
 *
 * To run a mutation, you first call `useSignOffPdfActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOffPdfActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOffPdfActionMutation, { data, loading, error }] = useSignOffPdfActionMutation({
 *   variables: {
 *      formAction: // value for 'formAction'
 *   },
 * });
 */
export function useSignOffPdfActionMutation(baseOptions?: Apollo.MutationHookOptions<SignOffPdfActionMutation, SignOffPdfActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignOffPdfActionMutation, SignOffPdfActionMutationVariables>(SignOffPdfActionDocument, options);
      }
export type SignOffPdfActionMutationHookResult = ReturnType<typeof useSignOffPdfActionMutation>;
export type SignOffPdfActionMutationResult = Apollo.MutationResult<SignOffPdfActionMutation>;
export type SignOffPdfActionMutationOptions = Apollo.BaseMutationOptions<SignOffPdfActionMutation, SignOffPdfActionMutationVariables>;