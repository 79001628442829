import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SaveFieldNotificationMutationVariables = Types.Exact<{
  id?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  fieldId: Types.Scalars['String']['input'];
  fieldType: Types.Scalars['String']['input'];
  delta: Types.Scalars['Int']['input'];
  sendToOrganisation: Types.Scalars['Boolean']['input'];
}>;


export type SaveFieldNotificationMutation = { __typename: 'PharosMutations', fieldNotification_Update: { __typename: 'field_notificationPayload', object: { __typename: 'FieldNotificationType', id: string, delta: number, sendToOrganisation: boolean, isActive: boolean, fieldNotificationUsers: Array<{ __typename: 'FieldNotificationUserType', id: string, isActive: boolean, user: { __typename: 'UserType', firstName: string, lastName: string } }> } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };

export type DeactivateNotificationMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeactivateNotificationMutation = { __typename: 'PharosMutations', fieldNotification_Update: { __typename: 'field_notificationPayload', object: { __typename: 'FieldNotificationType', id: string, isActive: boolean } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };

export type DeactivateNotificationUserMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeactivateNotificationUserMutation = { __typename: 'PharosMutations', fieldNotificationUser_Update: { __typename: 'field_notification_userPayload', object: { __typename: 'FieldNotificationUserType', id: string, isActive: boolean } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };

export type CreateNotificationUserMutationVariables = Types.Exact<{
  userId: Types.Scalars['ID']['input'];
  fieldNotificationId: Types.Scalars['ID']['input'];
}>;


export type CreateNotificationUserMutation = { __typename: 'PharosMutations', fieldNotificationUser_Update: { __typename: 'field_notification_userPayload', object: { __typename: 'FieldNotificationUserType', id: string, isActive: boolean, user: { __typename: 'UserType', id: string } } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const SaveFieldNotificationDocument = gql`
    mutation saveFieldNotification($id: ID, $fieldId: String!, $fieldType: String!, $delta: Int!, $sendToOrganisation: Boolean!) {
  fieldNotification_Update(
    input: {id: $id, fieldId: $fieldId, fieldContentType: $fieldType, delta: $delta, sendToOrganisation: $sendToOrganisation}
  ) {
    object {
      id
      delta
      sendToOrganisation
      isActive
      fieldNotificationUsers {
        id
        isActive
        user {
          firstName
          lastName
        }
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type SaveFieldNotificationMutationFn = Apollo.MutationFunction<SaveFieldNotificationMutation, SaveFieldNotificationMutationVariables>;

/**
 * __useSaveFieldNotificationMutation__
 *
 * To run a mutation, you first call `useSaveFieldNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveFieldNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveFieldNotificationMutation, { data, loading, error }] = useSaveFieldNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fieldId: // value for 'fieldId'
 *      fieldType: // value for 'fieldType'
 *      delta: // value for 'delta'
 *      sendToOrganisation: // value for 'sendToOrganisation'
 *   },
 * });
 */
export function useSaveFieldNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SaveFieldNotificationMutation, SaveFieldNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveFieldNotificationMutation, SaveFieldNotificationMutationVariables>(SaveFieldNotificationDocument, options);
      }
export type SaveFieldNotificationMutationHookResult = ReturnType<typeof useSaveFieldNotificationMutation>;
export type SaveFieldNotificationMutationResult = Apollo.MutationResult<SaveFieldNotificationMutation>;
export type SaveFieldNotificationMutationOptions = Apollo.BaseMutationOptions<SaveFieldNotificationMutation, SaveFieldNotificationMutationVariables>;
export const DeactivateNotificationDocument = gql`
    mutation deactivateNotification($id: ID!) {
  fieldNotification_Update(input: {id: $id, isActive: false}) {
    object {
      id
      isActive
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type DeactivateNotificationMutationFn = Apollo.MutationFunction<DeactivateNotificationMutation, DeactivateNotificationMutationVariables>;

/**
 * __useDeactivateNotificationMutation__
 *
 * To run a mutation, you first call `useDeactivateNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateNotificationMutation, { data, loading, error }] = useDeactivateNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateNotificationMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateNotificationMutation, DeactivateNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateNotificationMutation, DeactivateNotificationMutationVariables>(DeactivateNotificationDocument, options);
      }
export type DeactivateNotificationMutationHookResult = ReturnType<typeof useDeactivateNotificationMutation>;
export type DeactivateNotificationMutationResult = Apollo.MutationResult<DeactivateNotificationMutation>;
export type DeactivateNotificationMutationOptions = Apollo.BaseMutationOptions<DeactivateNotificationMutation, DeactivateNotificationMutationVariables>;
export const DeactivateNotificationUserDocument = gql`
    mutation deactivateNotificationUser($id: ID!) {
  fieldNotificationUser_Update(input: {id: $id, isActive: false}) {
    object {
      id
      isActive
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type DeactivateNotificationUserMutationFn = Apollo.MutationFunction<DeactivateNotificationUserMutation, DeactivateNotificationUserMutationVariables>;

/**
 * __useDeactivateNotificationUserMutation__
 *
 * To run a mutation, you first call `useDeactivateNotificationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivateNotificationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivateNotificationUserMutation, { data, loading, error }] = useDeactivateNotificationUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivateNotificationUserMutation(baseOptions?: Apollo.MutationHookOptions<DeactivateNotificationUserMutation, DeactivateNotificationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeactivateNotificationUserMutation, DeactivateNotificationUserMutationVariables>(DeactivateNotificationUserDocument, options);
      }
export type DeactivateNotificationUserMutationHookResult = ReturnType<typeof useDeactivateNotificationUserMutation>;
export type DeactivateNotificationUserMutationResult = Apollo.MutationResult<DeactivateNotificationUserMutation>;
export type DeactivateNotificationUserMutationOptions = Apollo.BaseMutationOptions<DeactivateNotificationUserMutation, DeactivateNotificationUserMutationVariables>;
export const CreateNotificationUserDocument = gql`
    mutation createNotificationUser($userId: ID!, $fieldNotificationId: ID!) {
  fieldNotificationUser_Update(
    input: {user: $userId, fieldNotification: $fieldNotificationId}
  ) {
    object {
      id
      isActive
      user {
        id
      }
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type CreateNotificationUserMutationFn = Apollo.MutationFunction<CreateNotificationUserMutation, CreateNotificationUserMutationVariables>;

/**
 * __useCreateNotificationUserMutation__
 *
 * To run a mutation, you first call `useCreateNotificationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNotificationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNotificationUserMutation, { data, loading, error }] = useCreateNotificationUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      fieldNotificationId: // value for 'fieldNotificationId'
 *   },
 * });
 */
export function useCreateNotificationUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateNotificationUserMutation, CreateNotificationUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateNotificationUserMutation, CreateNotificationUserMutationVariables>(CreateNotificationUserDocument, options);
      }
export type CreateNotificationUserMutationHookResult = ReturnType<typeof useCreateNotificationUserMutation>;
export type CreateNotificationUserMutationResult = Apollo.MutationResult<CreateNotificationUserMutation>;
export type CreateNotificationUserMutationOptions = Apollo.BaseMutationOptions<CreateNotificationUserMutation, CreateNotificationUserMutationVariables>;