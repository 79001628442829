import React from 'react'
import c from 'classnames'
import { MONTHS } from '../common/const'
import commonStyle from '../common/common.module.sass'
import { SearchOutlined } from '@ant-design/icons'
import { Row } from '../row'
import style from './index.module.sass'
import { Input } from '@/components/common/inputs'


type Props = {
	startYear: number
	startMonth: number
	setSearch: (search: string) => void
}

export const getMonths = ({startYear, startMonth}: {startYear: number, startMonth: number}) => {
	return MONTHS.map((_, i) => {
		const month = MONTHS[(i + startMonth - 1) % 12]
		return {
			month: month.index,
			year: startYear + Math.floor((startMonth + i - 1) / 12),
		}
	})
}

export const Headers = ({ startYear, startMonth, setSearch }: Props) => {
	const months = getMonths({startYear, startMonth})
	return (
		<>
			<Row
				left={
					<div
						className={c(style.searchContainer, style.headerContainer)}
						style={{ borderTopLeftRadius: 8 }}
					>
						<SearchOutlined />
						<Input
							className={style.searchInput}
							placeholder="Search..."
							onChange={e => {
								const search = e.target.value
								setSearch(search)
							}}
						/>
					</div>
				}
				right={
					<div
						className={c(commonStyle.monthsContainer, style.headerContainer)}
						style={{ borderTopRightRadius: 8 }}
					>
						{months.map(month => (
							<div
								key={month.month}
								className={c(
									commonStyle.monthCell,
									style.monthTitle,
									{
										[style.current]: (
											new Date().getFullYear() == month.year
											&& new Date().getMonth() + 1 == month.month
										),
										[style.monthJan]: month.month === 1,
									}
								)}
							>
								{MONTHS[month.month - 1].title}
							</div>
						))}
					</div>
				}
			/>
			<Row
				left={<div className={style.topRowSpacer} />}
				right={null}
			/>
		</>

	)
}