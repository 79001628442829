import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PdfFragment = { __typename: 'PdfType', id: string, name: string, created: string, origin: Types.PdfPdfOriginChoices, isActive: boolean };

export type DeletePdfMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type DeletePdfMutation = { __typename: 'PharosMutations', pdf_Update: { __typename: 'pdfPayload', object: { __typename: 'PdfType', id: string, isActive: boolean } | null } | null };

export const PdfFragmentDoc = gql`
    fragment Pdf on PdfType {
  id
  name
  created
  origin
  isActive
}
    `;
export const DeletePdfDocument = gql`
    mutation deletePdf($id: ID!) {
  pdf_Update(input: {id: $id, isActive: false}) {
    object {
      id
      isActive
    }
  }
}
    `;
export type DeletePdfMutationFn = Apollo.MutationFunction<DeletePdfMutation, DeletePdfMutationVariables>;

/**
 * __useDeletePdfMutation__
 *
 * To run a mutation, you first call `useDeletePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePdfMutation, { data, loading, error }] = useDeletePdfMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePdfMutation(baseOptions?: Apollo.MutationHookOptions<DeletePdfMutation, DeletePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePdfMutation, DeletePdfMutationVariables>(DeletePdfDocument, options);
      }
export type DeletePdfMutationHookResult = ReturnType<typeof useDeletePdfMutation>;
export type DeletePdfMutationResult = Apollo.MutationResult<DeletePdfMutation>;
export type DeletePdfMutationOptions = Apollo.BaseMutationOptions<DeletePdfMutation, DeletePdfMutationVariables>;