import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReuseSignaturesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type ReuseSignaturesQuery = { __typename: 'PharosQuery', formAction_List: { __typename: 'FormActionTypeListBase', objects: Array<{ __typename: 'FormActionType', id: string, signatureNote: string }> } | null };


export const ReuseSignaturesDocument = gql`
    query reuseSignatures {
  formAction_List(reusableSignatures: true) {
    objects {
      id
      signatureNote
    }
  }
}
    `;

/**
 * __useReuseSignaturesQuery__
 *
 * To run a query within a React component, call `useReuseSignaturesQuery` and pass it any options that fit your needs.
 * When your component renders, `useReuseSignaturesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReuseSignaturesQuery({
 *   variables: {
 *   },
 * });
 */
export function useReuseSignaturesQuery(baseOptions?: Apollo.QueryHookOptions<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>(ReuseSignaturesDocument, options);
      }
export function useReuseSignaturesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>(ReuseSignaturesDocument, options);
        }
export function useReuseSignaturesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>(ReuseSignaturesDocument, options);
        }
export type ReuseSignaturesQueryHookResult = ReturnType<typeof useReuseSignaturesQuery>;
export type ReuseSignaturesLazyQueryHookResult = ReturnType<typeof useReuseSignaturesLazyQuery>;
export type ReuseSignaturesSuspenseQueryHookResult = ReturnType<typeof useReuseSignaturesSuspenseQuery>;
export type ReuseSignaturesQueryResult = Apollo.QueryResult<ReuseSignaturesQuery, ReuseSignaturesQueryVariables>;