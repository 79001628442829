import React from 'react'
import { Link, Route } from 'react-router-dom'

import { useFormContext } from '@/state/main-form/reducer'
import { fromCompleteActionUrl, publicFormCompleteActionUrl } from '@/utils/url'
import { useGlobalContext } from '@/components/context'
import buttonStyle from '@sass/common/button.module.sass'
import { CompleteModal } from './complete-modal'


type Props = {
	formAction: {
		id: string;
		requestSignature: boolean;
		form: {
			pdfGenerators: {
				id: string
				name: string
			}[]
		}
		isScheduled: boolean
	}
}

export const CompleteFormActionButton = ({ formAction }: Props) => {
	const formContext = useFormContext()
	const globalContext = useGlobalContext()
	const code = formContext.publicForm?.code || null

	const url = code
		? publicFormCompleteActionUrl({publicFormCode: code, actionId: formAction.id})
		: fromCompleteActionUrl({
			...globalContext,
			recordId: formContext.record.id,
			formId: formContext.form.id,
			actionId: formAction.id,
		})

	return (
		<div>
			<Link to={url}>
				<button className={buttonStyle.primarySolid}>
					{formContext.form.publicLinkSubmitButtonText || 'Complete'}
				</button>
			</Link>

			<Route
				path={url}
				component={() => <CompleteModal formAction={formAction} />}
			/>
		</div>
	)
}