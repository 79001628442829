import React from 'react'
import * as Yup from 'yup'
import c from 'classnames'
import { Formik, Field, FormikProps } from 'formik'
import gql from "graphql-tag"
import { apolloClient } from '@/state/apollo-client'
import { Link } from 'react-router-dom'
import { signupUrl } from '@/utils/url'
import { TextField } from '@/components/common/formik'
import { SpinnerButton } from '@/components/common/button'
import generalStyle from '@sass/common/general.module.sass'
import { useTitle } from '@/utils/hooks'
import formStyle from '@sass/common/form.module.sass'
import buttonStyle from '@sass/common/button.module.sass'
import style from '../common.module.sass'
import { useLoginMfa } from './use-mfa-login'
import { useLoginUserMutation } from './__gen'

gql`
	mutation loginUser(
		$email: String!,
		$password: String!
	) {
		user_Login(
			email: $email,
			password: $password
		) {
			failed
			webauthnOptions
			user {
				id
			}
		}
	}
`


type FormValues = {
	email: string
	password: string
}


export const Login = () => {
	const [loginUser, { loading: loginLoading }] = useLoginUserMutation()
	const onSuccess = () => {
		apolloClient.resetStore()
	}
	const mfaLogin = useLoginMfa()
	useTitle('Login')
	return (
		<div className={formStyle.pageFormContainer}>
			<div style={{ marginTop: '10vh' }}></div>
			<h3>Log in</h3>
			<div className={formStyle.pageForm}></div>
			<Formik
				initialValues={{ email: '', password: '' }}
				validationSchema={Yup.object().shape({
					email: Yup.string().email().required('Required'),
					password: Yup.string().required('Required'),
				})}
				onSubmit={async (values, actions) => {
					const r = await loginUser({ variables: values })
					actions.setSubmitting(false)
					if (!r.data?.user_Login) {
						actions.setStatus('Error submitting authentication request')
					}
					else if (r.data.user_Login.failed) {
						actions.setStatus('Email or password incorrect')
					}
					else if (r.data.user_Login.webauthnOptions) {
						const result = await mfaLogin({ webauthnOptions: r.data.user_Login.webauthnOptions })
						if (result.success) {
							onSuccess()
						} else {
							actions.setStatus("Error validating MFA. If you wish to reset your MFA credentials, please contact your administrator.")
						}
					}
					else if (r.data.user_Login.user) {
						onSuccess()
					}
					else {
						actions.setStatus('Unexpected authentication error')
					}
				}}
			>
				{(props: FormikProps<FormValues>) => {
					const { status, isSubmitting, handleSubmit } = props
					return (
						<form onSubmit={handleSubmit} className={formStyle.commonForm}>

							<Field
								name="email"
								placeholder="Email"
								className={style.input}
								component={TextField}
							/>
							<Field
								name="password"
								placeholder="Password"
								className={style.input}
								component={TextField}
								type="password"
							/>

							<div className={generalStyle.error}>{status}</div>
							<div className={formStyle.formButton}>
								<SpinnerButton loading={isSubmitting} type="submit">
									Login
								</SpinnerButton>
							</div>
						</form>
					)
				}}
			</Formik>

			<a href="/stock_auth/password_reset/">
				<button
					type="button"
					className={c(buttonStyle.noOutline, buttonStyle.small, buttonStyle.grey)}
				>
					Reset Password
				</button>
			</a>
			<Link className={c(buttonStyle.noOutline, buttonStyle.small, buttonStyle.grey)} to={signupUrl()}>Sign up</Link>
		</div>
	)
}