import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnswerTextAddressesQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type AnswerTextAddressesQuery = { __typename: 'PharosQuery', answerText_List: { __typename: 'AnswerTextTypeListBase', objects: Array<{ __typename: 'AnswerTextType', id: string, content: string | null, contentObjectName: string | null, field: { __typename: 'FieldType', id: string, title: string }, tableField: { __typename: 'TableFieldType', id: string, title: string } | null, addressGeolocation: { __typename: 'AddressGeolocationType', id: string, lng: any, lat: any } | null, link: { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string } | null }> } | null };


export const AnswerTextAddressesDocument = gql`
    query answerTextAddresses($moduleId: ID!, $teamId: ID) {
  answerText_List(
    module: $moduleId
    team: $teamId
    deactivated_Isnull: true
    objectActive: true
    isAddressWithContent: true
    orderBy: [{field: content, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      content
      contentObjectName
      field {
        id
        title
      }
      tableField {
        id
        title
      }
      addressGeolocation {
        id
        lng
        lat
      }
      link {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useAnswerTextAddressesQuery__
 *
 * To run a query within a React component, call `useAnswerTextAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerTextAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerTextAddressesQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useAnswerTextAddressesQuery(baseOptions: Apollo.QueryHookOptions<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables> & ({ variables: AnswerTextAddressesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>(AnswerTextAddressesDocument, options);
      }
export function useAnswerTextAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>(AnswerTextAddressesDocument, options);
        }
export function useAnswerTextAddressesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>(AnswerTextAddressesDocument, options);
        }
export type AnswerTextAddressesQueryHookResult = ReturnType<typeof useAnswerTextAddressesQuery>;
export type AnswerTextAddressesLazyQueryHookResult = ReturnType<typeof useAnswerTextAddressesLazyQuery>;
export type AnswerTextAddressesSuspenseQueryHookResult = ReturnType<typeof useAnswerTextAddressesSuspenseQuery>;
export type AnswerTextAddressesQueryResult = Apollo.QueryResult<AnswerTextAddressesQuery, AnswerTextAddressesQueryVariables>;