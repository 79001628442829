import React from 'react'
import gql from "graphql-tag"
import c from 'classnames'
import formatDistanceToNow  from 'date-fns/formatDistanceToNow'

import { SmallSpinner } from '@/components/common/loading'
import iconStyle from '@sass/common/icon.module.sass'
import styles from './index.module.sass'
import { useEmailHistoryQuery } from './__gen'


gql`
	query emailHistory($regardingObjectId: ID!, $regardingObjectContentType: String!) {
		email_List(
			regardingObjectId: $regardingObjectId,
			regardingContentTypeString: $regardingObjectContentType,
			limit: 1000,
			orderBy: [{field: id}]
		) {
			objects {
				id
				sendToEmail
				created
			}
		}
	}
`
type Props = {
	regardingObjectId: string,
	regardingObjectContentType: string
}

const sentEmailList: React.FC<Props> = (props) => {
	const { data, loading } = useEmailHistoryQuery({ variables: props})
	if(loading) {
		return <div style={{margin: '0 8px'}}><SmallSpinner /></div>
	}
	return (
		<div>
			{data?.email_List?.objects.map((e) => (
				<div className={styles.emailRow} key={e.id}>
					<div className={c(styles.email, iconStyle.letter)}>{e.sendToEmail}</div>
					<div className={styles.created}>{formatDistanceToNow(new Date(e.created))} ago</div>
				</div>
			))}
			{data?.email_List?.objects.length == 0  &&
				<div className={styles.noContent}>No emails</div>
			}
		</div>
	)
}

export default sentEmailList
