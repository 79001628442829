import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionMetricsQueryVariables = Types.Exact<{
  recordIds: Array<Types.Scalars['ID']['input']>;
  yearMonth: Types.Scalars['String']['input'];
}>;


export type FormActionMetricsQuery = { __typename: 'PharosQuery', formAction_List: { __typename: 'FormActionTypeListBase', objects: Array<{ __typename: 'FormActionType', id: string, completedOn: string | null, created: string, assignedAnonEmail: string, assignedAnonName: string, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string }, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null }> } | null, formActionSchedule_List: { __typename: 'FormActionScheduleTypeListBase', objects: Array<{ __typename: 'FormActionScheduleType', id: string, assignedAnonEmail: string, assignedAnonName: string, dueAt: string, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string } }> } | null, formActionEscalationEvent_List: { __typename: 'FormActionEscalationEventTypeListBase', objects: Array<{ __typename: 'FormActionEscalationEventType', id: string, created: string, assignedAnonEmail: string, assignedAnonName: string, record: { __typename: 'RecordType', id: string }, form: { __typename: 'FormType', id: string }, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null }> } | null };


export const FormActionMetricsDocument = gql`
    query formActionMetrics($recordIds: [ID!]!, $yearMonth: String!) {
  formAction_List(record_In: $recordIds, yearMonth: $yearMonth) {
    objects {
      id
      completedOn
      created
      record {
        id
      }
      form {
        id
      }
      assignedUser {
        id
        firstName
        lastName
        email
      }
      assignedAnonEmail
      assignedAnonName
      assignedAnonEmailAnswer {
        id
        content
        contactName
      }
    }
  }
  formActionSchedule_List(record_In: $recordIds, yearMonth: $yearMonth) {
    objects {
      id
      assignedAnonEmail
      assignedAnonName
      dueAt
      unitAmount
      unitName
      assignedAnonEmailAnswer {
        id
        content
        contactName
      }
      assignedUser {
        id
        firstName
        lastName
        email
      }
      record {
        id
      }
      form {
        id
      }
    }
  }
  formActionEscalationEvent_List(record_In: $recordIds, yearMonth: $yearMonth) {
    objects {
      id
      created
      record {
        id
      }
      form {
        id
      }
      assignedUser {
        id
        firstName
        lastName
        email
      }
      assignedAnonEmail
      assignedAnonName
      assignedAnonEmailAnswer {
        id
        content
        contactName
      }
    }
  }
}
    `;

/**
 * __useFormActionMetricsQuery__
 *
 * To run a query within a React component, call `useFormActionMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionMetricsQuery({
 *   variables: {
 *      recordIds: // value for 'recordIds'
 *      yearMonth: // value for 'yearMonth'
 *   },
 * });
 */
export function useFormActionMetricsQuery(baseOptions: Apollo.QueryHookOptions<FormActionMetricsQuery, FormActionMetricsQueryVariables> & ({ variables: FormActionMetricsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionMetricsQuery, FormActionMetricsQueryVariables>(FormActionMetricsDocument, options);
      }
export function useFormActionMetricsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionMetricsQuery, FormActionMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionMetricsQuery, FormActionMetricsQueryVariables>(FormActionMetricsDocument, options);
        }
export function useFormActionMetricsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionMetricsQuery, FormActionMetricsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionMetricsQuery, FormActionMetricsQueryVariables>(FormActionMetricsDocument, options);
        }
export type FormActionMetricsQueryHookResult = ReturnType<typeof useFormActionMetricsQuery>;
export type FormActionMetricsLazyQueryHookResult = ReturnType<typeof useFormActionMetricsLazyQuery>;
export type FormActionMetricsSuspenseQueryHookResult = ReturnType<typeof useFormActionMetricsSuspenseQuery>;
export type FormActionMetricsQueryResult = Apollo.QueryResult<FormActionMetricsQuery, FormActionMetricsQueryVariables>;