import React, { useState } from 'react'
import c from 'classnames'
import _camelCase from 'lodash/camelCase'

import { GlobalContextProps, useGlobalContext, useRouter } from '@/components/context'
import { moduleUrl, relatedAnalyticsUrl, teamNoListUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import Icon from './icon'
import style from './icon-module.module.sass'
import AddTeamModal from '@/components/common/add-team-modal'

type Props = {
	module: GlobalContextProps["organisation"]["suite"]["moduleSetItems"][0]["module"]
	closeDropdown(): void
}


export default ({module, closeDropdown}: Props) => {
	const globalContext = useGlobalContext()
	const router = useRouter()
	const [showAddTeamModal, setShowAddTeamModal] = useState(false)

	const teamLinks = module.teams.map(team => {
		const to = relatedAnalyticsUrl({
			router,
			currContext: globalContext,
			nextContext: {...globalContext, currentModule: module, currentTeam: team},
		})
		// This is pretty dumb, I'm sure there's a better way to do this
		const current = globalContext.currentModule
			? teamNoListUrl({currentModule: globalContext.currentModule, currentTeam: globalContext.currentTeam})
			: '~~'
		return {
			name: team.name,
			to,
			active: to.startsWith(current),
		}
	})

	const toAll = relatedAnalyticsUrl({
		router,
		currContext: globalContext,
		nextContext: {...globalContext, currentModule: module, currentTeam: null},
	})
	const toAllCurrent = globalContext.currentModule
		? teamNoListUrl({currentModule: globalContext.currentModule, currentTeam: globalContext.currentTeam}) 
		: '~~'
	const viewAll = {
		name: <div className={c(style.topLink)}>View All</div>,
		to: relatedAnalyticsUrl({
			router,
			currContext: globalContext,
			nextContext: {...globalContext, currentModule: module, currentTeam: null},
		}),
		active: toAll.startsWith(toAllCurrent),
	}

	const addTeam = {
		name: (
			<button className={c(iconStyle.add, buttonStyle.small, buttonStyle.primarySolid, style.addTeamButton)}>
				New {module.teamWord}
			</button>
		),
		onClick: () => setShowAddTeamModal(true),
		active: false,
	}

	const links = [
		...(!module.singleton && globalContext.user.topLevelManager ? [addTeam] : []),
		...(module.teams.length >= 2 ? [viewAll] : []),
		...teamLinks,
	]

	// module.icon needs to be translated to iconStyle.medium*
	// backend should be updated
	const moduleIcon = _camelCase(module.icon?.replace('icon-', '')) as keyof typeof iconStyle
	const icon  = moduleIcon || 'cross'
	return (
		<>
			<Icon
				title={module.name}
				icon={icon}
				iconLink={relatedAnalyticsUrl({
					router,
					currContext: globalContext,
					nextContext: {
						...globalContext, 
						currentModule: module,
						currentTeam: module.teams.length >= 2 ? null: module.teams[0]
					},
				})}
				links={links}
				activePath={moduleUrl(module)}
				closeDropdown={closeDropdown}
			/>
			<AddTeamModal module={module} show={showAddTeamModal} callback={() => {setShowAddTeamModal(false)}} />
		</>
	)
}
