import React from 'react'
import _defer from 'lodash/defer'
import _debounce from 'lodash/debounce'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { GetFormParams } from '@/state/main-form/thunks'
import history from '@/utils/history'
import { apolloClient } from '@/state/apollo-client'
import { FORM_ANSWERS_AUTH, FORM_ANSWERS_PUBLIC } from '@/state/answer/get-form-answers'
import { SAVE_ANSWERS_AUTH } from '@/state/answer/save-answers-auth'
import { AppDispatch, RootState } from '@/state/redux-store'
import { showModal } from '@/state/modal/actions'
import { SUBMIT_PUBLIC_FORM } from '@/state/answer/submit-public-form'
import { publicFormSuccessUrl } from '@/utils/url'
import { selectAnswersForField, selectFieldForAnswer, selectInvalidAnswers, selectMissingMandatoryFields, selectObjectIdMap } from './selectors'
import { answerSlice } from './reducer'
import { addBlockNavigationListener, createMissingAnswers, extractSaveAnswerPubResponse, extractSaveAnswerResponse, getSaveAnswerVariables, waitForSave } from './utils'
import { ToSaveAnswer, GenericAnswer, isAnswerFile, isAnswerText, isAnswerDate, isAnswerBoolean, isAnswerDecimal } from './types'
import { SAVE_ANSWERS_PUBLIC } from './save-answers-public'
import { InitialAnswerType } from '@/state/main-form/types'
import { SaveAnswersPublicMutation, SaveAnswersPublicMutationVariables } from './save-answers-public/__gen'
import { PublicFormSubmitMutation, PublicFormSubmitMutationVariables } from './submit-public-form/__gen'
import { SaveAnswersAuthMutation, SaveAnswersAuthMutationVariables } from './save-answers-auth/__gen'
import { FormAnswersAuthQuery, FormAnswersAuthQueryVariables, FormAnswersPublicQuery } from './get-form-answers/__gen'


export const addInitialAnswers = () => (dispatch: AppDispatch, getState: () => RootState) => {
	const state = getState()
	if (!state.mainForm.data) {
		return
	}
	const objectIdMap = selectObjectIdMap(state)
	dispatch(answerSlice.actions.addInitialAnswers({
		objectIdMap,
		fields: state.mainForm.data.fields,
		initialAnswers: state.mainForm.data.initialAnswers,
	}))
	const missingAnswers = createMissingAnswers({ state, objectIdMap })
	if (missingAnswers.length > 0) {
		dispatch(answerSlice.actions.addMissingAnswers({ answers: missingAnswers }))
	}
}

export const addAnswerRow = (fieldId: string) => (dispatch: AppDispatch, getState: () => RootState) => {
	const state = getState()
	if (!state.mainForm.data) {
		throw Error("mainForm.data should not be empty here!")
	}
	const objectIdMap = selectObjectIdMap(state)
	dispatch(answerSlice.actions.addAnswerRow({
		objectIdMap,
		mainField: state.mainForm.data.fields[fieldId],
	}))
}

export const getAnswers = (params: GetFormParams) => async (dispatch: AppDispatch) => {
	let promise = 'publicFormCode' in params
		? dispatch(getAnswersPublic(params))
		: dispatch(getAnswersAuth(params))

	await promise

	dispatch(addInitialAnswers())
}

export const getAnswersAuth = createAsyncThunk<
	{ answers: GenericAnswer[] },
	{ recordId: string, formId: string },
	{ state: RootState }
>(
	'answers/getAuth',
	async ({ recordId, formId }, { dispatch, rejectWithValue, getState }) => {
		const { error, data } = await apolloClient.query<FormAnswersAuthQuery, FormAnswersAuthQueryVariables>({
			query: FORM_ANSWERS_AUTH,
			variables: { record: recordId, form: formId },
			fetchPolicy: 'no-cache',
		})
		const state = getState()
		if (formId !== state.mainForm.data?.form.id) {
			console.log("Ignorring answers due to form switch")
			return { answers: [] }
		}
		if (
			error
			|| !data?.answerBoolean_List?.objects
			|| !data?.answerDate_List?.objects
			|| !data?.answerDecimal_List?.objects
			|| !data?.answerFile_List?.objects
			|| !data?.answerText_List?.objects
		) {
			return rejectWithValue(null)
		}
		return {
			answers: [
				...data.answerBoolean_List.objects,
				...data.answerDate_List.objects,
				...data.answerDecimal_List.objects,
				...data.answerFile_List.objects,
				...data.answerText_List.objects,
			]
		}
	}
)

export const getAnswersPublic = createAsyncThunk<
	{ answers: GenericAnswer[] },
	{ publicFormCode: string },
	{ state: RootState }
>(
	'answers/getPublic',
	async ({ publicFormCode }: { publicFormCode: string }, { dispatch, rejectWithValue }) => {
		const { error, data } = await apolloClient.query<FormAnswersPublicQuery, {}>({
			query: FORM_ANSWERS_PUBLIC,
			context: { headers: { "X-Public-Form-Code": publicFormCode } },
			fetchPolicy: 'no-cache',
		})
		if (
			error
			|| !data?.answerBooleanPub_List?.objects
			|| !data?.answerDatePub_List?.objects
			|| !data?.answerDecimalPub_List?.objects
			|| !data?.answerFilePub_List?.objects
			|| !data?.answerTextPub_List?.objects
		) {
			return rejectWithValue(null)
		}
		return {
			answers: [
				...data.answerBooleanPub_List.objects,
				...data.answerDatePub_List.objects,
				...data.answerDecimalPub_List.objects,
				...data.answerFilePub_List.objects,
				...data.answerTextPub_List.objects,
			]
		}
	}
)


export const saveAnswers = createAsyncThunk<
	GenericAnswer[],
	void,
	{ state: RootState }
>(
	'answers/save',
	async (__, { dispatch, getState }
	) => {
		let state: RootState = getState()
		if (!state.mainForm.data) {
			alert('Unable to save answers. Form not loaded.')
			throw Error('Unable to save answers. Form not loaded.')
		}
		const variables = getSaveAnswerVariables(state)

		let objects = []
		if (state.mainForm.data.publicForm?.code) {
			let resp = await apolloClient.query<SaveAnswersPublicMutation, SaveAnswersPublicMutationVariables>({
				query: SAVE_ANSWERS_PUBLIC,
				variables,
				fetchPolicy: 'no-cache',
				context: { headers: { "X-Public-Form-Code": state.mainForm.data.publicForm.code } }
			})
			objects = extractSaveAnswerPubResponse(resp)
		} else {
			let resp = await apolloClient.query<SaveAnswersAuthMutation, SaveAnswersAuthMutationVariables>({
				query: SAVE_ANSWERS_AUTH,
				variables,
				fetchPolicy: 'no-cache',
			})
			objects = extractSaveAnswerResponse(resp)
		}

		state = getState()
		if (state.answers.queuedSave) {
			// Changes were made while this request was in flight, so save when
			// request is finished
			_defer(() => dispatch(saveAnswers()))
		} else if (state.answers.queuedSubmit) {
			// Might need to wait for a queued save first!
			const a = state.answers.queuedSubmit
			_defer(() => dispatch(doPublicFormSubmit(a)))
		}
		return objects
	}
)


const debounceSave = _debounce((dispatch) => {
	dispatch(saveAnswers())
}, 1000)


const preUpdateGuardAndValidation = ({ getState, toUpdateAnswers }: { getState: () => RootState, toUpdateAnswers: ToSaveAnswer[] }) => {
	// We don't want to save invalid answers to the backend (ie if we save an email, it should be valid)
	const state = getState()
	addBlockNavigationListener(getState)
	const invalidAnswers = selectInvalidAnswers(state)

	const updateInvalidAnswers = toUpdateAnswers.filter(
		a => invalidAnswers.find(i => i.id === a.id)
	)

	return updateInvalidAnswers.length > 0
}


export const updateAnswersImmutably = createAsyncThunk<
	void,
	{ answer: GenericAnswer, hasContent: boolean },
	{ state: RootState }
>('answers/updateImmutably', async ({ answer, hasContent }, { dispatch, getState }) => {
	// This exists to avoid overwriting files, as they are not archived in PDFs.
	// The URL on the PDF needs to link to the file at time of creation!
	const state = getState()
	if (preUpdateGuardAndValidation({ getState: getState, toUpdateAnswers: [answer] })) {
		return
	}

	if (!hasContent) {
		// No need to save empty content
		dispatch(updateAnswers({
			answers: [answer],
		}))
	} else {
		// Deactivate existing answer (don't update content here!)
		dispatch(updateAnswers({
			answers: [{ __typename: answer.__typename, id: answer.id, deactivated: new Date().toISOString() }],
		}))

		// HACK: Fake an initial answer to duplicate from
		const initialAnswer: InitialAnswerType = {
			__typename: "InitialAnswerType",
			id: answer.id,
			fieldId: answer.fieldId,
			tableFieldId: answer.tableFieldId,
			rank: answer.rank,
			contentText: null,
			contentDate: null,
			contentBoolean: false,
			contentDecimal: null,
			contentFile: null,
			contentFileName: null,
		}

		if (isAnswerFile(answer)) {
			initialAnswer.contentFile = answer.content
			initialAnswer.contentFileName = answer.name
		} else if (isAnswerDate(answer)) {
			initialAnswer.contentDate = answer.content
		} else if (isAnswerText(answer)) {
			initialAnswer.contentText = answer.content
		} else if (isAnswerBoolean(answer)) {
			initialAnswer.contentBoolean = answer.content
		} else if (isAnswerDecimal(answer)) {
			initialAnswer.contentDecimal = answer.content
		} else {
			throw Error('Unable to infer type')
		}

		const field = state.mainForm.data?.fields[answer.fieldId!]!
		const tableField = answer.tableFieldId ? field.tableFields.find(tf => tf.id == answer.tableFieldId)! : null

		dispatch(answerSlice.actions.addAnswer({
			field: field,
			tableField,
			objectIdMap: selectObjectIdMap(state),
			rank: answer.rank,
			initialAnswers: { [answer.rank]: [initialAnswer] }
		}))
	}

	debounceSave(dispatch)
})


export const updateAnswers = createAsyncThunk<
	void,
	{ answers: ToSaveAnswer[], saveNow?: boolean },
	{ state: RootState }
>('answers/update', async ({ answers, saveNow }, { dispatch, getState }) => {
	const state = getState()
	if (preUpdateGuardAndValidation({ getState: getState, toUpdateAnswers: answers })) {
		return
	}

	if (saveNow) {
		if (state.answers.saving) {
			dispatch(answerSlice.actions.setQueuedSave(true))
		} else {
			dispatch(saveAnswers())
		}
	} else {
		debounceSave(dispatch)
	}
})


export const validateFlushAndWaitForSave = createAsyncThunk<
	void,
	{ skipAnswerCheck?: boolean },
	{ state: RootState }
>('answers/validateFlushAndWaitForSave', async ({ skipAnswerCheck = false }, { dispatch, getState, rejectWithValue }) => {
	const state = getState()

	if (!skipAnswerCheck) {
		const result = await dispatch(validateAnswers({}))
		if (result.meta.requestStatus === 'rejected') {
			return rejectWithValue({ answersValid: false })
		}
	}

	// Could have dirty changes that aren't saving (due to initial answers)
	const changesNeedSaving = !state.answers.touched && Object.keys(state.answers.status.dirtyQueued).length
	if (changesNeedSaving) {
		dispatch(updateAnswers({ answers: [], saveNow: true }))
	}
	await waitForSave(getState)
})


export const reorderAnswers = createAsyncThunk<
	void,
	{ rankChanges: { [rank: number]: number }, fieldId: string },
	{ state: RootState }
>('answers/reorder', async ({ rankChanges, fieldId }, { dispatch, getState }) => {
	const state = getState()
	const answers = []
	for (const answer of Object.values(state.answers.objects)) {
		if (answer.fieldId == fieldId && answer.rank in rankChanges) {
			answers.push({
				__typename: answer.__typename,
				id: answer.id,
				rank: rankChanges[answer.rank],
			})
		}
	}
	dispatch(updateAnswers({ answers }))
})


export const toggleAnswerRowDeactivated = createAsyncThunk<
	void,
	{ rank: number, fieldId: string, deactivated: string | null },
	{ state: RootState }
>('answers/deactivate_row', async ({ rank, fieldId, deactivated }, { dispatch, getState }) => {
	const state = getState()
	const answers = selectAnswersForField(state, fieldId).filter(a => a.rank == rank && !!a.deactivated != !!deactivated)

	dispatch(updateAnswers({
		answers: answers.map(a => ({ ...a, deactivated })),
	}))
})

export const validateAnswers = createAsyncThunk<
	{ answersValid: boolean },
	{},
	{ state: RootState }
>(
	'answers/validate',
	async ({ }, { dispatch, getState, rejectWithValue }) => {
		let state = getState()

		const invalidAnswers = selectInvalidAnswers(state)
		const missingFields = selectMissingMandatoryFields(state)

		let message = []
		if (invalidAnswers.length) {
			const details = invalidAnswers.map((a, i) => <li key={i}>{selectFieldForAnswer(state, a).title}</li>)
			message.push(<div key="invalid">The following fields are invalid: <ul>{details}</ul></div>)
		}
		if (missingFields.length) {
			const details = missingFields.map((f, i) => <li key={i}>{f.title}</li>)
			message.push(<div key="missing">The following fields are required: <ul>{details}</ul></div>)
		}

		if (message.length) {
			dispatch(showModal({
				title: 'Unable to submit form',
				content: message,
				cancelText: 'Cancel'
			}))
			return rejectWithValue({ answersValid: false })
		}
		return { answersValid: true }
	}

)

export const doPublicFormSubmit = createAsyncThunk<
	{ success: boolean },
	{ submitterEmail: string },
	{ state: RootState }
>(
	'answers/submit',
	async ({ submitterEmail }, { dispatch, getState, rejectWithValue }) => {
		const result = await dispatch(validateFlushAndWaitForSave({}))
		if (result.meta.requestStatus == 'rejected') {
			return rejectWithValue({ answersValid: false })
		}

		let state = getState()
		if (state.answers.saving || Object.keys(state.answers.status.dirtyQueued).length > 0) {
			dispatch(answerSlice.actions.setQueuedSubmit({ submitterEmail }))
		}

		const { error, data } = await apolloClient.query<PublicFormSubmitMutation, PublicFormSubmitMutationVariables>({
			query: SUBMIT_PUBLIC_FORM,
			variables: {
				submitterEmail: submitterEmail || "",
				publicFormCode: state.mainForm.data!.publicForm!.code
			},
			fetchPolicy: 'no-cache',
		})

		if(data.publicForm_Submit?.success) {
			history.push(publicFormSuccessUrl())
			return { success: true }
		} else {
			alert("Error submitting public form. Please reload the page and try again. If this issue persists please contact support.")
			return { success: false}
		}
	}
)