import { matchPath } from 'react-router-dom'
import { GlobalContextProps } from "@/components/context"
import querystring from 'query-string'

type IdObj = { id: string }
export type Context = {
	currentModule: IdObj
	currentTeam: IdObj | null
}

const ROOT_DOMAINS: { [k: string]: string } = {
	'bastionsystems': 'bastionsystems.com',
	'pharoshr': 'pharoshr.com',
	'questos': 'questos.com.au',
	'socialandemotionallearning': 'socialandemotionallearning.com.au',
	'staging-pharos': 'staging-pharos.com',
	'localhost:8000': 'localhost:8000',
}

export const getDjangoDomain = () => {
	const protocol = location.protocol + "//"
	const domain = location.host
	if (ROOT_DOMAINS[domain.split('.')[0]] && domain !== 'localhost:8000') {
		// we could be on a client specific subdomain ie laser.pharoshr.com
		// in which case we shouldn't redirect to app.laser.pharoshr.com
		return protocol + 'app.' + domain
	} else {
		return protocol + domain
	}
}

export const getPublicSiteDomain = () => {
	const protocol = location.protocol + "//"
	const domain = location.host
	for (const d of domain.split('.')) {
		// check all depths of subdomains and go to root
		if (ROOT_DOMAINS[d]) {
			return protocol + ROOT_DOMAINS[d]
		}
	}
	console.error("What domain are we on???")
}

export const ALL_TEAMS = 'all'

export const landingPageUrl = () => `/`
export const apiUrl = () => `/api`

export const switchingOrgsUrl = () => `/switching-orgs`

export const dashboardUrl = () => `/dashboard`

export const accountUrl = () => `/account`
export const signupUrl = () => (
	`${accountUrl()}/signup`
)
export const loginUrl = (query?: { next?: string }) => (
	`${accountUrl()}/login${(query && query.next) ? '?' + querystring.stringify(query) : ''}`
)

export const oldPublicLandingPageUrl = () => `/public`
export const contactUrl = () => (
	`${oldPublicLandingPageUrl()}/contact`
)
export const termsUrl = () => (
	`${oldPublicLandingPageUrl()}/terms`
)

export const noTeamUrl = () => (
	`${dashboardUrl()}/no-team`
)

export const moduleUrl = (currentModule: IdObj) => (
	`${dashboardUrl()}/module/${currentModule.id}`
)

export const teamNoListUrl = ({ currentModule, currentTeam }: Context) => (
	`${moduleUrl(currentModule)}/team/${currentTeam ? currentTeam.id : ALL_TEAMS}`
)
export const teamUrl = (ctx: Context) => (
	`${teamNoListUrl(ctx)}/list`
)
export const teamSettingsUrl = (context: Context) => (
	`${teamUrl(context)}/settings`
)
export const teamFilesUrl = (context: Context) => (
	`${teamUrl(context)}/files`
)
export const teamAnalyticsBaseUrl = (context: Context) => (
	`${teamUrl(context)}/analytics`
)
export const teamMetricsUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/metrics`
)

export type FormActionMetricsUrlProps = { startYear: string, startMonth: string }
export const formActionMetricsBaseUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/form-action-metrics`
)
export const formActionMetricsUrl = (context: Context & FormActionMetricsUrlProps) => (
	`${formActionMetricsBaseUrl(context)}/${context.startYear}/${context.startMonth}`
)
export const formActionMetricsUrlDefault = (context: Context) => {
	let startMonth = 1
	let startYear = new Date().getFullYear()

	if (new Date().getMonth() <= 2) {
		startMonth = 6
		startYear -= 1
	}

	return formActionMetricsUrl({
		...context,
		startYear: startYear.toString(),
		startMonth: startMonth.toString(),
	})
}


type AnalyticsTableProps = Context & { tableId: string }
export const teamAggregatedTableBaseUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/aggregated-table`
)
export const teamAggregatedTableUrl = ({ tableId, ...context }: AnalyticsTableProps) => (
	`${teamAggregatedTableBaseUrl(context)}/${tableId}`
)
export const teamConcatenatedTableBaseUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/concatenated-table`
)
export const teamConcatenatedTableUrl = ({ tableId, ...context }: AnalyticsTableProps) => (
	`${teamConcatenatedTableBaseUrl(context)}/concatenated-table/${tableId}`
)
type DownloadConcatTableCsvParams = { tableId: string, teamId: string | null }
export const downloadConcatenatedTableCsvUrl = ({ tableId, teamId }: DownloadConcatTableCsvParams) => (
	`/api/v1/concat_table/csv_download/${tableId}/${teamId || ALL_TEAMS}`
)
export type CalendarUrlProps = { month: string, year: string }
export const calendarBaseUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/calendar`
)
export const calendarUrl = ({ month, year, ...context }: Context & CalendarUrlProps) => (
	`${calendarBaseUrl(context)}/${month}-${year}`
)
export const mapUrl = (context: Context) => (
	`${teamAnalyticsBaseUrl(context)}/map`
)
export const teamMetricsDefaultUrl = ({ organisation }: { organisation: GlobalContextProps["organisation"] }) => {
	const firstModuleSetItemWithTeam = organisation.suite.moduleSetItems.find(msi => msi.module.teams.length > 0)
	const currentModule = (firstModuleSetItemWithTeam || organisation.suite.moduleSetItems[0]).module
	const currentTeam = currentModule.teams[0]
	return teamMetricsUrl({
		currentModule,
		currentTeam
	})
}


export const recordAddUrl = (context: Context) => (
	`${teamUrl(context)}/record-add`
)
type RecordUrl = Context & { recordId: string }
export const recordUrl = ({ recordId, ...context }: RecordUrl) => (
	`${teamUrl(context)}/record/${recordId}`
)
export const recordEditUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/edit`
)
export const recordCopyUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/copy`
)
export const recordDashboardUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/dashboard`
)
export const recordMergePdfsUrl = (params: RecordUrl) => (
	`${recordUrl(params)}/merge-pdfs`
)
export const recordMergePdfsCreateUrl = (params: RecordUrl) => (
	`${recordMergePdfsUrl(params)}/create`
)
export const recordMergePdfsHistoryUrl = (params: RecordUrl) => (
	`${recordMergePdfsUrl(params)}/history`
)
export const mergedPdfDownloadApiUrl = ({ id }: { id: string }) => (
	`/api/v1/merged_pdf/download/${id}.pdf`
)

type FormUrl = RecordUrl & { formId: string, highlightFieldId?: string | null }
export const formUrl = ({ formId, highlightFieldId, ...params }: FormUrl) => (
	`${recordUrl(params)}/form/${formId}${highlightFieldId ? ('?highlightFieldId=' + highlightFieldId) : ''}`
)

type NotiProps = FormUrl & { fieldType: string, fieldId: string }
export const formFieldNotificationUrl = ({ fieldType, fieldId, ...params }: NotiProps) => (
	`${formUrl(params)}/field-notifications/${fieldType}/${fieldId}`
)

export const modifyFormFieldsUrl = (params: FormUrl) => (
	`${formUrl(params)}/modify-form-fields`
)

export const editFormActionsUrl = (params: FormUrl) => (
	`${formUrl(params)}/form-actions-edit`
)

export const resetFormActionsUrl = (params: FormUrl) => (
	`${formUrl(params)}/form-actions-reset`
)

type CompleteActionUrl = FormUrl & { actionId: string }
export const fromCompleteActionUrl = ({actionId, ...formParms}: CompleteActionUrl) => (
	`${formUrl(formParms)}/complete-action/${actionId}`
)

type FormSignOffParams = FormUrl & { pdfGeneratorId: string }
export const formSignOffUrl = (params: FormSignOffParams) => (
	`${formUrl(params)}/sign-off/${params.pdfGeneratorId}`
)

export const downloadPdfFileApiUrl = ({ id }: { id: string }) => (
	`/api/v1/pdf/download/${id}.pdf`
)
export const previewPdfFileApiUrl = (params: { pdfGeneratorId: string, recordId: string, formId: string }) => (
	`/api/v1/pdf/preview/${params.pdfGeneratorId}/${params.recordId}/${params.formId}`
)
export const previewPdfFilePublicApiUrl = (params: { pdfGeneratorId: string, code: string }) => (
	`/api/v1/pdf/public_preview/${params.pdfGeneratorId}/${params.code}`
)


export const formActionSignatureImageUrl = ({ formActionId }: { formActionId: string }) => (
	`/api/v1/form_action/signature_image/${formActionId}.png`
)

export const publicFormSuccessUrl = () => `/public-form-success`
export const publicFormUrl = (publicFormCode: string) => (
	`/public_form/${publicFormCode}`
)

type PublicFormCompleteActionProps = { publicFormCode: string, actionId: string }
export const publicFormCompleteActionUrl = ({publicFormCode, actionId}: PublicFormCompleteActionProps) => (
	`${publicFormUrl(publicFormCode)}/complete-action/${actionId}`
)

export const publicCreateRecordUrl = (params: { teamCode: string, linkCode: string }) => (
	`/public_create/${params.teamCode}/${params.linkCode}`
)
export const publicCreateRecordQrCodeUrl = (link: string) => (
	`/api/v1/qr_code.png?link=${link}`
)

type EmailModalUrl = FormUrl & {
	regardingObjectContentType: string,
	regardingObjectId: string,
}
export const emailModalUrl = ({
	regardingObjectContentType,
	regardingObjectId,
	...GetFormParams
}: EmailModalUrl) => (
	`${formUrl(GetFormParams)}/email/${regardingObjectContentType}/${regardingObjectId}`
)
export const emailModalSendUrl: typeof emailModalUrl = (...params) => (
	`${emailModalUrl(...params)}/send`
)
export const emailModalHistoryUrl: typeof emailModalUrl = (...params) => (
	`${emailModalUrl(...params)}/history`
)

type EmailPdfNameUrl = FormUrl & { pdfId: string }
export const editPdfNamelUrl = ({ pdfId, ...GetFormParams }: EmailPdfNameUrl) => (
	`${formUrl(GetFormParams)}/rename_pdf/${pdfId}`
)

export const exportDataUrl = (context: Context) => (
	`${teamUrl(context)}/export`
)

export const addModuleUrl = () => (
	`${dashboardUrl()}/add-team`
)

export const userManagementUrl = () => `${dashboardUrl()}/user-management`
export const userManagementAddUrl = () => `${userManagementUrl()}/add`
export const userManagementEditUrl = (id: string) => `${userManagementUrl()}/edit/${id}`

export const suiteAdminDashboardUrl = () => `${dashboardUrl()}/suite-admin-dashboard`

export const pharosAdminUrl = (search?: string) => `${dashboardUrl()}/pharos-admin${search ? `/${search}` : ''}`
export const adviceUrl = () => `${dashboardUrl()}/advice`
export const adviceItemUrl = (categoryId: string, subcategoryId: string) => `${adviceUrl()}/category/${categoryId}/subcategory/${subcategoryId}`
export const settingsUrl = () => `${dashboardUrl()}/settings`
export const settingsUserDetailsUrl = () => `${settingsUrl()}/user-details`
export const settingsUserPasswordUrl = () => `${settingsUrl()}/user-password`
export const settingsUserMfaUrl = () => `${settingsUrl()}/user-mfa`
export const settingsOrganisationUrl = () => `${settingsUrl()}/organisation`
// used for URL embedded in pdfs, so that we can use the web session's auth token
export const downloadAnswerFileFromPdfUrl = (fileId: string) => `${dashboardUrl()}/download-answer-file/${fileId}`


type GetFileType = { id: string | null, code: string | null }
export const downloadAnswerFileUrl = ({ id, code }: GetFileType) => {
	const query = code ? `?code=${code}` : ''
	return `/api/v1/answer_files/${id}/get_file${query}`
}
export const downloadInitialAnswerFileUrl = ({ id, code }: GetFileType) => {
	const query = code ? `?code=${code}` : ''
	return `/api/v1/initial_answers/${id}/get_file${query}`
}


export const GLOBAL_CONTEXT_PARAMS = { currentModule: { id: ':moduleId' }, currentTeam: { id: ':teamId' } }

type TeamParams = {
	teamId: string
	moduleId: string
}
export const getTeamParams = (url: string) => {
	const maybeTeamParams = matchPath<TeamParams>(url, {
		path: teamNoListUrl(GLOBAL_CONTEXT_PARAMS),
	})
	if (maybeTeamParams) {
		return {
			teamId: maybeTeamParams.params.teamId,
			moduleId: maybeTeamParams.params.moduleId
		}
	}
	return {}
}

type GetFormParams = {
	formId: string
}
export const getFormParam = (url: string) => {
	const maybeRecordParams = matchPath<GetFormParams>(url, {
		path: formUrl({ recordId: ':recordId', formId: ':formId', ...GLOBAL_CONTEXT_PARAMS }),
	})
	if (maybeRecordParams) {
		return {
			formId: maybeRecordParams.params.formId
		}
	}
	return {}
}
