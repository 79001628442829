import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserManagementListQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type UserManagementListQuery = { __typename: 'PharosQuery', user_List: { __typename: 'UserTypeListBase', objects: Array<{ __typename: 'UserType', id: string, firstName: string, lastName: string, email: string, topLevelManager: boolean }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const UserManagementListDocument = gql`
    query userManagementList {
  user_List(
    limit: 200
    isSelf: false
    offset: 0
    orderBy: [{field: firstName, modifiers: [CASE_INSENSITIVE]}, {field: lastName, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      firstName
      lastName
      email
      topLevelManager
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useUserManagementListQuery__
 *
 * To run a query within a React component, call `useUserManagementListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserManagementListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserManagementListQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserManagementListQuery(baseOptions?: Apollo.QueryHookOptions<UserManagementListQuery, UserManagementListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserManagementListQuery, UserManagementListQueryVariables>(UserManagementListDocument, options);
      }
export function useUserManagementListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserManagementListQuery, UserManagementListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserManagementListQuery, UserManagementListQueryVariables>(UserManagementListDocument, options);
        }
export function useUserManagementListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserManagementListQuery, UserManagementListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserManagementListQuery, UserManagementListQueryVariables>(UserManagementListDocument, options);
        }
export type UserManagementListQueryHookResult = ReturnType<typeof useUserManagementListQuery>;
export type UserManagementListLazyQueryHookResult = ReturnType<typeof useUserManagementListLazyQuery>;
export type UserManagementListSuspenseQueryHookResult = ReturnType<typeof useUserManagementListSuspenseQuery>;
export type UserManagementListQueryResult = Apollo.QueryResult<UserManagementListQuery, UserManagementListQueryVariables>;