import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateUserTeamMutationVariables = Types.Exact<{
  team: Types.Scalars['ID']['input'];
  user: Types.Scalars['ID']['input'];
}>;


export type CreateUserTeamMutation = { __typename: 'PharosMutations', userTeam_Update: { __typename: 'user_teamPayload', object: { __typename: 'UserTeamType', id: string, active: boolean, team: { __typename: 'TeamType', id: string }, user: { __typename: 'UserType', id: string } } | null } | null };

export type RemoveUserTeamMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type RemoveUserTeamMutation = { __typename: 'PharosMutations', userTeam_Update: { __typename: 'user_teamPayload', object: { __typename: 'UserTeamType', id: string, active: boolean } | null } | null };

export type _Fragment = { __typename: 'TeamType', memberId: string | null };

export const _FragmentDoc = gql`
    fragment _ on TeamType {
  memberId(userId: "")
}
    `;
export const CreateUserTeamDocument = gql`
    mutation createUserTeam($team: ID!, $user: ID!) {
  userTeam_Update(input: {team: $team, user: $user}) {
    object {
      id
      active
      team {
        id
      }
      user {
        id
      }
    }
  }
}
    `;
export type CreateUserTeamMutationFn = Apollo.MutationFunction<CreateUserTeamMutation, CreateUserTeamMutationVariables>;

/**
 * __useCreateUserTeamMutation__
 *
 * To run a mutation, you first call `useCreateUserTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserTeamMutation, { data, loading, error }] = useCreateUserTeamMutation({
 *   variables: {
 *      team: // value for 'team'
 *      user: // value for 'user'
 *   },
 * });
 */
export function useCreateUserTeamMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserTeamMutation, CreateUserTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserTeamMutation, CreateUserTeamMutationVariables>(CreateUserTeamDocument, options);
      }
export type CreateUserTeamMutationHookResult = ReturnType<typeof useCreateUserTeamMutation>;
export type CreateUserTeamMutationResult = Apollo.MutationResult<CreateUserTeamMutation>;
export type CreateUserTeamMutationOptions = Apollo.BaseMutationOptions<CreateUserTeamMutation, CreateUserTeamMutationVariables>;
export const RemoveUserTeamDocument = gql`
    mutation removeUserTeam($id: ID!) {
  userTeam_Update(input: {id: $id, active: false}) {
    object {
      id
      active
    }
  }
}
    `;
export type RemoveUserTeamMutationFn = Apollo.MutationFunction<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>;

/**
 * __useRemoveUserTeamMutation__
 *
 * To run a mutation, you first call `useRemoveUserTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserTeamMutation, { data, loading, error }] = useRemoveUserTeamMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveUserTeamMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>(RemoveUserTeamDocument, options);
      }
export type RemoveUserTeamMutationHookResult = ReturnType<typeof useRemoveUserTeamMutation>;
export type RemoveUserTeamMutationResult = Apollo.MutationResult<RemoveUserTeamMutation>;
export type RemoveUserTeamMutationOptions = Apollo.BaseMutationOptions<RemoveUserTeamMutation, RemoveUserTeamMutationVariables>;