import { startAuthentication } from '@simplewebauthn/browser'
import { PublicKeyCredentialRequestOptionsJSON } from '@simplewebauthn/typescript-types'
import gql from 'graphql-tag'
import { useWebauthnAuthenticateMutation } from './__gen/use-mfa-login'

gql`
	mutation WebauthnAuthenticate($credentialString: String!) {
		webauthnCredentials_Authenticate(credentialString: $credentialString) {
			success
		}
	}
`

type Params = {
	webauthnOptions: string
}

type ReturnType = {error: string, success: false} | {success: true, error: null}

export const useLoginMfa = () => {
	const [webauthnAuthenticate, {loading: webauthnLoading}] = useWebauthnAuthenticateMutation()	
	
	const startLogin = async ({webauthnOptions}: Params): Promise<ReturnType> => {
		let credentials
		try {
			// Pass the options to the authenticator and wait for a response
			const options: PublicKeyCredentialRequestOptionsJSON = JSON.parse(webauthnOptions)
			credentials = await startAuthentication(options);
		} catch (e) {
			console.error(e)
			if ((e instanceof Error)) {
				return {success: false, error: "MFA Error " + e.message}
			}
			return {success: false, error: 'Error authenticating via MFA/webauthn'}
		}
		
		const {data} = await webauthnAuthenticate({variables: {credentialString: JSON.stringify(credentials)}})
		if (!data?.webauthnCredentials_Authenticate?.success) {
			return {success: false, error: 'Error authenticating via MFA/webauthn'}
		}
		return {success: true, error: null}
	}

	return startLogin
}