import React from 'react'
import c from 'classnames'

import style from './index.module.sass'
import { ErrorBoundary } from '../error-boundary'
import { useAppSelector } from '@/utils/hooks'


export type PanelType = 'thin' | 'full-width' | 'wide'

export type SinglePanelTypeProps = {
	type?: PanelType
}

type SinglePanelProps = SinglePanelTypeProps & {
	children: React.ReactNode | React.ReactNode[]
	noPadding?: boolean
	removeScrollForMobile?: boolean
	noBoxShadow?: boolean
}

export function singlePanelWrapper<P extends JSX.IntrinsicAttributes>(Component: React.ComponentType<P>) {
	const wrappedComponent: React.FC<P> = (props) => (
		<SinglePanel>
			<Component {...props} />
		</SinglePanel> 
	)
	return wrappedComponent
}

const CLASS_LOOKUP: {[k in PanelType]: string} = {
	thin: style.panelContainerThin,
	wide: style.panelContainerWide,
	'full-width': style.panelContainerFullWidth
}

export const SinglePanel = React.forwardRef((props: SinglePanelProps, ref:React.Ref<HTMLDivElement>) => {
	const printableDashboard = useAppSelector(state => state.userInterface.printableDashboard)
	const containerClass = props.type ? CLASS_LOOKUP[props.type] : style.panelContainer
	return (
		<ErrorBoundary>
			<div
				ref={ref}
				style={printableDashboard ? {height: 'auto'}: {}}
				className={c(
					style.panelScrollContainer,
					{[style.panelBoxShadow]: !props.noBoxShadow},
					{[style.removeScrollForMobile]: props.removeScrollForMobile}
				)}
			>
				<div className={containerClass}>
					<div className={style.panel}>
						<div className={c(style.panelInner, {[style.noPadding]: props.noPadding})}>
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</ErrorBoundary>
	)
})
