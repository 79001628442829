import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FieldGroupMetricQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  isSuiteAdminDashboard?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type FieldGroupMetricQuery = { __typename: 'PharosQuery', fieldGroupMetric: { __typename: 'FieldGroupMetricType', id: string, name: string, values: Array<{ __typename: 'PieChartSegmentType', name: string, color: string, formLinks: Array<{ __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string }> }> } | null };


export const FieldGroupMetricDocument = gql`
    query fieldGroupMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
  fieldGroupMetric(id: $id) {
    id
    name
    values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
      name
      color
      formLinks {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useFieldGroupMetricQuery__
 *
 * To run a query within a React component, call `useFieldGroupMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldGroupMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldGroupMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      isSuiteAdminDashboard: // value for 'isSuiteAdminDashboard'
 *   },
 * });
 */
export function useFieldGroupMetricQuery(baseOptions: Apollo.QueryHookOptions<FieldGroupMetricQuery, FieldGroupMetricQueryVariables> & ({ variables: FieldGroupMetricQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>(FieldGroupMetricDocument, options);
      }
export function useFieldGroupMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>(FieldGroupMetricDocument, options);
        }
export function useFieldGroupMetricSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>(FieldGroupMetricDocument, options);
        }
export type FieldGroupMetricQueryHookResult = ReturnType<typeof useFieldGroupMetricQuery>;
export type FieldGroupMetricLazyQueryHookResult = ReturnType<typeof useFieldGroupMetricLazyQuery>;
export type FieldGroupMetricSuspenseQueryHookResult = ReturnType<typeof useFieldGroupMetricSuspenseQuery>;
export type FieldGroupMetricQueryResult = Apollo.QueryResult<FieldGroupMetricQuery, FieldGroupMetricQueryVariables>;