import AnswerAddress from './address'
import AnswerBoolean from './boolean'
import AnswerDate from './date'
import AnswerDropdown from './dropdown'
import AnswerDecimal from './decimal'
import AnswerFile from './file'
import AnswerImage from './image'
import AnswerInteger from './integer'
import AnswerRadio from './radio'
import AnswerEmail from './email'
import AnswerLink from './link'
import AnswerTextLong from './text-long'
import AnswerTextShort from './text-short'
import AnswerTime from './time'
import { TableFieldEnumType } from '@/state/main-form/types'
import AnswerRating from './rating'

type Lookup = {
	component: React.ComponentType<any>
	size: {flexBasis: number, flexGrow: number}
}

export const ANSWER_LOOKUP: { [K in TableFieldEnumType]: Lookup } = {
	ADDRESS: 		{ component: AnswerAddress, 	size: {flexBasis: 130,  flexGrow: 15} },
	BOOLEAN: 		{ component: AnswerBoolean, 	size: {flexBasis: 80,  flexGrow: 0.1} },
	DATE: 			{ component: AnswerDate, 		size: {flexBasis: 140, flexGrow: 0.1} },
	CURRENCY: 		{ component: AnswerDecimal, 	size: {flexBasis: 120, flexGrow: 0.1} },
	DROP_DOWN: 		{ component: AnswerDropdown, 	size: {flexBasis: 100, flexGrow: 8} },
	DROP_DOWN_OTHER:{ component: AnswerDropdown, 	size: {flexBasis: 100, flexGrow: 8} },
	EMAIL: 			{ component: AnswerEmail, 		size: {flexBasis: 100,  flexGrow: 8} },
	FILE: 			{ component: AnswerFile, 		size: {flexBasis: 120, flexGrow: 0.1} },
	IMAGE: 			{ component: AnswerImage, 		size: {flexBasis: 100, flexGrow: 10} },
	INTEGER: 		{ component: AnswerInteger, 	size: {flexBasis: 100, flexGrow: 0.1} },
	LINK: 			{ component: AnswerLink, 		size: {flexBasis: 90,  flexGrow: 4} },
	RADIO: 			{ component: AnswerRadio, 		size: {flexBasis: 100, flexGrow: 8} },
	RATING: 		{ component: AnswerRating, 		size: {flexBasis: 50, flexGrow: 8} },
	TEXT_LONG: 		{ component: AnswerTextLong, 	size: {flexBasis: 130, flexGrow: 15} },
	TEXT_SHORT: 	{ component: AnswerTextShort, 	size: {flexBasis: 100, flexGrow: 10} },
	TEXT_TINY: 		{ component: AnswerTextShort, 	size: {flexBasis: 60,  flexGrow: 0.1} },
	TIME: 			{ component: AnswerTime, 		size: {flexBasis: 100, flexGrow: 0.1} },
}