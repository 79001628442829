import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PdfsToMergeQueryVariables = Types.Exact<{
  recordId: Types.Scalars['ID']['input'];
}>;


export type PdfsToMergeQuery = { __typename: 'PharosQuery', pdf_List: { __typename: 'PdfTypeListBase', objects: Array<{ __typename: 'PdfType', id: string, name: string, created: string, form: { __typename: 'FormType', id: string, category: { __typename: 'CategoryType', id: string, title: string | null } | null } }> } | null };


export const PdfsToMergeDocument = gql`
    query pdfsToMerge($recordId: ID!) {
  pdf_List(
    recordId: $recordId
    orderBy: [{field: form_Category_Rank}, {field: form_Rank}]
  ) {
    objects {
      id
      name
      created
      form {
        id
        category {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __usePdfsToMergeQuery__
 *
 * To run a query within a React component, call `usePdfsToMergeQuery` and pass it any options that fit your needs.
 * When your component renders, `usePdfsToMergeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePdfsToMergeQuery({
 *   variables: {
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function usePdfsToMergeQuery(baseOptions: Apollo.QueryHookOptions<PdfsToMergeQuery, PdfsToMergeQueryVariables> & ({ variables: PdfsToMergeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PdfsToMergeQuery, PdfsToMergeQueryVariables>(PdfsToMergeDocument, options);
      }
export function usePdfsToMergeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PdfsToMergeQuery, PdfsToMergeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PdfsToMergeQuery, PdfsToMergeQueryVariables>(PdfsToMergeDocument, options);
        }
export function usePdfsToMergeSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PdfsToMergeQuery, PdfsToMergeQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PdfsToMergeQuery, PdfsToMergeQueryVariables>(PdfsToMergeDocument, options);
        }
export type PdfsToMergeQueryHookResult = ReturnType<typeof usePdfsToMergeQuery>;
export type PdfsToMergeLazyQueryHookResult = ReturnType<typeof usePdfsToMergeLazyQuery>;
export type PdfsToMergeSuspenseQueryHookResult = ReturnType<typeof usePdfsToMergeSuspenseQuery>;
export type PdfsToMergeQueryResult = Apollo.QueryResult<PdfsToMergeQuery, PdfsToMergeQueryVariables>;