import React from 'react'
import { Link } from 'react-router-dom'
import {
	FormOutlined,
	PlusOutlined,
	SaveOutlined,
	FilePdfOutlined,
	DeleteOutlined,
	ShareAltOutlined,
	UndoOutlined,
} from '@ant-design/icons'
import { formUrl, recordEditUrl } from '@/utils/url'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import { ActivityEventTypeType } from '@/__gen/types'

import style from './event.module.sass'
import { useGlobalContext } from '@/components/context'
import { Button } from 'antd';
import { EventListQuery } from './__gen'

type Props = { event: NonNullable<EventListQuery["activityEvent_List"]>["objects"][number] }

type ItemConfig = {
	Component: (props: { recordName: React.ReactNode, formTitle: React.ReactNode, cap: boolean }) => React.ReactElement,
	icon: React.ReactNode,
	color: string,
}

const ComponentLookup: { [k in ActivityEventTypeType]: ItemConfig } = {
	// FORM_SAVE
	'S': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'S' : 's'}aved {formTitle || 'Unknown Form'} for {recordName}</>
		),
		icon: <FormOutlined />,
		color: '#51b9e3',
	},
	// ADD_EMPLOYEE
	'A': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'A' : 'a'}dded {recordName}</>
		),
		icon: <PlusOutlined />,
		color: '#a989c5',
	},
	// EMPLOYEE_UPDATE
	'U': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'U' : 'u'}pdated details for {recordName}</>
		),
		icon: <SaveOutlined />,
		color: '#7172ad',
	},
	// CREATE_PDF
	'P': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'C' : 'c'}reated pdf {formTitle || 'Unknown form'} for {recordName}</>
		),
		icon: <FilePdfOutlined />,
		color: '#89be4d',
	},
	// RECORD_ARCHIVE
	'D': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'A' : 'a'}rchived {recordName}</>
		),
		icon: <DeleteOutlined />,
		color: '#ee6c6b',
	},
	// RECORD_UNARCHIVE
	'R': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'U' : 'u'}n-archived {recordName}</>
		),
		icon: <DeleteOutlined />,
		color: '#f2a76f',
	},
	// PUBLIC_FORM_SUBMIT
	'F': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'E' : 'e'}xternal link submitted for {recordName}</>
		),
		icon: <ShareAltOutlined />,
		color: '#f9d35c',
	},
	// FORM_RESET
	'FR': {
		Component: ({ recordName, formTitle, cap }) => (
			<>{cap ? 'R' : 'r'}eset form {formTitle || 'Unknown form'} for {recordName}</>
		),
		icon: <UndoOutlined />,
		color: '#98d9d9',
	},

}


export const Event = ({ event }: Props) => {
	let link = null
	const globalContext = useGlobalContext()
	if (event.record && event.form) {
		link = formUrl({ recordId: event.record.id, formId: event.form.id, ...globalContext })
	}
	else if (event.record) {
		link = recordEditUrl({ recordId: event.record.id, ...globalContext })
	} else {
		throw Error("Event has no record")
	}
	const typeConfig = ComponentLookup[event.type];
	return (
		<Link className={style.container} to={link}>
			<Button
					size="middle"
					type="primary"
					shape="circle"
					icon={typeConfig.icon}
					style={{
						background: typeConfig.color,
						borderColor: typeConfig.color,
						marginRight: 10,
						opacity: 0.8
					}}
			/>
			<div className={style.textContainer}>
				<div className={style.leftContent}>
					<b>{event.user?.firstName} </b>
					<typeConfig.Component
						recordName={<b>{event.record.name || 'No Name'}</b>} // Can happen if user doesn't supply name
						formTitle={<b>{event.form?.title || 'Unknown Form'}</b>} // Should never happen
						cap={!event.user}
					/>
				</div>
				<div className={style.rightContent}>{formatDistanceToNowStrict(new Date(event.created))} ago</div>
			</div>
		</Link>
	)
}
