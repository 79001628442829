import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ConcatenatedTablesQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
}>;


export type ConcatenatedTablesQuery = { __typename: 'PharosQuery', concatenatedTable_List: { __typename: 'ConcatenatedTableTypeListBase', objects: Array<{ __typename: 'ConcatenatedTableType', id: string, name: string }> } | null };


export const ConcatenatedTablesDocument = gql`
    query concatenatedTables($moduleId: ID!) {
  concatenatedTable_List(module: $moduleId) {
    objects {
      id
      name
    }
  }
}
    `;

/**
 * __useConcatenatedTablesQuery__
 *
 * To run a query within a React component, call `useConcatenatedTablesQuery` and pass it any options that fit your needs.
 * When your component renders, `useConcatenatedTablesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConcatenatedTablesQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *   },
 * });
 */
export function useConcatenatedTablesQuery(baseOptions: Apollo.QueryHookOptions<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables> & ({ variables: ConcatenatedTablesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>(ConcatenatedTablesDocument, options);
      }
export function useConcatenatedTablesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>(ConcatenatedTablesDocument, options);
        }
export function useConcatenatedTablesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>(ConcatenatedTablesDocument, options);
        }
export type ConcatenatedTablesQueryHookResult = ReturnType<typeof useConcatenatedTablesQuery>;
export type ConcatenatedTablesLazyQueryHookResult = ReturnType<typeof useConcatenatedTablesLazyQuery>;
export type ConcatenatedTablesSuspenseQueryHookResult = ReturnType<typeof useConcatenatedTablesSuspenseQuery>;
export type ConcatenatedTablesQueryResult = Apollo.QueryResult<ConcatenatedTablesQuery, ConcatenatedTablesQueryVariables>;