import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AnswerDatesForCalendarQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  filterFields?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['ID']['input']>>>;
  contentAfter?: Types.InputMaybe<Types.Scalars['PharosDatetime']['input']>;
  contentBefore?: Types.InputMaybe<Types.Scalars['PharosDatetime']['input']>;
}>;


export type AnswerDatesForCalendarQuery = { __typename: 'PharosQuery', answerDate_List: { __typename: 'AnswerDateTypeListBase', objects: Array<{ __typename: 'AnswerDateType', id: string, content: any | null, contentObjectName: string | null, field: { __typename: 'FieldType', id: string, title: string }, tableField: { __typename: 'TableFieldType', id: string, title: string } | null, link: { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string } | null }> } | null };


export const AnswerDatesForCalendarDocument = gql`
    query answerDatesForCalendar($moduleId: ID!, $teamId: ID, $filterFields: [ID], $contentAfter: PharosDatetime, $contentBefore: PharosDatetime) {
  answerDate_List(
    module: $moduleId
    team: $teamId
    deactivated_Isnull: true
    objectActive: true
    filterFields: $filterFields
    content_Gte: $contentAfter
    content_Lte: $contentBefore
    orderBy: [{field: content}, {field: field_Title, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      content
      contentObjectName
      field {
        id
        title
      }
      tableField {
        id
        title
      }
      link {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useAnswerDatesForCalendarQuery__
 *
 * To run a query within a React component, call `useAnswerDatesForCalendarQuery` and pass it any options that fit your needs.
 * When your component renders, `useAnswerDatesForCalendarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAnswerDatesForCalendarQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      teamId: // value for 'teamId'
 *      filterFields: // value for 'filterFields'
 *      contentAfter: // value for 'contentAfter'
 *      contentBefore: // value for 'contentBefore'
 *   },
 * });
 */
export function useAnswerDatesForCalendarQuery(baseOptions: Apollo.QueryHookOptions<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables> & ({ variables: AnswerDatesForCalendarQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>(AnswerDatesForCalendarDocument, options);
      }
export function useAnswerDatesForCalendarLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>(AnswerDatesForCalendarDocument, options);
        }
export function useAnswerDatesForCalendarSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>(AnswerDatesForCalendarDocument, options);
        }
export type AnswerDatesForCalendarQueryHookResult = ReturnType<typeof useAnswerDatesForCalendarQuery>;
export type AnswerDatesForCalendarLazyQueryHookResult = ReturnType<typeof useAnswerDatesForCalendarLazyQuery>;
export type AnswerDatesForCalendarSuspenseQueryHookResult = ReturnType<typeof useAnswerDatesForCalendarSuspenseQuery>;
export type AnswerDatesForCalendarQueryResult = Apollo.QueryResult<AnswerDatesForCalendarQuery, AnswerDatesForCalendarQueryVariables>;