import React from 'react'

import Placeholder from '@/components/common/placeholder'


export const PLACEHOLDER_CARDS = 12
export const PLACHOLDER_CARD_HEIGHT = 100


const LoadingRecords = () => (
	<>
		{
			[...Array(PLACEHOLDER_CARDS).keys()].map(i => (
				<div key={i}>
					<Placeholder style={{margin: '10px 0'}} />
				</div>
			))
		}
	</>
)

export default LoadingRecords