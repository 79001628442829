import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { PdfFragmentDoc } from './pdf';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreatePdfMutationVariables = Types.Exact<{
  pdfGenerator: Types.Scalars['ID']['input'];
  form: Types.Scalars['ID']['input'];
  record: Types.Scalars['ID']['input'];
  name: Types.Scalars['String']['input'];
  createdBy: Types.Scalars['ID']['input'];
}>;


export type CreatePdfMutation = { __typename: 'PharosMutations', pdf_Update: { __typename: 'pdfPayload', object: { __typename: 'PdfType', id: string, name: string, created: string, origin: Types.PdfPdfOriginChoices, isActive: boolean } | null } | null };


export const CreatePdfDocument = gql`
    mutation createPdf($pdfGenerator: ID!, $form: ID!, $record: ID!, $name: String!, $createdBy: ID!) {
  pdf_Update(
    input: {pdfGenerator: $pdfGenerator, record: $record, form: $form, name: $name, createdBy: $createdBy}
  ) {
    object {
      ...Pdf
    }
  }
}
    ${PdfFragmentDoc}`;
export type CreatePdfMutationFn = Apollo.MutationFunction<CreatePdfMutation, CreatePdfMutationVariables>;

/**
 * __useCreatePdfMutation__
 *
 * To run a mutation, you first call `useCreatePdfMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePdfMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPdfMutation, { data, loading, error }] = useCreatePdfMutation({
 *   variables: {
 *      pdfGenerator: // value for 'pdfGenerator'
 *      form: // value for 'form'
 *      record: // value for 'record'
 *      name: // value for 'name'
 *      createdBy: // value for 'createdBy'
 *   },
 * });
 */
export function useCreatePdfMutation(baseOptions?: Apollo.MutationHookOptions<CreatePdfMutation, CreatePdfMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePdfMutation, CreatePdfMutationVariables>(CreatePdfDocument, options);
      }
export type CreatePdfMutationHookResult = ReturnType<typeof useCreatePdfMutation>;
export type CreatePdfMutationResult = Apollo.MutationResult<CreatePdfMutation>;
export type CreatePdfMutationOptions = Apollo.BaseMutationOptions<CreatePdfMutation, CreatePdfMutationVariables>;