import React, { useState } from 'react'
import { Modal } from 'antd'

import history from '@/utils/history'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { formUrl } from '@/utils/url'
import { useSelector } from 'react-redux'
import { ReduxState } from '@/types/redux'
import { FieldRow } from './field-row'

export const ModifyFormModal = () => {
	const { user } = useGlobalContext()
	const formContext = useFormContext()
	const globalContext = useGlobalContext()
	const fields = formContext.fields
	
	if (!user.topLevelManager) {
		return null
	}

	return (
		<Modal
			title="Modify form fields"
			open
			maskClosable
			onCancel={() => history.push(formUrl({recordId: formContext.record.id, formId: formContext.form.id, ...globalContext}))}
			footer={null}
			width={600}
		>
			<p>
				Select the fields for this form. These changes will apply for <b>all</b> {globalContext.currentModule.recordWord.toLocaleLowerCase()}s
				in this {globalContext.currentModule.teamWord}.
			</p>
			<br/>
			{Object.keys(fields).map(id => fields[id]).sort(f => f.rank).map(field => (
				<div key={field.id}>
					<FieldRow fieldId={field.id} />
				</div>
			))}
		</Modal>
	)
}
