import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { adviceItemUrl } from '@/utils/url'
import { AdviceSectionQuery } from '../__gen'
import style from './category.module.sass'

type Category = NonNullable<AdviceSectionQuery["adviceSection_List"]>["objects"][number]["categories"][number]


type Props = {
	category: Category
	currentCategoryId: string | null
	currentSubcategoryId: string | null
}

export default function AdviceSection(props: Props) {
	const selected = props.category.id == props.currentCategoryId
	const [open, setOpen] = useState(selected)
	const categoryClass = classNames(style.category, {[style.categorySelected]: selected})

	return (
		<div>
			<h5
				className={categoryClass}
				onClick={() => setOpen(!open)}
			>
				{props.category.title}
			</h5>
			{ open && props.category.subcategories.map((s) => {
				const subcategoryClass = classNames(
					style.subcategory,
					{[style.subcategorySelected]: props.currentSubcategoryId == s.id}
				)
				return (
					<Link
						key={s.id}
						className={subcategoryClass}
						to={adviceItemUrl(props.category.id, s.id)}
					>
						{s.title}
					</Link>
				)
			})}
		</div>
	)
}