
import React from 'react'
import c from 'classnames'
import iconStyle from '@sass/common/icon.module.sass'
import style from './index.module.sass'

type Props = {
	selected: boolean
	semiSelected?: boolean
	onClick?: (...any: any) => any
}

const TickButton : React.FC<Props> =  ({ selected, semiSelected, onClick }) => (
	<div onClick={onClick}>
		<div 
			className={c(
				style.button,
				iconStyle.tick,
				{
					[style.buttonMatched]: selected,
					[style.buttonMatchedParent]: semiSelected,
					[style.buttonMatchedMatchedParent]: semiSelected && selected,
				},
			)}
		/>
	</div>
)

export default TickButton
