import React, { useState } from 'react'
import c from 'classnames'

import { useGlobalContext } from '@/components/context'
import buttonStyle from '@sass/common/button.module.sass'
import { PanelTypes } from '../const'
import { RecordListQuery } from './__gen'
import RecordListItem from './record-list-item'
import style from './record-list.module.sass'
import LoadingRecords, { PLACEHOLDER_CARDS, PLACHOLDER_CARD_HEIGHT } from './loading-records'


type Props = {
	data: NonNullable<RecordListQuery["record_List"]>
	setShowPanel: (panel: PanelTypes) => void
	loading: boolean
	fetchMore: () => void
	recordId: string | null
}

const RecordList = ({data, loading, fetchMore, setShowPanel, recordId}: Props) => {
	const globalContext = useGlobalContext()
	const [showArchived, setShowArchived] = useState(false)
	const records = data.objects

	const active   = records.filter((e) => !e.archived)
	const archived = records.filter((e) =>  e.archived)

	const emptyList = (
		!data.pageInfo.hasNextPage &&
		!data.objects.length
	)

	const loadingHiddenArchived = archived.length && !showArchived
		
	const isContainerFull = (scrollEvent: React.UIEvent<HTMLDivElement>) => {
		const container = scrollEvent.currentTarget
		let full
		if(container.clientHeight - container.scrollHeight >= 0) {
			full = false
		}
		else {
			//have we scrolled near the bottom?
			const bottomOfView = container.clientHeight + container.scrollTop
			const distanceFromBottom = container.scrollHeight - bottomOfView
			full = distanceFromBottom > PLACHOLDER_CARD_HEIGHT * PLACEHOLDER_CARDS + 200
		}
		if (!full && !loadingHiddenArchived) {
			fetchMore()
		}
	}

	
	return (
		<div className={style.recordListScrollContainerOuter} onScroll={isContainerFull}>
			<div className={style.recordListScrollContainerInner}>
				<div className={style.recordList}>
					{ emptyList && !loading &&
						<div className={style.noRecordsText}>
							There are no {globalContext.currentModule.recordWord.toLowerCase()}s to show
						</div>
					}
					{ active.map((e) => (
						<RecordListItem 
							key={e.id} 
							record={e}
							setShowPanel={setShowPanel}
							recordParamId={recordId}
						/>
					))}
				</div>

				{ archived.length > 0 && 
					<>
						{ showArchived
							? <>
								<h5>Archived</h5>
								<div className={style.recordList}>
									{archived.map((e) => (
										<RecordListItem 
											key={e.id} 
											record={e}
											setShowPanel={setShowPanel}
											recordParamId={recordId}
										/>
									))}
								</div>
							</>
							: (
								<button
									className={c(style.viewArchived, buttonStyle.noOutline, buttonStyle.grey)}
									onClick={() => {setShowArchived(true); fetchMore()}}
								>
									View archived
								</button>
							)
						}
					</>
				}

				{ loading &&  <LoadingRecords /> }
			</div>
		</div>
	)
}

export default RecordList

