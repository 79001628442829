import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserToManageQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
}>;


export type UserToManageQuery = { __typename: 'PharosQuery', user: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string, topLevelManager: boolean, webauthnCredentials: Array<{ __typename: 'WebauthnCredentialsType', id: string }>, teams: Array<{ __typename: 'TeamType', id: string, name: string }> } | null };


export const UserToManageDocument = gql`
    query userToManage($id: ID!) {
  user(id: $id) {
    id
    firstName
    lastName
    email
    topLevelManager
    webauthnCredentials {
      id
    }
    teams {
      id
      name
    }
  }
}
    `;

/**
 * __useUserToManageQuery__
 *
 * To run a query within a React component, call `useUserToManageQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserToManageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserToManageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserToManageQuery(baseOptions: Apollo.QueryHookOptions<UserToManageQuery, UserToManageQueryVariables> & ({ variables: UserToManageQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserToManageQuery, UserToManageQueryVariables>(UserToManageDocument, options);
      }
export function useUserToManageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserToManageQuery, UserToManageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserToManageQuery, UserToManageQueryVariables>(UserToManageDocument, options);
        }
export function useUserToManageSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserToManageQuery, UserToManageQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<UserToManageQuery, UserToManageQueryVariables>(UserToManageDocument, options);
        }
export type UserToManageQueryHookResult = ReturnType<typeof useUserToManageQuery>;
export type UserToManageLazyQueryHookResult = ReturnType<typeof useUserToManageLazyQuery>;
export type UserToManageSuspenseQueryHookResult = ReturnType<typeof useUserToManageSuspenseQuery>;
export type UserToManageQueryResult = Apollo.QueryResult<UserToManageQuery, UserToManageQueryVariables>;