import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebauthnBeginRegistrationMutationVariables = Types.Exact<{ [key: string]: never; }>;


export type WebauthnBeginRegistrationMutation = { __typename: 'PharosMutations', webauthnCredentials_BeginRegistration: { __typename: 'WebauthnBeginRegistration', optionsString: string | null } | null };

export type WebauthnCompleteRegistrationMutationVariables = Types.Exact<{
  registrationResponse: Types.Scalars['String']['input'];
  displayName: Types.Scalars['String']['input'];
}>;


export type WebauthnCompleteRegistrationMutation = { __typename: 'PharosMutations', webauthnCredentials_CompleteRegistration: { __typename: 'WebauthnCompleteRegistration', errorText: string | null } | null };


export const WebauthnBeginRegistrationDocument = gql`
    mutation webauthnBeginRegistration {
  webauthnCredentials_BeginRegistration {
    optionsString
  }
}
    `;
export type WebauthnBeginRegistrationMutationFn = Apollo.MutationFunction<WebauthnBeginRegistrationMutation, WebauthnBeginRegistrationMutationVariables>;

/**
 * __useWebauthnBeginRegistrationMutation__
 *
 * To run a mutation, you first call `useWebauthnBeginRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebauthnBeginRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webauthnBeginRegistrationMutation, { data, loading, error }] = useWebauthnBeginRegistrationMutation({
 *   variables: {
 *   },
 * });
 */
export function useWebauthnBeginRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<WebauthnBeginRegistrationMutation, WebauthnBeginRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebauthnBeginRegistrationMutation, WebauthnBeginRegistrationMutationVariables>(WebauthnBeginRegistrationDocument, options);
      }
export type WebauthnBeginRegistrationMutationHookResult = ReturnType<typeof useWebauthnBeginRegistrationMutation>;
export type WebauthnBeginRegistrationMutationResult = Apollo.MutationResult<WebauthnBeginRegistrationMutation>;
export type WebauthnBeginRegistrationMutationOptions = Apollo.BaseMutationOptions<WebauthnBeginRegistrationMutation, WebauthnBeginRegistrationMutationVariables>;
export const WebauthnCompleteRegistrationDocument = gql`
    mutation webauthnCompleteRegistration($registrationResponse: String!, $displayName: String!) {
  webauthnCredentials_CompleteRegistration(
    registrationResponse: $registrationResponse
    displayName: $displayName
  ) {
    errorText
  }
}
    `;
export type WebauthnCompleteRegistrationMutationFn = Apollo.MutationFunction<WebauthnCompleteRegistrationMutation, WebauthnCompleteRegistrationMutationVariables>;

/**
 * __useWebauthnCompleteRegistrationMutation__
 *
 * To run a mutation, you first call `useWebauthnCompleteRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebauthnCompleteRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webauthnCompleteRegistrationMutation, { data, loading, error }] = useWebauthnCompleteRegistrationMutation({
 *   variables: {
 *      registrationResponse: // value for 'registrationResponse'
 *      displayName: // value for 'displayName'
 *   },
 * });
 */
export function useWebauthnCompleteRegistrationMutation(baseOptions?: Apollo.MutationHookOptions<WebauthnCompleteRegistrationMutation, WebauthnCompleteRegistrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebauthnCompleteRegistrationMutation, WebauthnCompleteRegistrationMutationVariables>(WebauthnCompleteRegistrationDocument, options);
      }
export type WebauthnCompleteRegistrationMutationHookResult = ReturnType<typeof useWebauthnCompleteRegistrationMutation>;
export type WebauthnCompleteRegistrationMutationResult = Apollo.MutationResult<WebauthnCompleteRegistrationMutation>;
export type WebauthnCompleteRegistrationMutationOptions = Apollo.BaseMutationOptions<WebauthnCompleteRegistrationMutation, WebauthnCompleteRegistrationMutationVariables>;