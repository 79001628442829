import React, { useState } from 'react'
import c from "classnames"

import { AnswerUploadingState, selectAnswerUploadingState, selectIsFromInitialAnswerId } from '@/state/answer/selectors'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { checkImage } from '@/utils/functions'
import { LoadingBar } from '@/components/common/loading'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { AnswerFileBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'
import { downloadAnswerFileUrl, downloadInitialAnswerFileUrl } from '@/utils/url'
import { updateAnswers, updateAnswersImmutably } from '@/state/answer/thunks'


const AnswerImage = ({answer, publicFormCode, disabled}: AnswerFileBaseProps) => {
	const dispatch = useAppDispatch()
	const [error, setError] = useState<string | null>(null)
	const uploadState = useAppSelector(s => selectAnswerUploadingState(s, answer.id))
	const initialAnswerId = useAppSelector(s => selectIsFromInitialAnswerId(s, answer.id))
	
	let value = null
	if(answer.content) {
		value = answer.content.size ? answer.content
			: initialAnswerId ? downloadInitialAnswerFileUrl({id: initialAnswerId, code: publicFormCode})
			: downloadAnswerFileUrl({id: answer.id, code: publicFormCode})
	}
	const onChange = (value: File | null) => {
		const error = value ? checkImage(value) : null
		if (error) {
			setError(error)
			return
		}
	
		dispatch(updateAnswersImmutably({
			hasContent: !!answer.content,
			answer: {
				...answer,
				content: value,
				name: value ? value.name : null,
			}
		}))
	} 

	// If this is not unique it will upload to the FIRST file input that matches!
	const fileInputId = `file-upload-${answer.id}`

	return (
		<div className={style.container}>
			{
				disabled ?
					answer.content ? (
						<div className={style.imageContainer}>
							<div
								className={style.image}
								style={{
									backgroundImage: `url(${answer.content})`,
								}}
							/>
						</div>
					) : <i className={commonStyle.noContent}>No content</i>
				: answer.content ? (
					<div className={style.imageContainer}>
						<button
							className={c(style.cancel, iconStyle.reset, buttonStyle.greySolid)}
							onClick={() => onChange(null)}
						/>
						<div
							className={style.image}
							style={{
								backgroundImage: `url(${typeof answer.content === 'string' ? answer.content : URL.createObjectURL(answer.content)})`,
							}}
						/>
						{uploadState != AnswerUploadingState.NONE && <LoadingBar />}
					</div>
				) : (
					<>
						<button className={c(buttonStyle.greySolid, style.uploadButton)}>
							<label htmlFor={fileInputId}>
								Select Image
							</label>
							<input
								id={fileInputId}
								type="file"
								className={style.input}
								onChange={e => {
									const file = e.target.files && e.target.files.length > 0 && e.target.files[0]
									if (file) {
										onChange(file)
									}
								}}
							/>
						</button>
						<div className={generalStyle.error}>{error}</div>
					</>
				)
			}
		</div>
	)
}

export default AnswerImage