import { Spinner } from "@/components/common/loading"
import { useRouter } from "@/components/context"
import { USER_ESSENTIALS_FRAGMENT } from "@/fragments"
import gql from "graphql-tag"
import React from "react"
import { apolloClient } from "@/state/apollo-client"
import { landingPageUrl, switchingOrgsUrl, teamMetricsDefaultUrl } from "@/utils/url"

import { SwitchOrgMutation, useSwitchOrgMutation } from "./__gen/switch"

gql`
	mutation switchOrg($orgId: ID!, $teamId: ID) {
		user_SwitchOrgAdmin(organisationId: $orgId, teamId: $teamId) {
			user {
				...UserEssentials
			}
		}
	}
	${USER_ESSENTIALS_FRAGMENT}
`

type Props = {
	orgId: string
	teamId?: string
	children: React.ReactNode
}

export const Switch = ({ orgId, teamId, children }: Props) => {
	const router = useRouter()

	const onCompleted = (data: SwitchOrgMutation) => {
		const organisation = data?.user_SwitchOrgAdmin?.user?.organisation
		if (!organisation) {
			apolloClient.resetStore()
			router.history.push(landingPageUrl())
			alert("Error switching orgs")
			return
		}
		apolloClient.resetStore()
		router.history.push(teamMetricsDefaultUrl({ organisation }))
	}

	const [switchOrg, { loading, error }] = useSwitchOrgMutation({
		variables: teamId ? { orgId, teamId } : { orgId },
		onCompleted,
	})

	const doSwitch = () => {
		router.history.push(switchingOrgsUrl())
		switchOrg()
	}

	if (loading) {
		return <Spinner />
	}
	return <div onClick={doSwitch}>{children}</div>
}
