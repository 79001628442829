import React from 'react'
import { browserSupportsWebAuthn } from '@simplewebauthn/browser'
import { formatDistanceStrict } from 'date-fns'
import gql from 'graphql-tag'

import { Spinner } from '@/components/common/loading'
import { useGlobalContext } from '@/components/context'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import formStyle from '@sass/common/form.module.sass'
import { CreateMfaButton } from './create-mfa-button'
import { RemoveMfaButton } from './remove-mfa-button'
import style from './index.module.sass'
import { useWebauthnCredentialsListQuery } from './__gen'


gql`
	query webauthnCredentialsList($userId: ID!) {
		webauthnCredentials_List(user: $userId, orderBy: {field: lastUsed, direction: DESC}) {
			objects {
				id
				lastUsed
				created
				displayName
			}
		}
	}
`

const formatAgo = (date: string) => {
	return formatDistanceStrict(
		new Date(date),
		new Date(),
		{ addSuffix: true }
	)
}


export const Mfa = () => {
	const { user } = useGlobalContext()
	const { data, loading, error, refetch } = useWebauthnCredentialsListQuery({variables: {userId: user.id}})
	const credentials = data?.webauthnCredentials_List?.objects
	if (loading) {
		return <Spinner />
	} else if (!credentials || error) {
		return <div className={generalStyle.error}>Error fetching MFA devices</div>
	}

	return (
		<div>
			<h3 className={formStyle.commonFormTitle}>Multi Factor Authentication</h3>
			<p>
				Keep your account secure by enabling multi factor authentication.
				Use a physical device like your laptop, phone or physical hardware key to add an additional layer of protection.
				With features like FaceID, TouchId and Windows Hello it's quick and easy.
			</p>
			<p>If you add multiple factors (eg both your laptop and phone), only one factor will be required for login.</p>
			<p>
				You have MFA
				<b className={credentials.length ? style.enabledText : style.disabledText}>
					{credentials.length
						? <> Enabled <span className={iconStyle.tick} /></>
						: ' Disabled'
					}
				</b>
			</p>

			{credentials.length > 0 && <p>If you want to disable MFA, remove all of your MFA devices.</p>}

			{!browserSupportsWebAuthn() &&
				<p className={generalStyle.error}>
					This browser does not support MFA via WebAuthn.
					If you are using a modern browser and you believe this is incorrect, please contact support.
				</p>
			}
			<br/>
			<h6>Your active credentials</h6>
			{credentials.map(cred => (
				<div key={cred.id} className={style.credential}>
					<div className={style.topRow}>
						<div>Name: <b>{cred.displayName}</b></div>
						<RemoveMfaButton credentialId={cred.id} onComplete={refetch} />
					</div>
					<div className={style.date}>
						{cred.lastUsed
							? `Last used ${formatAgo(cred.lastUsed)}`
							: 'This credential has not been used'
						}
					</div>
					<div className={style.date}>Created {formatAgo(cred.created)}</div>
				</div>
			))}

			<CreateMfaButton onComplete={refetch} />
		</div>
	)
}