import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
export type MetricFormLinkEssentialsFragment = { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string };

export type RecordEssentialsFragment = { __typename: 'RecordType', id: string, archived: boolean, image: any | null, name: string, position: string, team: { __typename: 'TeamType', id: string } };

export type UserEssentialsFragment = { __typename: 'UserType', id: string, adminOfSuite: boolean, email: string, firstName: string, howYouHeardAboutUs: string | null, isActive: boolean, isAdmin: boolean, lastName: string, recordCount: number, seenWelcomeMessage: boolean, topLevelManager: boolean, usingTemporaryPassword: boolean, organisation: { __typename: 'OrganisationType', id: string, csvDownloadEnabled: boolean, logo: any | null, name: string, showTeamFiles: boolean, subdomain: string | null, suite: { __typename: 'SuiteType', id: string, logo: any | null, suiteAdminDashboardTitle: string | null, welcomeMessageHtml: string | null, moduleWord: string, moduleSetItems: Array<{ __typename: 'SuiteModuleType', id: string, rank: number, hiddenWhenEmpty: boolean, module: { __typename: 'ModuleType', id: string, addTeamPhrase: string | null, name: string, icon: string | null, singleton: boolean, descriptionHtml: string, showActiveLinksOnDashboard: boolean, showActionDashboard: boolean, allowRecordTemplateChange: boolean, allowPositionTextChange: boolean, recordFilesEnabled: boolean, recordImages: boolean, recordWord: string, showCalendar: boolean, showMap: boolean, positionWord: string, teamWord: string, teams: Array<{ __typename: 'TeamType', id: string, publicRecordCreateCode: string, logo: any | null, reminderFrequencyDays: number, name: string }>, publicRecordCreateLinks: Array<{ __typename: 'PublicRecordCreateLinkType', id: string, title: string, code: string }> } }>, adviceSection: { __typename: 'AdviceSectionType', id: string, title: string } | null } } };

export const MetricFormLinkEssentialsFragmentDoc = gql`
    fragment MetricFormLinkEssentials on MetricFormLink {
  formId
  formTitle
  recordId
  recordName
  fieldId
  fieldTitle
  organisationName
  teamName
}
    `;
export const RecordEssentialsFragmentDoc = gql`
    fragment RecordEssentials on RecordType {
  id
  archived
  image
  name
  position
  team {
    id
  }
}
    `;
export const UserEssentialsFragmentDoc = gql`
    fragment UserEssentials on UserType {
  id
  adminOfSuite
  email
  firstName
  howYouHeardAboutUs
  isActive
  isAdmin
  lastName
  recordCount
  seenWelcomeMessage
  topLevelManager
  usingTemporaryPassword
  organisation {
    id
    csvDownloadEnabled
    logo
    name
    showTeamFiles
    subdomain
    suite {
      id
      logo
      suiteAdminDashboardTitle
      welcomeMessageHtml
      moduleSetItems(orderBy: [{field: rank}]) {
        id
        rank
        hiddenWhenEmpty
        module {
          id
          addTeamPhrase
          name
          icon
          singleton
          descriptionHtml
          showActiveLinksOnDashboard
          showActionDashboard
          allowRecordTemplateChange
          allowPositionTextChange
          recordFilesEnabled
          recordImages
          recordWord
          showCalendar
          showMap
          positionWord
          teamWord
          teams(orderBy: [{field: name}]) {
            id
            publicRecordCreateCode
            logo
            reminderFrequencyDays
            name
          }
          publicRecordCreateLinks {
            id
            title
            code
          }
        }
      }
      moduleWord
      adviceSection {
        id
        title
      }
    }
  }
}
    `;