import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MoveDateOnCopyFieldsQueryVariables = Types.Exact<{
  templateId: Types.Scalars['ID']['input'];
}>;


export type MoveDateOnCopyFieldsQuery = { __typename: 'PharosQuery', field_List: { __typename: 'FieldTypeListBase', objects: Array<{ __typename: 'FieldType', id: string, title: string }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null, tableField_List: { __typename: 'TableFieldTypeListBase', objects: Array<{ __typename: 'TableFieldType', id: string, title: string, field: { __typename: 'FieldType', id: string, title: string } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const MoveDateOnCopyFieldsDocument = gql`
    query moveDateOnCopyFields($templateId: ID!) {
  field_List(
    limit: 100
    offset: 0
    moveDateOnCopy: true
    inTemplate: $templateId
    orderBy: [{field: title, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      title
    }
    pageInfo {
      hasNextPage
      total
    }
  }
  tableField_List(
    limit: 100
    offset: 0
    moveDateOnCopy: true
    inTemplate: $templateId
    orderBy: [{field: title, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      title
      field {
        id
        title
      }
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useMoveDateOnCopyFieldsQuery__
 *
 * To run a query within a React component, call `useMoveDateOnCopyFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMoveDateOnCopyFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMoveDateOnCopyFieldsQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useMoveDateOnCopyFieldsQuery(baseOptions: Apollo.QueryHookOptions<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables> & ({ variables: MoveDateOnCopyFieldsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>(MoveDateOnCopyFieldsDocument, options);
      }
export function useMoveDateOnCopyFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>(MoveDateOnCopyFieldsDocument, options);
        }
export function useMoveDateOnCopyFieldsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>(MoveDateOnCopyFieldsDocument, options);
        }
export type MoveDateOnCopyFieldsQueryHookResult = ReturnType<typeof useMoveDateOnCopyFieldsQuery>;
export type MoveDateOnCopyFieldsLazyQueryHookResult = ReturnType<typeof useMoveDateOnCopyFieldsLazyQuery>;
export type MoveDateOnCopyFieldsSuspenseQueryHookResult = ReturnType<typeof useMoveDateOnCopyFieldsSuspenseQuery>;
export type MoveDateOnCopyFieldsQueryResult = Apollo.QueryResult<MoveDateOnCopyFieldsQuery, MoveDateOnCopyFieldsQueryVariables>;