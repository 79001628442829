import React, { useState } from 'react'
import c from 'classnames'
import Dropzone from 'react-dropzone'

import { updateAnswersImmutably } from '@/state/answer/thunks'
import { downloadAnswerFileUrl, downloadInitialAnswerFileUrl } from '@/utils/url'
import { AnswerUploadingState, selectAnswerUploadingState, selectIsFromInitialAnswerId } from '@/state/answer/selectors'
import { useAppDispatch, useAppSelector } from '@/utils/hooks'
import { LoadingBar } from '@/components/common/loading'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { AnswerFileBaseProps } from '../types'
import commonStyle from '../common.module.sass'
import style from './index.module.sass'


const AnswerFile = ({answer, disabled, publicFormCode}: AnswerFileBaseProps) => {
	const dispatch = useAppDispatch()
	const [error, setError] = useState<string | null>(null)
	const uploadState = useAppSelector(s => selectAnswerUploadingState(s, answer.id))
	const initialAnswerId = useAppSelector(s => selectIsFromInitialAnswerId(s, answer.id))

	const checkFile = (f: File) => {
		const MAX_SIZE = 8 //Mb
		if(f.size/1024/1024 > MAX_SIZE) {
			setError(`Please select a file under ${MAX_SIZE}Mb`)
		} else {
			setError(null)
			dispatch(updateAnswersImmutably({
				hasContent: !!answer.content,
				answer: {...answer, content: f, name: f.name, }
			}))
		}
	}

	return (
		<div>
			{ disabled && !answer.content ?
					<i className={commonStyle.noContent}>No content</i>
			:
				answer.content ?
					<div className={style.file}>
						<a
							href={
								answer.content.preview ||
								(initialAnswerId && downloadInitialAnswerFileUrl({id: initialAnswerId, code: publicFormCode})) ||
								downloadAnswerFileUrl({id: answer.id, code: publicFormCode})
							}
							className={`${iconStyle.doc} ${style.filename}`} target="_blank"
						>
							{answer.name}
						</a>
						
						{ !disabled &&
							<div
								className={`${style.removeIcon} ${iconStyle.cross}`}
								onClick={() => dispatch(updateAnswersImmutably({ hasContent: true, answer: {...answer, content: null, name: null}}))}
							/>
						}

						{uploadState != AnswerUploadingState.NONE && <LoadingBar />}
					</div>
				:
						// @ts-ignore This is a react 18 type change with children
					<Dropzone
						onDrop={( filesToUpload, e ) => checkFile(filesToUpload[0])}
						multiple={false}
						className={style.dropzoneAnswer}
					>
						<div>
							<button className={c(iconStyle.doc, buttonStyle.greySolid)}>
								Select
							</button>
						</div>
					</Dropzone>
			}
			<div className={generalStyle.error}>{error}</div>
		</div>
	)
}

export default AnswerFile