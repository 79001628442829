import c from 'classnames'
import React from 'react'
import { Link, Route } from 'react-router-dom'

import { FormFormActionsFormatChoices } from '@/__gen/types'
import Placeholder from '@/components/common/placeholder'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { editFormActionsUrl, resetFormActionsUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'

import commonStyle from '../common.module.sass'

import { useFormActionsForRecordQuery } from './common/__gen/query'
import { EditFormActionModal } from './edit-modal'
import style from './index.module.sass'
import { ResetFormActionModal } from './reset-modal'
import { FormActionSummary } from './summary'

export const FormActions = () => {
	const globalContext = useGlobalContext()
	const formContext = useFormContext()

	const variables = { record: formContext.record.id, form: formContext.form.id }
	const { data, loading } = useFormActionsForRecordQuery({ variables })
	const formActions = data?.formAction_List?.objects
	const formActionSchedule = data?.formActionSchedule_List?.objects[0] || null

	const enabled = formContext.form.actionsFormat === FormFormActionsFormatChoices.Customisable
	if (!enabled) {
		return null
	}

	const editUrl = editFormActionsUrl({
		...globalContext,
		recordId: formContext.record.id,
		formId: formContext.form.id,
	})
	const resetUrl = resetFormActionsUrl({
		...globalContext,
		recordId: formContext.record.id,
		formId: formContext.form.id,
	})

	return (
		<div>
			<div className={c(commonStyle.sectionTitle, style.titleRow)}>
				<div>Actions</div>
				<div className={style.buttonGroup}>
					{formActions && formActions.length > 0 && !formActionSchedule && (
						<Link to={resetUrl}>
							<button className={c(buttonStyle.small, buttonStyle.greySolid)}>Reset</button>
						</Link>
					)}
					<Link to={editUrl}>
						<button className={c(buttonStyle.small, buttonStyle.greySolid)}>View</button>
					</Link>
				</div>
			</div>
			{loading ?
				<Placeholder style={{ height: 100 }} />
			: formActions === undefined ?
				<div className={generalStyle.error}>error</div>
			:	<>
					<FormActionSummary formActions={formActions} formActionSchedule={formActionSchedule} />
					<Route
						path={editUrl}
						component={() => (
							<EditFormActionModal formActions={formActions} formActionSchedule={formActionSchedule} />
						)}
					/>
					<Route path={resetUrl} component={() => <ResetFormActionModal formActions={formActions} />} />
				</>
			}
		</div>
	)
}
