import React from 'react'
import gql from "graphql-tag"
import { RouteComponentProps } from 'react-router-dom'

import Placeholder from '@/components/common/placeholder'
import { downloadAnswerFileUrl } from '@/utils/url'
import buttonStyle from '@sass/common/button.module.sass'
import generalStyle from '@sass/common/general.module.sass'
import style from './index.module.sass'
import { useAnswerFileToDownloadQuery } from './__gen'

gql`
	query answerFileToDownload($id: ID!) {
		answerFile(id: $id) {
			id
			name
			modified
			field {
				id
				title
			}
		}
	}
`

type Props = RouteComponentProps<{fileId: string}>

export const DownloadAnswerFile = ({ match }: Props) => {
	const {data, loading} = useAnswerFileToDownloadQuery({variables: {id: match.params.fileId}})
	return (
		<div className={style.container}>
			<div className={style.card}>
				{loading
					? <Placeholder style={{height: 200}} />
					: ((!data?.answerFile)
						?	<div>error</div>
						: (
							<>
								<h5>{data.answerFile.field.title}</h5>
								<div><span className={generalStyle.muted}>Name: </span>{data.answerFile.name}</div>
								<br/>
								<a href={downloadAnswerFileUrl({id: data.answerFile.id, code: null})}>
									<button className={buttonStyle.primarySolid}>Download file</button>
								</a>
							</>
						)
					)
				}
			</div>
		</div>
	)
}
