import { ReduxState } from '@/types/redux'

export const SHOW_MODAL = "SHOW_MODAL"
export const HIDE_MODAL = "HIDE_MODAL"

export function showModal(modal: ReduxState["modal"]) {
  return {
    type: SHOW_MODAL,
    modal,
  }
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  }
}

type Args = {
  title: string,
  content: string
}
export function showErrorModal(modalData?: Args) {
  const modal: ReduxState["modal"] = modalData || {
    title: 'Oh dear',
    content: 'It appears something has gone wrong. If this issue persists, please contact support',
  }
	return {
		type: 'SHOW_MODAL',
		modal,
	}
}