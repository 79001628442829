import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ActionRecordFormsQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type ActionRecordFormsQuery = { __typename: 'PharosQuery', record_List: { __typename: 'RecordTypeListBase', objects: Array<{ __typename: 'RecordType', id: string, name: string, template: { __typename: 'TemplateType', id: string } }> } | null, template_List: { __typename: 'TemplateTypeListBase', objects: Array<{ __typename: 'TemplateType', id: string, formSet: { __typename: 'FormSetType', id: string, forms: Array<{ __typename: 'FormType', id: string, title: string }> } }> } | null };


export const ActionRecordFormsDocument = gql`
    query actionRecordForms($moduleId: ID!, $teamId: ID) {
  record_List(
    module: $moduleId
    team: $teamId
    archived: false
    orderBy: [{field: name, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      name
      template {
        id
      }
    }
  }
  template_List(module: $moduleId) {
    objects {
      id
      formSet {
        id
        forms(
          actionsFormat_In: [CUSTOMISABLE, PDF_CREATION]
          orderBy: [{field: title, modifiers: [CASE_INSENSITIVE]}]
        ) {
          id
          title
        }
      }
    }
  }
}
    `;

/**
 * __useActionRecordFormsQuery__
 *
 * To run a query within a React component, call `useActionRecordFormsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionRecordFormsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionRecordFormsQuery({
 *   variables: {
 *      moduleId: // value for 'moduleId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useActionRecordFormsQuery(baseOptions: Apollo.QueryHookOptions<ActionRecordFormsQuery, ActionRecordFormsQueryVariables> & ({ variables: ActionRecordFormsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>(ActionRecordFormsDocument, options);
      }
export function useActionRecordFormsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>(ActionRecordFormsDocument, options);
        }
export function useActionRecordFormsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>(ActionRecordFormsDocument, options);
        }
export type ActionRecordFormsQueryHookResult = ReturnType<typeof useActionRecordFormsQuery>;
export type ActionRecordFormsLazyQueryHookResult = ReturnType<typeof useActionRecordFormsLazyQuery>;
export type ActionRecordFormsSuspenseQueryHookResult = ReturnType<typeof useActionRecordFormsSuspenseQuery>;
export type ActionRecordFormsQueryResult = Apollo.QueryResult<ActionRecordFormsQuery, ActionRecordFormsQueryVariables>;