import React, { useEffect, useRef } from 'react'
import gql from 'graphql-tag'
import c from 'classnames'
import { Link } from 'react-router-dom'
import { Empty } from 'antd'

import { useGlobalContext } from '@/components/context'
import { formUrl } from '@/utils/url'
import { useAppSelector } from '@/utils/hooks'
import { useConcatenatedTableDataQuery, ConcatenatedTableDataQuery } from './__gen/rows'
import style from './rows.module.sass'
import { getLoadingCards } from './common'

const FETCH_LIMIT = 20

gql`
	query concatenatedTableData(
		$concatenatedTableId: ID!,
		$teamId: ID,
		$limit: Int!,
		$offset: Int!,
		$ordering: [ConcatenatedTableOrdering]!,
		$search: String!
	) {
		concatenatedTableData(
			concatenatedTableId: $concatenatedTableId,
			teamId: $teamId,
			limit: $limit,
			offset: $offset,
			ordering: $ordering,
			search: $search
		) {
			rows {
				jsonData
				link {
					formId
					recordId
					teamId
					fieldId
				}
			}
			hasNextPage
	  }
	}

`
export const ASC = 'asc'
export const DESC = 'desc'

export type ColumnOrderItem = {columnIndex: number, direction: typeof ASC | typeof DESC}

type DataRow = (string | number | boolean)[]

type Props = {
	concatenatedTableId: string
	columnStyle: React.CSSProperties[]
	search: string
	orderColumns: ColumnOrderItem[]
}

const isContainerFull = (container: HTMLDivElement) => {
	let full
	if(container.clientHeight - container.scrollHeight >= 0) {
		// is container even full?
		full = false
	}
	else {
		// have we scrolled near the bottom?
		const bottomOfView = container.clientHeight + container.scrollTop
		const distanceFromBottom = container.scrollHeight - bottomOfView
		full = distanceFromBottom > 100
	}
	return full
}

const Rows = ({ concatenatedTableId, columnStyle, search, orderColumns }: Props) => {
	const globalContext = useGlobalContext()
	const scrollContainer = useRef<HTMLDivElement>(null)
	const printableDashboard = useAppSelector((state) => state.userInterface.printableDashboard)

	const fetchVariables = {
		concatenatedTableId: concatenatedTableId,
		teamId: globalContext.currentTeam?.id || null,
		limit: FETCH_LIMIT,
		ordering: orderColumns,
		offset: 0,
		search: search,
	}

	const { data, loading, fetchMore } = useConcatenatedTableDataQuery({
		variables: fetchVariables,
		notifyOnNetworkStatusChange: true,
	})

	const rows = data?.concatenatedTableData?.rows

	const fetchMoreRows = () => fetchMore({
		variables: {
			...fetchVariables,
			offset: rows?.length
		},
		updateQuery: (prev: ConcatenatedTableDataQuery, { fetchMoreResult })  => {
			if (!fetchMoreResult) return prev
			// has issue picking overloaded type definition, need to be explicit
			const next: ConcatenatedTableDataQuery = {
				...prev,
				concatenatedTableData: {
					__typename: 'ConcatenatedTableDataType',
					...prev.concatenatedTableData,
					rows: [
						...(prev?.concatenatedTableData?.rows || []),
						...(fetchMoreResult?.concatenatedTableData?.rows || []),
					],
					hasNextPage: fetchMoreResult?.concatenatedTableData?.hasNextPage || false
				}
			}
			return next
		}
	})

	useEffect(() => {
		if (
			!loading
			&& rows?.length
			&& scrollContainer.current
			&& !isContainerFull(scrollContainer.current)
		) {
			// if container isn't full after fetch, fetch again (for tall displays)
			fetchMoreRows()
		}
	}, [rows?.length])

	const onScroll = (scrollEvent: React.UIEvent<HTMLDivElement>) => {
		const container = scrollEvent.target as HTMLDivElement
		if (!loading && data?.concatenatedTableData?.hasNextPage && !isContainerFull(container)) {
			fetchMoreRows()
		}
	}

	return (
		<div
			className={c(style.container, {[style.noScroll]: printableDashboard})}
			onScroll={onScroll} ref={scrollContainer}
		>
			{rows?.map((row, i) => (
				<Link
					key={i}
					className={style.dataRow}
					to={formUrl({
						formId: row.link.formId,
						recordId: row.link.recordId,
						highlightFieldId: row.link.fieldId,
						...globalContext
					})}
				>
					{(JSON.parse(row.jsonData) as DataRow).map((cell, j) => (
						<div key={`${i}-${j}`} className={style.cell} style={columnStyle[j]}>
							{typeof cell === "boolean" ? (cell ? 'Yes' : 'No') : cell}
						</div>
					))}
				</Link>
			))}
			{loading && getLoadingCards(8)}
			{!loading && data?.concatenatedTableData?.hasNextPage && 
				// To make dashboard printable, we disable scroll in the inner div. This stops
				// infinite scrolling, so add button in that case.
				<button onClick={fetchMoreRows}>load more</button>
			}
			{!loading && rows?.length == 0 && <><br/><br/><br/><br/><Empty /></>}
		</div>
	)
}

export default Rows