import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SetArchivedMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  archived: Types.Scalars['Boolean']['input'];
  archivedBy?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type SetArchivedMutation = { __typename: 'PharosMutations', record_Update: { __typename: 'recordPayload', object: { __typename: 'RecordType', id: string, archived: boolean } | null } | null };


export const SetArchivedDocument = gql`
    mutation setArchived($id: ID!, $archived: Boolean!, $archivedBy: ID) {
  record_Update(input: {id: $id, archived: $archived, archivedBy: $archivedBy}) {
    object {
      id
      archived
    }
  }
}
    `;
export type SetArchivedMutationFn = Apollo.MutationFunction<SetArchivedMutation, SetArchivedMutationVariables>;

/**
 * __useSetArchivedMutation__
 *
 * To run a mutation, you first call `useSetArchivedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetArchivedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setArchivedMutation, { data, loading, error }] = useSetArchivedMutation({
 *   variables: {
 *      id: // value for 'id'
 *      archived: // value for 'archived'
 *      archivedBy: // value for 'archivedBy'
 *   },
 * });
 */
export function useSetArchivedMutation(baseOptions?: Apollo.MutationHookOptions<SetArchivedMutation, SetArchivedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetArchivedMutation, SetArchivedMutationVariables>(SetArchivedDocument, options);
      }
export type SetArchivedMutationHookResult = ReturnType<typeof useSetArchivedMutation>;
export type SetArchivedMutationResult = Apollo.MutationResult<SetArchivedMutation>;
export type SetArchivedMutationOptions = Apollo.BaseMutationOptions<SetArchivedMutation, SetArchivedMutationVariables>;