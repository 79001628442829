import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { useGlobalContext } from '@/components/context'
import { SinglePanel } from '@/components/common/panel'
import { Input } from '@/components/common/inputs'
import { useTitle } from '@/utils/hooks'

import { Orgs } from './search-results/orgs'
import style from './index.module.sass'


export const AdminPanel = ({ match }: RouteComponentProps<{search: string}>) => {
	const [search, setSearch] = useState(match.params.search || '')
	const globalContext = useGlobalContext()
	useTitle("Super User - Switch")
	return (
		<SinglePanel type="thin">
			<div className={style.adminPanel}>
				<h2>Switch company</h2>
				<div className={style.currentOrg}>
					Current:
					<div className={style.org}>{globalContext.organisation.name}</div>
					{!globalContext.currentTeam 
						? '(top level)'
						: globalContext.currentTeam.name
					}
				</div>
				{!globalContext.user.isAdmin &&
					<div>You are not admin</div>
				}
				<div className={style.searchRow}>
					<Input
						onChange={(e) => setSearch(e.target.value)}
						value={search}
						placeholder="Search"
						autoFocus
					/>
				</div>
				{ search &&
					<Orgs search={search} />
				}
			</div>
		</SinglePanel>
	)
}
