import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CompleteFormActionMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  completedOn?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  signatureImage?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  signatureText?: Types.InputMaybe<Types.Scalars['String']['input']>;
  signatureNote?: Types.InputMaybe<Types.Scalars['String']['input']>;
  reuseSignatureFormActionId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
}>;


export type CompleteFormActionMutation = { __typename: 'PharosMutations', formAction_Update: { __typename: 'form_actionPayload', object: { __typename: 'FormActionType', id: string, completedOn: string | null } | null } | null };

export type CompleteFormActionPubMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  completedOn?: Types.InputMaybe<Types.Scalars['DateTime']['input']>;
  signatureImage?: Types.InputMaybe<Types.Scalars['Upload']['input']>;
  signatureNote?: Types.InputMaybe<Types.Scalars['String']['input']>;
  signatureText?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type CompleteFormActionPubMutation = { __typename: 'PharosMutations', formActionPub_Update: { __typename: 'form_action_pubPayload', object: { __typename: 'FormActionPubType', id: string, completedOn: string | null } | null } | null };


export const CompleteFormActionDocument = gql`
    mutation CompleteFormAction($id: ID!, $completedOn: DateTime, $signatureImage: Upload, $signatureText: String, $signatureNote: String, $reuseSignatureFormActionId: ID) {
  formAction_Update(
    input: {id: $id, completedOn: $completedOn, signatureImage: $signatureImage, signatureText: $signatureText, signatureNote: $signatureNote, reuseSignatureFormActionId: $reuseSignatureFormActionId}
  ) {
    object {
      id
      completedOn
    }
  }
}
    `;
export type CompleteFormActionMutationFn = Apollo.MutationFunction<CompleteFormActionMutation, CompleteFormActionMutationVariables>;

/**
 * __useCompleteFormActionMutation__
 *
 * To run a mutation, you first call `useCompleteFormActionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFormActionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFormActionMutation, { data, loading, error }] = useCompleteFormActionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completedOn: // value for 'completedOn'
 *      signatureImage: // value for 'signatureImage'
 *      signatureText: // value for 'signatureText'
 *      signatureNote: // value for 'signatureNote'
 *      reuseSignatureFormActionId: // value for 'reuseSignatureFormActionId'
 *   },
 * });
 */
export function useCompleteFormActionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFormActionMutation, CompleteFormActionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFormActionMutation, CompleteFormActionMutationVariables>(CompleteFormActionDocument, options);
      }
export type CompleteFormActionMutationHookResult = ReturnType<typeof useCompleteFormActionMutation>;
export type CompleteFormActionMutationResult = Apollo.MutationResult<CompleteFormActionMutation>;
export type CompleteFormActionMutationOptions = Apollo.BaseMutationOptions<CompleteFormActionMutation, CompleteFormActionMutationVariables>;
export const CompleteFormActionPubDocument = gql`
    mutation CompleteFormActionPub($id: ID!, $completedOn: DateTime, $signatureImage: Upload, $signatureNote: String, $signatureText: String) {
  formActionPub_Update(
    input: {id: $id, completedOn: $completedOn, signatureImage: $signatureImage, signatureNote: $signatureNote, signatureText: $signatureText}
  ) {
    object {
      id
      completedOn
    }
  }
}
    `;
export type CompleteFormActionPubMutationFn = Apollo.MutationFunction<CompleteFormActionPubMutation, CompleteFormActionPubMutationVariables>;

/**
 * __useCompleteFormActionPubMutation__
 *
 * To run a mutation, you first call `useCompleteFormActionPubMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFormActionPubMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFormActionPubMutation, { data, loading, error }] = useCompleteFormActionPubMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completedOn: // value for 'completedOn'
 *      signatureImage: // value for 'signatureImage'
 *      signatureNote: // value for 'signatureNote'
 *      signatureText: // value for 'signatureText'
 *   },
 * });
 */
export function useCompleteFormActionPubMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFormActionPubMutation, CompleteFormActionPubMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFormActionPubMutation, CompleteFormActionPubMutationVariables>(CompleteFormActionPubDocument, options);
      }
export type CompleteFormActionPubMutationHookResult = ReturnType<typeof useCompleteFormActionPubMutation>;
export type CompleteFormActionPubMutationResult = Apollo.MutationResult<CompleteFormActionPubMutation>;
export type CompleteFormActionPubMutationOptions = Apollo.BaseMutationOptions<CompleteFormActionPubMutation, CompleteFormActionPubMutationVariables>;