import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UserSettingsUpdateMutationVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  email: Types.Scalars['String']['input'];
  firstName: Types.Scalars['String']['input'];
  lastName: Types.Scalars['String']['input'];
}>;


export type UserSettingsUpdateMutation = { __typename: 'PharosMutations', user_Update: { __typename: 'userPayload', object: { __typename: 'UserType', id: string, email: string, firstName: string, lastName: string } | null, errors: Array<{ __typename: 'ErrorType', field: string, messages: Array<string> } | null> | null } | null };


export const UserSettingsUpdateDocument = gql`
    mutation userSettingsUpdate($id: ID!, $email: String!, $firstName: String!, $lastName: String!) {
  user_Update(
    input: {id: $id, email: $email, firstName: $firstName, lastName: $lastName}
  ) {
    object {
      id
      email
      firstName
      lastName
    }
    errors {
      field
      messages
    }
  }
}
    `;
export type UserSettingsUpdateMutationFn = Apollo.MutationFunction<UserSettingsUpdateMutation, UserSettingsUpdateMutationVariables>;

/**
 * __useUserSettingsUpdateMutation__
 *
 * To run a mutation, you first call `useUserSettingsUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSettingsUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSettingsUpdateMutation, { data, loading, error }] = useUserSettingsUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *   },
 * });
 */
export function useUserSettingsUpdateMutation(baseOptions?: Apollo.MutationHookOptions<UserSettingsUpdateMutation, UserSettingsUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSettingsUpdateMutation, UserSettingsUpdateMutationVariables>(UserSettingsUpdateDocument, options);
      }
export type UserSettingsUpdateMutationHookResult = ReturnType<typeof useUserSettingsUpdateMutation>;
export type UserSettingsUpdateMutationResult = Apollo.MutationResult<UserSettingsUpdateMutation>;
export type UserSettingsUpdateMutationOptions = Apollo.BaseMutationOptions<UserSettingsUpdateMutation, UserSettingsUpdateMutationVariables>;