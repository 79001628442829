import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetUsersForAssignmentQueryVariables = Types.Exact<{
  teamId: Types.Scalars['ID']['input'];
  search?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetUsersForAssignmentQuery = { __typename: 'PharosQuery', user_List: { __typename: 'UserTypeListBase', objects: Array<{ __typename: 'UserType', id: string, firstName: string, lastName: string, email: string }> } | null };


export const GetUsersForAssignmentDocument = gql`
    query getUsersForAssignment($teamId: ID!, $search: String) {
  user_List(
    hasAccessToTeam: $teamId
    search: $search
    orderBy: [{field: firstName, modifiers: [CASE_INSENSITIVE]}, {field: lastName, modifiers: [CASE_INSENSITIVE]}]
  ) {
    objects {
      id
      firstName
      lastName
      email
    }
  }
}
    `;

/**
 * __useGetUsersForAssignmentQuery__
 *
 * To run a query within a React component, call `useGetUsersForAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersForAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersForAssignmentQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetUsersForAssignmentQuery(baseOptions: Apollo.QueryHookOptions<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables> & ({ variables: GetUsersForAssignmentQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>(GetUsersForAssignmentDocument, options);
      }
export function useGetUsersForAssignmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>(GetUsersForAssignmentDocument, options);
        }
export function useGetUsersForAssignmentSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>(GetUsersForAssignmentDocument, options);
        }
export type GetUsersForAssignmentQueryHookResult = ReturnType<typeof useGetUsersForAssignmentQuery>;
export type GetUsersForAssignmentLazyQueryHookResult = ReturnType<typeof useGetUsersForAssignmentLazyQuery>;
export type GetUsersForAssignmentSuspenseQueryHookResult = ReturnType<typeof useGetUsersForAssignmentSuspenseQuery>;
export type GetUsersForAssignmentQueryResult = Apollo.QueryResult<GetUsersForAssignmentQuery, GetUsersForAssignmentQueryVariables>;