import React from 'react'
import gql from 'graphql-tag'
import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import ListMetric from '../../types/list'
import MetricPlaceholder from '../metric-placeholder'
import { useFieldDateRangeMetricQuery } from './__gen'


gql`
	query fieldDateRangeMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		fieldDateRangeMetric(id: $id) {
			id
			name
			values(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				content
				contentObjectName
				formLink {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FieldDateRangeMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useFieldDateRangeMetricQuery({
		variables: {
			id,
			teamId: globalContext.currentTeam?.id,
			isSuiteAdminDashboard,
		}
	})

	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.fieldDateRangeMetric) {
		return <div>error</div>
	}
	return (
		<ListMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...data?.fieldDateRangeMetric} />
	)

}

export default FieldDateRangeMetricWrapper