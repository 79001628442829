import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type AdminOrganisationsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type AdminOrganisationsQuery = { __typename: 'PharosQuery', adminOrgSwitchHistory_List: { __typename: 'AdminOrgSwitchHistoryTypeListBase', objects: Array<{ __typename: 'AdminOrgSwitchHistoryType', id: string, organisation: { __typename: 'AdminOrganisationType', id: string, name: string } }> } | null };


export const AdminOrganisationsDocument = gql`
    query adminOrganisations {
  adminOrgSwitchHistory_List(
    limit: 6
    orderBy: [{field: lastSwitch, direction: DESC}]
  ) {
    objects {
      id
      organisation {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useAdminOrganisationsQuery__
 *
 * To run a query within a React component, call `useAdminOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAdminOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAdminOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAdminOrganisationsQuery(baseOptions?: Apollo.QueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
      }
export function useAdminOrganisationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
        }
export function useAdminOrganisationsSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>(AdminOrganisationsDocument, options);
        }
export type AdminOrganisationsQueryHookResult = ReturnType<typeof useAdminOrganisationsQuery>;
export type AdminOrganisationsLazyQueryHookResult = ReturnType<typeof useAdminOrganisationsLazyQuery>;
export type AdminOrganisationsSuspenseQueryHookResult = ReturnType<typeof useAdminOrganisationsSuspenseQuery>;
export type AdminOrganisationsQueryResult = Apollo.QueryResult<AdminOrganisationsQuery, AdminOrganisationsQueryVariables>;