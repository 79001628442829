import { getAvailableDataRequest } from '@/utils/resources-deprecated'

export function gotAvailableData(fields, positions) {
	return {
		type: "GOT_AVAILABLE_CSV_DATA",
		fields,
		positions, 
	}
}

export function getAvailableData(queryString) {
	return dispatch => {
		return getAvailableDataRequest(queryString).then(response => {
			return response.json().then(data => {
				return dispatch(gotAvailableData(data.fields, data.positions))
			})
		})
	}
}

