import React from 'react'
import { Link } from 'react-router-dom'

import { useGlobalContext } from '@/components/context'
import { formUrl } from '@/utils/url'
import style from './index.module.sass'
import { AnswerDate } from '../../types'

type Props = {
	answer: AnswerDate
}

const Answer = ({ answer }: Props ) => {
	const globalContext = useGlobalContext()
	const seed = parseInt(answer.field.id) * (parseInt(answer.tableField?.id || '1'))

	const hue = Math.floor((Math.abs(Math.sin(seed) * 255)) % 255); // 0 - 255
	const lightness = Math.floor((Math.abs(Math.sin(seed) * 40)) % 40) + 10; // 10 - 50 
	const saturation = Math.floor((Math.abs(Math.sin(seed+7) * 50)) % 50) + 50; // 50 - 100

	const color = "hsl(" + hue + ", " + saturation + "%, " + lightness + "%)";

	const content = (
		<div className={style.container} style={{backgroundColor: color}}>
			<div>
				{answer.field.title}
				{answer.tableField && <> · {answer.tableField.title}</>}
			</div>
			<div>
				{answer.contentObjectName}
			</div>
		</div>
	)

	if (!answer.link) {
		return <div>Error - Inaccessible: {content}</div>
	}

	return (
		<Link
			key={answer.id}
			className={style.linkContainer}
			to={formUrl({
				...globalContext,
				recordId: answer.link.recordId,
				formId: answer.link.formId,
				highlightFieldId: answer.field.id
			})}
		>
			{content}
		</Link>
	)
}

export default Answer