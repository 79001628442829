import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { useSelector } from 'react-redux'
import { ReduxState } from '@/types/redux'


export const useShowModifyForm = () => {
	const { user } = useGlobalContext()
	const formContext = useFormContext()
	const showButton = useSelector((state: ReduxState) => (
		user.topLevelManager && Object.values(formContext.fields)
		.filter(field => field.canHide)
		.length > 0
	))

	return showButton
}