import React, { Component } from 'react'
import { SinglePanel } from '@/components/common/panel'
import formStyle from '@sass/common/form.module.sass'

export default (props: {children: React.ReactNode}) => (
    <SinglePanel type="thin">
        <div className={formStyle.pageFormContainer}>
            <div className={formStyle.pageForm}>
                {props.children}
            </div>
        </div>
    </SinglePanel>
)