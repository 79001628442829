import React from 'react'
import gql from "graphql-tag"
import { Link } from 'react-router-dom'

import { useRouter } from '@/components/context'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import Forms from './forms'
import ActionButtons from './action-buttons'
import style from './index.module.sass'
import commonStyle from '../common.module.sass'
import { TEAM_PANEL, PanelTypes } from '../const'
import Placeholder from '@/components/common/placeholder'
import { Pill } from '@/components/common/pill'
import { useGlobalContext } from '@/components/context'
import { apolloClient } from '@/state/apollo-client'
import { teamMetricsUrl, recordDashboardUrl } from '@/utils/url'
import { useFormsForRecordQuery } from './__gen'

gql`
	query formsForRecord($id: ID!) {
		record(
			id: $id,
		) {
			id
			name
			position
			archived
			team {
				id
				name
			}
			template {
				id
				formSet {
					id
					forms(orderBy: [{field: category_Rank}, {field: rank}]) {
						id
						hasCreatedPdf(recordId: $id)
						hasSaved(recordId: $id)
						title
						rank
						category {
								id
								title
								rank
								collapsible
						}
						dependency {
							id
						}
					}
				}
			}
		}
	}
`

export const changeFormSaveStatus = (formId: string, recordId: string, value: boolean) => {
	apolloClient.writeFragment({
		id: 'FormType:' + formId,
		fragment: gql`
			fragment UpdateForm_hasSaved on FormType {
				hasSaved(recordId: "${recordId}")
			}
		`,
		data: {
			hasSaved: value,
			__typename: 'FormType'
		},
	})
}

export const changeFormPdfStatus = (formId: string, recordId: string, value: boolean) => {
	apolloClient.writeFragment({
		id: 'FormType:' + formId,
		fragment: gql`
			fragment UpdateForm_hasCreatedPdf on FormType {
				hasCreatedPdf(recordId: "${recordId}")
			}
		`,
		data: {
			hasCreatedPdf: value,
			__typename: 'FormType'
		},
	})
}



type BaseProps = {
	setShowPanel: (panel: PanelTypes) => void
}
type InnerProps = BaseProps & {
	recordId: string
}


const Record = (props: InnerProps) => {
	const globalContext = useGlobalContext()
	const router = useRouter()
	const { data, error, loading } = useFormsForRecordQuery({ variables: { id: props.recordId } })

	return (
		<>
			<button
				className={`${iconStyle.arrowLeft} ${buttonStyle.greySolid} ${commonStyle.backButton}`}
				onClick={() => {
					props.setShowPanel(TEAM_PANEL)
					router.history.push(teamMetricsUrl(globalContext))
				}}
			>
				All {globalContext.currentModule.recordWord}s
			</button>
			{loading
				? <>
					<Placeholder />
					<Placeholder style={{width: '100%', height: 300}} />
					<Placeholder style={{width: '100%', height: 400}} />
				</>
				: (!data?.record || error)
					? <div>Error</div>
					: <>
						<Link className={commonStyle.title} to={recordDashboardUrl({...props, ...globalContext})}>
							{data.record.name || <div style={{fontStyle: 'italic'}}>No Name</div>}
							{data.record.archived && <>&nbsp;<Pill color='red'>ARCHIVED</Pill></>}
						</Link>
						<div className={style.recordPosition}>
							{data.record.position}
							<Link
								className={style.recordTeam}
								to={teamMetricsUrl({...globalContext, currentTeam: data.record.team})}
							>
								&nbsp;·&nbsp;{data.record.team.name}
							</Link>
						</div>
						<ActionButtons record={data.record} />
						<div className={commonStyle.lineSpacer} />
						<Forms record={data.record} />
					</>
			}
			
			
		</>
	)
}

type OuterProps = BaseProps & {
	recordId: string | null
}

export default (props: OuterProps) => {
	if (!props.recordId) {
		return null
	}
	return <Record {...props} recordId={props.recordId} />
}
