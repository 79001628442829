import gql from 'graphql-tag'
import React, { useState } from 'react'
import { Input } from '@/components/common/inputs'
import Placeholder from '@/components/common/placeholder'
import { useDebounce } from '@/utils/hooks'
import generalStyle from '@sass/common/general.module.sass'
import { useGetUsersForAssignmentQuery } from './__gen'
import style from './index.module.sass'
import { User, UserType } from './user'

type Props = {
	onSelect: (u: UserType | null) => void
	teamId: string
	disabledUsers: string[]
}

gql`
	query getUsersForAssignment($teamId: ID!, $search: String) {
		user_List(
			hasAccessToTeam: $teamId
			search: $search
			orderBy: [
				{ field: firstName, modifiers: [CASE_INSENSITIVE] }
				{ field: lastName, modifiers: [CASE_INSENSITIVE] }
			]
		) {
			objects {
				id
				firstName
				lastName
				email
			}
		}
	}
`

export const SelectUser = ({ onSelect, teamId, disabledUsers }: Props) => {
	const [search, setSearch] = useState('')
	const [debouncedSearch, setDebouncedSearch] = useState(search)
	useDebounce(() => setDebouncedSearch(search), 200, [search])

	const variables = { variables: { teamId, search: debouncedSearch } }
	const { data, loading } = useGetUsersForAssignmentQuery(variables)

	const objects = data?.user_List?.objects

	const onInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(e.target.value)
	}

	return (
		<div className={style.container}>
			<Input
				className={style.search}
				type="text"
				value={search}
				onChange={onInputChanged}
				placeholder="Search Users..."
				autoFocus
			/>
			<div className={style.userList}>
				{objects && objects.length > 0 ?
					objects.map((u) => <User key={u.id} user={u} onClick={() => onSelect(u)} />)
				: loading ?
					[...Array(6)].map((_, i) => (
						<Placeholder key={i} style={{ width: 120, height: 28, margin: '3px 0px' }} />
					))
				:	<div className={generalStyle.error}>error</div>}
			</div>
		</div>
	)
}
