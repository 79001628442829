import React, { useState, useCallback } from 'react'
import {
	DndContext,
	closestCenter,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragEndEvent,
} from '@dnd-kit/core'
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	verticalListSortingStrategy,
	useSortable,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Empty } from 'antd'

import { DragHandle } from '@/components/common/drag-handle'
import { Actions, State } from './state'
import { FormActionRow } from './form-action-row'
import { Row } from './row'


type SortableItemProps = {
	sortEnabled: boolean,
	id: string,
	children: React.ReactNode
}

export function SortableItem({ id, sortEnabled, children }: SortableItemProps) {
	const {
		attributes,
		listeners,
		setNodeRef,
		transform,
		transition,
	} = useSortable({ id: id });

	const style = {
		transform: CSS.Translate.toString(transform),
		transition,
		display: 'flex'
	};

	return (
		<div ref={setNodeRef} style={style} >
			{sortEnabled && <DragHandle attributes={attributes} listeners={listeners} />}
			{children}
		</div>
	);
}


type Props = {
	state: State
	dispatch: React.Dispatch<Actions>
}


export const FormActionTable = ({ state, dispatch }: Props) => {
	const sortEnabled = true
	const [hasReordered, setHasReordered] = useState(false)

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleDragEnd = useCallback((event: DragEndEvent) => {
		const { active, over } = event;
		if (!over) {
			return
		}

		if (active.id !== over.id) {
			const oldIndex = state.findIndex((i) => i.id == active.id);
			const newIndex = state.findIndex((i) => i.id == over.id);
			
			const nextSortRows = arrayMove(state, oldIndex, newIndex).map(n => ({...n}));
			
			dispatch({type: 'REORDER', objs: nextSortRows})
			setHasReordered(true)
		}
	}, [state])

	let prevComplete = true

	return (
		<>
			<Row
				header
				priority="Priority"
				type="Type"
				reminder="Reminder"
				requestSignature="Signature"
				name="Name"
			/>
			<DndContext
				sensors={sensors}
				collisionDetection={closestCenter}
				onDragEnd={handleDragEnd}
			>
				<SortableContext
					items={state}
					strategy={verticalListSortingStrategy}
				>
					{state.map(a => {
						if (a.deleted) {
							return null
						}
						const isCurrent = prevComplete && !a.completedOn
						prevComplete = prevComplete && !!a.completedOn
						return (
							<SortableItem key={a.id} id={a.id} sortEnabled={sortEnabled} >
								<FormActionRow formAction={a} dispatch={dispatch} isCurrent={isCurrent} hasReordered={hasReordered} />
							</SortableItem>
						)
					})}
					{state.filter(a => !a.deleted).length == 0 &&
						<div style={{padding: 50}}>
							<Empty description="No Actions" />
						</div>
					}
				</SortableContext>
			</DndContext>
		</>
	)
}