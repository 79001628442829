import React from 'react'
import gql from 'graphql-tag'
import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import ValueMetric from '../../types/value'
import MetricPlaceholder from '../metric-placeholder'
import { useFieldAggregationMetricQuery } from './__gen'


gql`
	query fieldAggregationMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
		fieldAggregationMetric(id: $id) {
			id
			name
			result(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
				value
				formLink {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`


type Props = {
	id: string
	isSuiteAdminDashboard?: boolean
}

const FieldAggregationMetricWrapper = ({ id, isSuiteAdminDashboard }: Props) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useFieldAggregationMetricQuery({
		variables: {
			id,
			teamId: globalContext.currentTeam?.id,
			isSuiteAdminDashboard,
		}
	})
	if (loading) {
		return <MetricPlaceholder />
	}
	if (!data || !data.fieldAggregationMetric) {
		return <div>error</div>
	}
	return (
		<ValueMetric isSuiteAdminDashboard={isSuiteAdminDashboard} {...data?.fieldAggregationMetric} />
	)

}

export default FieldAggregationMetricWrapper