import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionFragment = { __typename: 'FormActionType', id: string, assignedAnonEmail: string, assignedAnonName: string, completedOn: string | null, deleted: boolean, isScheduled: boolean, link: string | null, priority: number, reminderNext: any | null, reminderLast: any | null, requestSignature: boolean, userSuppliedContext: string, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, form: { __typename: 'FormType', pdfGenerators: Array<{ __typename: 'PdfGeneratorType', id: string, name: string }> } };

export type FormActionScheduleFragment = { __typename: 'FormActionScheduleType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, dueAt: string, requestSignature: boolean, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, userSuppliedContext: string, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null };

export type FormActionsForRecordQueryVariables = Types.Exact<{
  record: Types.Scalars['ID']['input'];
  form: Types.Scalars['ID']['input'];
}>;


export type FormActionsForRecordQuery = { __typename: 'PharosQuery', formAction_List: { __typename: 'FormActionTypeListBase', objects: Array<{ __typename: 'FormActionType', id: string, assignedAnonEmail: string, assignedAnonName: string, completedOn: string | null, deleted: boolean, isScheduled: boolean, link: string | null, priority: number, reminderNext: any | null, reminderLast: any | null, requestSignature: boolean, userSuppliedContext: string, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, form: { __typename: 'FormType', pdfGenerators: Array<{ __typename: 'PdfGeneratorType', id: string, name: string }> } }> } | null, formActionSchedule_List: { __typename: 'FormActionScheduleTypeListBase', objects: Array<{ __typename: 'FormActionScheduleType', id: string, assignedAnonEmail: string, assignedAnonName: string, deleted: boolean, dueAt: string, requestSignature: boolean, unitAmount: number, unitName: Types.FormActionFormActionScheduleUnitNameChoices, userSuppliedContext: string, assignedAnonEmailAnswer: { __typename: 'AnswerTextPubType', id: string, content: string | null, contactName: string | null } | null, assignedUser: { __typename: 'UserType', id: string, firstName: string, lastName: string, email: string } | null }> } | null };

export const FormActionFragmentDoc = gql`
    fragment FormAction on FormActionType {
  id
  assignedUser {
    id
    firstName
    lastName
    email
  }
  assignedAnonEmail
  assignedAnonName
  assignedAnonEmailAnswer {
    id
    content
    contactName
  }
  completedOn
  deleted
  form {
    pdfGenerators {
      id
      name
    }
  }
  isScheduled
  link
  priority
  reminderNext
  reminderLast
  requestSignature
  userSuppliedContext
}
    `;
export const FormActionScheduleFragmentDoc = gql`
    fragment FormActionSchedule on FormActionScheduleType {
  id
  assignedAnonEmail
  assignedAnonName
  assignedAnonEmailAnswer {
    id
    content
    contactName
  }
  assignedUser {
    id
    firstName
    lastName
    email
  }
  deleted
  dueAt
  requestSignature
  unitAmount
  unitName
  userSuppliedContext
}
    `;
export const FormActionsForRecordDocument = gql`
    query formActionsForRecord($record: ID!, $form: ID!) {
  formAction_List(record: $record, form: $form, orderBy: {field: priority}) {
    objects {
      ...FormAction
    }
  }
  formActionSchedule_List(record: $record, form: $form) {
    objects {
      ...FormActionSchedule
    }
  }
}
    ${FormActionFragmentDoc}
${FormActionScheduleFragmentDoc}`;

/**
 * __useFormActionsForRecordQuery__
 *
 * To run a query within a React component, call `useFormActionsForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionsForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionsForRecordQuery({
 *   variables: {
 *      record: // value for 'record'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useFormActionsForRecordQuery(baseOptions: Apollo.QueryHookOptions<FormActionsForRecordQuery, FormActionsForRecordQueryVariables> & ({ variables: FormActionsForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>(FormActionsForRecordDocument, options);
      }
export function useFormActionsForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>(FormActionsForRecordDocument, options);
        }
export function useFormActionsForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>(FormActionsForRecordDocument, options);
        }
export type FormActionsForRecordQueryHookResult = ReturnType<typeof useFormActionsForRecordQuery>;
export type FormActionsForRecordLazyQueryHookResult = ReturnType<typeof useFormActionsForRecordLazyQuery>;
export type FormActionsForRecordSuspenseQueryHookResult = ReturnType<typeof useFormActionsForRecordSuspenseQuery>;
export type FormActionsForRecordQueryResult = Apollo.QueryResult<FormActionsForRecordQuery, FormActionsForRecordQueryVariables>;