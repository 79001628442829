import React from 'react'
import gql from "graphql-tag"
import { Link } from 'react-router-dom'
import c from 'classnames'
import { QueryResult } from '@apollo/client'

import Placeholder from '@/components/common/placeholder'
import buttonStyle from '@sass/common/button.module.sass'
import iconStyle from '@sass/common/icon.module.sass'
import { userManagementAddUrl } from '@/utils/url'
import style from './index.module.sass'
import UserItem from './user-item'
import { UserManagementListQuery } from './__gen'


gql`
	query userManagementList {
		user_List(
			limit: 200,
			isSelf: false,
			offset: 0,
			orderBy: [
				{field: firstName, modifiers: [CASE_INSENSITIVE]},
				{field: lastName, modifiers: [CASE_INSENSITIVE]}
			],
		) {
			objects {
				id
				firstName
				lastName
				email
				topLevelManager
			}
			pageInfo {
				hasNextPage
				total
			}
		}
	}
`

type Q = QueryResult<UserManagementListQuery>

export default ({ data, loading }: Pick<Q, 'data' | 'loading'>) => {

	return (
		<div className={style.containerOuter}>
			<div className={style.container}>
				<div className={style.title}>User Management</div>
				<Link
					to={userManagementAddUrl()}
					className={style.addLink}
				>
					<button className={c(buttonStyle.primarySolid, iconStyle.add)}>
						Invite User
					</button>
				</Link>
				{ loading
					? (
						<>
							{[...Array(10).keys()].map((i) => (
								<Placeholder key={i} style={{marginTop: 0, }} />
							))}
						</>
					)
					: data && data.user_List && data.user_List.objects.map(u => (
						<UserItem key={u.id} user={u} />
					))
				}
			</div>
		</div>
	)
}
