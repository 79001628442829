import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { ExternalLinkFragmentDoc } from './external-link';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicFormsForRecordQueryVariables = Types.Exact<{
  record: Types.Scalars['ID']['input'];
  form: Types.Scalars['ID']['input'];
}>;


export type PublicFormsForRecordQuery = { __typename: 'PharosQuery', publicForm_List: { __typename: 'PublicFormTypeListBase', objects: Array<{ __typename: 'PublicFormType', id: string, active: boolean, code: string }> } | null };

export type CreatePublicFormMutationVariables = Types.Exact<{
  record: Types.Scalars['ID']['input'];
  form: Types.Scalars['ID']['input'];
}>;


export type CreatePublicFormMutation = { __typename: 'PharosMutations', publicForm_Update: { __typename: 'public_formPayload', object: { __typename: 'PublicFormType', id: string, active: boolean, code: string } | null } | null };


export const PublicFormsForRecordDocument = gql`
    query publicFormsForRecord($record: ID!, $form: ID!) {
  publicForm_List(record: $record, form: $form) {
    objects {
      ...ExternalLink
    }
  }
}
    ${ExternalLinkFragmentDoc}`;

/**
 * __usePublicFormsForRecordQuery__
 *
 * To run a query within a React component, call `usePublicFormsForRecordQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicFormsForRecordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicFormsForRecordQuery({
 *   variables: {
 *      record: // value for 'record'
 *      form: // value for 'form'
 *   },
 * });
 */
export function usePublicFormsForRecordQuery(baseOptions: Apollo.QueryHookOptions<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables> & ({ variables: PublicFormsForRecordQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>(PublicFormsForRecordDocument, options);
      }
export function usePublicFormsForRecordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>(PublicFormsForRecordDocument, options);
        }
export function usePublicFormsForRecordSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>(PublicFormsForRecordDocument, options);
        }
export type PublicFormsForRecordQueryHookResult = ReturnType<typeof usePublicFormsForRecordQuery>;
export type PublicFormsForRecordLazyQueryHookResult = ReturnType<typeof usePublicFormsForRecordLazyQuery>;
export type PublicFormsForRecordSuspenseQueryHookResult = ReturnType<typeof usePublicFormsForRecordSuspenseQuery>;
export type PublicFormsForRecordQueryResult = Apollo.QueryResult<PublicFormsForRecordQuery, PublicFormsForRecordQueryVariables>;
export const CreatePublicFormDocument = gql`
    mutation createPublicForm($record: ID!, $form: ID!) {
  publicForm_Update(input: {record: $record, form: $form}) {
    object {
      ...ExternalLink
    }
  }
}
    ${ExternalLinkFragmentDoc}`;
export type CreatePublicFormMutationFn = Apollo.MutationFunction<CreatePublicFormMutation, CreatePublicFormMutationVariables>;

/**
 * __useCreatePublicFormMutation__
 *
 * To run a mutation, you first call `useCreatePublicFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePublicFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPublicFormMutation, { data, loading, error }] = useCreatePublicFormMutation({
 *   variables: {
 *      record: // value for 'record'
 *      form: // value for 'form'
 *   },
 * });
 */
export function useCreatePublicFormMutation(baseOptions?: Apollo.MutationHookOptions<CreatePublicFormMutation, CreatePublicFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePublicFormMutation, CreatePublicFormMutationVariables>(CreatePublicFormDocument, options);
      }
export type CreatePublicFormMutationHookResult = ReturnType<typeof useCreatePublicFormMutation>;
export type CreatePublicFormMutationResult = Apollo.MutationResult<CreatePublicFormMutation>;
export type CreatePublicFormMutationOptions = Apollo.BaseMutationOptions<CreatePublicFormMutation, CreatePublicFormMutationVariables>;