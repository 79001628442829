import React, {useCallback} from 'react'
import cx from 'classnames'
import { Route } from 'react-router-dom'
import gql from 'graphql-tag'

import SimpleIconButton from '@/components/common/simple-icon-button'
import { useGlobalContext } from '@/components/context'
import { formFieldNotificationUrl } from '@/utils/url'
import iconStyle from '@sass/common/icon.module.sass'
import FieldNotificationModal from './modal'
import { ModalProps, NotificationQueryContext } from './common'
import styles from './index.module.sass'
import { useFieldNotificationsForUserQuery } from './__gen'


gql`
	query fieldNotificationsForUser($fieldId: String!, $fieldType: String!, $userId: ID!) {
		fieldNotification_List(
			fieldId: $fieldId,
			fieldContentType: $fieldType,
			relevantToUser: $userId,
		) {
			objects {
				id
				delta
				sendToOrganisation
				isActive
				fieldNotificationUsers {
					id
					isActive
					user {
						id
						firstName
						lastName
					}
				}
			}
		}
	}
`

const FieldNotification = (props: ModalProps) => {
	const globalContext = useGlobalContext()
	const variables = { fieldId: props.fieldId.toString(), fieldType: props.fieldType, userId: globalContext.user.id }
	const queryProps = useFieldNotificationsForUserQuery({ variables })
	const count = queryProps.data?.fieldNotification_List?.objects?.filter(n => n.isActive).length || 0

	// Without memoization AND useContext, modal will flicker when data is updated
	const renderComponent = useCallback(
		() => <FieldNotificationModal {...props} />,
		[props.fieldId, props.recordId]
	)
	return (
		<NotificationQueryContext.Provider value={queryProps}>
			<div className={cx(styles.iconContainer, {[styles.iconContainerActive]: count > 0})}>
				<SimpleIconButton
					to={formFieldNotificationUrl({...globalContext, ...props})}
					iconClass={count ? cx(iconStyle.bellSolid, styles.iconContainerActive) : iconStyle.bell}
					title={count ? `Notifications enabled` : 'Click to configure notifications'}
					small
					tooltipDirection="right"
				/>
			</div>
			<Route
				path={formFieldNotificationUrl({...globalContext, ...props})}
				component={renderComponent}
			/>
		</NotificationQueryContext.Provider>
	)
}

export default FieldNotification