import React from 'react'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router-dom'
import "mapbox-gl/dist/mapbox-gl.css"

import { ANALYTICS_LINK_FRAGMENT } from '@/fragments'
import { useGlobalContext } from '@/components/context'
import { LoadingBar } from '@/components/common/loading'
import style from './index.module.sass'
import { MapboxMap } from './map'
import { AnswerTextAddressesQuery, useAnswerTextAddressesQuery } from './__gen'

type AnswerText = NonNullable<AnswerTextAddressesQuery["answerText_List"]>["objects"][number]


gql`
	query answerTextAddresses(
		$moduleId: ID!,
		$teamId: ID,
	) {
		answerText_List(
			module: $moduleId,
			team: $teamId,
			deactivated_Isnull: true,
			objectActive: true,
			isAddressWithContent: true,
			orderBy: [
				{field: content, modifiers: [CASE_INSENSITIVE]},
			]
		) {
			objects {
				id
				content
				contentObjectName
				field {
					id
					title
				}
				tableField {
					id
					title
				}
				addressGeolocation {
					id
					lng
					lat
				}
				link {
					...MetricFormLinkEssentials
				}
			}
		}
	}
	${ANALYTICS_LINK_FRAGMENT}
`

type Props = RouteComponentProps

const AnswerMap = ({ }: Props) => {
	const globalContext = useGlobalContext()

	const { data, loading } = useAnswerTextAddressesQuery({
		variables: {
			moduleId: globalContext.currentModule.id,
			teamId: globalContext.currentTeam && globalContext.currentTeam.id,
		},
	})

	const answerMap: {[id: string]: AnswerText} = {}
	if (data?.answerText_List?.objects) {
		data?.answerText_List?.objects.forEach(a => {
			answerMap[a.id] = a
		})
	}

	return (
		<div className={style.container}>
			{loading && <LoadingBar small />}
			{data?.answerText_List?.objects &&
				<MapboxMap answers={answerMap} />
			}
		</div>
	)
}

export default AnswerMap