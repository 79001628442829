import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicSchemasQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type PublicSchemasQuery = { __typename: 'PharosQuery', schemaPublic_List: { __typename: 'PublicSuiteTypeListBase', objects: Array<{ __typename: 'PublicSuiteType', id: string, name: string, public: boolean, signupSubdomain: string, moduleSetItems: Array<{ __typename: 'PublicSuiteModuleType', module: { __typename: 'PublicModuleType', id: string, name: string } }> }> } | null };


export const PublicSchemasDocument = gql`
    query publicSchemas {
  schemaPublic_List(orderBy: [{field: name}]) {
    objects {
      id
      name
      public
      signupSubdomain
      moduleSetItems {
        module {
          id
          name
        }
      }
    }
  }
}
    `;

/**
 * __usePublicSchemasQuery__
 *
 * To run a query within a React component, call `usePublicSchemasQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicSchemasQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicSchemasQuery({
 *   variables: {
 *   },
 * });
 */
export function usePublicSchemasQuery(baseOptions?: Apollo.QueryHookOptions<PublicSchemasQuery, PublicSchemasQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicSchemasQuery, PublicSchemasQueryVariables>(PublicSchemasDocument, options);
      }
export function usePublicSchemasLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicSchemasQuery, PublicSchemasQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicSchemasQuery, PublicSchemasQueryVariables>(PublicSchemasDocument, options);
        }
export function usePublicSchemasSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PublicSchemasQuery, PublicSchemasQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicSchemasQuery, PublicSchemasQueryVariables>(PublicSchemasDocument, options);
        }
export type PublicSchemasQueryHookResult = ReturnType<typeof usePublicSchemasQuery>;
export type PublicSchemasLazyQueryHookResult = ReturnType<typeof usePublicSchemasLazyQuery>;
export type PublicSchemasSuspenseQueryHookResult = ReturnType<typeof usePublicSchemasSuspenseQuery>;
export type PublicSchemasQueryResult = Apollo.QueryResult<PublicSchemasQuery, PublicSchemasQueryVariables>;