import { createAsyncThunk } from "@reduxjs/toolkit"
import { apolloClient } from "@/state/apollo-client"
import { AppDispatch, RootState } from "@/state/redux-store"
import { showNotification } from "@/state/user-interface"
import { getErrorType } from "@/utils/functions"
import { QUERY_MAIN_FORM_AUTH } from "./get-main-form-auth"
import { QUERY_MAIN_FORM_PUBLIC } from "./get-main-form-public"
import { TOGGLE_HIDDEN_FIELD } from "./toggle-hidden-field"
import { RequestFailures } from "@/utils/functions"
import { FormType, InitialAnswerType, RecordType } from "./types"
import { waitForSave } from "@/state/answer/utils"
import { MainFormAuthQuery, MainFormAuthQueryVariables } from "./get-main-form-auth/__gen"
import { MainFormPublicQuery, MainFormPublicQueryVariables } from "./get-main-form-public/__gen"
import { ToggleHiddenFieldMutation, ToggleHiddenFieldMutationVariables } from "./toggle-hidden-field/__gen"

export type GetFormParams = {recordId: string, formId: string} | {publicFormCode: string}


export const getForm = (params: GetFormParams) => async (dispatch: AppDispatch, getState: () => RootState) => {
	// If we just switched from one form to another, wait for any answers to save before flushing state
	await waitForSave(getState)
	
	if ('publicFormCode' in params) {
		await dispatch(getFormPublic(params))
	} else {
		await dispatch(getFormAuth(params))
	}
}

export const getFormAuth = createAsyncThunk<
{
	form: FormType,
	record: RecordType,
	initialAnswers: InitialAnswerType[],
},
MainFormAuthQueryVariables,
{state: RootState}
>(
	'mainForm/getAuth',
	async (params, {dispatch, rejectWithValue, getState}) => {
		const {data, error, errors} = await apolloClient.query<MainFormAuthQuery, MainFormAuthQueryVariables>({
			query: QUERY_MAIN_FORM_AUTH,
			variables: params,
			fetchPolicy: 'no-cache',
		})
		const errorReason = getErrorType(error, errors)
		if (errorReason) {
			return rejectWithValue(errorReason)
		}
		if (!data.form || !data.record || !data.initialAnswer_List?.objects) {
			return rejectWithValue(RequestFailures.UNKNOWN_ERROR)
		}
		return {
			form: data.form,
			record: data.record,
			initialAnswers: data.initialAnswer_List.objects,
		}
	}
)

export const getFormPublic = createAsyncThunk(
	'mainForm/getPublic',
	async (params: MainFormPublicQueryVariables, {dispatch, rejectWithValue}) => {
		const resp = await apolloClient.query<MainFormPublicQuery, MainFormPublicQueryVariables>({
			query: QUERY_MAIN_FORM_PUBLIC,
			variables: params,
			context: { 
				headers: { 
				"X-Public-Form-Code": params.publicFormCode
				} 
			},
			fetchPolicy: 'no-cache',
		})
		const error = getErrorType(resp.error, resp.errors)

		if (error) {
			return rejectWithValue(error)
		}
		if (
			!resp.data.publicFormPub
			|| !resp.data.initialAnswerPub_List?.objects
		) {
			return rejectWithValue(RequestFailures.UNKNOWN_ERROR)
		}
		return {
			...resp.data.publicFormPub,
			initialAnswers: resp.data.initialAnswerPub_List.objects,
		}
	}
)


export const setFieldToHidden = createAsyncThunk<
NonNullable<NonNullable<ToggleHiddenFieldMutation["hiddenField_Update"]>["object"]>,
{fieldId: string, hiddenFieldId: string | null, teamId: string, recordId: string},
{state: RootState}
>(
	'mainForm/setHiddenField',
	async ({fieldId, hiddenFieldId, teamId, recordId}, {dispatch, rejectWithValue}) => {
		const {data, errors} = await apolloClient.query<ToggleHiddenFieldMutation, ToggleHiddenFieldMutationVariables>({
			query: TOGGLE_HIDDEN_FIELD,
			variables: hiddenFieldId
				? {id: hiddenFieldId, fieldId, teamId, recordId, deleted: true}
				: {fieldId, teamId, recordId, deleted: false},
			fetchPolicy: 'no-cache',
		})
		if (errors || !data.hiddenField_Update?.object) {
			const action = showNotification({
				content: `Error changing field`,
				type: 'error',
			})
			// @ts-ignore
			dispatch(action)
			throw Error('Error saving hidden field')
		}
		const obj = data.hiddenField_Update.object
		return obj
	}
)
