import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicRecordCreateLinkQueryVariables = Types.Exact<{
  teamCode: Types.Scalars['String']['input'];
  linkCode: Types.Scalars['String']['input'];
}>;


export type PublicRecordCreateLinkQuery = { __typename: 'PharosQuery', publicRecordCreateLink: { __typename: 'PublicRecordCreateLinkType', title: string, htmlMessage: string, recordNamePlaceholder: string | null, choices: Array<{ __typename: 'PublicRecordCreateChoiceType', id: string, positionName: string, template: { __typename: 'TemplateType', namePlaceholder: string | null }, form: { __typename: 'FormType', id: string } }> } | null };

export type CreateRecordMutationVariables = Types.Exact<{
  teamCode: Types.Scalars['String']['input'];
  linkCode: Types.Scalars['String']['input'];
  recordName: Types.Scalars['String']['input'];
  choice: Types.Scalars['ID']['input'];
}>;


export type CreateRecordMutation = { __typename: 'PharosMutations', publicRecordCreate: { __typename: 'PublicRecordCreateMutation', publicFormCode: string | null, success: boolean | null } | null };


export const PublicRecordCreateLinkDocument = gql`
    query publicRecordCreateLink($teamCode: String!, $linkCode: String!) {
  publicRecordCreateLink(teamCode: $teamCode, linkCode: $linkCode) {
    title
    htmlMessage
    recordNamePlaceholder
    choices {
      id
      positionName
      template {
        namePlaceholder
      }
      form {
        id
      }
    }
  }
}
    `;

/**
 * __usePublicRecordCreateLinkQuery__
 *
 * To run a query within a React component, call `usePublicRecordCreateLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicRecordCreateLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicRecordCreateLinkQuery({
 *   variables: {
 *      teamCode: // value for 'teamCode'
 *      linkCode: // value for 'linkCode'
 *   },
 * });
 */
export function usePublicRecordCreateLinkQuery(baseOptions: Apollo.QueryHookOptions<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables> & ({ variables: PublicRecordCreateLinkQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>(PublicRecordCreateLinkDocument, options);
      }
export function usePublicRecordCreateLinkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>(PublicRecordCreateLinkDocument, options);
        }
export function usePublicRecordCreateLinkSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>(PublicRecordCreateLinkDocument, options);
        }
export type PublicRecordCreateLinkQueryHookResult = ReturnType<typeof usePublicRecordCreateLinkQuery>;
export type PublicRecordCreateLinkLazyQueryHookResult = ReturnType<typeof usePublicRecordCreateLinkLazyQuery>;
export type PublicRecordCreateLinkSuspenseQueryHookResult = ReturnType<typeof usePublicRecordCreateLinkSuspenseQuery>;
export type PublicRecordCreateLinkQueryResult = Apollo.QueryResult<PublicRecordCreateLinkQuery, PublicRecordCreateLinkQueryVariables>;
export const CreateRecordDocument = gql`
    mutation createRecord($teamCode: String!, $linkCode: String!, $recordName: String!, $choice: ID!) {
  publicRecordCreate(
    teamCode: $teamCode
    linkCode: $linkCode
    recordName: $recordName
    choice: $choice
  ) {
    publicFormCode
    success
  }
}
    `;
export type CreateRecordMutationFn = Apollo.MutationFunction<CreateRecordMutation, CreateRecordMutationVariables>;

/**
 * __useCreateRecordMutation__
 *
 * To run a mutation, you first call `useCreateRecordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRecordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRecordMutation, { data, loading, error }] = useCreateRecordMutation({
 *   variables: {
 *      teamCode: // value for 'teamCode'
 *      linkCode: // value for 'linkCode'
 *      recordName: // value for 'recordName'
 *      choice: // value for 'choice'
 *   },
 * });
 */
export function useCreateRecordMutation(baseOptions?: Apollo.MutationHookOptions<CreateRecordMutation, CreateRecordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRecordMutation, CreateRecordMutationVariables>(CreateRecordDocument, options);
      }
export type CreateRecordMutationHookResult = ReturnType<typeof useCreateRecordMutation>;
export type CreateRecordMutationResult = Apollo.MutationResult<CreateRecordMutation>;
export type CreateRecordMutationOptions = Apollo.BaseMutationOptions<CreateRecordMutation, CreateRecordMutationVariables>;