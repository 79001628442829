import React from 'react'
import { useSelector } from 'react-redux'

import { ANSWER_LOOKUP } from './answer-types/lookup'
import tableStyle from './row-table.module.sass'

import { Cell } from './cell'
import { selectAnswers, selectAnswersForField } from '@/state/answer/selectors'
import { RootState } from '@/state/redux-store'
import { useFormContext } from '@/state/main-form/reducer'
import { FieldTypeEnum } from '@/state/main-form/types'
import { useAppSelector } from '@/utils/hooks'


type Props = {
	fieldId: string
	rank: number
	tableFieldShowColumns: number
	fieldIsDisabled: boolean
}

export const RowAnswer = ({ fieldId, rank, tableFieldShowColumns, fieldIsDisabled }: Props) => {
	const formContext = useFormContext()
	const field = formContext.fields[fieldId]
	const tableFields = field.type == FieldTypeEnum.Table ? formContext.fields[fieldId].tableFields : [null]

	const answers = useAppSelector(
		// NOTE: Don't filter out deactivated here, as they will be visible during the animation
		//       (and will cause lookup issues)
		(state: RootState) => (
			selectAnswersForField(state, fieldId)
			.filter(a => a.rank == rank)
			// Sort deactivated answers LAST, we only need it for animation if we don't have active answer
			.sort((a, b) => Number(b.deactivated===null)-Number(a.deactivated===null))
		),
		(a, b) => a.map(a => a.id).join('-') == b.map(a => a.id).join('-')
	)

	return (
		<div className={tableStyle.answerTable}>
			<div className={tableStyle.answerTableRow}>
				{tableFields.map((tableField, i) => (
					(i + 1 > tableFieldShowColumns)
						? null
						: (
							<div
								key={tableField?.id || '0'}
								className={tableStyle.cell}
								style={tableField
									? ANSWER_LOOKUP[tableField.type].size
									: {width: '100%', justifyContent: 'flex-start'}
								}
							>

								<Cell
									id={answers.find(a => a.tableFieldId == (tableField?.id || null))!.id}
									tableFieldId={tableField?.id || null}
									fieldId={fieldId}
									fieldIsDisabled={fieldIsDisabled}
								/>
							</div>
						)
				))}
			</div>
		</div>
	)
}