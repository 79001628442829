import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type PublicFormListQueryVariables = Types.Exact<{
  module?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  team?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  offset?: Types.InputMaybe<Types.Scalars['Int']['input']>;
}>;


export type PublicFormListQuery = { __typename: 'PharosQuery', publicForm_List: { __typename: 'PublicFormTypeListBase', objects: Array<{ __typename: 'PublicFormType', id: string, created: string, record: { __typename: 'RecordType', id: string, name: string }, createdBy: { __typename: 'UserType', id: string, firstName: string } | null, form: { __typename: 'FormType', id: string, title: string } }>, pageInfo: { __typename: 'PageInfo', hasNextPage: boolean | null, total: number | null } } | null };


export const PublicFormListDocument = gql`
    query publicFormList($module: ID, $team: ID, $offset: Int) {
  publicForm_List(
    module: $module
    team: $team
    limit: 7
    offset: $offset
    orderBy: [{field: created, direction: DESC}]
  ) {
    objects {
      id
      record {
        id
        name
      }
      createdBy {
        id
        firstName
      }
      form {
        id
        title
      }
      created
    }
    pageInfo {
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __usePublicFormListQuery__
 *
 * To run a query within a React component, call `usePublicFormListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicFormListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicFormListQuery({
 *   variables: {
 *      module: // value for 'module'
 *      team: // value for 'team'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function usePublicFormListQuery(baseOptions?: Apollo.QueryHookOptions<PublicFormListQuery, PublicFormListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PublicFormListQuery, PublicFormListQueryVariables>(PublicFormListDocument, options);
      }
export function usePublicFormListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PublicFormListQuery, PublicFormListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PublicFormListQuery, PublicFormListQueryVariables>(PublicFormListDocument, options);
        }
export function usePublicFormListSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<PublicFormListQuery, PublicFormListQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PublicFormListQuery, PublicFormListQueryVariables>(PublicFormListDocument, options);
        }
export type PublicFormListQueryHookResult = ReturnType<typeof usePublicFormListQuery>;
export type PublicFormListLazyQueryHookResult = ReturnType<typeof usePublicFormListLazyQuery>;
export type PublicFormListSuspenseQueryHookResult = ReturnType<typeof usePublicFormListSuspenseQuery>;
export type PublicFormListQueryResult = Apollo.QueryResult<PublicFormListQuery, PublicFormListQueryVariables>;