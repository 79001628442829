import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type FormActionContactQueryVariables = Types.Exact<{
  recordId: Types.Scalars['ID']['input'];
}>;


export type FormActionContactQuery = { __typename: 'PharosQuery', formActionContact_List: Array<{ __typename: 'FormActionContact', id: string, email: string, name: string, fieldTitle: string }> | null };


export const FormActionContactDocument = gql`
    query formActionContact($recordId: ID!) {
  formActionContact_List(recordId: $recordId) {
    id
    email
    name
    fieldTitle
  }
}
    `;

/**
 * __useFormActionContactQuery__
 *
 * To run a query within a React component, call `useFormActionContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormActionContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormActionContactQuery({
 *   variables: {
 *      recordId: // value for 'recordId'
 *   },
 * });
 */
export function useFormActionContactQuery(baseOptions: Apollo.QueryHookOptions<FormActionContactQuery, FormActionContactQueryVariables> & ({ variables: FormActionContactQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormActionContactQuery, FormActionContactQueryVariables>(FormActionContactDocument, options);
      }
export function useFormActionContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormActionContactQuery, FormActionContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormActionContactQuery, FormActionContactQueryVariables>(FormActionContactDocument, options);
        }
export function useFormActionContactSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FormActionContactQuery, FormActionContactQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FormActionContactQuery, FormActionContactQueryVariables>(FormActionContactDocument, options);
        }
export type FormActionContactQueryHookResult = ReturnType<typeof useFormActionContactQuery>;
export type FormActionContactLazyQueryHookResult = ReturnType<typeof useFormActionContactLazyQuery>;
export type FormActionContactSuspenseQueryHookResult = ReturnType<typeof useFormActionContactSuspenseQuery>;
export type FormActionContactQueryResult = Apollo.QueryResult<FormActionContactQuery, FormActionContactQueryVariables>;