import React from 'react'

import Metrics from './metrics'
import style from './suite-admin-dashboard.module.sass'
import { useGlobalContext } from '@/components/context'
import { SinglePanel } from '@/components/common/panel'
import { useTitle } from '@/utils/hooks'


export const SuiteAdminDashboard = () => {
	const globalContext = useGlobalContext()
	const title = globalContext.organisation.suite.suiteAdminDashboardTitle || 'Dashboard'
	useTitle('Overview Dashboard')
	return (
		<SinglePanel type="wide"> 
			<div className={style.title}>{title}</div>
			<Metrics isSuiteAdminDashboard />
		</SinglePanel>
	)
}
