import React, { useState, useMemo } from 'react'
import { Doughnut as DoughnutChart } from 'react-chartjs-2'
import { Chart as ChartJS, ArcElement, Tooltip, LinearScale } from 'chart.js'
import classNames from 'classnames'

import { OptionalLink } from '@/components/common/optional-link'
import { formUrl } from '@/utils/url'
import { useGlobalContext } from '@/components/context'
import { FormLink, NoData, ListItemTooltip } from '../common'
import style from './index.module.sass'

ChartJS.register(ArcElement, Tooltip, LinearScale) // Tree shake chartjs

type Props = {
	isSuiteAdminDashboard?: boolean
	useFormTitleInList?: boolean
	values: {
		name: string
		formLinks: FormLink[]
		color: string
	}[]
}

const PieMetric = ({values, isSuiteAdminDashboard, useFormTitleInList}: Props) => {
	const globalContext = useGlobalContext()
	const [showDetails, setShowDetails] = useState<number | null>(null)
	const labels = values.map(series => series.name)
	const processData = useMemo(() => ({
		data: values.map(series => series.formLinks.length),
		backgroundColor: values.map(v => v.color),
		details: values.map(series => series.formLinks),
	}), values)

	if (processData.data.reduce((a, b) => a + b, 0) === 0) {
		return <NoData />
	}

	return (
		<div className={style.container}>
			<div className={style.pie}>
				<DoughnutChart 
					data={{datasets:[processData], labels: labels}}
					options={{
						// legend: false,
						// circumference: 200,
						// legend: { display: false },
						plugins: {
							tooltip: {
								callbacks: {
									label: (yDatapoint) => ' ' + yDatapoint.formattedValue,
								}
							}
						},
						cutout: '75%',
					}}
				/>
			</div>
			<div className={style.pieLabels}>
				{ processData.details.map((set, i) => {
					const selected = showDetails===i
					const classes = classNames(style.pieLabel, {[style.selected]: selected}) 
					return (
						<div 
							key={labels[i]} 
							className={classes} 
							onClick={() => setShowDetails(i === showDetails ? null : i)} 
							style={{backgroundColor: selected ? processData.backgroundColor[i] : "rgba(0,0,0,0)"}}
						>
							<div className={style.labelDot} style={{backgroundColor:processData.backgroundColor[i]}}></div>
							<div className={style.labelText}>
								{labels[i]}
							</div>
						</div>
					)
				})}
			</div>
			
			{ showDetails !== null &&
				<div className={style.pieDetailedValuesContainer}>
					<div className={style.pieDetailedValues}>
						{ processData.details[showDetails].map(formLink => {
							const to = isSuiteAdminDashboard
								? null
								: formUrl({recordId: formLink.recordId, formId: formLink.formId, highlightFieldId: formLink.fieldId, ...globalContext})
							
							return (
								<ListItemTooltip
									key={formLink.recordId + "-" + formLink.formTitle}
									formLink={formLink}
									isSuiteAdminDashboard={isSuiteAdminDashboard}
								>
									<OptionalLink className={style.formLinkRow} to={to}>
										<div className={style.topContent}>{formLink.recordName}</div>
										<div className={style.bottomContent}>
											{useFormTitleInList ? formLink.formTitle : formLink.fieldTitle}
										</div>
									</OptionalLink>
								</ListItemTooltip>
							)
						})}
						{ processData.data[showDetails] === 0 && <NoData />}
					</div>
				</div>
			}
		</div>
	)
}

export default PieMetric