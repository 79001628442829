import React from 'react'
import style from './index.module.sass'
import { Link } from 'react-router-dom'
import { formUrl } from '@/utils/url'
import { useGlobalContext } from '@/components/context'
import { ActionRecordFormsQuery } from '../../__gen'


type Props = {
	record: NonNullable<ActionRecordFormsQuery["record_List"]>["objects"][number]
	form: NonNullable<ActionRecordFormsQuery["template_List"]>["objects"][number]["formSet"]["forms"][number]
}
export const RecordTitle = ({record, form}: Props) => {
	const globalContext = useGlobalContext()
	return (
		<Link
			className={style.container}
			to={formUrl({...globalContext, formId: form.id, recordId: record.id})}
		>
			<div className={style.line}><b>{record.name}</b></div>
			<div className={style.line}>{form.title}</div>
		</Link>
	)
}