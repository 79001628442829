import React from 'react'
import gql from 'graphql-tag'

import Placeholder from '@/components/common/placeholder'
import { useGlobalContext } from '@/components/context'
import MetricContainer from './types/container'
import styles from './index.module.sass'
import FieldGroupMetric from './wrappers/field-group-metric'
import FormStatusMetric from './wrappers/form-status-metric'
import SpecificMetric from './wrappers/specific-metric'
import FieldAggregationMetric from './wrappers/field-aggregation-metric'
import FieldDateRangeMetric from './wrappers/field-date-range-metric'
import NotificationsMetric from './wrappers/notifications-metric'
import { useDashboardMetricsQuery } from './__gen'


gql`
	query DashboardMetrics($moduleId: ID, $visibleToSuiteAdmin: Boolean) {
		fieldDateRangeMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
		fieldAggregationMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
		fieldGroupMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
		specificMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
		formStatusMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
		notificationsMetric_List(limit:100, module: $moduleId, visibleToSuiteAdmin: $visibleToSuiteAdmin) {
			objects {
				id
				name
				order
			}
		}
	}
`

const Metrics = ({isSuiteAdminDashboard}: {isSuiteAdminDashboard?: boolean}) => {
	const globalContext = useGlobalContext()
	const { loading, data } = useDashboardMetricsQuery({
		variables: (
			isSuiteAdminDashboard
			? { visibleToSuiteAdmin: true }
			: { moduleId: globalContext.currentModule.id }
		)
	})

	if (loading) {
		return (
			<div className={styles.container}>
				{[...Array(6).keys()].map((i) => (
					<Placeholder key={i} style={{width: 270, height: 320, margin: 20}} />
				))}
			</div>
		)
	}
	if (!data) {
		return <div>Error loading dashboard!</div>
	}
	let components: { component: React.ReactNode, order: number, name: string}[] = []

	data.formStatusMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FormStatusMetric id={metric.id} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})
	data.fieldGroupMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldGroupMetric id={metric.id} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})
	data.fieldDateRangeMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldDateRangeMetric {...metric} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})
	data.fieldAggregationMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <FieldAggregationMetric id={metric.id} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})
	data.specificMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <SpecificMetric id={metric.id} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})
	data.notificationsMetric_List?.objects.forEach(metric => {
		components.push({
			order: metric.order,
			name: metric.name,
			component: <NotificationsMetric id={metric.id} isSuiteAdminDashboard={isSuiteAdminDashboard} />,
		})
	})

	components.sort((a, b) => a.order-b.order)

	return (
		<div className={styles.container}>
			{components.map((c, i) => (
				<MetricContainer name={c.name} key={i}>
					{c.component}
				</MetricContainer>
			))}
		</div>
	)
}

export default Metrics


