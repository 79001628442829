import { Modal, Radio } from 'antd'
import React, { useState } from 'react'

import Placeholder from '@/components/common/placeholder'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import history from '@/utils/history'
import { formUrl } from '@/utils/url'

import { FormActionsForRecordQuery } from '../common/__gen/query'

import { useEscalationFetch } from './common/escalations/hooks'
import { OnceOff } from './once-off'
import { State } from './once-off/state'
import { Recurring } from './recurring'

type Props = {
	formActions: State
	formActionSchedule:
		| NonNullable<FormActionsForRecordQuery['formActionSchedule_List']>['objects'][number]
		| null
}

type ActionType = 'once-off' | 'recurring'

export const EditFormActionModal = ({ formActions, formActionSchedule }: Props) => {
	const [type, setType] = useState<ActionType>(formActionSchedule ? 'recurring' : 'once-off')
	const { error, escalations, loading } = useEscalationFetch()
	const globalContext = useGlobalContext()
	const formContext = useFormContext()
	const close = () =>
		history.push(formUrl({ recordId: formContext.record.id, formId: formContext.form.id, ...globalContext }))

	return (
		<Modal title="Actions" open maskClosable onCancel={close} footer={null} width={900}>
			{loading && <Placeholder />}
			{error && <div>Error loading escalations</div>}
			{!loading && escalations != null && (
				<>
					<>
						<Radio.Group
							value={type}
							onChange={() => setType(type === 'once-off' ? 'recurring' : 'once-off')}
						>
							<Radio.Button value="once-off">Once off</Radio.Button>
							<Radio.Button value="recurring">Recurring</Radio.Button>
						</Radio.Group>
						<br />
						<br />
					</>

					{type === 'once-off' && (
						<OnceOff formActions={formActions} escalations={escalations} close={close} />
					)}
					{type === 'recurring' && (
						<Recurring
							formActionSchedule={formActionSchedule}
							formAction={formActions.find((a) => a.isScheduled) || null}
							escalations={escalations}
							close={close}
						/>
					)}
				</>
			)}
		</Modal>
	)
}
