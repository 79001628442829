import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type WebauthnAuthenticateMutationVariables = Types.Exact<{
  credentialString: Types.Scalars['String']['input'];
}>;


export type WebauthnAuthenticateMutation = { __typename: 'PharosMutations', webauthnCredentials_Authenticate: { __typename: 'WebauthnAuthenticate', success: boolean | null } | null };


export const WebauthnAuthenticateDocument = gql`
    mutation WebauthnAuthenticate($credentialString: String!) {
  webauthnCredentials_Authenticate(credentialString: $credentialString) {
    success
  }
}
    `;
export type WebauthnAuthenticateMutationFn = Apollo.MutationFunction<WebauthnAuthenticateMutation, WebauthnAuthenticateMutationVariables>;

/**
 * __useWebauthnAuthenticateMutation__
 *
 * To run a mutation, you first call `useWebauthnAuthenticateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useWebauthnAuthenticateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [webauthnAuthenticateMutation, { data, loading, error }] = useWebauthnAuthenticateMutation({
 *   variables: {
 *      credentialString: // value for 'credentialString'
 *   },
 * });
 */
export function useWebauthnAuthenticateMutation(baseOptions?: Apollo.MutationHookOptions<WebauthnAuthenticateMutation, WebauthnAuthenticateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<WebauthnAuthenticateMutation, WebauthnAuthenticateMutationVariables>(WebauthnAuthenticateDocument, options);
      }
export type WebauthnAuthenticateMutationHookResult = ReturnType<typeof useWebauthnAuthenticateMutation>;
export type WebauthnAuthenticateMutationResult = Apollo.MutationResult<WebauthnAuthenticateMutation>;
export type WebauthnAuthenticateMutationOptions = Apollo.BaseMutationOptions<WebauthnAuthenticateMutation, WebauthnAuthenticateMutationVariables>;