import React, { Component } from 'react'

import { TEAM_CONTENT_TYPE } from '@/utils/constants'
import { wrapWithContextPanel, wrapWithContext } from '@/components/context'

import Files from '../../../../common/file/files'


const Inner = (props) => {
	const team = props.currentTeam
		? props.currentTeam
		: props.currentModule.teams.find(t => t.id == props.teamId)
	if(!team) return (
		<h5>Please select a {props.currentModule.teamWord} on the left to view files</h5>
	)
	return (
		<>
			<h3>{props.currentModule.teamWord} Files: {team.name}</h3>
			<Files 
				contentType={TEAM_CONTENT_TYPE}
				objectId={team.id}

				linkMode={props.linkMode}
				matchFolders={props.matchFolders}
				matchFiles={props.matchFiles}
				removeFormLink={props.removeFormLink}
				createFormLink={props.createFormLink}
				readOnly={props.readOnly}
			/>
			{/*<PublicUrl url={`/public_team_files/${props.team.id}`}/>*/}
		</>
	)
}

export const FilesTeam = wrapWithContextPanel(Inner)