import * as Types from '@/__gen/types';

import { gql } from '@apollo/client';
import { MetricFormLinkEssentialsFragmentDoc } from '../../../../../../../../../../../fragments/__gen/index';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SpecificMetricQueryVariables = Types.Exact<{
  id: Types.Scalars['ID']['input'];
  teamId?: Types.InputMaybe<Types.Scalars['ID']['input']>;
  isSuiteAdminDashboard?: Types.InputMaybe<Types.Scalars['Boolean']['input']>;
}>;


export type SpecificMetricQuery = { __typename: 'PharosQuery', specificMetric: { __typename: 'SpecificMetricType', id: string, name: string, result: { __typename: 'ValueResultType', value: string | null, formLink: { __typename: 'MetricFormLink', formId: string, formTitle: string, recordId: string, recordName: string, fieldId: string | null, fieldTitle: string | null, organisationName: string, teamName: string } | null } } | null };


export const SpecificMetricDocument = gql`
    query specificMetric($id: ID!, $teamId: ID, $isSuiteAdminDashboard: Boolean) {
  specificMetric(id: $id) {
    id
    name
    result(teamId: $teamId, isSuiteAdminDashboard: $isSuiteAdminDashboard) {
      value
      formLink {
        ...MetricFormLinkEssentials
      }
    }
  }
}
    ${MetricFormLinkEssentialsFragmentDoc}`;

/**
 * __useSpecificMetricQuery__
 *
 * To run a query within a React component, call `useSpecificMetricQuery` and pass it any options that fit your needs.
 * When your component renders, `useSpecificMetricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSpecificMetricQuery({
 *   variables: {
 *      id: // value for 'id'
 *      teamId: // value for 'teamId'
 *      isSuiteAdminDashboard: // value for 'isSuiteAdminDashboard'
 *   },
 * });
 */
export function useSpecificMetricQuery(baseOptions: Apollo.QueryHookOptions<SpecificMetricQuery, SpecificMetricQueryVariables> & ({ variables: SpecificMetricQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SpecificMetricQuery, SpecificMetricQueryVariables>(SpecificMetricDocument, options);
      }
export function useSpecificMetricLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SpecificMetricQuery, SpecificMetricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SpecificMetricQuery, SpecificMetricQueryVariables>(SpecificMetricDocument, options);
        }
export function useSpecificMetricSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SpecificMetricQuery, SpecificMetricQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SpecificMetricQuery, SpecificMetricQueryVariables>(SpecificMetricDocument, options);
        }
export type SpecificMetricQueryHookResult = ReturnType<typeof useSpecificMetricQuery>;
export type SpecificMetricLazyQueryHookResult = ReturnType<typeof useSpecificMetricLazyQuery>;
export type SpecificMetricSuspenseQueryHookResult = ReturnType<typeof useSpecificMetricSuspenseQuery>;
export type SpecificMetricQueryResult = Apollo.QueryResult<SpecificMetricQuery, SpecificMetricQueryVariables>;