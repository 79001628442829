import gql from 'graphql-tag'
import React, { useReducer } from 'react'
import { Form_Action_BulkBulkInput, Origin_Format } from '@/__gen/types'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { getFormAuth } from '@/state/main-form/thunks'
import { showNotification } from '@/state/user-interface'
import { TEMP_ID_PREFIX } from '@/utils/constants'
import history from '@/utils/history'
import { useAppDispatch } from '@/utils/hooks'
import { formUrl } from '@/utils/url'
import { FormActionsForRecordDocument } from '../../common/__gen/query'
import { FORM_ACTION_FRAGMENT } from '../../common/query'
import { BottomRow } from '../common/botton-row'
import { EscalationsForActionQuery } from '../common/escalations/__gen/hooks'
import { AddEscalation } from '../common/escalations/add'
import {
	FORM_ACTION_ESCALATION_FRAGMENT,
	transformStateForSave,
	useEscalationState,
} from '../common/escalations/hooks'
import { EscalationList } from '../common/escalations/list'
import { useSaveFormActionsMutation } from './__gen'
import { Add } from './add'
import { State, reducer } from './state'
import { FormActionTable } from './table'

type Props = {
	formActions: State
	escalations: NonNullable<EscalationsForActionQuery['formActionEscalation_List']>['objects']
	close: () => void
}

gql`
	mutation saveFormActions(
		$formActions: [form_action_bulkBulkInput!]!
		$escalations: [form_action_escalation_bulkBulkInput!]!
	) {
		formAction_BulkUpdate(input: { objects: $formActions }) {
			objects {
				...FormAction
			}
			errors {
				field
				messages
			}
		}
		formActionEscalation_BulkUpdate(input: { objects: $escalations }) {
			objects {
				...formActionEscalation
			}
			errors {
				field
				messages
			}
		}
	}
	${FORM_ACTION_FRAGMENT}
	${FORM_ACTION_ESCALATION_FRAGMENT}
`

export const OnceOff = (props: Props) => {
	const { user } = useGlobalContext()
	const deepCopy = JSON.parse(JSON.stringify(props.formActions.filter((fa) => !fa.isScheduled))) as State
	const [state, dispatch] = useReducer(reducer, deepCopy)

	const { state: escalationState, dispatch: escalationDispatch } = useEscalationState({
		initialEscalations: props.escalations,
	})
	const reduxDispatch = useAppDispatch()
	const formContext = useFormContext()
	const globalContext = useGlobalContext()

	const [saveFormAction, { loading }] = useSaveFormActionsMutation({
		onCompleted: () => {
			reduxDispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
		},
	})

	const close = () =>
		history.push(formUrl({ recordId: formContext.record.id, formId: formContext.form.id, ...globalContext }))

	const save = async () => {
		let toSaveActions = state.map(
			(a) =>
				({
					...(a.id.startsWith(TEMP_ID_PREFIX) ? {} : { id: a.id }),
					priority: a.priority,
					form: formContext.form.id,
					record: formContext.record.id,
					deleted: a.deleted,
					assignedUser: a.assignedUser?.id,
					assignedAnonEmail: a.assignedAnonEmail,
					assignedAnonName: a.assignedAnonName,
					assignedAnonEmailAnswer: a.assignedAnonEmailAnswer?.id || null,
					userSuppliedContext: a.userSuppliedContext,
					requestSignature: a.requestSignature,
					originFormat: Origin_Format.Customisable,
				}) satisfies Form_Action_BulkBulkInput,
		)

		// Ensure we save deletions first to avoid the unique constraint
		toSaveActions = toSaveActions.sort((a, b) => Number(b.deleted) - Number(a.deleted))

		const toSaveEscalations = transformStateForSave(formContext, escalationState)

		const resp = await saveFormAction({
			variables: {
				formActions: toSaveActions,
				escalations: toSaveEscalations,
			},
			refetchQueries: [FormActionsForRecordDocument],
		})
		if (resp.errors) {
			resp.errors.forEach((e) => {
				if (e.message.toLowerCase().includes('unique')) {
					reduxDispatch(
						showNotification({
							type: 'neutral',
							content: `${user.firstName} ${user.lastName} is already assigned`,
						}),
					)
				}
			})
		} else {
			close()
		}
	}

	return (
		<>
			<p>
				Assign this form to a user or email. Assignees will be notified and reminded in order to complete this
				action. Reminders will stop after 1 year.
			</p>
			<FormActionTable state={state} dispatch={dispatch} />
			<br />
			<EscalationList state={escalationState} dispatch={escalationDispatch} />
			<BottomRow
				left={
					<>
						<Add dispatch={dispatch} state={state} />
						<AddEscalation dispatch={escalationDispatch} state={escalationState} />
					</>
				}
				save={save}
				close={props.close}
				loading={loading}
			/>
		</>
	)
}
