import { PlusOutlined } from '@ant-design/icons'
import gql from 'graphql-tag'
import _pick from 'lodash/pick'
import React, { useState } from 'react'

import { FormActionFormActionScheduleUnitNameChoices } from '@/__gen/types'
import { useGlobalContext } from '@/components/context'
import { useFormContext } from '@/state/main-form/reducer'
import { getFormAuth } from '@/state/main-form/thunks'
import { TEMP_ID_PREFIX } from '@/utils/constants'
import { useAppDispatch } from '@/utils/hooks'
import buttonStyle from '@sass/common/button.module.sass'

import { FormActionsForRecordQuery } from '../../common/__gen/query'
import { FORM_ACTION_SCHEDULE_FRAGMENT } from '../../common/query'
import { BottomRow } from '../common/botton-row'
import { EscalationsForActionQuery } from '../common/escalations/__gen/hooks'
import { AddEscalation } from '../common/escalations/add'
import {
	FORM_ACTION_ESCALATION_FRAGMENT,
	transformStateForSave,
	useEscalationState,
} from '../common/escalations/hooks'
import { EscalationList } from '../common/escalations/list'

import { SaveFormActionScheduleMutationVariables, useSaveFormActionScheduleMutation } from './__gen'
import { Add, AddFormState } from './add'
import { Details } from './details'

type FormActionSchedule = NonNullable<FormActionsForRecordQuery['formActionSchedule_List']>['objects'][number]

type Props = {
	formActionSchedule: FormActionSchedule | null
	formAction: { id: string; reminderLast: Date | null; reminderNext: Date | null } | null
	escalations: NonNullable<EscalationsForActionQuery['formActionEscalation_List']>['objects']
	close: () => void
}

gql`
	mutation saveFormActionSchedule(
		$formActionSchedule: form_action_scheduleInput!
		$escalations: [form_action_escalation_bulkBulkInput!]!
	) {
		formActionSchedule_Update(input: $formActionSchedule) {
			object {
				...FormActionSchedule
			}
			errors {
				field
				messages
			}
		}
		formActionEscalation_BulkUpdate(input: { objects: $escalations }) {
			objects {
				...formActionEscalation
			}
			errors {
				field
				messages
			}
		}
	}
	${FORM_ACTION_SCHEDULE_FRAGMENT}
	${FORM_ACTION_ESCALATION_FRAGMENT}
`

export const Recurring = ({ formActionSchedule, formAction, escalations, close }: Props) => {
	const { user } = useGlobalContext()
	const [showAdd, setShowAdd] = useState<boolean>(false)

	const formContext = useFormContext()
	const [state, setState] = useState<AddFormState | null>(
		formActionSchedule ?
			{
				id: formActionSchedule.id,
				assigneeType: formActionSchedule.assignedUser ? 'user' : 'anon',
				deleted: formActionSchedule.deleted,
				dueAt: new Date(formActionSchedule.dueAt),
				unitAmount: formActionSchedule.unitAmount,
				unitName: formActionSchedule.unitName as FormActionFormActionScheduleUnitNameChoices.Months,
				assignedAnonEmail: formActionSchedule.assignedAnonEmail,
				assignedAnonName: formActionSchedule.assignedAnonName,
				assignedAnonEmailAnswer: formActionSchedule.assignedAnonEmailAnswer,
				userSuppliedContext: formActionSchedule.userSuppliedContext,
				requestSignature: formActionSchedule.requestSignature,
				assignedUser: formActionSchedule.assignedUser,
			}
		:	null,
	)

	const { state: escalationState, dispatch: escalationDispatch } = useEscalationState({
		initialEscalations: escalations,
	})

	const reduxDispatch = useAppDispatch()

	const [updateFormActionSchedule, { loading }] = useSaveFormActionScheduleMutation({
		onCompleted: () => {
			reduxDispatch(getFormAuth({ formId: formContext.form.id, recordId: formContext.record.id }))
		},
	})

	const save = async () => {
		let variables: SaveFormActionScheduleMutationVariables

		if (formActionSchedule && formActionSchedule.id !== state?.id) {
			// delete existing first
			await updateFormActionSchedule({
				variables: {
					formActionSchedule: {
						id: formActionSchedule?.id,
						deleted: true,
					},
					escalations: [],
				},
			})
		}

		if (state) {
			// create new (if necessary)
			variables = {
				formActionSchedule: {
					...(state.id.startsWith(TEMP_ID_PREFIX) ? {} : { id: state.id }),
					assignedAnonEmail: state.assignedAnonEmail,
					assignedAnonName: state.assignedAnonName,
					assignedAnonEmailAnswer: state.assignedAnonEmailAnswer?.id || null,
					userSuppliedContext: state.userSuppliedContext,
					requestSignature: state.requestSignature,
					assignedUser: state.assignedUser?.id || null,
					form: formContext.form.id,
					record: formContext.record.id,
					deleted: state.deleted,
					dueAt: state.dueAt,
					unitAmount: state.unitAmount,
					unitName: state.unitName,
				},
				escalations: transformStateForSave(formContext, escalationState),
			}
			await updateFormActionSchedule({ variables })
		}

		close()
	}

	return (
		<>
			<Details state={state} formAction={formAction} />
			<EscalationList state={escalationState} dispatch={escalationDispatch} />

			<Add show={showAdd} initialState={state} stageChange={(s) => setState(s)} setShow={setShowAdd} />

			<BottomRow
				loading={loading}
				save={save}
				close={close}
				left={
					<>
						{!state || state.deleted ?
							<button className={buttonStyle.primarySolid} onClick={() => setShowAdd(true)}>
								<PlusOutlined /> Schedule
							</button>
						:	<button className={buttonStyle.greySolid} onClick={() => setState(null)}>
								Remove Schedule
							</button>
						}
						<AddEscalation dispatch={escalationDispatch} state={escalationState} />
					</>
				}
			/>
		</>
	)
}
