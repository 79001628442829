import React from 'react'
import { Route } from 'react-router-dom'

import { SinglePanel } from '@/components/common/panel'
import AddUser from './user/add'
import EditUser from './user/edit'
import LeftMenu from './left-menu'
import style from './index.module.sass'
import { userManagementAddUrl, userManagementEditUrl } from '@/utils/url'
import { UserListContext } from './context'
import { useTitle } from '@/utils/hooks'
import { useGlobalContext } from '@/components/context'
import { useUserManagementListQuery } from './left-menu/__gen'


export const UserManagement = () => {
	const { user } = useGlobalContext()
	const apolloProps = useUserManagementListQuery({ skip: !user.topLevelManager })
	useTitle(`User management`)

	return (
		<UserListContext.Provider value={apolloProps.refetch}>
			<div className={style.container}>
				<LeftMenu {...apolloProps} />
				<SinglePanel type="thin">
					{user.topLevelManager ?
						<>
							<Route path={userManagementAddUrl()} component={AddUser} />
							<Route path={userManagementEditUrl(':userId')} component={EditUser} />
						</>
						: <div>You do not have access to this page</div>
					}
				</SinglePanel>
			</div>
		</UserListContext.Provider>
	)
}